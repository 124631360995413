import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { IndexedDBService } from '../services/indexed-db.service';
import { DatePipe } from '@angular/common';
import { EventEmitterService } from '../model/event-emitter.service'; 
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html',
  styleUrls: ['./header-inner.component.css']
})
export class HeaderInnerComponent implements OnInit {
  @Input() ImportSurveyList: any;
  surveyId: any;
  reportId!: any;
  closeResult!: string;
  pageTitle!: string;
  isNewSurveyButton:boolean=false;
  /*isRealDashboardPage:boolean=false;*/
  submitted = false;
  NotesMessage="";
  NotesForm = new FormGroup({
    note : new FormControl(),    
  });
  imageSrc!: string;
  selectedImage!:null;
  uploadForm = new FormGroup({
    image : new FormControl(),    
    image_note : new FormControl(),    
  });

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private _Activatedroute: ActivatedRoute, private IndexedDBService: IndexedDBService, public datepipe: DatePipe, private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    /***for set dynamic page title***/
    this._Activatedroute.data.subscribe(data => {
      this.pageTitle = data.title;
    });

    /***** for add new/notes button condition ****/
    var DashboardPages = ['dashboard', 'survey-import-list', 'setting'];
    var current_segment = window.location.pathname;
    var pagePath = current_segment.replace('/', '');
    if (!DashboardPages.includes(pagePath)) {
      this.isNewSurveyButton= false;
    }else{
      this.isNewSurveyButton=true;
    }

    /*
    var realDashboardPages = ['dashboard'];
    var current_segment = window.location.pathname;
    var pagePath = current_segment.replace('/', '');
    if (!realDashboardPages.includes(pagePath)) {
      this.isRealDashboardPage= false;
    }else{
      this.isRealDashboardPage=true;
    }
    */
   
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");

    this.NotesForm = this.formBuilder.group({      
      note: ['', Validators.required],      
    });

    this.uploadForm = this.formBuilder.group({      
      image: ['', Validators.required],      
      image_note: [''],      
    });

  }
  
  get NotesFormControl() {
    return this.NotesForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    let currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd HH:mm:ss');
    
    // let survey_id= this.NotesForm.controls.note.value;//this._Activatedroute.snapshot.paramMap.get("sid");
    // let surveyor_id=this.NotesForm.controls.note.value;//this._Activatedroute.snapshot.paramMap.get("rid");
    // let survey_format_version=this.NotesForm.controls.note.value;
    
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];

    if (this.NotesForm.valid) {
      const NotePayload = {
        note: this.NotesForm.controls.note.value,
        note_time: currentDateTime
      }
          
      this.IndexedDBService.addNotesFormData(NotePayload, surveyPayload).then((response: any) => {                   
        if(response) {
          Swal.fire('Added', 'Note added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            
          });
          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });    
      
    }
   // this.invalidLogin = true;     
  }
  

  get UploadFormControl() {
    return this.uploadForm.controls;
  }

  onFileChange(event:any) {
    const reader = new FileReader(); 
    this.imageSrc ='';  
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.selectedImage = event.target.files[0];
      reader.readAsDataURL(file);
      //reader.readAsBinaryString(file);
      reader.onload = () => {   
        this.imageSrc = reader.result as string;     
        this.uploadForm.patchValue({
          fileSource: reader.result
        });   
      };   
    }
  }


  onSubmitUpload() {
    this.submitted = true;
    //console.log(this.uploadForm);
    if (this.uploadForm.valid) {
      let currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd HH:mm:ss');
      const uploadPayload = {
        image: this.imageSrc,        
        image_time: currentDateTime,
        image_note: this.uploadForm.controls.image_note.value,
        image_type:'base64'      
      }
      
      let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];

      this.IndexedDBService.uploadImageFormData(uploadPayload, surveyPayload).then((response: any) => {                   
        if(response) {  
          Swal.fire('Uploaded', 'Image uploaded succesfully!', 'success');        
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            this.eventEmitterService.surveyImportData(getResponse.data); 
          });
          //this.uploadMessage='success'
          this.uploadForm.reset();
          this.uploadForm.markAsUntouched();
          this.submitted=false;

          this.modalService.dismissAll('Dismissed modal box after saving data');
        }else{
          //this.uploadMessage='error'
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });

    }
  }


  open(content: any) {  
    this.imageSrc=''; 
    this.NotesForm.reset(); 
    this.uploadForm.reset();   
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',
    beforeDismiss: () => {
      if (!this.NotesForm.pristine && !this.submitted) {
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false;     
        
      }else if(!this.uploadForm.pristine && !this.submitted){
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false;
      }else {                
        return true;
      }
    } 
   }).result.then((result) => {      
      this.closeResult = 'Closed with: ${result}';            
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
      //this.NotesForm.markAsPristine();
      this.NotesForm.markAsUntouched();
      this.uploadForm.markAsUntouched();
      this.submitted=false;     
    });
  }

  private getDismissReason(reason: any): string {    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }


  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {
    if (!this.NotesForm.pristine || !this.uploadForm.pristine) {
        $event.returnValue = true;
    }
  }

}
