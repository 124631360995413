import { Component } from '@angular/core';
import { Router, CanActivate  } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { IndexedDBService } from './services/indexed-db.service';
import { ApiService } from "./services/api.service";
import { AuthService } from './services/auth.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cube Survey';
  loggedIn: any;
  loginStatus=false;
  constructor(private authService: AuthService, public router: Router, private http: HttpClient, private IndexedDBService: IndexedDBService, private apiService: ApiService) {
     
  }
  

}
