import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { IndexedDBService } from '../services/indexed-db.service';
import { EventEmitterService } from '../model/event-emitter.service'; 
import Swal from 'sweetalert2';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {
  @Input() ImportSurveyList: any;
  @Input() currentTabTypeid: any;  
  @Input() loader: any;
  addTemplateForm = new FormGroup({});
  surveyId: any;
  reportId!: any;
  submittedTemplate = false;
  templateList:any;
  templateDetails:any;
  templateName = 'template_name';
  isLoadingResult: boolean = false;  
  selectedPlan='';
  submittedBasement=false;
  basementForm = new FormGroup({}); 
  areaFormFrom=new FormGroup({});
  loftForm = new FormGroup({});
  floorForm = new FormGroup({});

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private modalService: NgbModal, private _Activatedroute:ActivatedRoute, private eventEmitterService: EventEmitterService, private IndexedDBService: IndexedDBService) { }

  ngOnInit(): void {
    this.currentTabTypeid='from';
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");

    this.addTemplateForm = this.formBuilder.group({  
      template_id : new FormControl(''),
      template_name : new FormControl('', Validators.required)
    });

  }

  resetTemplateList(){    
    this.templateList=[];
  }

  get addTemplateFormControl() {
    return this.addTemplateForm.controls;
  }

  onChangeSearchTemplate(str: string){
    this.isLoadingResult = true;    
    this.IndexedDBService.getTemplateList()
    .then((data:any) => {       
      if(data!=undefined){
        this.templateList = data;
        this.isLoadingResult = false;
      }else{         
        // call if data not available in indexedDB
        this.apiService.getTemplateList().subscribe(  
          (res)=>{         
          console.log(res); 
          this.IndexedDBService
          .addTemplateList(res.data)
          //.then()  
          this.templateList = res.data; 
          this.isLoadingResult = false;            
        },
        (err)=>{  
          console.log("fail");      
        });
      }       
      
    });      
   
  }  

  selectEventTemplate(item: any) { 
    this.addTemplateForm.patchValue({
      template_id: item.template_id       
    });  
    
  }
  
  onFocusedTemplate(e:any){ 
    this.onChangeSearchTemplate(e);   
  }

  onClearedTemplate(e:any) {
    console.log("onClearedTemplate");
    //this.itemFormClear();
  }


  onSubmitTemplateForm() {    
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.addTemplateForm.valid) {
      this.submittedTemplate = true;
      const templateDetailsPayload = {
        template_id : this.addTemplateForm.controls.template_id.value                          
      }
      this.apiService.getTemplateDetails(templateDetailsPayload).subscribe(  
        (res)=>{         
        this.templateDetails = res.data;
        if(res.data!=null){
          const templatePayload = {
            plan : res.data.plan                           
          }
          this.IndexedDBService.addTemplateData(templatePayload, surveyPayload, 'from').then((response: any) => {                   
            if(response) {
              this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
                console.log(getResponse);                     
                this.eventEmitterService.surveyImportData(getResponse.data);          
                this.modalService.dismissAll('Dismissed modal box after saving data');
                //this.addTemplateForm.reset();
                this.addTemplateForm.patchValue({
                  template_id: '',
                  //survey_type: '',
                  template_name: '',
                }); 
                this.addTemplateForm.markAsUntouched();
                this.addTemplateForm.markAsPristine();
                this.submittedTemplate=false;           
                Swal.fire('Added', 'Template added succesfully!', 'success')
              });          
            }else{
              Swal.fire('Error', 'Template Not import', 'error');
              this.submittedTemplate=false;  
            } 
          });  
        }else{
          Swal.fire('Error', 'Template Details are not found', 'error');
          this.submittedTemplate=false;  
        }
      },
      (err)=>{  
        console.log("fail");
        this.submittedTemplate=false;        
      });
    }   
  }


  onPlanSelect(value:string){
    this.submittedBasement=false;  
    this.resetFormValues();
    this.selectedPlan=value;    
  }

  resetFormValues(){
    this.basementForm = this.formBuilder.group({            
      basement_name : ['', Validators.required],    
      basement_packing : [false],
      basement_ntg : ['TG', Validators.required],
      basement_seen : [true],
      basement_fully_lit : [false],
      basement_box_estimate : ['0'],
      move_light_fittings : [false],
      move_curtains_blinds : [false],
      move_carpets : [false]  
    });

    this.areaFormFrom = this.formBuilder.group({ 
      area_name : new FormControl('', Validators.required),
      area_packing : new FormControl(false),
      area_ntg : new FormControl('TG', Validators.required),
      area_seen : new FormControl(true),
      area_box_estimate : new FormControl('0'),
      move_carpets : new FormControl(false),
      move_curtains_blinds : new FormControl(false),
    });

    this.loftForm = this.formBuilder.group({      
      loft_name : ['', Validators.required],    
      loft_packing : [false],
      loft_ntg : ['TG', Validators.required],
      loft_seen : [true],
      loft_client_to_empty : [false],
      loft_fixed_ladders : [false],
      loft_fully_boarded : [false],
      loft_fully_lit : [false],
      loft_box_estimate : ['0'],
      move_light_fittings : [false],
      move_curtains_blinds : [false],
      move_carpets : [false]  
    });

    this.floorForm = this.formBuilder.group({      
      floor_name: ['', Validators.required]         
    });

  }

  get basementFormControl() {
    return this.basementForm.controls;
  }


  onSubmitBasement() {
    this.submittedBasement = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.basementForm.valid) {
      const BasementPayload = {
        basement_name : this.basementForm.controls.basement_name.value,    
        basement_packing : this.basementForm.controls.basement_packing.value,
        basement_ntg : this.basementForm.controls.basement_ntg.value,
        basement_seen : this.basementForm.controls.basement_seen.value,
        basement_fully_lit : this.basementForm.controls.basement_fully_lit.value,
        basement_box_estimate : this.basementForm.controls.basement_box_estimate.value,
        move_light_fittings : this.basementForm.controls.move_light_fittings.value,
        move_curtains_blinds : this.basementForm.controls.move_curtains_blinds.value,
        move_carpets : this.basementForm.controls.move_carpets.value,
        packing_materials:{
          "small": 0,
          "med": 0,
          "large": 0,
          "layflat": 0,	
          "wardrobe": 0,	
          "picturepack": 0,	
          "golfbox": 0,	
          "bikebox": 0,	
          "mattressbagsingle": 0,	
          "mattressbagdouble": 0,	
          "mattressbagking": 0,	
          "mattressbagsuperking": 0,	
          "tvbox24": 0,	
          "tvbox32": 0,	
          "tvbox36": 0,	
          "tvbox43": 0,	
          "tvbox48": 0,
          "tvbox55": 0,	
          "tvbox70": 0,	
          "tvbox85": 0
        },
        inventory:[]       
      }
       console.log(this.currentTabTypeid);
      
      this.IndexedDBService.addBasementFormData(BasementPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Added', 'Basement added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            this.resetFormValues();
            this.basementForm.markAsUntouched();
            this.submittedBasement=false; 
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }

  get areaFormFromControl() {
    return this.areaFormFrom.controls;
  }

  onSubmitAreaFrom() {
    this.submittedBasement = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.areaFormFrom.valid) {
      const AreaPayload = {
        area_name : this.areaFormFrom.controls.area_name.value,
        area_packing : this.areaFormFrom.controls.area_packing.value,
        area_ntg : this.areaFormFrom.controls.area_ntg.value,
        area_seen : this.areaFormFrom.controls.area_seen.value,
        area_box_estimate : this.areaFormFrom.controls.area_box_estimate.value,
        move_carpets : this.areaFormFrom.controls.move_carpets.value,
        move_curtains_blinds : this.areaFormFrom.controls.move_curtains_blinds.value,
        packing_materials:{
          "small": 0,
          "med": 0,
          "large": 0,
          "layflat": 0,	
          "wardrobe": 0,	
          "picturepack": 0,	
          "golfbox": 0,	
          "bikebox": 0,	
          "mattressbagsingle": 0,	
          "mattressbagdouble": 0,	
          "mattressbagking": 0,	
          "mattressbagsuperking": 0,	
          "tvbox24": 0,	
          "tvbox32": 0,	
          "tvbox36": 0,	
          "tvbox43": 0,	
          "tvbox48": 0,
          "tvbox55": 0,	
          "tvbox70": 0,	
          "tvbox85": 0
        },
        inventory:[]                    
      }
       
      this.IndexedDBService.addAreaFormData(AreaPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Added', 'Room added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            console.log(getResponse);                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            this.resetFormValues();
            this.areaFormFrom.markAsUntouched();
            this.submittedBasement=false;           
              
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });  
      
    }   
  }


  get loftFormControl() {
    return this.loftForm.controls;
  }


  onSubmitLoft() {
    this.submittedBasement = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
   
    if (this.loftForm.valid) {
      const LoftPayload = {
        loft_name : this.loftForm.controls.loft_name.value,    
        loft_packing : this.loftForm.controls.loft_packing.value,
        loft_ntg : this.loftForm.controls.loft_ntg.value,
        loft_seen : this.loftForm.controls.loft_seen.value,
        loft_client_to_empty : this.loftForm.controls.loft_client_to_empty.value,
        loft_fixed_ladders : this.loftForm.controls.loft_fixed_ladders.value,
        loft_fully_boarded : this.loftForm.controls.loft_fully_boarded.value,
        loft_fully_lit : this.loftForm.controls.loft_fully_lit.value,
        loft_box_estimate : this.loftForm.controls.loft_box_estimate.value,
        move_light_fittings : this.loftForm.controls.move_light_fittings.value,
        move_curtains_blinds : this.loftForm.controls.move_curtains_blinds.value,
        move_carpets : this.loftForm.controls.move_carpets.value,
        packing_materials:{
          "small": 0,
          "med": 0,
          "large": 0,
          "layflat": 0,	
          "wardrobe": 0,	
          "picturepack": 0,	
          "golfbox": 0,	
          "bikebox": 0,	
          "mattressbagsingle": 0,	
          "mattressbagdouble": 0,	
          "mattressbagking": 0,	
          "mattressbagsuperking": 0,	
          "tvbox24": 0,	
          "tvbox32": 0,	
          "tvbox36": 0,	
          "tvbox43": 0,	
          "tvbox48": 0,
          "tvbox55": 0,	
          "tvbox70": 0,	
          "tvbox85": 0
        },
        inventory:[]       
      }
       
      this.IndexedDBService.addloftFormData(LoftPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Added', 'Loft added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            this.resetFormValues();
            this.loftForm.markAsUntouched();
            this.submittedBasement=false;  
                    
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }


  get floorFormControl() {
    return this.floorForm.controls;
  }

  onSubmitAddFloor() {
    this.submittedBasement = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    console.log(this.floorForm);
    if (this.floorForm.valid) {
      const FloorPayload = {
        floor_name : this.floorForm.controls.floor_name.value,
        floor_rooms:[]            
      }
       
      this.IndexedDBService.addFloorFormData(FloorPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Added', 'Floor added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
                               
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            this.resetFormValues();
            this.floorForm.markAsUntouched();
            this.submittedBasement=false;           
              
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   
  }


}
