import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { IndexedDBService } from '../services/indexed-db.service';
import { EventEmitterService } from '../model/event-emitter.service'; 
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  @Input() ImportSurveyList: any;
  @Input() loader: any;
  private _album: Array<any> = [];
  surveyId: any;
  reportId!: any;
  closeResult!: string;
  userToken:any;
  submitted = false;
  submittedNotes= false;
  imageSrc!: string;
  selectedImage!:null;
  uploadMessage="";
  uploadForm = new FormGroup({
    image : new FormControl(),    
    image_note : new FormControl(),    
  });
  NotesForm = new FormGroup({});
  imageIndex!:number;
  editNotesSubmitted=false;
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private _Activatedroute: ActivatedRoute, private IndexedDBService: IndexedDBService, public datepipe: DatePipe, private eventEmitterService: EventEmitterService, private _lightbox: Lightbox) { 

    /* get token for image */
    if (window.localStorage.getItem('token')) {
      this.userToken= window.localStorage.getItem('token');
    }
    
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({      
      image: ['', Validators.required],      
      image_note: [''],      
    });


    this.NotesForm = this.formBuilder.group({      
      image_note: ['', Validators.required],      
      imageIndex: ['', Validators.required],      
    });


    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");
    
    //setTimeout(()=>{                     
      if(this.ImportSurveyList.images!=null){
        this.galleryUrlModal(this.ImportSurveyList.images);
      }      
   // }, 2000);
    
  }

  
  get UploadFormControl() {
    return this.uploadForm.controls;
  }

  onFileChange(event:any) {
    const reader = new FileReader();  
    this.imageSrc =''; 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.selectedImage = event.target.files[0];
      reader.readAsDataURL(file);
      //reader.readAsBinaryString(file);
      reader.onload = () => {   
        this.imageSrc = reader.result as string;     
        this.uploadForm.patchValue({
          fileSource: reader.result
        });   
      };   
    }
  }


  onSubmit() {
    this.submitted = true;
    //console.log(this.uploadForm);
    if (this.uploadForm.valid) {
      let currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd HH:mm:ss');
      const uploadPayload = {
        image: this.imageSrc,        
        image_time: currentDateTime,
        image_note: this.uploadForm.controls.image_note.value,
        image_type:'base64'      
      }
      
      let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
      
      this.IndexedDBService.uploadImageFormData(uploadPayload, surveyPayload).then((response: any) => {                   
        if(response) {          
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            this.eventEmitterService.surveyImportData(getResponse.data); 
          });
          //this.uploadMessage='success'
          this.uploadForm.reset();
          this.uploadForm.markAsUntouched();
          this.submitted=false;
          this.imageSrc='';
          Swal.fire('Added', 'Image uploaded succesfully!', 'success')
        }else{
          //this.uploadMessage='error'
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });

    }
  }


  deleteImage(imageIndex:any){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove it!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {       

        let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
        this.IndexedDBService.deleteImageGallery(imageIndex, surveyPayload).then((response: any) => {                   
          if(response) {
            swalWithBootstrapButtons.fire('Deleted!', 'Image has been deleted.', 'success')
            this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
              console.log(getResponse);                     
              this.eventEmitterService.surveyImportData(getResponse.data);
            });
            
          }else{
            Swal.fire('Error', 'Something went wrong!', 'error');
          }

        });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })    
      
  }

 galleryUrlModal(imageData:any){ 
   this._album=[];  
    if(imageData.length>0){    
      imageData.forEach((_childObj: any)=> {
        let src='';
        if(!_childObj.image_type){          
          src = _childObj.image+"&token="+this.userToken;
        }else{
          src = _childObj.image;
        }
        const caption = _childObj.image_note;          
        const album = {
          src: src,
          caption: caption            
        };

        this._album.push(album);               
     });
    
    }
  }


  openGalleryModal(index: number): void {
    // open lightbox
    this.galleryUrlModal(this.ImportSurveyList.images);
    console.log(this._album);
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  open(content: any) {   
    this.NotesForm.reset();    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',
    beforeDismiss: () => {
      if (!this.NotesForm.pristine && !this.submittedNotes) {
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false; 
      }else {                
        return true;
      }
    } 
   }).result.then((result) => {      
      this.closeResult = 'Closed with: ${result}';            
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
      //this.NotesForm.markAsPristine();
      this.NotesForm.markAsUntouched(); 
      this.submittedNotes=false; 
     // this.editNotesSubmitted=false;         
    });
  }


  get NotesFormControl() {
    return this.NotesForm.controls;
  }

  setEditNoteValue(image_index:any, note_text:any){
    this.imageIndex=image_index;
    this.NotesForm.patchValue({
      imageIndex: image_index,
      image_note: note_text,
    }); 
  }


  editNote(){   
    this.submittedNotes=true;
    let currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd HH:mm:ss');
    let imageIndex=this.NotesForm.controls.imageIndex.value;
    const uploadPayload = {
      image_note: this.NotesForm.controls.image_note.value, 
      image_time: currentDateTime    
    }
    console.log(uploadPayload);
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    this.IndexedDBService.editImageNote(uploadPayload, surveyPayload, imageIndex).then((response: any) => {                   
      if(response) {
        //Swal.fire('Updated', 'Basement updated succesfully!', 'success')
        this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                              
          this.eventEmitterService.surveyImportData(getResponse.data);
          this.modalService.dismissAll('Dismissed modal box after saving data');
              this.submittedNotes=false;
        });
        
      }else{
        Swal.fire('Error', 'Something went wrong!', 'error');
      }
     // this.editNotesSubmitted=false;
    }); 
      
  }


  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {      
    if (!this.NotesForm.pristine) {
        $event.returnValue = true;
    }
  }

  
}
