<app-sidebar></app-sidebar>

<app-header-inner></app-header-inner>

<div class="table_list settings mt-4">
  <div class="row">
    <div class="col-lg-6 d-lg-flex align-items-lg-stretch my-2">
      <div class="card">
        <div class="card-body">
          <h3><i class="fas fa-cloud-download-alt"></i> Library Update</h3>
          <p><em>Here you can update the catalogue library, property templates and other important settings.</em></p>
          <div class="catlog-box-btn text-center">
            <p class="text-info" *ngIf="submittedItem"><i class="fas fa-spinner"></i></p>
            <p class="text-success" *ngIf="listUpdatedMessage!==''">
              {{listUpdatedMessage}}
            </p>
            <button class="btn text-white" style="background-color: #3B2C67;" (click)="latestListDownload()"><i
                class="fas fa-cloud-download-alt"></i> Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 d-lg-flex align-items-lg-stretch my-2">
      <div class="card">
        <div class="card-body">
          <h3><i class="fas fa-ruler"></i> Measurement units</h3>
          <p><em>Here you can set your preffered measuement units.</em></p>
          <form [formGroup]="measurementUnitsForm" (ngSubmit)="onSubmitUnits()">
            <div class="row">
              <div class="col-6">
                <div class="measurement-div">
                  <label>Preferred linear measurement units</label>
                  <select class="form-control form-control-custom" formControlName="linear_units">
                    <option value="cm">Cm</option>
                    <option value="inch">Inch</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="measurement-div">
                  <label>Preferred cubic measurement units</label>
                  <select class="form-control form-control-custom" formControlName="cubic_units">
                    <option value="cm">Cm</option>
                    <option value="ft">Ft</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="catlog-box-btn text-center mt-3">
              <p class="text-info" *ngIf="submittedUnits"><i class="fas fa-spinner"></i></p>
              <p class="text-success" *ngIf="unitUpdatedMessage!==''">
                {{unitUpdatedMessage}}
              </p>
              <button type="submit" class="btn my-2 my-sm-0 text-white" style="background-color: #3B2C67;"><i class="fas fa-save"></i> Save
                Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- BJW commented out below becasue i dont know what it does -->
<!--
<ng-template #packingmodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form>
      <label for="exampleInputEmail1"><b>Name</b></label>
      <input type="text" class="form-control mb-3" id="username" placeholder="Name">

      <label for="exampleInputEmail1"><b>Size</b></label>
      <input type="text" class="form-control mb-3" id="username" placeholder="Size">

      <label for="exampleInputEmail1"><b>Volume</b></label>
      <input type="text" class="form-control mb-3" id="username" placeholder="Volume">
    </form>
  </div>
  <div class="modal-footer border-0 sumit_popup">
    <button type="button" class="btn border-0 text-white pl-4 pr-4" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>
-->