<div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

<div *ngIf="ImportSurveyList!='' && !loader">
    <h6 class="mb-4"><u></u></h6>
    <section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
      <div class="tab-content">
        <form (ngSubmit)="onSubmitOverviewUpdate(overviewUpdateForm)" #overviewUpdateForm="ngForm">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Survey Name</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.survey_name }}"
                  placeholder="Survey Name" autocomplete="off" name="survey_name" [ngModel]="ImportSurveyList.survey_name" #survey_name="ngModel">
               
              </div>
            </div>
            <div class="col">
              <div class="calendar-box position-relative">
              <div class="form-group">
                <label for="exampleInputSurvey">Survey Date</label>
                <input type="hidden" value="{{surveyDate}}" name="survey_date" [(ngModel)]="surveyDate" #survey_date="ngModel" />
                <input class="form-control" placeholder="yyyy-mm-dd" name="survey_datePicker" value="{{ImportSurveyList.survey_date!='' ? ImportSurveyList.survey_date : todayDate }}" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateChange($event)" (click)="d.toggle()" autocomplete="off" >
               
                  <span class="date-span"(click)="d.toggle()" ><img src="../assets/img/calendar.png" alt="calender" style="max-width:23px;"></span>
                 
              </div>
            </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Survey Time</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.survey_time }}"
                  placeholder="Survey Time" autocomplete="off" name="survey_time" [(ngModel)]="ImportSurveyList.survey_time" #survey_time="ngModel">
                  
              </div>
            </div>
          </div>

          <div class="row">
            <!-- <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Survey Type</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.survey_type }}"
                  placeholder="Survey Type" autocomplete="off">
              </div>
            </div> -->
            
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Proposed Move Date</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.proposed_move_date }}"
                  placeholder="Proposed Move Date" autocomplete="off" name="proposed_move_date" [(ngModel)]="ImportSurveyList.proposed_move_date" #proposed_move_date="ngModel" >
               
              </div>
            </div>
            <div class="col">
              <div class="form-group stateownerageent">
                <label for="exampleInputSurvey" class="w-100 mb-4">Is storage required?</label>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="move_requires_storage"
                      [checked]="ImportSurveyList.move_requires_storage=='Yes'" [(ngModel)]="ImportSurveyList.move_requires_storage" value="Yes">Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="move_requires_storage"
                      [checked]="ImportSurveyList.move_requires_storage=='No' || ImportSurveyList.move_requires_storage==null" [(ngModel)]="ImportSurveyList.move_requires_storage" value="No">No
                  </label>
                </div>
              </div>
            </div>
            <div class="col">
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label for="exampleInputSurvey">Format Version</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.survey_format_version }}"
                  placeholder="Format Version" autocomplete="off">
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Storage Requirements</label>
                <textarea row="10" class="w-100 d-inline-block p-2"
                  value="{{ ImportSurveyList.storage_requirements }}" placeholder="Please provide details on storage requirements"
                  autocomplete="off" name="storage_requirements" [(ngModel)]="ImportSurveyList.storage_requirements" #storage_requirements="ngModel" ></textarea>

               
              </div>
            </div>
          </div>

          <div class="row">
            <h4 class="w-100 d-inline-block p-2">Primary Contact</h4>
            <div class="col"> 
            
              <div class="form-group">
                <label for="exampleInputSurvey">Title</label>
                <select class="form-control form-control-custom" id="title_primary_contact" name="contact_primary_title" [(ngModel)]="ImportSurveyList.contact_primary.title"
                #contact_primary_title="ngModel" >
                  <option value="null" [selected]="(ImportSurveyList.contact_primary.title==null) ? true:false">Select</option>
                  <option [selected]="(ImportSurveyList.contact_primary.title)=='Mr' ? true:false" >Mr</option>
                  <option [selected]="(ImportSurveyList.contact_primary.title)=='Ms' ? true:false" >Ms</option>
                  <option [selected]="(ImportSurveyList.contact_primary.title)=='Mrs' ? true:false">Mrs</option>
                  <option [selected]="(ImportSurveyList.contact_primary.title)=='Dr' ? true:false">Dr</option>
                  <option [selected]="(ImportSurveyList.contact_primary.title)=='Prof' ? true:false">Prof</option>
                </select>
                
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">First Name</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.contact_primary.first_name }}"
                  placeholder="First Name" autocomplete="off" name="first_name_p" [(ngModel)]="ImportSurveyList.contact_primary.first_name" #first_name_p="ngModel" >
               
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Last Name</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.contact_primary.last_name }}"
                  placeholder="Last Name" autocomplete="off" name="last_name_p" [(ngModel)]="ImportSurveyList.contact_primary.last_name" #last_name_p="ngModel" >
                
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Telephone</label>
                <input type="number" class="form-control" value="{{ ImportSurveyList.contact_primary.tel }}"
                  placeholder="Telephone" autocomplete="off" name="tel_p" [(ngModel)]="ImportSurveyList.contact_primary.tel" #tel_p="ngModel" >
               
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Mobile</label>
                <input type="number" class="form-control" value="{{ ImportSurveyList.contact_primary.mob }}"
                  placeholder="Mobile" autocomplete="off" name="mob_p" [(ngModel)]="ImportSurveyList.contact_primary.mob" #mob_p="ngModel"
                  >
                
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Email</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.contact_primary.email }}"
                  placeholder="Email" autocomplete="off" name="email_p" [(ngModel)]="ImportSurveyList.contact_primary.email" #email_p="ngModel" >
               
              </div>
            </div>
          </div>

          <div class="row">
            <h4 class="w-100 d-inline-block p-2">Secondary Contact</h4>
            <div class="col">             
              <div class="form-group">
                <label for="exampleInputSurvey">Title</label>
                <select class="form-control form-control-custom" id="title_secondary_contact" name="contact_secondary_title" [(ngModel)]="ImportSurveyList.contact_secondary.title"
                #contact_secondary_title="ngModel" >
                  <option value="null" [selected]="(ImportSurveyList.contact_secondary.title==null) ? true:false">Select</option>
                  <option [selected]="(ImportSurveyList.contact_secondary.title)=='Mr' ? true:false" >Mr</option>
                  <option [selected]="(ImportSurveyList.contact_secondary.title)=='Ms' ? true:false" >Ms</option>
                  <option [selected]="(ImportSurveyList.contact_secondary.title)=='Mrs' ? true:false">Mrs</option>
                  <option [selected]="(ImportSurveyList.contact_secondary.title)=='Dr' ? true:false">Dr</option>
                  <option [selected]="(ImportSurveyList.contact_secondary.title)=='Prof' ? true:false">Prof</option>
                </select>
               
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">First Name</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.contact_secondary.first_name }}"
                  placeholder="First Name" autocomplete="off" name="first_name_s" [(ngModel)]="ImportSurveyList.contact_secondary.first_name" #first_name_s="ngModel" >
               
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Last Name</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.contact_secondary.last_name }}"
                  placeholder="Last Name" autocomplete="off" name="last_name_s" [(ngModel)]="ImportSurveyList.contact_secondary.last_name" #last_name_s="ngModel" >
               
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Telephone</label>
                <input type="number" class="form-control" value="{{ ImportSurveyList.contact_secondary.tel }}"
                  placeholder="Telephone" autocomplete="off" name="tel_s" [(ngModel)]="ImportSurveyList.contact_secondary.tel" #tel_s="ngModel" >
              
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Mobile</label>
                <input type="number" class="form-control" value="{{ ImportSurveyList.contact_secondary.mob }}"
                  placeholder="Mobile" autocomplete="off" name="mob_s" [(ngModel)]="ImportSurveyList.contact_secondary.mob" #mob_s="ngModel" >
               
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="exampleInputSurvey">Email</label>
                <input type="text" class="form-control" value="{{ ImportSurveyList.contact_secondary.email }}"
                  placeholder="Email" autocomplete="off" name="email_s" [(ngModel)]="ImportSurveyList.contact_secondary.email" #email_s="ngModel" >
               
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary mt-4"><i class="far fa-save"></i>  Save Changes</button>
          <!--
          <div class="modal-footer border-0 sumit_popup">
            <button type="submit" class="btn text-white"><i class="far fa-save"></i> Save Changes</button>
          </div>
          -->
        </form>  
      </div>
    </section>
  </div>