<section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
  <nav class="mb-4">
    <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'from' ? 'active' : ''}}" id="nav-overview-tab"
        data-toggle="tab" href="#nav-from-basement" role="tab" aria-controls="nav-Info" aria-selected="true"
        (click)="tabTypeClickFunction('from');">From</a>
      <!-- <a class="nav-item nav-link border-0 {{currentTabTypeid == 'to' ? 'active' : ''}}" id="nav-profile-tab"
        data-toggle="tab" href="#nav-to-basement" role="tab" aria-controls="nav-Parking" aria-selected="false"
        (click)="tabTypeClickFunction('to');">To
      </a> -->
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>
    <div class="tab-pane fade {{currentTabTypeid == 'from' ? 'show active' : ''}}" id="nav-from-basement"
      role="tabpanel" aria-labelledby="nav-overview-tab" *ngIf="ImportSurveyList.property?.from">
      <p class="text-center"
        *ngIf="(ImportSurveyList.property?.from.plan.basements?.length <=0) && !loader">No basements added!</p>

      <span class="w-100 d-inline-block">
        <button class="btn btn-primary border-0 text-white float-right font-weight-bold"
          (click)="open(addBasementModal)">Add Basement</button>
      </span>


      <div id="accordion" role="tablist" class="o-accordion"
        *ngIf="(ImportSurveyList.property?.from.plan.basements?.length > 0)">
        <div class="card mt-2 mb-2"
          *ngFor="let basement of ImportSurveyList.property.from.plan.basements; let basementRow=index;">
          <div role="tab" id="Basementinner{{basementRow}}" class="card-header basement-tab-sec">
            <h5 class="mb-0"><a data-toggle="collapse" data-parent="#accordion"
                href="#collapseBasement_fb_{{basementRow}}" aria-expanded="false"
                [attr.aria-controls]="'collapseBasement_fb_'+basementRow"
                class="w-100 d-inline-block floor-accordion collapsed">{{
                basement.basement_name }} <i class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
              </a>
              <span class="delete-icon" (click)="deleteBasement(basementRow)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
            </h5>
          </div>
          <div id="collapseBasement_fb_{{basementRow}}" role="tabpanel"
            [attr.aria-labelledby]="'Basementinner'+basementRow" class="plan-content-section collapse">
            <div class="card-block p-4">
              <form (ngSubmit)="onSubmitBasementUpdate(basementUpdateForm)" #basementUpdateForm="ngForm">
                <input type="hidden" [ngModel]="basementRow" name="basement_row" />
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group test1">
                      <label for="exampleInputSurvey">Basement Name</label>
                      <input type="text" class="form-control" placeholder="Basement Name" autocomplete="off"
                        value="{{ basement.basement_name }}" name="basement_name" [ngModel]="basement.basement_name"
                        #basement_name="ngModel" required>

                      <div class="text-danger" *ngIf="!basement_name.value">
                        Basement Name is Required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group ">
                      <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving the contents of this
                        basement?</label>
                      <select class="form-control form-control-custom" name="basement_ntg"
                        [ngModel]="basement.basement_ntg" #basement_ntg="ngModel" required>
                        <option value="" [selected]="(basement.basement_ntg=='') ? true:false">Select</option>
                        <option value="TG" [selected]="(basement.basement_ntg=='TG') ? true:false">To Go</option>
                        <option value="NTG" [selected]="(basement.basement_ntg=='NTG') ? true:false">Not To Go</option>
                        <option value="MTG" [selected]="(basement.basement_ntg=='MTG') ? true:false">Maybe To Go
                        </option>
                      </select>
                      <div class="text-danger" *ngIf="!basement_ntg.value">
                        Basement NTG is Required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group ">
                      <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this
                        basement </label>
                      <input type="number" class="form-control" placeholder="Basement Box Estimate" min="0"
                        autocomplete="off" value="{{ basement.basement_box_estimate }}" name="basement_box_estimate"
                        [ngModel]="basement.basement_box_estimate" #basement_box_estimate="ngModel">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this basement?</label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_packing==true"
                              name="basement_packing" [value]=true [(ngModel)]="basement.basement_packing">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_packing==false"
                              name="basement_packing" [value]=false [(ngModel)]="basement.basement_packing">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Have we been able to see the basement? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_seen==true"
                              name="basement_seen" [value]=true [(ngModel)]="basement.basement_seen">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_seen==false"
                              name="basement_seen" [value]=false [(ngModel)]="basement.basement_seen">No
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">

                      <label for="exampleInputSurvey" class="mb-4">Are we moving the light fittings in this basement?
                      </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_light_fittings==true"
                              name="move_light_fittings" [value]=true [(ngModel)]="basement.move_light_fittings">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_light_fittings==false"
                              name="move_light_fittings" [value]=false [(ngModel)]="basement.move_light_fittings">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="row">

                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this
                        basement? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_carpets==true"
                              name="move_carpets" [value]=true [(ngModel)]="basement.move_carpets">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_carpets==false"
                              name="move_carpets" [value]=false [(ngModel)]="basement.move_carpets">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains/blinds in this
                        basement? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_curtains_blinds==true"
                              name="move_curtains_blinds" [value]=true [(ngModel)]="basement.move_curtains_blinds">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input"
                              [checked]="basement.move_curtains_blinds==false" name="move_curtains_blinds" [value]=false
                              [(ngModel)]="basement.move_curtains_blinds">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Is this basement fully lit? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_fully_lit==true"
                              name="basement_fully_lit" [value]=true [(ngModel)]="basement.basement_fully_lit">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_fully_lit==false"
                              name="basement_fully_lit" [value]=false [(ngModel)]="basement.basement_fully_lit">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer border-0 sumit_popup">
                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                </div>
              </form>

              <div id="accordion" role="tablist" class="o-accordion">
                <!----packing material start-->
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingOne_p">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                        data-parent="#accordion" href="#collapseOne_p{{basementRow}}" aria-expanded="false"
                        aria-controls="collapseOne">Packing Materials <i class="fa fa-chevron-down arrow-down"
                          aria-hidden="true"></i>
                      </a>
                    </h5>
                  </div>

                  <div id="collapseOne_p{{basementRow}}" class="collapse" role="tabpanel"
                    [attr.aria-labelledby]="'collapseOne_p'+basementRow">
                    <div class="card-block p-4">

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Small</label>
                            <input type="number" class="form-control" placeholder="Small" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.small }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Medium</label>
                            <input type="number" class="form-control" placeholder="Medium" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.med }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Large</label>
                            <input type="number" class="form-control" placeholder="Large" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.large }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Layflat</label>
                            <input type="number" class="form-control" placeholder="Layflat" autocomplete="off"
                              value="{{ basement.packing_materials?.layflat }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Wardrobe</label>
                            <input type="number" class="form-control" placeholder="Wardrobe" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.wardrobe }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Picture Pack</label>
                            <input type="number" class="form-control" placeholder="Picture Pack" min="0"
                              autocomplete="off" value="{{ basement.packing_materials?.picturepack }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Golf Box</label>
                            <input type="number" class="form-control" placeholder="Golf Box" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.golfbox }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Bike Box</label>
                            <input type="number" class="form-control" placeholder="Bike Box" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.bikebox }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Single</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag Single" min="0"
                              autocomplete="off" value="{{ basement.packing_materials?.mattressbagsingle }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Double</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag Double" min="0"
                              autocomplete="off" value="{{ basement.packing_materials?.mattressbagdouble }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag King</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag King" min="0"
                              autocomplete="off" value="{{ basement.packing_materials?.mattressbagking }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Super King</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag Super King" min="0"
                              autocomplete="off" value="{{ basement.packing_materials?.mattressbagsuperking }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 24</label>
                            <input type="number" class="form-control" placeholder="Tv Box 24" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox24 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 32</label>
                            <input type="number" class="form-control" placeholder="Tv Box 32" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox32 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 36</label>
                            <input type="number" class="form-control" placeholder="Tv Box 36" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox36 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 43</label>
                            <input type="number" class="form-control" placeholder="Tv Box 43" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox43 }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 48</label>
                            <input type="number" class="form-control" placeholder="Tv Box 48" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox48 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 55</label>
                            <input type="number" class="form-control" placeholder="Tv Box 55" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox55 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 70</label>
                            <input type="number" class="form-control" placeholder="Tv Box 70" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox70 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 85</label>
                            <input type="number" class="form-control" placeholder="Tv Box 85" min="0" autocomplete="off"
                              value="{{ basement.packing_materials?.tvbox85 }}">
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
                <!----packing material end-->

                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingTwo_fb_">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                        data-parent="#accordion" href="#collapseTwo_fb_{{basementRow}}" aria-expanded="false"
                        [attr.aria-controls]="'collapseTwo_fb_'+basementRow"> Inventory <i class="fa fa-chevron-down arrow-down"
                          aria-hidden="true"></i>
                      </a>
                      <!-- <span class="delete-icon">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </span> -->
                    </h5>
                  </div>
                  <div id="collapseTwo_fb_{{basementRow}}" class="collapse" role="tabpanel"
                    [attr.aria-labelledby]="'headingTwo_fb_'+basementRow">
                    <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">

                      <p class="text-center mt-4"
                        *ngIf="basement?.inventory==''|| basement?.inventory==null">No Inventory Items Added</p>

                      <span class="w-100 d-inline-block"><button
                          class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold"
                          (click)="open(inventoryModal); setBasementIndex(basementRow)">Add Item</button></span>

                      <div *ngIf="basement?.inventory!='' || basement?.inventory!=null">
                        <div class="card multi  mt-0 mb-3 ml-3 mr-3 "
                          *ngFor="let BasementInventory of basement.inventory; let inventoryRow=index">
                          <div class="card-header" role="tab" id="headingOne-1">
                            <h5 class="mb-0">
                              <a class="w-100 floor-accordion d-inline-block collapsed" data-toggle="collapse"
                                data-parent="#accordion" href="#collapseOneinnner_fb_{{basementRow}}{{inventoryRow}}"
                                aria-expanded="true"
                                [attr.aria-controls]="'collapseOneinnner_fb_'+basementRow+inventoryRow">
                                {{BasementInventory.item_name}} <i class="fa fa-chevron-down arrow-down mx-3"
                                  aria-hidden="true"></i>
                              </a>
                              <span class="delete-icon" (click)="deleteInventoryItem(basementRow, inventoryRow)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </h5>
                          </div>

                          <div id="collapseOneinnner_fb_{{basementRow}}{{inventoryRow}}" class="collapse"
                            role="tabpanel" aria-labelledby="headingOne-1">
                            <div class="card-block p-4">
                              <form (ngSubmit)="onSubmitInventoryUpdate(inventoryUpdateForm)"
                                #inventoryUpdateForm="ngForm">
                                <input type="hidden" name="basement_row" [ngModel]="basementRow" />
                                <input type="hidden" name="inventory_row" [ngModel]="inventoryRow" />
                                <input type="hidden" name="item_name" [ngModel]="BasementInventory.item_name"
                                  #item_name="ngModel">
                                <input type="hidden" value="{{BasementInventory.item_id}}" name="item_id"
                                  [(ngModel)]="BasementInventory.item_id">
                                <input type="hidden" value="{{BasementInventory.type_id}}" name="type_id"
                                  [(ngModel)]="BasementInventory.type_id">
                                <div class="row inventory-item-row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Name</label>
                                      <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.item_name"></a>
                                      </ng-template>
                                      <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                      </ng-template>
                                      <ng-autocomplete [data]="itemList" [searchKeyword]="keyword"
                                        (inputChanged)='onChangeSearch($event)'
                                        (selected)='selectEventItem($event, basementRow,inventoryRow)'
                                        (inputCleared)="onClearedItem($event, basementRow,inventoryRow)"
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                        placeholder="Item Name" [isLoading]="isLoadingResult" [minQueryLength]="1"
                                        name="item_name_auto" [ngModel]="BasementInventory.item_name"
                                        #item_name_auto="ngModel" required>
                                      </ng-autocomplete>

                                      <div class="text-danger" *ngIf="!item_name_auto.value">
                                        Item name is required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item?</label>
                                      <select class="form-control form-control-custom" name="item_to_go"
                                        [ngModel]="BasementInventory.item_to_go" #item_to_go="ngModel" required>
                                        <option value="" [selected]="(BasementInventory.item_to_go=='') ? true:false">
                                          Select</option>
                                        <option value="TG"
                                          [selected]="(BasementInventory.item_to_go=='TG') ? true:false">Yes: To Go</option>
                                        <option value="NTG"
                                          [selected]="(BasementInventory.item_to_go=='NTG') ? true:false">NO: Not To Go
                                        </option>
                                        <option value="MTG"
                                          [selected]="(BasementInventory.item_to_go=='MTG') ? true:false">Not sure: Maybe To Go
                                        </option>
                                      </select>
                                      <div class="text-danger" *ngIf="!item_to_go.value">
                                        This field is required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Width Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Width Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_width_cm }}"
                                        name="item_width_cm" [(ngModel)]="BasementInventory.item_width_cm"
                                        #item_width_cm="ngModel" ng-required="defaultUnits?.linear_units=='cm'">

                                      <div class="text-danger" *ngIf="item_width_cm.value==undefined">
                                        Width is required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Height Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Height Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_height_cm }}"
                                        name="item_height_cm" [(ngModel)]="BasementInventory.item_height_cm"
                                        #item_height_cm="ngModel" ng-required="defaultUnits?.linear_units=='cm'">

                                      <div class="text-danger" *ngIf="item_height_cm.value==undefined">
                                        Height is required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Depth Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Depth Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_depth_cm }}"
                                        name="item_depth_cm" [(ngModel)]="BasementInventory.item_depth_cm"
                                        #item_depth_cm="ngModel" ng-required="defaultUnits?.linear_units=='cm'">

                                      <div class="text-danger" *ngIf="item_depth_cm.value==undefined">
                                        Depth is required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Width Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Width Inch"
                                        autocomplete="off" value="{{ BasementInventory.item_width_inch }}"
                                        name="item_width_inch" [(ngModel)]="BasementInventory.item_width_inch"
                                        #item_width_inch="ngModel" ng-required="defaultUnits?.linear_units=='inch'">

                                      <div class="text-danger" *ngIf="item_width_inch.value==undefined">
                                        Width is required
                                      </div>
                                    </div>
                                  </div>


                                  <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Height Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Height Inch"
                                        autocomplete="off" value="{{ BasementInventory.item_height_inch }}"
                                        name="item_height_inch" [(ngModel)]="BasementInventory.item_height_inch"
                                        #item_height_inch="ngModel" ng-required="defaultUnits?.linear_units=='inch'">

                                      <div class="text-danger" *ngIf="item_height_inch.value==undefined">
                                        Height is required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Depth Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Depth Inch"
                                        autocomplete="off" value="{{ BasementInventory.item_depth_inch }}"
                                        name="item_depth_inch" [(ngModel)]="BasementInventory.item_depth_inch"
                                        #item_depth_inch="ngModel" ng-required="defaultUnits?.linear_units=='inch'">

                                      <div class="text-danger" *ngIf="item_depth_inch.value==undefined">
                                        Depth is required 
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-none">
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Weight</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Weight"
                                          autocomplete="off" value="{{BasementInventory.item_weight }}" name="item_weight"
                                          [(ngModel)]="BasementInventory.item_weight" #item_weight="ngModel" >
                                        
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">People required to carry item</label>
                                        <input type="number" min="0" class="form-control" placeholder="People required to carry item"
                                          autocomplete="off" name="item_carry_people"
                                          [(ngModel)]="BasementInventory.item_carry_people" #item_carry_people="ngModel">
                                      
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Category</label>
                                        <input type="text" class="form-control" placeholder="Category" autocomplete="off"
                                          name="type_name" [(ngModel)]="BasementInventory.type_name" #type_name="ngModel">
                                        
                                      </div>
                                    </div>
                                    
                                    <div class="col {{defaultUnits?.cubic_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Cm</label>
                                        <input type="number" min="0" class="form-control" placeholder="Cubic Cm"
                                          autocomplete="off" value="{{ BasementInventory.item_cube_cm }}"
                                          name="item_cube_cm" [(ngModel)]="BasementInventory.item_cube_cm"
                                          #item_cube_cm="ngModel">
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.cubic_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Cm dismantled</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Cubic Cm dismantled" autocomplete="off"
                                          value="{{ BasementInventory.item_cube_dismantled_cm }}"
                                          name="item_cube_dismantled_cm"
                                          [(ngModel)]="BasementInventory.item_cube_dismantled_cm"
                                          #item_cube_dismantled_cm="ngModel">
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.cubic_units=='ft' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Ft</label>
                                        <input type="number" min="0" class="form-control" placeholder="Cubic Ft"
                                          autocomplete="off" value="{{ BasementInventory.item_cube_ft }}"
                                          name="item_cube_ft" [(ngModel)]="BasementInventory.item_cube_ft"
                                          #item_cube_ft="ngModel">
                                      </div>
                                    </div>
                                    
                                    
                                    <div class="col {{defaultUnits?.cubic_units=='ft' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Ft dismantled</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Cubic Ft dismantled" autocomplete="off"
                                          value="{{ BasementInventory.item_cube_dismantled_ft }}"
                                          name="item_cube_dismantled_ft"
                                          [(ngModel)]="BasementInventory.item_cube_dismantled_ft"
                                          #item_cube_dismantled_ft="ngModel">
                                      </div>
                                    </div>

                                    <div class="col">
                                      <div class="form-group ">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Dismantling required</label>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_dismantle"
                                              [checked]="BasementInventory.item_do_dismantle==true" [value]=true
                                              [(ngModel)]="BasementInventory.item_do_dismantle">Yes
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_dismantle"
                                              [checked]="BasementInventory.item_do_dismantle==false" [value]=false
                                              [(ngModel)]="BasementInventory.item_do_dismantle">No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Time required to dismantle</label>
                                        <input type="text" class="form-control" placeholder="Time required to dismantle"
                                          autocomplete="off" value="{{ BasementInventory.item_dismantle_time }}"
                                          name="item_dismantle_time" [(ngModel)]="BasementInventory.item_dismantle_time"
                                          #item_dismantle_time="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group ">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Reassembly required</label>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_reassemble"
                                              [checked]="BasementInventory.item_do_reassemble==true" [value]=true
                                              [(ngModel)]="BasementInventory.item_do_reassemble">Yes
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_reassemble"
                                              [checked]="BasementInventory.item_do_reassemble==false" [value]=false
                                              [(ngModel)]="BasementInventory.item_do_reassemble">No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Reassemble time required</label>
                                        <input type="text" class="form-control" placeholder="Reassemble time required"
                                          autocomplete="off" value="{{ BasementInventory.item_reassemble_time }}"
                                          name="item_reassemble_time" [(ngModel)]="BasementInventory.item_reassemble_time"
                                          #item_reassemble_time="ngModel">

                                        
                                      </div>
                                    </div>
                                
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Typical financial value</label>
                                        <input type="number" min="0" class="form-control" placeholder="Typical financial value"
                                          autocomplete="off" value="{{ BasementInventory.item_typical_value }}"
                                          name="item_typical_value" [(ngModel)]="BasementInventory.item_typical_value"
                                          #item_typical_value="ngModel" >

                                      
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group ">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Provides storage?</label>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_provides_storage"
                                              [checked]="BasementInventory.item_provides_storage==true" [value]=true
                                              [(ngModel)]="BasementInventory.item_provides_storage">Yes
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_provides_storage"
                                              [checked]="BasementInventory.item_provides_storage==false" [value]=false
                                              [(ngModel)]="BasementInventory.item_provides_storage">No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. small boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. small boxes"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_small }}"
                                          name="item_pack_small" [(ngModel)]="BasementInventory.item_pack_small"
                                          #item_pack_small="ngModel" >
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. medium boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. medium boxes"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_med }}"
                                          name="item_pack_med" [(ngModel)]="BasementInventory.item_pack_med"
                                          #item_pack_med="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. large boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. large boxes"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_large }}"
                                          name="item_pack_large" [(ngModel)]="BasementInventory.item_pack_large"
                                          #item_pack_large="ngModel" >
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. layflat boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. layflat boxes"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_layflat }}"
                                          name="item_pack_layflat" [(ngModel)]="BasementInventory.item_pack_layflat"
                                          #item_pack_layflat="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. wardrobe boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. wardrobe boxes"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_wardrobe }}"
                                          name="item_pack_wardrobe" [(ngModel)]="BasementInventory.item_pack_wardrobe"
                                          #item_pack_wardrobe="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. picturepack</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. picturepack" autocomplete="off"
                                          value="{{ BasementInventory.item_pack_picturepack }}"
                                          name="item_pack_picturepack"
                                          [(ngModel)]="BasementInventory.item_pack_picturepack"
                                          #item_pack_picturepack="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. golfbox</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. golfbox"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_golfbox }}"
                                          name="item_pack_golfbox" [(ngModel)]="BasementInventory.item_pack_golfbox"
                                          #item_pack_golfbox="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. bikebox</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. bikebox"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_bikebox }}"
                                          name="item_pack_bikebox" [(ngModel)]="BasementInventory.item_pack_bikebox"
                                          #item_pack_bikebox="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. single mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. single mattress bag" autocomplete="off"
                                          value="{{ BasementInventory.item_pack_mattressbagsingle }}"
                                          name="item_pack_mattressbagsingle"
                                          [(ngModel)]="BasementInventory.item_pack_mattressbagsingle"
                                          #item_pack_mattressbagsingle="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. double mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. double mattress bag" autocomplete="off"
                                          value="{{ BasementInventory.item_pack_mattressbagdouble }}"
                                          name="item_pack_mattressbagdouble"
                                          [(ngModel)]="BasementInventory.item_pack_mattressbagdouble"
                                          #item_pack_mattressbagdouble="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. king mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. king mattress bag" autocomplete="off"
                                          value="{{ BasementInventory.item_pack_mattressbagking }}"
                                          name="item_pack_mattressbagking"
                                          [(ngModel)]="BasementInventory.item_pack_mattressbagking"
                                          #item_pack_mattressbagking="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. super king mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Item Pack Mattressbagsuperking" autocomplete="off"
                                          value="{{ BasementInventory.item_pack_mattressbagsuperking }}"
                                          name="item_pack_mattressbagsuperking"
                                          [(ngModel)]="BasementInventory.item_pack_mattressbagsuperking"
                                          #item_pack_mattressbagsuperking="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 24</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 24"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox24 }}"
                                          name="item_pack_tvbox24" [(ngModel)]="BasementInventory.item_pack_tvbox24"
                                          #item_pack_tvbox24="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 32</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 32"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox32 }}"
                                          name="item_pack_tvbox32" [(ngModel)]="BasementInventory.item_pack_tvbox32"
                                          #item_pack_tvbox32="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 36</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 36"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox36 }}"
                                          name="item_pack_tvbox36" [(ngModel)]="BasementInventory.item_pack_tvbox36"
                                          #item_pack_tvbox36="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 43</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 43"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox43 }}"
                                          name="item_pack_tvbox43" [(ngModel)]="BasementInventory.item_pack_tvbox43"
                                          #item_pack_tvbox43="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 48</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 48"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox48 }}"
                                          name="item_pack_tvbox48" [(ngModel)]="BasementInventory.item_pack_tvbox48"
                                          #item_pack_tvbox48="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 55</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 55"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox55 }}"
                                          name="item_pack_tvbox55" [(ngModel)]="BasementInventory.item_pack_tvbox55"
                                          #item_pack_tvbox55="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 70</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 70"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox70 }}"
                                          name="item_pack_tvbox70" [(ngModel)]="BasementInventory.item_pack_tvbox70"
                                          #item_pack_tvbox70="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 85</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 85"
                                          autocomplete="off" value="{{ BasementInventory.item_pack_tvbox85 }}"
                                          name="item_pack_tvbox85" [(ngModel)]="BasementInventory.item_pack_tvbox85"
                                          #item_pack_tvbox85="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Percentage full (If provides storage)</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Percentage full" autocomplete="off"
                                          value="{{ BasementInventory.item_percentage_full }}" name="item_percentage_full"
                                          [(ngModel)]="BasementInventory.item_percentage_full"
                                          #item_percentage_full="ngModel">
                                      </div>
                                    </div>
                                  
                                  </div>
                                </div>
                                <div class="modal-footer border-0 sumit_popup">
                                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!----end ---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade {{currentTabTypeid == 'to' ? 'show active' : ''}}" id="nav-to-basement" role="tabpanel"
      aria-labelledby="nav-profile-tab" *ngIf="ImportSurveyList.property?.to">
      <span class="w-100 d-inline-block">
        <button class="btn btn-primary border-0 text-white float-right font-weight-bold"
          (click)="open(addBasementModal)">Add Basement</button>
      </span>
      <div class="alert alert-danger text-center"
        *ngIf="(ImportSurveyList.property?.to.plan.basements?.length <=0) && !loader">No Basement Added</div>

      <div id="accordion" role="tablist" class="o-accordion"
        *ngIf="(ImportSurveyList.property?.to.plan.basements?.length > 0)">
        <div class="card mt-2 mb-2"
          *ngFor="let basement of ImportSurveyList.property.to.plan.basements; let basementRow=index;">
          <div role="tab" id="Basementinner{{basementRow}}" class="card-header">
            <h5 class="mb-0"><a data-toggle="collapse" data-parent="#accordion"
                href="#collapseBasement_tb_{{basementRow}}" aria-expanded="false"
                [attr.aria-controls]="'collapseBasement_tb_'+basementRow"
                class="w-100 d-inline-block floor-accordion collapsed">{{
                basement.basement_name }} <i class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
              </a>
              <span class="delete-icon" (click)="deleteBasement(basementRow)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
            </h5>
          </div>
          <div id="collapseBasement_tb_{{basementRow}}" role="tabpanel"
            [attr.aria-labelledby]="'Basementinner'+basementRow" class="collapse">
            <div class="card-block p-4">
              <form (ngSubmit)="onSubmitBasementUpdate(basementUpdateForm)" #basementUpdateForm="ngForm">
                <input type="hidden" [ngModel]="basementRow" name="basement_row" />
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group test1">
                      <label for="exampleInputSurvey">Basement Name</label>
                      <input type="text" class="form-control" placeholder="Basement Name" autocomplete="off"
                        value="{{ basement.basement_name }}" name="basement_name" [ngModel]="basement.basement_name"
                        #basement_name="ngModel" required>

                      <div class="text-danger" *ngIf="!basement_name.value">
                        Basement Name is Required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group ">
                      <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving the contents of this
                        basement?</label>
                      <select class="form-control form-control-custom" name="basement_ntg"
                        [ngModel]="basement.basement_ntg" #basement_ntg="ngModel" required>
                        <option value="" [selected]="(basement.basement_ntg=='') ? true:false">Select</option>
                        <option value="TG" [selected]="(basement.basement_ntg=='TG') ? true:false">To Go</option>
                        <option value="NTG" [selected]="(basement.basement_ntg=='NTG') ? true:false">Not To Go</option>
                        <option value="MTG" [selected]="(basement.basement_ntg=='MTG') ? true:false">Maybe To Go
                        </option>
                      </select>
                      <div class="text-danger" *ngIf="!basement_ntg.value">
                        Basement NTG is Required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group ">
                      <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this
                        basement </label>
                      <input type="number" class="form-control" placeholder="Basement Box Estimate" min="0"
                        autocomplete="off" value="{{ basement.basement_box_estimate }}" name="basement_box_estimate"
                        [ngModel]="basement.basement_box_estimate" #basement_box_estimate="ngModel">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this basement?</label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_packing==true"
                              name="basement_packing" [value]=true [(ngModel)]="basement.basement_packing">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_packing==false"
                              name="basement_packing" [value]=false [(ngModel)]="basement.basement_packing">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Have we been able to see the basement? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_seen==true"
                              name="basement_seen" [value]=true [(ngModel)]="basement.basement_seen">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_seen==false"
                              name="basement_seen" [value]=false [(ngModel)]="basement.basement_seen">No
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">

                      <label for="exampleInputSurvey" class="mb-4">Are we moving the light fittings in this basement?
                      </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_light_fittings==true"
                              name="move_light_fittings" [value]=true [(ngModel)]="basement.move_light_fittings">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_light_fittings==false"
                              name="move_light_fittings" [value]=false [(ngModel)]="basement.move_light_fittings">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="row">


                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this basement? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_carpets==true"
                              name="move_carpets" [value]=true [(ngModel)]="basement.move_carpets">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_carpets==false"
                              name="move_carpets" [value]=false [(ngModel)]="basement.move_carpets">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains/blinds in this basement? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.move_curtains_blinds==true"
                              name="move_curtains_blinds" [value]=true [(ngModel)]="basement.move_curtains_blinds">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input"
                              [checked]="basement.move_curtains_blinds==false" name="move_curtains_blinds" [value]=false
                              [(ngModel)]="basement.move_curtains_blinds">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Is this basement fully lit? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_fully_lit==true"
                              name="basement_fully_lit" [value]=true [(ngModel)]="basement.basement_fully_lit">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="basement.basement_fully_lit==false"
                              name="basement_fully_lit" [value]=false [(ngModel)]="basement.basement_fully_lit">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer border-0 sumit_popup">
                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                </div>
              </form>

              <div id="accordion" role="tablist" class="o-accordion">
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingTwo">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                        data-parent="#accordion" href="#collapseTwo{{basementRow}}" aria-expanded="false"
                        aria-controls="collapseTwo"> Inventory <i class="fa fa-chevron-down arrow-down"
                          aria-hidden="true"></i>
                      </a>
                    </h5>
                  </div>
                  <div id="collapseTwo{{basementRow}}" class="collapse" role="tabpanel"
                    [attr.aria-labelledby]="'headingTwo'+basementRow">
                    <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">

                      <span class="w-100 d-inline-block"><button
                          class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold"
                          (click)="open(inventoryModal); setBasementIndex(basementRow)">Add Item</button></span>

                      <div class="alert alert-danger text-center"
                        *ngIf="basement?.inventory=='' || basement?.inventory==null">No Inventory Items Added</div>

                      <div *ngIf="basement?.inventory!='' || basement?.inventory!=null">
                        <div class="card multi  mt-0 mb-3 ml-3 mr-3 "
                          *ngFor="let BasementInventory of basement.inventory; let inventoryRow=index">
                          <div class="card-header" role="tab" id="headingOne-1">
                            <h5 class="mb-0">
                              <a class="w-100 floor-accordion d-inline-block collapsed" data-toggle="collapse"
                                data-parent="#accordion" href="#collapseOneinnner_tb_{{basementRow}}{{inventoryRow}}"
                                aria-expanded="true"
                                [attr.aria-controls]="'collapseOneinnner_tb_'+basementRow+inventoryRow">
                                {{BasementInventory.item_name}} <i class="fa fa-chevron-down arrow-down mx-3"
                                  aria-hidden="true"></i>
                              </a>
                              <span class="delete-icon" (click)="deleteInventoryItem(basementRow, inventoryRow)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </h5>
                          </div>

                          <div id="collapseOneinnner_tb_{{basementRow}}{{inventoryRow}}" class="collapse"
                            role="tabpanel" aria-labelledby="headingOne-1">
                            <div class="card-block p-4">
                              <form (ngSubmit)="onSubmitInventoryUpdate(inventoryUpdateForm)"
                                #inventoryUpdateForm="ngForm">
                                <input type="hidden" name="basement_row" [ngModel]="basementRow" />
                                <input type="hidden" name="inventory_row" [ngModel]="inventoryRow" />
                                <input type="hidden" name="item_name" [ngModel]="BasementInventory.item_name"
                                  #item_name="ngModel">
                                <input type="hidden" value="{{BasementInventory.item_id}}" name="item_id"
                                  [(ngModel)]="BasementInventory.item_id">
                                <input type="hidden" value="{{BasementInventory.type_id}}" name="type_id"
                                  [(ngModel)]="BasementInventory.type_id">
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Name</label>
                                      <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.item_name"></a>
                                      </ng-template>
                                      <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                      </ng-template>
                                      <ng-autocomplete [data]="itemList" [searchKeyword]="keyword"
                                        (inputChanged)='onChangeSearch($event)'
                                        (selected)='selectEventItem($event, basementRow,inventoryRow)'
                                        (inputCleared)="onClearedItem($event, basementRow,inventoryRow)"
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                        placeholder="Item Name" [isLoading]="isLoadingResult" [minQueryLength]="1"
                                        name="item_name_auto" [ngModel]="BasementInventory.item_name"
                                        #item_name_auto="ngModel" required>
                                      </ng-autocomplete>

                                      <div class="text-danger" *ngIf="!item_name_auto.value">
                                        Item Name is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Weight</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Weight"
                                        autocomplete="off" value="{{BasementInventory.item_weight }}" name="item_weight"
                                        [(ngModel)]="BasementInventory.item_weight" #item_weight="ngModel" required>
                                      <div class="text-danger" *ngIf="item_weight.value==undefined">
                                        Item Weight is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Carry People</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Carry People"
                                        autocomplete="off" name="item_carry_people"
                                        [(ngModel)]="BasementInventory.item_carry_people" #item_carry_people="ngModel"
                                        required>
                                      <div class="text-danger" *ngIf="item_carry_people.value==undefined">
                                        Item Carry People is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Type Name</label>
                                      <input type="text" class="form-control" placeholder="Type Name" autocomplete="off"
                                        name="type_name" [(ngModel)]="BasementInventory.type_name" #type_name="ngModel"
                                        required>
                                      <div class="text-danger" *ngIf="!type_name.value">
                                        Type Name is Required
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group ">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Dismantle</label>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_dismantle"
                                            [checked]="BasementInventory.item_do_dismantle==true" [value]=true
                                            [(ngModel)]="BasementInventory.item_do_dismantle">Yes
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_dismantle"
                                            [checked]="BasementInventory.item_do_dismantle==false" [value]=false
                                            [(ngModel)]="BasementInventory.item_do_dismantle">No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Dismantle Time</label>
                                      <input type="text" class="form-control" placeholder="Item Dismantle Time"
                                        autocomplete="off" value="{{ BasementInventory.item_dismantle_time }}"
                                        name="item_dismantle_time" [(ngModel)]="BasementInventory.item_dismantle_time"
                                        #item_dismantle_time="ngModel" required>

                                      <div class="text-danger" *ngIf="item_dismantle_time.value===''">
                                        Item Dismantle Time is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group ">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Reassemble</label>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_reassemble"
                                            [checked]="BasementInventory.item_do_reassemble==true" [value]=true
                                            [(ngModel)]="BasementInventory.item_do_reassemble">Yes
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_reassemble"
                                            [checked]="BasementInventory.item_do_reassemble==false" [value]=false
                                            [(ngModel)]="BasementInventory.item_do_reassemble">No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Reassemble Time</label>
                                      <input type="text" class="form-control" placeholder="Item Reassemble Time"
                                        autocomplete="off" value="{{ BasementInventory.item_reassemble_time }}"
                                        name="item_reassemble_time" [(ngModel)]="BasementInventory.item_reassemble_time"
                                        #item_reassemble_time="ngModel" required>

                                      <div class="text-danger" *ngIf="item_reassemble_time.value===''">
                                        Item Reassemble Time is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Typical Value</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Typical Value"
                                        autocomplete="off" value="{{ BasementInventory.item_typical_value }}"
                                        name="item_typical_value" [(ngModel)]="BasementInventory.item_typical_value"
                                        #item_typical_value="ngModel" required>

                                      <div class="text-danger" *ngIf="item_typical_value.value==undefined">
                                        Item Typical Value is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group ">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item Provides Storage</label>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_provides_storage"
                                            [checked]="BasementInventory.item_provides_storage==true" [value]=true
                                            [(ngModel)]="BasementInventory.item_provides_storage">Yes
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_provides_storage"
                                            [checked]="BasementInventory.item_provides_storage==false" [value]=false
                                            [(ngModel)]="BasementInventory.item_provides_storage">No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item
                                        ?</label>
                                      <select class="form-control form-control-custom" name="item_to_go"
                                        [ngModel]="BasementInventory.item_to_go" #item_to_go="ngModel" required>
                                        <option value="" [selected]="(BasementInventory.item_to_go=='') ? true:false">
                                          Select</option>
                                        <option value="TG"
                                          [selected]="(BasementInventory.item_to_go=='TG') ? true:false">To Go</option>
                                        <option value="NTG"
                                          [selected]="(BasementInventory.item_to_go=='NTG') ? true:false">Not To Go
                                        </option>
                                        <option value="MTG"
                                          [selected]="(BasementInventory.item_to_go=='MTG') ? true:false">Maybe To Go
                                        </option>
                                      </select>
                                      <div class="text-danger" *ngIf="!item_to_go.value">
                                        Item To Go is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Small</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Small"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_small }}"
                                        name="item_pack_small" [(ngModel)]="BasementInventory.item_pack_small"
                                        #item_pack_small="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_small.value==undefined">
                                        Item Pack Small is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Medium</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Medium"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_med }}"
                                        name="item_pack_med" [(ngModel)]="BasementInventory.item_pack_med"
                                        #item_pack_med="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_med.value==undefined">
                                        Item Pack Medium is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Large</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Large"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_large }}"
                                        name="item_pack_large" [(ngModel)]="BasementInventory.item_pack_large"
                                        #item_pack_large="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_large.value==undefined">
                                        Item Pack Large is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Layflat</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Layflat"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_layflat }}"
                                        name="item_pack_layflat" [(ngModel)]="BasementInventory.item_pack_layflat"
                                        #item_pack_layflat="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_layflat.value==undefined">
                                        Item Pack Layflat is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Wardrobe"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_wardrobe }}"
                                        name="item_pack_wardrobe" [(ngModel)]="BasementInventory.item_pack_wardrobe"
                                        #item_pack_wardrobe="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_wardrobe.value==undefined">
                                        Item Pack Wardrobe is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Picturepack</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Picturepack" autocomplete="off"
                                        value="{{ BasementInventory.item_pack_picturepack }}"
                                        name="item_pack_picturepack"
                                        [(ngModel)]="BasementInventory.item_pack_picturepack"
                                        #item_pack_picturepack="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_picturepack.value==undefined">
                                        Item Pack Picturepack is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Golfbox</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Golfbox"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_golfbox }}"
                                        name="item_pack_golfbox" [(ngModel)]="BasementInventory.item_pack_golfbox"
                                        #item_pack_golfbox="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_golfbox.value==undefined">
                                        Item Pack Golfbox is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Bikebox</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Bikebox"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_bikebox }}"
                                        name="item_pack_bikebox" [(ngModel)]="BasementInventory.item_pack_bikebox"
                                        #item_pack_bikebox="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_bikebox.value==undefined">
                                        Item Pack Bikebox is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagsingle" autocomplete="off"
                                        value="{{ BasementInventory.item_pack_mattressbagsingle }}"
                                        name="item_pack_mattressbagsingle"
                                        [(ngModel)]="BasementInventory.item_pack_mattressbagsingle"
                                        #item_pack_mattressbagsingle="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagsingle.value==undefined">
                                        Item Pack Mattressbagsingle is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagdouble" autocomplete="off"
                                        value="{{ BasementInventory.item_pack_mattressbagdouble }}"
                                        name="item_pack_mattressbagdouble"
                                        [(ngModel)]="BasementInventory.item_pack_mattressbagdouble"
                                        #item_pack_mattressbagdouble="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagdouble.value==undefined">
                                        Item Pack Mattressbagdouble is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagking" autocomplete="off"
                                        value="{{ BasementInventory.item_pack_mattressbagking }}"
                                        name="item_pack_mattressbagking"
                                        [(ngModel)]="BasementInventory.item_pack_mattressbagking"
                                        #item_pack_mattressbagking="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagking.value==undefined">
                                        Item Pack Mattressbagking is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagsuperking" autocomplete="off"
                                        value="{{ BasementInventory.item_pack_mattressbagsuperking }}"
                                        name="item_pack_mattressbagsuperking"
                                        [(ngModel)]="BasementInventory.item_pack_mattressbagsuperking"
                                        #item_pack_mattressbagsuperking="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagsuperking.value==undefined">
                                        Item Pack Mattressbagsuperking is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox24"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox24 }}"
                                        name="item_pack_tvbox24" [(ngModel)]="BasementInventory.item_pack_tvbox24"
                                        #item_pack_tvbox24="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox24.value==undefined">
                                        Item Pack Tvbox24 is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox32"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox32 }}"
                                        name="item_pack_tvbox32" [(ngModel)]="BasementInventory.item_pack_tvbox32"
                                        #item_pack_tvbox32="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox32.value==undefined">
                                        Item Pack Tvbox32 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox36"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox36 }}"
                                        name="item_pack_tvbox36" [(ngModel)]="BasementInventory.item_pack_tvbox36"
                                        #item_pack_tvbox36="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox36.value==undefined">
                                        Item Pack Tvbox36 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox43"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox43 }}"
                                        name="item_pack_tvbox43" [(ngModel)]="BasementInventory.item_pack_tvbox43"
                                        #item_pack_tvbox43="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox43.value==undefined">
                                        Item Pack Tvbox43 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox48</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox48"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox48 }}"
                                        name="item_pack_tvbox48" [(ngModel)]="BasementInventory.item_pack_tvbox48"
                                        #item_pack_tvbox48="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox48.value==undefined">
                                        Item Pack Tvbox48 is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox55"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox55 }}"
                                        name="item_pack_tvbox55" [(ngModel)]="BasementInventory.item_pack_tvbox55"
                                        #item_pack_tvbox55="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox55.value==undefined">
                                        Item Pack Tvbox55 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox70"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox70 }}"
                                        name="item_pack_tvbox70" [(ngModel)]="BasementInventory.item_pack_tvbox70"
                                        #item_pack_tvbox70="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox70.value==undefined">
                                        Item Pack Tvbox70 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox85"
                                        autocomplete="off" value="{{ BasementInventory.item_pack_tvbox85 }}"
                                        name="item_pack_tvbox85" [(ngModel)]="BasementInventory.item_pack_tvbox85"
                                        #item_pack_tvbox85="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox85.value==undefined">
                                        Item Pack Tvbox85 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Percentage Full</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Percentage Full" autocomplete="off"
                                        value="{{ BasementInventory.item_percentage_full }}" name="item_percentage_full"
                                        [(ngModel)]="BasementInventory.item_percentage_full"
                                        #item_percentage_full="ngModel" required>

                                      <div class="text-danger" *ngIf="item_percentage_full.value==undefined">
                                        Item Percentage Full is Required
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Width Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Width Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_width_cm }}"
                                        name="item_width_cm" [(ngModel)]="BasementInventory.item_width_cm"
                                        #item_width_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_width_cm.value==undefined">
                                        Item Width Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Height Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Height Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_height_cm }}"
                                        name="item_height_cm" [(ngModel)]="BasementInventory.item_height_cm"
                                        #item_height_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_height_cm.value==undefined">
                                        Item Height Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Depth Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Depth Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_depth_cm }}"
                                        name="item_depth_cm" [(ngModel)]="BasementInventory.item_depth_cm"
                                        #item_depth_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_depth_cm.value==undefined">
                                        Item Depth Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Width Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Width Inch"
                                        autocomplete="off" value="{{ BasementInventory.item_width_inch }}"
                                        name="item_width_inch" [(ngModel)]="BasementInventory.item_width_inch"
                                        #item_width_inch="ngModel" required>

                                      <div class="text-danger" *ngIf="item_width_inch.value==undefined">
                                        Item Width Inch is Required
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Height Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Height Inch"
                                        autocomplete="off" value="{{ BasementInventory.item_height_inch }}"
                                        name="item_height_inch" [(ngModel)]="BasementInventory.item_height_inch"
                                        #item_height_inch="ngModel" required>

                                      <div class="text-danger" *ngIf="item_height_inch.value==undefined">
                                        Item Height Inch is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Depth Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Depth Inch"
                                        autocomplete="off" value="{{ BasementInventory.item_depth_inch }}"
                                        name="item_depth_inch" [(ngModel)]="BasementInventory.item_depth_inch"
                                        #item_depth_inch="ngModel" required>

                                      <div class="text-danger" *ngIf="item_depth_inch.value==undefined">
                                        Item Depth Inch is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Cube Cm"
                                        autocomplete="off" value="{{ BasementInventory.item_cube_cm }}"
                                        name="item_cube_cm" [(ngModel)]="BasementInventory.item_cube_cm"
                                        #item_cube_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_cm.value==undefined">
                                        Item Cube Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Ft</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Cube Ft"
                                        autocomplete="off" value="{{ BasementInventory.item_cube_ft }}"
                                        name="item_cube_ft" [(ngModel)]="BasementInventory.item_cube_ft"
                                        #item_cube_ft="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_ft.value==undefined">
                                        Item Cube Ft is Required
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Cube Dismantled Cm" autocomplete="off"
                                        value="{{ BasementInventory.item_cube_dismantled_cm }}"
                                        name="item_cube_dismantled_cm"
                                        [(ngModel)]="BasementInventory.item_cube_dismantled_cm"
                                        #item_cube_dismantled_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_dismantled_cm.value==undefined">
                                        Item Cube Dismantled is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Cube Dismantled Ft" autocomplete="off"
                                        value="{{ BasementInventory.item_cube_dismantled_ft }}"
                                        name="item_cube_dismantled_ft"
                                        [(ngModel)]="BasementInventory.item_cube_dismantled_ft"
                                        #item_cube_dismantled_ft="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_dismantled_ft.value==undefined">
                                        Item Cube Dismantled Ft is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col"></div>
                                  <div class="col"></div>
                                </div>
                                <div class="modal-footer border-0 sumit_popup">
                                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!----end ---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--add basement details modal box start-->
<ng-template #addBasementModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form [formGroup]="basementForm" (ngSubmit)="onSubmitBasement()">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group test2">
            <label for="exampleInputSurvey">Basement Name</label>
            <input type="text" class="form-control" placeholder="Basement Name" autocomplete="off" value=""
              formControlName="basement_name">
            <div class="text-danger"
              *ngIf="(basementFormControl.basement_name.touched || submittedBasement) && basementFormControl.basement_name.errors?.required">
              Basement Name is required
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group ">
            <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving the contents of this basement?</label>
            <select class="form-control  border" id="exampleFormControlSelect2" formControlName="basement_ntg">
              <option value="">--Select--</option>
              <option value="TG">To Go</option>
              <option value="NTG">Not To Go</option>
              <option value="MTG">Maybe To Go</option>
            </select>
            <div class="text-danger"
              *ngIf="(basementFormControl.basement_ntg.touched || submittedBasement) && basementFormControl.basement_ntg.errors?.required">
              Basement ntg is required
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this basement
            </label>
            <input type="number" class="form-control" placeholder="Basement Box Estimate" min="0" autocomplete="off"
              value="" formControlName="basement_box_estimate">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label class="mb-4">Are we packing the contents of this basement?</label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="basement_packing">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="basement_packing">No
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label class="mb-4">Have we been able to see the basement?</label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="basement_seen">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="basement_seen">No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Are we moving the light fittings in this basement? </label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="move_light_fittings">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="move_light_fittings">No
                </label>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Are we moving the light carpets in this basement? </label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="move_carpets">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="move_carpets">No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Are we moving the light curtains or blinds in this basement?
            </label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="move_curtains_blinds">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="move_curtains_blinds">No
                </label>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Basement Fully Lit</label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="basement_fully_lit">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="basement_fully_lit">No
                </label>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
      </div>
    </form>
  </div>
</ng-template>
<!--add basement details modal box end-->

<!-----add inventory modal  box start---->
<ng-template #inventoryModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <div class="card-block p-4 project-tab">
      <!-- tabs create -->
      <ul class="nav nav-tabs popup-inside-tabs" id="popup-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="catalogue-search-tab" data-toggle="tab" href="#cataloguesearch" role="tab" (click)="resetItemFormValue()">Search Catalogue</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="catalogue-tab" data-toggle="tab" href="#catalogue" role="tab"
            (click)="resetItemFormValue()">Browse Catalogue</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="manual-tab" data-toggle="tab" href="#manual" role="tab"
            (click)="resetItemFormValue()">Custom Item</a>
        </li>
      </ul>
      <!-- tabs creqte end-->
      <!-- tabs-content start -->
      <div class="tab-content" id="popup-tabs">

        <div class="tab-pane fade show active" id="cataloguesearch" role="tabpanel">
          
          <form [formGroup]="InventoryItemFormFrom" (ngSubmit)="onSubmitInventory()">

            <input type="hidden" value="" formControlName="item_name">
            <input type="hidden" value="" formControlName="item_id">
            <input type="hidden" value="" formControlName="item_type">
            <input type="hidden" value="" formControlName="item_type_search">
            <input type="hidden" value="" formControlName="type_id">
            <input type="hidden" value="" formControlName="loftIndex" />

            <div class="row">
              <div class="col">

                <div class="form-group">
                  <label for="exampleInputSurvey">Search</label>
                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.item_name"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <ng-autocomplete [data]="itemListUnFiltered" [searchKeyword]="keyword" (inputChanged)='onChangeSearch($event)'
                  (selected)='selectEvent($event)' 
                  (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate" placeholder="Item Name" [isLoading]="isLoadingResult"
                  [minQueryLength]="1" formControlName="item_name_search">
                  </ng-autocomplete>
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_name.touched || submittedInventoryForm) && InventoryFormFromControl.item_name.errors?.required">
                    Item name is required
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <div class="form-group">
                  <label for="exampleInputSurvey">Quantity</label>
                  <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                    min="1" formControlName="total_items">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.total_items.touched || submittedInventoryForm) && InventoryFormFromControl.total_items.errors?.required">
                    Number of items is required
                  </div>
                  <div class="text-danger" *ngIf="InventoryFormFromControl.total_items.errors?.min">
                    Number of items can not less than 1
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">To go?</label>
                  <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                    <option value="">--Select--</option>
                    <option value="TG">Yes: To go</option>
                    <option value="NTG">No: Not to go</option>
                    <option value="MTG">Not sure: Maybe to go</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_to_go.touched || submittedInventoryForm) && InventoryFormFromControl.item_to_go.errors?.required">
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Cm</label>
                  <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                    min="0" formControlName="item_width_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_width_cm.touched || submittedInventoryForm) && InventoryFormFromControl.item_width_cm.errors?.required">
                    Item width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Cm</label>
                  <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                    min="0" formControlName="item_height_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_height_cm.touched || submittedInventoryForm) && InventoryFormFromControl.item_height_cm.errors?.required">
                    Item height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Cm</label>
                  <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                    min="0" formControlName="item_depth_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_depth_cm.touched || submittedInventoryForm) && InventoryFormFromControl.item_depth_cm.errors?.required">
                    Item depth is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Inch</label>
                  <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                    min="0" formControlName="item_width_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_width_inch.touched || submittedInventoryForm) && InventoryFormFromControl.item_width_inch.errors?.required">
                    Item width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Inch</label>
                  <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                    min="0" formControlName="item_height_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_height_inch.touched || submittedInventoryForm) && InventoryFormFromControl.item_height_inch.errors?.required">
                    Item height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Inch</label>
                  <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                    min="0" formControlName="item_depth_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_depth_inch.touched || submittedInventoryForm) && InventoryFormFromControl.item_depth_inch.errors?.required">
                    Item depth is required
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer border-0">
              <button type="submit" class="btn btn-primary border-0 pl-4 pr-4"><i class="fas fa-plus-square"></i> Add</button>
            </div>
          </form>
        </div>

        <div class="tab-pane fade" id="catalogue" role="tabpanel">
          <form [formGroup]="InventoryItemFormFrom" (ngSubmit)="onSubmitInventory()">
            <input type="hidden" value="" formControlName="item_name">
            <input type="hidden" value="" formControlName="item_id">
            <input type="hidden" value="" formControlName="item_type">
            <input type="hidden" value="" formControlName="type_id">
            <input type="hidden" value="" formControlName="basementIndex" />
            <div class="row inventory-item-row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Type</label>
                  <ng-template #itemTypeTemplate let-item>
                    <a [innerHTML]="item.type_name"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <ng-autocomplete [data]="itemTypeList" [searchKeyword]="keywordType"
                    (inputChanged)='onChangeSearchType($event)' (selected)='selectEventType($event)'
                    (inputFocused)='onFocusedType($event)' (inputCleared)="onClearedType($event)"
                    [itemTemplate]="itemTypeTemplate" [minQueryLength]="1" [notFoundTemplate]="notFoundTemplate"
                    placeholder="Type Name" [isLoading]="isLoadingResult" formControlName="item_type_search">
                  </ng-autocomplete>
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_type.touched || submittedInventoryForm) && InventoryFormFromControl.item_type.errors?.required">
                    Item Type is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Name</label>
                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.item_name"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <ng-autocomplete [data]="itemListFiltered" [searchKeyword]="keyword"
                    (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                    (inputFocused)='onFocused($event)' (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate"
                    [minQueryLength]="1" [notFoundTemplate]="notFoundTemplate" placeholder="Item Name"
                    [isLoading]="isLoadingResult" [disabled]="!selectedItemTypeId ? true : false"
                    formControlName="item_name_search">
                  </ng-autocomplete>
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_name.touched || submittedInventoryForm) && InventoryFormFromControl.item_name.errors?.required">
                    Item Name is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Number of items </label>
                  <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                    min="1" formControlName="total_items">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.total_items.touched || submittedInventoryForm) && InventoryFormFromControl.total_items.errors?.required">
                    Number of items is required
                  </div>
                  <div class="text-danger" *ngIf="InventoryFormFromControl.total_items.errors?.min">
                    Number of items can not less than 1
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item ?</label>
                  <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                    <option value="">--Select--</option>
                    <option value="TG">To Go</option>
                    <option value="NTG">Not To Go</option>
                    <option value="MTG">Maybe To Go</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_to_go.touched || submittedInventoryForm) && InventoryFormFromControl.item_to_go.errors?.required">
                    Item To Go is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Width Cm</label>
                  <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                    min="0" formControlName="item_width_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_width_cm.touched || submittedInventoryForm) && InventoryFormFromControl.item_width_cm.errors?.required">
                    Item Width Cm is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Height Cm</label>
                  <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                    min="0" formControlName="item_height_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_height_cm.touched || submittedInventoryForm) && InventoryFormFromControl.item_height_cm.errors?.required">
                    Item Height Cm is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Depth Cm</label>
                  <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                    min="0" formControlName="item_depth_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_depth_cm.touched || submittedInventoryForm) && InventoryFormFromControl.item_depth_cm.errors?.required">
                    Item Depth Cm is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Width Inch</label>
                  <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                    min="0" formControlName="item_width_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_width_inch.touched || submittedInventoryForm) && InventoryFormFromControl.item_width_inch.errors?.required">
                    Item Width Inch is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Height Inch</label>
                  <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                    min="0" formControlName="item_height_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_height_inch.touched || submittedInventoryForm) && InventoryFormFromControl.item_height_inch.errors?.required">
                    Item Height Inch is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Depth Inch</label>
                  <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                    min="0" formControlName="item_depth_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormFromControl.item_depth_inch.touched || submittedInventoryForm) && InventoryFormFromControl.item_depth_inch.errors?.required">
                    Item Depth Inch is required
                  </div>
                </div>
              </div>

              <!-- <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Weight</label>
                    <input type="number" class="form-control" placeholder="Item Weight" autocomplete="off" value=""
                      min="0" formControlName="item_weight">
                    
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Carry People</label>
                    <input type="number" class="form-control" placeholder="Item Carry People" autocomplete="off" value=""
                      min="0" formControlName="item_carry_people">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Type Name</label>
                    <input type="text" class="form-control" placeholder="Type Name" autocomplete="off" value=""
                      formControlName="type_name">

                  </div>
                </div>
              
                <div class="col {{defaultUnits.cubic_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Cm</label>
                    <input type="number" class="form-control" placeholder="Item Cube Cm" autocomplete="off" value=""
                      min="0" formControlName="item_cube_cm">

                  </div>
                </div>
                <div class="col {{defaultUnits.cubic_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                    <input type="number" class="form-control" placeholder="Item Cube Dismantled Cm" autocomplete="off"
                      value="" min="0" formControlName="item_cube_dismantled_cm">

                  </div>
                </div>
                
                <div class="col {{defaultUnits.cubic_units=='ft' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Ft</label>
                    <input type="number" class="form-control" placeholder="Item Cube Ft" autocomplete="off" value=""
                      min="0" formControlName="item_cube_ft">

                  </div>
                </div>
                <div class="col {{defaultUnits.cubic_units=='ft' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                    <input type="number" class="form-control" placeholder="Item Cube Dismantled Ft" autocomplete="off"
                      value="" min="0" formControlName="item_cube_dismantled_ft">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group ">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Dismantle</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_dismantle" [value]=true>Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_dismantle" [value]=false>No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Dismantle Time</label>
                    <input type="text" class="form-control" placeholder="Item Dismantle Time" autocomplete="off" value=""
                      min="0" formControlName="item_dismantle_time">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group ">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Reassemble</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_reassemble" [value]=true>Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_reassemble" [value]=false>No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Reassemble Time</label>
                    <input type="text" class="form-control" placeholder="Item Reassemble Time" autocomplete="off" value=""
                      min="0" formControlName="item_reassemble_time">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Typical Value</label>
                    <input type="number" class="form-control" placeholder="Item Typical Value" autocomplete="off" value=""
                      min="0" formControlName="item_typical_value">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Item Provides Storage</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_provides_storage"
                          [value]=true>Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_provides_storage"
                          [value]=false>No
                      </label>
                    </div>
                  </div>
                </div>
                
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Small</label>
                    <input type="number" class="form-control" placeholder="Item Pack Small" autocomplete="off" value=""
                      min="0" formControlName="item_pack_small">

                  </div>
                </div>
             
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Medium</label>
                    <input type="number" class="form-control" placeholder="Item Pack Medium" autocomplete="off" value=""
                      min="0" formControlName="item_pack_med">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Large</label>
                    <input type="number" class="form-control" placeholder="Item Pack Large" autocomplete="off" value=""
                      min="0" formControlName="item_pack_large">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Layflat</label>
                    <input type="number" class="form-control" placeholder="Item Pack Layflat" autocomplete="off" value=""
                      min="0" formControlName="item_pack_layflat">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                    <input type="number" class="form-control" placeholder="Item Pack Wardrobe" autocomplete="off" value=""
                      min="0" formControlName="item_pack_wardrobe">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Picturepack</label>
                    <input type="number" class="form-control" placeholder="Item Pack Picturepack" autocomplete="off"
                      value="" min="0" formControlName="item_pack_picturepack">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Golfbox</label>
                    <input type="number" class="form-control" placeholder="Item Pack Golfbox" autocomplete="off" value=""
                      min="0" formControlName="item_pack_golfbox">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Bikebox</label>
                    <input type="number" class="form-control" placeholder="Item Pack Bikebox" autocomplete="off" value=""
                      min="0" formControlName="item_pack_bikebox">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagsingle" autocomplete="off"
                      value="" min="0" formControlName="item_pack_mattressbagsingle">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagdouble" autocomplete="off"
                      value="" min="0" formControlName="item_pack_mattressbagdouble">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagking" autocomplete="off"
                      value="" min="0" formControlName="item_pack_mattressbagking">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagsuperking"
                      autocomplete="off" value="" min="0" formControlName="item_pack_mattressbagsuperking">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox24" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox24">

                  </div>
                </div>
             
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox32" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox32">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox36" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox36">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox43" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox43">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox48</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox48" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox48">

                  </div>
                </div>
             
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox55" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox55">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox70" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox70">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox85" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox85">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Percentage Full</label>
                    <input type="number" class="form-control" placeholder="Item Percentage Full" autocomplete="off"
                      value="" min="0" formControlName="item_percentage_full">

                  </div>
                </div> -->

            </div>

            <div class="modal-footer border-0 sumit_popup">
              <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
            </div>
          </form>
        </div>

        <!---manual add item section-->
        <div class="tab-pane fade" id="manual" role="tabpanel">
          <form [formGroup]="InventoryItemFormManual" (ngSubmit)="onSubmitInventoryManual()">
            <input type="hidden" value="" formControlName="item_id">
            <input type="hidden" value="" formControlName="type_id">
            <input type="hidden" value="" formControlName="basementIndex" />
            <div class="row inventory-item-row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Name</label>
                  <input type="text" class="form-control" placeholder="Item Name" autocomplete="off" value=""
                    formControlName="item_name">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_name.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_name.errors?.required">
                    Item Name is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Number of items </label>
                  <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                    min="1" formControlName="total_items">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.total_items.touched || submittedInventoryFormManual) && InventoryFormManualControl.total_items.errors?.required">
                    Number of items is required
                  </div>
                  <div class="text-danger" *ngIf="InventoryFormManualControl.total_items.errors?.min">
                    Number of items can not less than 1
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item ?</label>
                  <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                    <option value="">--Select--</option>
                    <option value="TG">To Go</option>
                    <option value="NTG">Not To Go</option>
                    <option value="MTG">Maybe To Go</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_to_go.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_to_go.errors?.required">
                    Item To Go is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Width Cm</label>
                  <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                    min="0" formControlName="item_width_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_width_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_width_cm.errors?.required">
                    Item Width Cm is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Height Cm</label>
                  <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                    min="0" formControlName="item_height_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_height_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_height_cm.errors?.required">
                    Item Height Cm is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Depth Cm</label>
                  <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                    min="0" formControlName="item_depth_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_depth_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_depth_cm.errors?.required">
                    Item Depth Cm is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Width Inch</label>
                  <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                    min="0" formControlName="item_width_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_width_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_width_inch.errors?.required">
                    Item Width Inch is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Height Inch</label>
                  <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                    min="0" formControlName="item_height_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_height_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_height_inch.errors?.required">
                    Item Height Inch is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Depth Inch</label>
                  <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                    min="0" formControlName="item_depth_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_depth_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_depth_inch.errors?.required">
                    Item Depth Inch is required
                  </div>
                </div>
              </div>

              <!-- <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Weight</label>
                    <input type="number" class="form-control" placeholder="Item Weight" autocomplete="off" value=""
                      min="0" formControlName="item_weight">
                    
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Carry People</label>
                    <input type="number" class="form-control" placeholder="Item Carry People" autocomplete="off" value=""
                      min="0" formControlName="item_carry_people">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Type Name</label>
                    <select class="form-control border" id="room_type_new" formControlName="type_name">
                      <option value="">--Select--</option>
                      <option *ngFor="let cat of itemTypeList" [value]="cat.type_name">{{cat.type_name}}</option>                      
                    </select>
                  </div>
                </div>
                
                <div class="col {{defaultUnits.cubic_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Cm</label>
                    <input type="number" class="form-control" placeholder="Item Cube Cm" autocomplete="off" value=""
                      min="0" formControlName="item_cube_cm">

                  </div>
                </div>                
                <div class="col {{defaultUnits.cubic_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                    <input type="number" class="form-control" placeholder="Item Cube Dismantled Cm" autocomplete="off"
                      value="" min="0" formControlName="item_cube_dismantled_cm">

                  </div>
                </div>
                <div class="col {{defaultUnits.cubic_units=='ft' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Ft</label>
                    <input type="number" class="form-control" placeholder="Item Cube Ft" autocomplete="off" value=""
                      min="0" formControlName="item_cube_ft">

                  </div>
                </div>
                <div class="col {{defaultUnits.cubic_units=='ft' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                    <input type="number" class="form-control" placeholder="Item Cube Dismantled Ft" autocomplete="off"
                      value="" min="0" formControlName="item_cube_dismantled_ft">

                  </div>
                </div>

                <div class="col">
                  <div class="form-group ">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Dismantle</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_dismantle" [value]=true>Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_dismantle" [value]=false>No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Dismantle Time</label>
                    <input type="text" class="form-control" placeholder="Item Dismantle Time" autocomplete="off" value=""
                      min="0" formControlName="item_dismantle_time">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group ">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Reassemble</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_reassemble" [value]=true>Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_do_reassemble" [value]=false>No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Reassemble Time</label>
                    <input type="text" class="form-control" placeholder="Item Reassemble Time" autocomplete="off" value=""
                      min="0" formControlName="item_reassemble_time">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Typical Value</label>
                    <input type="number" class="form-control" placeholder="Item Typical Value" autocomplete="off" value=""
                      min="0" formControlName="item_typical_value">

                  </div>
                </div>
                
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Item Provides Storage</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_provides_storage"
                          [value]=true>Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" formControlName="item_provides_storage"
                          [value]=false>No
                      </label>
                    </div>
                  </div>
                </div>
                
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Small</label>
                    <input type="number" class="form-control" placeholder="Item Pack Small" autocomplete="off" value=""
                      min="0" formControlName="item_pack_small">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Medium</label>
                    <input type="number" class="form-control" placeholder="Item Pack Medium" autocomplete="off" value=""
                      min="0" formControlName="item_pack_med">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Large</label>
                    <input type="number" class="form-control" placeholder="Item Pack Large" autocomplete="off" value=""
                      min="0" formControlName="item_pack_large">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Layflat</label>
                    <input type="number" class="form-control" placeholder="Item Pack Layflat" autocomplete="off" value=""
                      min="0" formControlName="item_pack_layflat">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                    <input type="number" class="form-control" placeholder="Item Pack Wardrobe" autocomplete="off" value=""
                      min="0" formControlName="item_pack_wardrobe">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Picturepack</label>
                    <input type="number" class="form-control" placeholder="Item Pack Picturepack" autocomplete="off"
                      value="" min="0" formControlName="item_pack_picturepack">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Golfbox</label>
                    <input type="number" class="form-control" placeholder="Item Pack Golfbox" autocomplete="off" value=""
                      min="0" formControlName="item_pack_golfbox">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Bikebox</label>
                    <input type="number" class="form-control" placeholder="Item Pack Bikebox" autocomplete="off" value=""
                      min="0" formControlName="item_pack_bikebox">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagsingle" autocomplete="off"
                      value="" min="0" formControlName="item_pack_mattressbagsingle">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagdouble" autocomplete="off"
                      value="" min="0" formControlName="item_pack_mattressbagdouble">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagking" autocomplete="off"
                      value="" min="0" formControlName="item_pack_mattressbagking">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                    <input type="number" class="form-control" placeholder="Item Pack Mattressbagsuperking"
                      autocomplete="off" value="" min="0" formControlName="item_pack_mattressbagsuperking">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox24" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox24">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox32" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox32">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox36" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox36">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox43" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox43">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox48</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox48" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox48">

                  </div>
                </div>
              
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox55" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox55">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox70" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox70">

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                    <input type="number" class="form-control" placeholder="Item Pack Tvbox85" autocomplete="off" value=""
                      min="0" formControlName="item_pack_tvbox85">

                  </div>
                </div>                
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Percentage Full</label>
                    <input type="number" class="form-control" placeholder="Item Percentage Full" autocomplete="off"
                      value="" min="0" formControlName="item_percentage_full">

                  </div>
                </div>          -->

            </div>
            <div class="modal-footer border-0 sumit_popup">
              <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
            </div>
          </form>
        </div>
        <!-- last-div -->
      </div>

    </div>


  </div>
</ng-template>
<!-----add inventory modal  box end---->