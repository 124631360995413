import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { ApiService } from "../services/api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogin: any;
  constructor(private router: Router, private apiService: ApiService) { }

  get isLoggedIn(){
    const user = window.localStorage.getItem('token');
    return (user !== null && user!='') ? true : false;    
  }

  get isValidLogin(): Observable<any> {
    const statusPayload={}
    return this.apiService.loginStatus(statusPayload).pipe(tap(data => this.isLogin = data));
 }


}
