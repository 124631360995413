import { Injectable, Component, OnInit } from '@angular/core';
import { openDB, IDBPDatabase, deleteDB, DBSchema } from "idb";
import {Router, ActivatedRoute} from "@angular/router";
import Swal from 'sweetalert2';

interface MyDB {
  'surveyor': {
    key: string;
    value: string;
  };
}


@Injectable({
  providedIn: 'root'
})

export class IndexedDBService {
  version = 1;
  databaseName = "pwa-survey";  

  private db!: IDBPDatabase<MyDB>;
  constructor(private router: Router, private _Activatedroute:ActivatedRoute) {     
    this.connectToDB();
    
    // navigator.storage.estimate().then(({usage, quota}) => {
    //   console.log(`Using ${usage} out of ${quota} bytes.`);
    // });
    
  }

  
  async connectToDB() {
    this.db=  await openDB<MyDB>(this.databaseName, this.version, {
      upgrade(db){
        console.log("db upgrade");
        if (!db.objectStoreNames.contains('item-list')) {
          db.createObjectStore('item-list');
        }
        if (!db.objectStoreNames.contains('category-list')) {
          db.createObjectStore('category-list');
        }
        if (!db.objectStoreNames.contains('template-list')) {
          db.createObjectStore('template-list');
        }
        
        if (!db.objectStoreNames.contains('profile-details')) {
          db.createObjectStore('profile-details');
        }

        if (!db.objectStoreNames.contains('survey-data')) {
          db.createObjectStore("survey-data", { keyPath: ['survey_id', 'surveyor_id', 'survey_format_version', 'report_id'] }); 
        }

        if (!db.objectStoreNames.contains('units')) {
          db.createObjectStore('units');
        }

        // db.createObjectStore('item-list');
        // db.createObjectStore('category-list');
        // db.createObjectStore("survey-data", { keyPath: ['survey_id', 'client_id'] }); 
      },
    });
  }

  addUser(name:string){
    return this.db.put('survey-data', name, 'name');
  }  

  deleteUser(key:string){
    return this.db.delete('survey-data', key);
  }

  addProfileDetails(data:any){
    if(!this.db.objectStoreNames.contains("profile-details")) {
      this.db.createObjectStore('profile-details');
    }
    return this.db.put('profile-details', data, 'profile-data');
  } 

  getProfileDetails(){    
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["profile-details"]);
      var objectStore0 = transaction.objectStore("profile-details");
     // console.log(objectStore0);
      objectStore0.get("profile-data").onsuccess = function(event:any) {        
        var cursor2 = event.target.result; 
        resolve(cursor2);               
      }      
    });    
    
  }

  addInventoryItems(data:string){
    if(!this.db.objectStoreNames.contains("item-list")) {
      this.db.createObjectStore('item-list');
    }
    return this.db.put('item-list', data, 'item-data');
  }  

  getInventoryItems(){    
    const tx = this.db.transaction('item-list');
    const store = tx.objectStore('item-list');
    return store.get('item-data'); 
    // store.get('item-data').then( 
    //   res => console.log(res)
    // );  
    
  }

  addCategories(data:string){
    if(!this.db.objectStoreNames.contains("category-list")) {
      this.db.createObjectStore('category-list');
    }
    return this.db.put('category-list', data, 'category-data');
  }

  getCateogries(){    
    const tx = this.db.transaction('category-list');
    const store = tx.objectStore('category-list');
    return store.get('category-data'); 
    
  }

  addTemplateList(data:string){
    if(!this.db.objectStoreNames.contains("template-list")) {
      this.db.createObjectStore('template-list');
    }
    return this.db.put('template-list', data, 'template-data');
  }
  
  getTemplateList(){    
    const tx = this.db.transaction('template-list');
    const store = tx.objectStore('template-list');
    return new Promise((resolve,reject)=>store.get('template-data')
      .then(res =>{        
        resolve(res);   
      })
      .catch(function (err) { 
        console.log('Some error has occured');
        reject(err); 
      })
    )
    //return store.get('template-data'); 
    
  }

  addMeasurementUnits(unitsData:any){  
    if(!this.db.objectStoreNames.contains("units")) {
      this.db.createObjectStore('units');
    }
    return this.db.put('units', unitsData, 'units-data');    
  }


  getDefaultUnits(){    
    const tx = this.db.transaction('units');
    const store = tx.objectStore('units');
    return new Promise((resolve,reject)=>store.get('units-data')
      .then(res =>{        
        resolve(res);   
      })
      .catch(function (err) { 
        console.log('Some error has occured');
        reject(err); 
      })
    )   
  }

  getSurveyDetailsLocal(Payload:any){
    
    var transaction = this.db.transaction(["survey-data"]);
    var objectStore = transaction.objectStore("survey-data");
    
    return new Promise((resolve,reject)=>objectStore.get(Payload)
      .then(res =>{        
        resolve(res);   
      })
      .catch(function (err) { 
        console.log('Some error has occured');
        reject(err); 
      })
    )
  }

  getSurveyDetailsLocalApiError(Payload:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"]);
      var objectStore0 = transaction.objectStore("survey-data");
          
      objectStore0.getAll().onsuccess = function(event:any) {        
        var cursor2 = event.target.result;  
            
        if (cursor2) { 
          let idx = cursor2.findIndex((elem:any) => (elem.survey_id) === (Payload.survey_id) && (elem.report_id) === (Payload.report_id))
           
          if (idx !== -1){
            cursor2=cursor2[idx]
          }else{
            cursor2=[];
          }            
          resolve(cursor2);
        }          
      }
      
    });
    
  }


  getSurveyListLocal(){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"]);
      var objectStore0 = transaction.objectStore("survey-data");
          
      objectStore0.getAll().onsuccess = function(event:any) {        
        var cursor2 = event.target.result;              
        if (cursor2) { 
          resolve(cursor2);
        }          
      }      
    });
    
  }

  deleteSurveyLocal(data:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(data)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          cursor2.delete();         
          resolve({success :true});             
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }

  
  addSurveyDetailsLocal(data:any, report_id:any){
   
    if(!this.db.objectStoreNames.contains("survey-data")) {
      this.db.createObjectStore("survey-data", { keyPath: ['survey_id', 'surveyor_id', 'survey_format_version', 'report_id'] });         
    }
    
    var transaction = this.db.transaction(["survey-data"], 'readwrite');
    var objectStore11 = transaction.objectStore("survey-data");
    
    const surveyPayload=[Number(data.survey_id), Number(data.surveyor_id), Number(data.survey_format_version), Number(report_id)];
   
    return new Promise((resolve,reject)=>objectStore11.get(surveyPayload).then(res =>{      
      if(res==undefined){
        objectStore11.put({
          survey_id: data.survey_id,  // first part of key
          surveyor_id: data.surveyor_id,  // second part of key
          survey_format_version: data.survey_format_version,
          report_id: Number(report_id),
          data: data  // other data to store
        });       
        
        resolve({success :true});
        console.log("added successfully");
      }else{
        resolve({success :true});
      }     
    })
    .catch(function (err) {
      reject(err); 
      console.log('Some error has occured'); 
    })
    );

  }

  
  addNotesFormData(notesData:any, currentSurvey:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateNotes = cursor2.value;
          var updateNotesList = cursor2.value.data.notes;
          
          if(updateNotesList!=null){
            updateNotesList.push(notesData);            
          }else{
            updateNotesList=[];
            updateNotesList.push(notesData);            
          }

          updateNotes.data.notes= updateNotesList;
          const requestNotes = cursor2.update(updateNotes);
          requestNotes.onsuccess = function() {
            console.log('notes updated');
            resolve({success :true});
          };  
          requestNotes.onerror = function(err:any) {
            //status= "error";
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };                 
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  deleteNoteSingle(noteIndex:any, surveyPayload:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateNotes = cursor2.value;
          var updateNotesList = cursor2.value.data.notes; 
          // updateNotesList = updateNotesList.filter(function( obj:any ) {
          //   console.log(obj);
          //   //return obj.note !== "Note about Bed 4";
          // });
          
          updateNotesList.splice(noteIndex, 1);                 
          updateNotes.data.notes= updateNotesList;

          const requestNotes = cursor2.update(updateNotes);
          requestNotes.onsuccess = function() {
            console.log('note removed');
            resolve({success :true});
          };  
          requestNotes.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };                 
        } else {
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  editNoteSingle(noteData:any, surveyPayload:any, noteIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateNotes = cursor2.value;
          var updateNotesList = cursor2.value.data.notes; 

          updateNotesList[noteIndex] =noteData;
          updateNotes.data.notes= updateNotesList;
          
          const requestNotes = cursor2.update(updateNotes);
          requestNotes.onsuccess = function() {
            console.log('note updated');
            resolve({success :true});
          };  
          requestNotes.onerror = function(err:any) {
            reject(err);
          };                 
        } else {
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  uploadImageFormData(imageData:any, currentSurvey:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;
                
        if (cursor2) {              
          var updateImages = cursor2.value;
          var updateImagesList = cursor2.value.data.images;   
         
          if(updateImagesList!=null){
            updateImagesList.push(imageData);           
          }else{
            updateImagesList=[];
            updateImagesList.push(imageData);           
          }    
          updateImages.data.images= updateImagesList;          
          const requestNotes = cursor2.update(updateImages);
          requestNotes.onsuccess = function() {
            console.log('image uploaded');
            resolve({success :true});
          };  
          requestNotes.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };                 
        } else {
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }      
    });   
    
  }


  deleteImageGallery(imageIndex:any, surveyPayload:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateImages = cursor2.value;
          var updateImagesList = cursor2.value.data.images; 
          // updateImagesList = updateImagesList.filter(function( obj:any ) {
          //   console.log(obj);
          //   //return obj.note !== "Note about Bed 4";
          // });
          
          updateImagesList.splice(imageIndex, 1);  
          updateImages.data.images= updateImagesList;

          const requestImages = cursor2.update(updateImages);
          requestImages.onsuccess = function() {
            console.log('image removed');            
            resolve({success :true});
          };  
          requestImages.onerror = function(err:any) {
            //Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };                 
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addPropertyFormData(propertyData:any, currentSurvey:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateProperty = cursor2.value;          
          var updatePropertyList = cursor2.value.data.property;
          if(propertyData.formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(propertyData.formType=='from'){            
            const updateData=  updatePropertyList.from;
            updateData.add1= propertyData.add1;
            updateData.property_type= propertyData.property_type;
            updateData.property_floor= propertyData.property_floor;
            updateData.add2= propertyData.add2;
            updateData.estate_agent= propertyData.estate_agent;
            updateData.estate_agent_name= propertyData.estate_agent_name;
            updateData.add3= propertyData.add3;
            updateData.bedroom_count= propertyData.bedroom_count;
            updateData.ownership= propertyData.ownership;
            updateData.add4= propertyData.add4;
            updateData.new_build= propertyData.new_build;
            updateData.country= propertyData.country;
            updateData.postcode= propertyData.postcode;

            updatePropertyList= updateData;           
            const requestProperty = cursor2.update(updateProperty);
            requestProperty.onsuccess = function() {
              console.log('From Property updated');
              resolve({success :true});
            };  
            requestProperty.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(propertyData.formType=='to'){            
            const updateData=  updatePropertyList.to;
            updateData.add1= propertyData.add1;
            updateData.property_type= propertyData.property_type;
            updateData.property_floor= propertyData.property_floor;
            updateData.add2= propertyData.add2;
            updateData.estate_agent= propertyData.estate_agent;
            updateData.estate_agent_name= propertyData.estate_agent_name;
            updateData.add3= propertyData.add3;
            updateData.bedroom_count= propertyData.bedroom_count;
            updateData.ownership= propertyData.ownership;
            updateData.add4= propertyData.add4;
            updateData.new_build= propertyData.new_build;
            updateData.country= propertyData.country;
            updateData.postcode= propertyData.postcode;

            updatePropertyList= updateData;           
            const requestProperty = cursor2.update(updateProperty);
            requestProperty.onsuccess = function() {
              console.log('From Property updated');
              resolve({success :true});
            };  
            requestProperty.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addAccessParkingFormData(parkingData:any, currentSurvey:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateParking = cursor2.value;          
          var updateParkingList = cursor2.value.data.property;
          if(parkingData.formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(parkingData.formType=='from'){            
            const updateData=  updateParkingList.from.access;
            updateData.driveway= parkingData.driveway;
            updateData.driveway_type= parkingData.driveway_type;
            updateData.driveway_shared= parkingData.driveway_shared;
            updateData.park_outside= parkingData.park_outside;
            updateData.park_restrictions= parkingData.park_restrictions;
            updateData.park_suspensions= parkingData.park_suspensions;
            
            updateParkingList= updateData;                   
            const requestParking = cursor2.update(updateParking);
            requestParking.onsuccess = function() {
              console.log('From Access Parking updated');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(parkingData.formType=='to'){            
            const updateData=  updateParkingList.to.access;
            updateData.driveway= parkingData.driveway;
            updateData.driveway_type= parkingData.driveway_type;
            updateData.driveway_shared= parkingData.driveway_shared;
            updateData.park_outside= parkingData.park_outside;
            updateData.park_restrictions= parkingData.park_restrictions;
            updateData.park_suspensions= parkingData.park_suspensions;            

            updateParkingList= updateData;       
            const requestProperty = cursor2.update(updateParking);
            requestProperty.onsuccess = function() {
              console.log('From Access Parking updated');
              resolve({success :true});
            };  
            requestProperty.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addBasementFormData(basementData:any, currentSurvey:any, formType:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let basementDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateBasement = cursor2.value;          
          var updateBasementList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');            
          }
          if(formType=='from'){            
            basementDataArr =  updateBasementList.from.plan.basements;
            if(basementDataArr!=null){                
              let idx = basementDataArr.findIndex((elem:any) => (elem.basement_name).toLowerCase() === (basementData.basement_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Basement Name already exist!', 'error');                            
              }else{
                basementDataArr.push(basementData);
                updateBasement.data.property.from.plan.basements= basementDataArr;
                                
                const requestBasement = cursor2.update(updateBasement);
                requestBasement.onsuccess = function() {
                  console.log('From Basement Added');
                  resolve({success :true});
                };  
                requestBasement.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              basementDataArr=[];
              basementDataArr.push(basementData);
              updateBasement.data.property.from.plan.basements= basementDataArr;
              // console.log(updateBasement);              
              const requestBasement = cursor2.update(updateBasement);
              requestBasement.onsuccess = function() {
                console.log('From Basement Added');
                resolve({success :true});
              };  
              requestBasement.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
            
          }

          if(formType=='to'){            
            basementDataArr =  updateBasementList.to.plan.basements;
            if(basementDataArr!=null){                
              let idx = basementDataArr.findIndex((elem:any) => (elem.basement_name).toLowerCase() === (basementData.basement_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Basement Name already exist!', 'error');                              
              }else{
                basementDataArr.push(basementData);
                updateBasement.data.property.to.plan.basements= basementDataArr;
                // console.log(updateBasement);
                
                const requestBasement = cursor2.update(updateBasement);
                requestBasement.onsuccess = function() {
                  console.log('To Basement Added');
                  resolve({success :true});
                };  
                requestBasement.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              basementDataArr=[];
              basementDataArr.push(basementData);
              updateBasement.data.property.to.plan.basements= basementDataArr;
              // console.log(updateBasement);              
              const requestBasement = cursor2.update(updateBasement);
              requestBasement.onsuccess = function() {
                console.log('To Basement Added');
                resolve({success :true});
              };  
              requestBasement.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  updateBasementFormData(basementData:any, currentSurvey:any, formType:any, basementIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let basementDataArr=[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateBasement = cursor2.value;          
          var updateBasementList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){            
            basementDataArr=  updateBasementList.from.plan.basements;                        
            let idx = basementDataArr.findIndex((elem:any) => (elem.basement_name).toLowerCase() === (basementData.basement_name).toLowerCase())
            
            if (idx !== -1 && idx!=basementIndex){
              Swal.fire('Error', 'Basement Name already exist!', 'error');              
            }else{
              basementDataArr[basementIndex].basement_name = basementData.basement_name;
              basementDataArr[basementIndex].basement_packing = basementData.basement_packing;
              basementDataArr[basementIndex].basement_ntg = basementData.basement_ntg;
              basementDataArr[basementIndex].basement_seen = basementData.basement_seen;
              basementDataArr[basementIndex].basement_fully_lit = basementData.basement_fully_lit;             
              basementDataArr[basementIndex].basement_box_estimate = basementData.basement_box_estimate;             
              basementDataArr[basementIndex].move_light_fittings = basementData.move_light_fittings;             
              basementDataArr[basementIndex].move_curtains_blinds = basementData.move_curtains_blinds;             
              basementDataArr[basementIndex].move_carpets = basementData.move_carpets;             
              
              const requesBasement = cursor2.update(updateBasement);
              requesBasement.onsuccess = function() {
                console.log('Basement updated');
                resolve({success :true});
              };  
              requesBasement.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }
          }

          if(formType=='to'){            
            basementDataArr=  updateBasementList.to.plan.basements;                        
            let idx = basementDataArr.findIndex((elem:any) => (elem.basement_name).toLowerCase() === (basementData.basement_name).toLowerCase())
            
            if (idx !== -1 && idx!=basementIndex){
              Swal.fire('Error', 'Basement Name already exist!', 'error');              
            }else{
              basementDataArr[basementIndex].basement_name = basementData.basement_name;
              basementDataArr[basementIndex].basement_packing = basementData.basement_packing;
              basementDataArr[basementIndex].basement_ntg = basementData.basement_ntg;
              basementDataArr[basementIndex].basement_seen = basementData.basement_seen;
              basementDataArr[basementIndex].basement_fully_lit = basementData.basement_fully_lit;             
              basementDataArr[basementIndex].basement_box_estimate = basementData.basement_box_estimate;             
              basementDataArr[basementIndex].move_light_fittings = basementData.move_light_fittings;             
              basementDataArr[basementIndex].move_curtains_blinds = basementData.move_curtains_blinds;             
              basementDataArr[basementIndex].move_carpets = basementData.move_carpets;             
              
              const requesBasement = cursor2.update(updateBasement);
              requesBasement.onsuccess = function() {
                console.log('Basement updated');
                resolve({success :true});
              };  
              requesBasement.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  updatePackingMaterialFormData(PackingData:any, currentSurvey:any, formType:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updatePacking = cursor2.value;          
          var updatePackingList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){            
            const updateData=  updatePackingList.from.plan.basements;
            updateData.packing_materials= PackingData;
                     
            updatePackingList= updateData;                   
            const requestParking = cursor2.update(updatePacking);
            requestParking.onsuccess = function() {
              console.log('Packing Material updated');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){            
            const updateData=  updatePackingList.to.plan.basements;
            updateData.packing_materials= PackingData;            

            updatePackingList= updateData;                   
            const requestParking = cursor2.update(updatePacking);
            requestParking.onsuccess = function() {
              console.log('Packing Material updated');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addInventoryFormData(InventoryData:any, currentSurvey:any, formType:any, basementIndex:any, total_items:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateData=  updateInventoryList.from.plan.basements[basementIndex].inventory;
            for (let i = 0; i < total_items; i++) {
              updateData.push(InventoryData);
            } 
            updateInventory.data.property.from.plan.basements[basementIndex].inventory= updateData;
            const requestParking = cursor2.update(updateInventory);
            requestParking.onsuccess = function() {
              console.log('Inventory Item added');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){            
            var updateData=  updateInventoryList.to.plan.basements[basementIndex].inventory;            
            updateData.push(InventoryData);
            updateInventory.data.property.to.plan.basements[basementIndex].inventory= updateData;
            const requestParking = cursor2.update(updateInventory);
            requestParking.onsuccess = function() {
              console.log('Inventory Item added');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }


  addloftFormData(loftData:any, currentSurvey:any, formType:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let loftDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateLoft = cursor2.value;          
          var updateLoftList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){            
            loftDataArr =  updateLoftList.from.plan.lofts;
            if(loftDataArr!=null){                
              let idx = loftDataArr.findIndex((elem:any) => (elem.loft_name).toLowerCase() === (loftData.loft_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Loft Name already exist!', 'error');
                          
              }else{
                loftDataArr.push(loftData);
                updateLoft.data.property.from.plan.lofts= loftDataArr;
                // console.log(updateLoft);
                
                const requestLoft = cursor2.update(updateLoft);
                requestLoft.onsuccess = function() {
                  console.log('From Loft Added');
                  resolve({success :true});
                };  
                requestLoft.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              loftDataArr=[];
              loftDataArr.push(loftData);
              updateLoft.data.property.from.plan.lofts= loftDataArr;
              // console.log(updateLoft);              
              const requestLoft = cursor2.update(updateLoft);
              requestLoft.onsuccess = function() {
                console.log('From Loft Added');
                resolve({success :true});
              };  
              requestLoft.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
            
          }

          if(formType=='to'){            
            loftDataArr =  updateLoftList.to.plan.lofts;
            if(loftDataArr!=null){                
              let idx = loftDataArr.findIndex((elem:any) => (elem.loft_name).toLowerCase() === (loftData.loft_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Loft Name already exist!', 'error');              
              }else{
                loftDataArr.push(loftData);
                updateLoft.data.property.to.plan.lofts= loftDataArr;
                // console.log(updateLoft);
                
                const requestLoft = cursor2.update(updateLoft);
                requestLoft.onsuccess = function() {
                  console.log('To Loft Added');
                  resolve({success :true});
                };  
                requestLoft.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              loftDataArr=[];
              loftDataArr.push(loftData);
              updateLoft.data.property.to.plan.lofts= loftDataArr;
              // console.log(updateLoft);              
              const requestLoft = cursor2.update(updateLoft);
              requestLoft.onsuccess = function() {
                console.log('To Loft Added');
                resolve({success :true});
              };  
              requestLoft.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });   
    
  }


  updateLoftFormData(loftData:any, currentSurvey:any, formType:any, loftIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let loftDataArr=[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateLoft = cursor2.value;          
          var updateLoftList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){            
            loftDataArr=  updateLoftList.from.plan.lofts;   
                               
            let idx = loftDataArr.findIndex((elem:any) => (elem.loft_name).toLowerCase() === (loftData.loft_name).toLowerCase())
            
            if (idx !== -1 && idx!=loftIndex){
              Swal.fire('Error', 'Loft Name already exist!', 'error');              
            }else{
              loftDataArr[loftIndex].loft_name = loftData.loft_name;
              loftDataArr[loftIndex].loft_packing = loftData.loft_packing;
              loftDataArr[loftIndex].loft_ntg = loftData.loft_ntg;
              loftDataArr[loftIndex].loft_seen = loftData.loft_seen;
              loftDataArr[loftIndex].loft_client_to_empty = loftData.loft_client_to_empty;
              loftDataArr[loftIndex].loft_fixed_ladders = loftData.loft_fixed_ladders;
              loftDataArr[loftIndex].loft_fully_boarded = loftData.loft_fully_boarded;
              loftDataArr[loftIndex].loft_fully_lit = loftData.loft_fully_lit;             
              loftDataArr[loftIndex].loft_box_estimate = loftData.loft_box_estimate;             
              loftDataArr[loftIndex].move_light_fittings = loftData.move_light_fittings;             
              loftDataArr[loftIndex].move_curtains_blinds = loftData.move_curtains_blinds;             
              loftDataArr[loftIndex].move_carpets = loftData.move_carpets;             
         
              const requesBasement = cursor2.update(updateLoft);
              requesBasement.onsuccess = function() {
                console.log('Loft updated');
                resolve({success :true});
              };  
              requesBasement.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            } 
          }

          if(formType=='to'){            
            loftDataArr=  updateLoftList.to.plan.lofts;                        
            let idx = loftDataArr.findIndex((elem:any) => (elem.loft_name).toLowerCase() === (loftData.loft_name).toLowerCase())
            
            if (idx !== -1 && idx!=loftIndex){
              Swal.fire('Error', 'Loft Name already exist!', 'error');              
            }else{
              loftDataArr[loftIndex].loft_name = loftData.loft_name;
              loftDataArr[loftIndex].loft_packing = loftData.loft_packing;
              loftDataArr[loftIndex].loft_ntg = loftData.loft_ntg;
              loftDataArr[loftIndex].loft_seen = loftData.loft_seen;
              loftDataArr[loftIndex].loft_client_to_empty = loftData.loft_client_to_empty;
              loftDataArr[loftIndex].loft_fixed_ladders = loftData.loft_fixed_ladders;
              loftDataArr[loftIndex].loft_fully_boarded = loftData.loft_fully_boarded;
              loftDataArr[loftIndex].loft_fully_lit = loftData.loft_fully_lit;             
              loftDataArr[loftIndex].loft_box_estimate = loftData.loft_box_estimate;             
              loftDataArr[loftIndex].move_light_fittings = loftData.move_light_fittings;             
              loftDataArr[loftIndex].move_curtains_blinds = loftData.move_curtains_blinds;             
              loftDataArr[loftIndex].move_carpets = loftData.move_carpets;             
         
              const requesBasement = cursor2.update(updateLoft);
              requesBasement.onsuccess = function() {
                console.log('Loft updated');
                resolve({success :true});
              };  
              requesBasement.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            } 
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  updateLoftsPackingMaterialFormData(PackingData:any, currentSurvey:any, formType:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updatePacking = cursor2.value;          
          var updatePackingList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){            
            const updateData=  updatePackingList.from.plan.lofts;
            updateData.packing_materials= PackingData;
                     
            updatePackingList= updateData;                   
            const requestParking = cursor2.update(updatePacking);
            requestParking.onsuccess = function() {
              console.log('Packing Material updated');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){            
            const updateData=  updatePackingList.to.plan.lofts;
            updateData.packing_materials= PackingData;            

            updatePackingList= updateData;                   
            const requestParking = cursor2.update(updatePacking);
            requestParking.onsuccess = function() {
              console.log('Packing Material updated');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addLoftInventoryFormData(InventoryData:any, currentSurvey:any, formType:any, loftIndex:number, total_items:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){            
            var updateData=  updateInventoryList.from.plan.lofts[loftIndex].inventory;            
             
            for (let i = 0; i < total_items; i++) {
              updateData.push(InventoryData);
            }
            updateInventory.data.property.from.plan.lofts[loftIndex].inventory= updateData;
            const requestParking = cursor2.update(updateInventory);
            requestParking.onsuccess = function() {
              console.log('Inventory Item added');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){            
            var updateData=  updateInventoryList.to.plan.lofts[loftIndex].inventory;            
            updateData.push(InventoryData);

            updateInventory.data.property.to.plan.lofts[loftIndex].inventory= updateData;             
            const requestParking = cursor2.update(updateInventory);
            requestParking.onsuccess = function() {
              console.log('Inventory Item added');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }


  addFloorFormData(floorData:any, currentSurvey:any, formType:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let floorDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateFloor = cursor2.value;          
          var updateFloorList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            floorDataArr=  updateFloorList.from.plan.floors; 
            if(floorDataArr!=null){                
              let idx = floorDataArr.findIndex((elem:any) => (elem.floor_name).toLowerCase() === (floorData.floor_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Floor Name already exist!', 'error');
                             
              }else{
                floorDataArr.push(floorData);
                updateFloor.data.property.from.plan.floors= floorDataArr;
                // console.log(updateFloor);
                
                const requestFloor = cursor2.update(updateFloor);
                requestFloor.onsuccess = function() {
                  console.log('From Floor Added');
                  resolve({success :true});
                };  
                requestFloor.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              floorDataArr=[];
              floorDataArr.push(floorData);
              updateFloor.data.property.from.plan.floors= floorDataArr;
              // console.log(updateFloor);              
              const requestFloor = cursor2.update(updateFloor);
              requestFloor.onsuccess = function() {
                console.log('From Floor Added');
                resolve({success :true});
              };  
              requestFloor.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
          }

          if(formType=='to'){  
            floorDataArr=  updateFloorList.to.plan.floors; 
            if(floorDataArr!=null){                
              let idx = floorDataArr.findIndex((elem:any) => (elem.floor_name).toLowerCase() === (floorData.floor_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Floor Name already exist!', 'error');                          
              }else{
                floorDataArr.push(floorData);
                updateFloor.data.property.to.plan.floors= floorDataArr;
                // console.log(updateFloor);
                
                const requestFloor = cursor2.update(updateFloor);
                requestFloor.onsuccess = function() {
                  console.log('To Floor Added');
                  resolve({success :true});
                };  
                requestFloor.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              floorDataArr=[];
              floorDataArr.push(floorData);
              updateFloor.data.property.to.plan.floors= floorDataArr;
              // console.log(updateFloor);              
              const requestFloor = cursor2.update(updateFloor);
              requestFloor.onsuccess = function() {
                console.log('To Floor Added');
                resolve({success :true});
              };  
              requestFloor.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addRoomFormData(roomData:any, currentSurvey:any, formType:any, roomIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let roomDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateRoom = cursor2.value;          
          var updateRoomList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            roomDataArr=  updateRoomList.from.plan.floors[roomIndex].floor_rooms; 
            if(roomDataArr!=''){                
              let idx = roomDataArr.findIndex((elem:any) => (elem.room_name).toLowerCase() === (roomData.room_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Room Name already exist!', 'error');
                              
              }else{
                roomDataArr.push(roomData);
                updateRoom.data.property.from.plan.floors[roomIndex].floor_rooms= roomDataArr;
                // console.log(updateRoom);                
                const requestRoom = cursor2.update(updateRoom);
                requestRoom.onsuccess = function() {
                  console.log('From Room Added');
                  resolve({success :true});
                };  
                requestRoom.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              roomDataArr=[];
              roomDataArr.push(roomData);
              updateRoom.data.property.from.plan.floors[roomIndex].floor_rooms= roomDataArr;
              // console.log(updateRoom);              
              const requestRoom = cursor2.update(updateRoom);
              requestRoom.onsuccess = function() {
                console.log('From Room Added');
                resolve({success :true});
              };  
              requestRoom.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
          }

          if(formType=='to'){  
            roomDataArr=  updateRoomList.to.plan.floors[roomIndex].floor_rooms; 
            if(roomDataArr!=''){                
              let idx = roomDataArr.findIndex((elem:any) => (elem.room_name).toLowerCase() === (roomData.room_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Room Name already exist!', 'error');             
              }else{
                roomDataArr.push(roomData);
                updateRoom.data.property.to.plan.floors[roomIndex].floor_rooms= roomDataArr;
                const requestRoom = cursor2.update(updateRoom);
                requestRoom.onsuccess = function() {
                  console.log('To Room Added');
                  resolve({success :true});
                };  
                requestRoom.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              roomDataArr=[];
              roomDataArr.push(roomData);
              updateRoom.data.property.to.plan.floors[roomIndex].floor_rooms= roomDataArr;
              // console.log(updateRoom);              
              const requestRoom = cursor2.update(updateRoom);
              requestRoom.onsuccess = function() {
                console.log('To Room Added');
                resolve({success :true});
              };  
              requestRoom.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
          }               
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }      
    });    
    
  }


  updateRoomFormData(roomData:any, currentSurvey:any, formType:any, floorIndex:number, roomIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let roomDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateRoom = cursor2.value;          
          var updateRoomList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            roomDataArr=  updateRoomList.from.plan.floors[floorIndex].floor_rooms; 
                           
              let idx = roomDataArr.findIndex((elem:any) => (elem.room_name).toLowerCase() === (roomData.room_name).toLowerCase())
              if (idx !== -1 && idx!=roomIndex){
                Swal.fire('Error', 'Room Name already exist!', 'error');
                              
              }else{
                roomDataArr[roomIndex].room_name = roomData.room_name;
                roomDataArr[roomIndex].room_type = roomData.room_type;
                roomDataArr[roomIndex].room_packing = roomData.room_packing;
                roomDataArr[roomIndex].room_ntg = roomData.room_ntg;
                roomDataArr[roomIndex].room_seen = roomData.room_seen;             
                roomDataArr[roomIndex].room_box_estimate = roomData.room_box_estimate;             
                roomDataArr[roomIndex].move_light_fittings = roomData.move_light_fittings;             
                roomDataArr[roomIndex].move_curtains_blinds = roomData.move_curtains_blinds;             
                roomDataArr[roomIndex].move_carpets = roomData.move_carpets;               
                const requestRoom = cursor2.update(updateRoom);
                requestRoom.onsuccess = function() {
                  console.log('Room updated');
                  resolve({success :true});
                };  
                requestRoom.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
          
          }

          if(formType=='to'){  
            roomDataArr=  updateRoomList.to.plan.floors[floorIndex].floor_rooms; 
                          
              let idx = roomDataArr.findIndex((elem:any) => (elem.room_name).toLowerCase() === (roomData.room_name).toLowerCase())
              if (idx !== -1 && idx!=roomIndex){
                Swal.fire('Error', 'Room Name already exist!', 'error');
                              
              }else{
                roomDataArr[roomIndex].room_name = roomData.room_name;
                roomDataArr[roomIndex].room_type = roomData.room_type;
                roomDataArr[roomIndex].room_packing = roomData.room_packing;
                roomDataArr[roomIndex].room_ntg = roomData.room_ntg;
                roomDataArr[roomIndex].room_seen = roomData.room_seen;             
                roomDataArr[roomIndex].room_box_estimate = roomData.room_box_estimate;             
                roomDataArr[roomIndex].move_light_fittings = roomData.move_light_fittings;             
                roomDataArr[roomIndex].move_curtains_blinds = roomData.move_curtains_blinds;             
                roomDataArr[roomIndex].move_carpets = roomData.move_carpets;               
                const requestRoom = cursor2.update(updateRoom);
                requestRoom.onsuccess = function() {
                  console.log('Room updated');
                  resolve({success :true});
                };  
                requestRoom.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              } 
          }               
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }      
    });    
    
  }



  addAreaFormData(areaData:any, currentSurvey:any, formType:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let areaDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateArea = cursor2.value;          
          var updateAreaList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            areaDataArr=  updateAreaList.from.plan.other_areas; 
            if(areaDataArr!=null){                
              let idx = areaDataArr.findIndex((elem:any) => (elem.area_name).toLowerCase() === (areaData.area_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Area Name already exist!', 'error');              
              }else{
                areaDataArr.push(areaData);
                updateArea.data.property.from.plan.other_areas= areaDataArr;
                // console.log(updateArea);
                
                const requestArea = cursor2.update(updateArea);
                requestArea.onsuccess = function() {
                  console.log('From Area Added');
                  resolve({success :true});
                };  
                requestArea.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              areaDataArr=[];
              areaDataArr.push(areaData);
              updateArea.data.property.from.plan.other_areas= areaDataArr;
              // console.log(updateArea);              
              const requestArea = cursor2.update(updateArea);
              requestArea.onsuccess = function() {
                console.log('From Area Added');
                resolve({success :true});
              };  
              requestArea.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
          }

          if(formType=='to'){  
            areaDataArr=  updateAreaList.to.plan.other_areas; 
            if(areaDataArr!=null){                
              let idx = areaDataArr.findIndex((elem:any) => (elem.area_name).toLowerCase() === (areaData.area_name).toLowerCase())
              if (idx !== -1){
                Swal.fire('Error', 'Area Name already exist!', 'error');              
              }else{
                areaDataArr.push(areaData);
                updateArea.data.property.to.plan.other_areas= areaDataArr;
                // console.log(updateArea);
                
                const requestArea = cursor2.update(updateArea);
                requestArea.onsuccess = function() {
                  console.log('To Area Added');
                  resolve({success :true});
                };  
                requestArea.onerror = function(err:any) {
                  Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                  reject(err);
                };
              }              
            }else{              
              areaDataArr=[];
              areaDataArr.push(areaData);
              updateArea.data.property.to.plan.other_areas= areaDataArr;
              // console.log(updateArea);              
              const requestArea = cursor2.update(updateArea);
              requestArea.onsuccess = function() {
                console.log('To Area Added');
                resolve({success :true});
              };  
              requestArea.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };                            
            }
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }

  updateAreaFormData(areaData:any, currentSurvey:any, formType:any, areaIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let areaDataArr =[];      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateArea = cursor2.value;          
          var updateAreaList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            areaDataArr=  updateAreaList.from.plan.other_areas;                        
            let idx = areaDataArr.findIndex((elem:any) => (elem.area_name).toLowerCase() === (areaData.area_name).toLowerCase())
            
            if (idx !== -1 && idx!=areaIndex){
              Swal.fire('Error', 'Area Name already exist!', 'error');              
            }else{
              areaDataArr[areaIndex].area_name = areaData.area_name;
              areaDataArr[areaIndex].area_ntg = areaData.area_ntg;
              areaDataArr[areaIndex].area_box_estimate = areaData.area_box_estimate;
              areaDataArr[areaIndex].area_packing = areaData.area_packing;
              areaDataArr[areaIndex].area_seen = areaData.area_seen;             
              areaDataArr[areaIndex].move_carpets = areaData.move_carpets;
              areaDataArr[areaIndex].move_curtains_blinds = areaData.move_curtains_blinds;             
              
              const requestArea = cursor2.update(updateArea);
              requestArea.onsuccess = function() {
                console.log('Area updated');
                resolve({success :true});
              };  
              requestArea.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            } 
          }

          if(formType=='to'){  
            areaDataArr=  updateAreaList.to.plan.other_areas;                        
            let idx = areaDataArr.findIndex((elem:any) => (elem.area_name).toLowerCase() === (areaData.area_name).toLowerCase())
            
            if (idx !== -1 && idx!=areaIndex){
              Swal.fire('Error', 'Area Name already exist!', 'error');              
            }else{
              areaDataArr[areaIndex].area_name = areaData.area_name;
              areaDataArr[areaIndex].area_ntg = areaData.area_ntg;
              areaDataArr[areaIndex].area_box_estimate = areaData.area_box_estimate;
              areaDataArr[areaIndex].area_packing = areaData.area_packing;
              areaDataArr[areaIndex].area_seen = areaData.area_seen;             
              
              const requestArea = cursor2.update(updateArea);
              requestArea.onsuccess = function() {
                console.log('Area updated');
                resolve({success :true});
              };  
              requestArea.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }   
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  addRoomInventoryFormData(inventoryData:any, currentSurvey:any, formType:any, floorIndex:any, roomIndex:any, total_items:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let inventoryDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;        
         if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            inventoryDataArr=  updateInventoryList.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory;             
            if(inventoryDataArr!=null){
              for (let i = 0; i < total_items; i++) {
                inventoryDataArr.push(inventoryData);
              } 
               
              updateInventory.data.property.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory= inventoryDataArr;
              
              const requestInventory = cursor2.update(updateInventory);
              requestInventory.onsuccess = function() {
                console.log('From Inventory Added');
                resolve({success :true});
              };  
              requestInventory.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }else{
              inventoryDataArr=[];
              for (let i = 0; i < total_items; i++) {
                inventoryDataArr.push(inventoryData);
              } 
              updateInventory.data.property.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory= inventoryDataArr;
              
              const requestInventory = cursor2.update(updateInventory);
              requestInventory.onsuccess = function() {
                console.log('From Inventory Added');
                resolve({success :true});
              };  
              requestInventory.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }
                                       
            
          }

          if(formType=='to'){  
            inventoryDataArr=  updateInventoryList.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory;
            
            if(inventoryDataArr!=null){
              inventoryDataArr.push(inventoryData); 
              updateInventory.data.property.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory= inventoryDataArr;
              
              const requestInventory = cursor2.update(updateInventory);
              requestInventory.onsuccess = function() {
                console.log('To Inventory Added');
                resolve({success :true});
              };  
              requestInventory.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }else{
              inventoryDataArr=[];
              inventoryDataArr.push(inventoryData); 
              updateInventory.data.property.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory= inventoryDataArr;
              
              const requestInventory = cursor2.update(updateInventory);
              requestInventory.onsuccess = function() {
                console.log('To Inventory Added');
                resolve({success :true});
              };  
              requestInventory.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }


  addAreaInventoryFormData(inventoryData:any, currentSurvey:any, formType:any, areaIndex:any, total_items:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let inventoryDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;        
         if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            inventoryDataArr=  updateInventoryList.from.plan.other_areas[areaIndex].inventory;
            
            for (let i = 0; i < total_items; i++) {
              inventoryDataArr.push(inventoryData);
            } 
            //inventoryDataArr.push(inventoryData); 
            updateInventory.data.property.from.plan.other_areas[areaIndex].inventory= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('From Inventory Added');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };                           
            
          }

          if(formType=='to'){  
            inventoryDataArr=  updateInventoryList.to.plan.other_areas[areaIndex].inventory;
                        
            inventoryDataArr.push(inventoryData); 
            updateInventory.data.property.to.plan.other_areas[areaIndex].inventory= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('To Inventory Added');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            }; 
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }


  addTemplateData(templateData:any, currentSurvey:any, formType:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let templateDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateTemplate = cursor2.value;          
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){            
            templateDataArr.push(templateData);
            updateTemplate.data.property.from.plan= templateData.plan;
            const requestTemplate = cursor2.update(updateTemplate);
            requestTemplate.onsuccess = function() {
              console.log('From Template Added');
              resolve({success :true});
            };  
            requestTemplate.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
            
          }

          if(formType=='to'){  
            templateDataArr.push(templateData);
            updateTemplate.data.property.to.plan= templateData.plan;
            const requestTemplate = cursor2.update(updateTemplate);
            requestTemplate.onsuccess = function() {
              console.log('To Template Added');
              resolve({success :true});
            };  
            requestTemplate.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 

  }


  deleteBasement(surveyPayload:any, formType:any, basementIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.basements; 
            updateDataArr.splice(basementIndex, 1);          
            updateInventoryList.from.plan.basements= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Basement Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.basements; 
            updateDataArr.splice(basementIndex, 1);          
            updateInventoryList.to.plan.basements= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Basement Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  deleteBasementItem(surveyPayload:any, formType:any, basementIndex:number, itemIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.basements[basementIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.from.plan.basements[basementIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Basement Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.basements[basementIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.to.plan.basements[basementIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Basement Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }



  deleteLoft(surveyPayload:any, formType:any, loftIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.lofts; 
            updateDataArr.splice(loftIndex, 1);          
            updateInventoryList.from.plan.lofts= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Loft Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.lofts; 
            updateDataArr.splice(loftIndex, 1);          
            updateInventoryList.to.plan.lofts= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Loft Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  deleteLoftItem(surveyPayload:any, formType:any, loftIndex:number, itemIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.lofts[loftIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.from.plan.lofts[loftIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Loft Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.lofts[loftIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.to.plan.lofts[loftIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Loft Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }



  deleteArea(surveyPayload:any, formType:any, areaIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.other_areas; 
            updateDataArr.splice(areaIndex, 1);          
            updateInventoryList.from.plan.other_areas= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Area Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.other_areas; 
            updateDataArr.splice(areaIndex, 1);          
            updateInventoryList.to.plan.other_areas= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Area Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  deleteAreaItem(surveyPayload:any, formType:any, areaIndex:number, itemIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.other_areas[areaIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.from.plan.other_areas[areaIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Area Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.other_areas[areaIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.to.plan.other_areas[areaIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Area Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }

  
  deleteRoomItem(surveyPayload:any, formType:any, floorIndex:number, roomIndex:number, itemIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Floor Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory; 
            updateDataArr.splice(itemIndex, 1);          
            updateInventoryList.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Floor Item Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
    }); 
  }


  
  deleteRoom(surveyPayload:any, formType:any, floorIndex:number, roomIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.floors[floorIndex].floor_rooms;
            updateDataArr.splice(roomIndex, 1);          
            updateInventoryList.from.plan.floors[floorIndex].floor_rooms= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Room Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.floors[floorIndex].floor_rooms; 
            updateDataArr.splice(roomIndex, 1);          
            updateInventoryList.to.plan.floors[floorIndex].floor_rooms= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Room Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  deleteFloor(surveyPayload:any, formType:any, floorIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          if(formType=='from'){   
            var updateDataArr=  updateInventoryList.from.plan.floors;
            updateDataArr.splice(floorIndex, 1);          
            updateInventoryList.from.plan.floors= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Floor Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

          if(formType=='to'){
            var updateDataArr=  updateInventoryList.to.plan.floors; 
            updateDataArr.splice(floorIndex, 1);          
            updateInventoryList.to.plan.floors= updateDataArr;            
            updateData.data.property=updateInventoryList;
            
            const requestParking = cursor2.update(updateData);
            requestParking.onsuccess = function() {
              console.log('Floor Removed');
              resolve({success :true});
            };  
            requestParking.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };
          }

        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  updateAreaInventoryData(inventoryData:any, currentSurvey:any, formType:any, areaIndex:any, inventoryIndex:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let inventoryDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;        
         if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            inventoryDataArr=  updateInventoryList.from.plan.other_areas[areaIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.from.plan.other_areas[areaIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };                           
            
          }

          if(formType=='to'){  
            inventoryDataArr=  updateInventoryList.to.plan.other_areas[areaIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.to.plan.other_areas[areaIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            }; 
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }


  editFloorFormData(floorData:any, currentSurvey:any, formType:any, floorIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let floorDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateFloor = cursor2.value;          
          var updateFloorList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            floorDataArr=  updateFloorList.from.plan.floors;                             
            let idx = floorDataArr.findIndex((elem:any) => (elem.floor_name).toLowerCase() === (floorData.floor_name).toLowerCase())
            if (idx !== -1 && idx!=floorIndex){
              Swal.fire('Error', 'Floor Name already exist!', 'error');
                            
            }else{
              floorDataArr[floorIndex].floor_name = floorData.floor_name;
              const requestFloor = cursor2.update(updateFloor);
              requestFloor.onsuccess = function() {
                console.log('Floor updated');
                resolve({success :true});
              };  
              requestFloor.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }              
            
          }

          if(formType=='to'){  
            floorDataArr=  updateFloorList.to.plan.floors;                             
            let idx = floorDataArr.findIndex((elem:any) => (elem.floor_name).toLowerCase() === (floorData.floor_name).toLowerCase())
            if (idx !== -1 && idx!=floorIndex){
              Swal.fire('Error', 'Floor Name already exist!', 'error');
                            
            }else{
              floorDataArr[floorIndex].floor_name = floorData.floor_name;
              const requestFloor = cursor2.update(updateFloor);
              requestFloor.onsuccess = function() {
                console.log('Floor updated');
                resolve({success :true});
              };  
              requestFloor.onerror = function(err:any) {
                Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
                reject(err);
              };
            }  
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  updateBasementInventoryData(inventoryData:any, currentSurvey:any, formType:any, basementIndex:any, inventoryIndex:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let inventoryDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;        
         if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            inventoryDataArr=  updateInventoryList.from.plan.basements[basementIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.from.plan.basements[basementIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };                           
            
          }

          if(formType=='to'){  
            inventoryDataArr=  updateInventoryList.to.plan.basements[basementIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.to.plan.basements[basementIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            }; 
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }



  updateLoftInventoryData(inventoryData:any, currentSurvey:any, formType:any, loftIndex:any, inventoryIndex:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let inventoryDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;        
         if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            inventoryDataArr=  updateInventoryList.from.plan.lofts[loftIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.from.plan.lofts[loftIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };                           
            
          }

          if(formType=='to'){  
            inventoryDataArr=  updateInventoryList.to.plan.lofts[loftIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.to.plan.lofts[loftIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            }; 
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }



  updateFloorInventoryData(inventoryData:any, currentSurvey:any, formType:any, floorIndex:any, roomIndex:any, inventoryIndex:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      let inventoryDataArr =[];
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateInventory = cursor2.value;          
          var updateInventoryList = cursor2.value.data.property;        
         if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
          
          if(formType=='from'){ 
            inventoryDataArr=  updateInventoryList.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.from.plan.floors[floorIndex].floor_rooms[roomIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            };                           
            
          }

          if(formType=='to'){  
            inventoryDataArr=  updateInventoryList.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory[inventoryIndex];
            inventoryDataArr.item_id=  inventoryData.item_id;    
            inventoryDataArr.type_id=  inventoryData.type_id;    
            inventoryDataArr.item_name=  inventoryData.item_name;    
            inventoryDataArr.item_weight=  inventoryData.item_weight;    
            inventoryDataArr.item_carry_people= inventoryData.item_carry_people; 
            inventoryDataArr.type_name= inventoryData.type_name;
            inventoryDataArr.item_do_dismantle = inventoryData.item_do_dismantle;
            inventoryDataArr.item_dismantle_time = inventoryData.item_dismantle_time;    
            inventoryDataArr.item_do_reassemble = inventoryData.item_do_reassemble;   
            inventoryDataArr.item_reassemble_time =inventoryData.item_reassemble_time;
            inventoryDataArr.item_typical_value = inventoryData.item_typical_value;
            inventoryDataArr.item_provides_storage = inventoryData.item_provides_storage;    
            inventoryDataArr.item_to_go = inventoryData.item_to_go;
            inventoryDataArr.item_pack_small = inventoryData.item_pack_small;
            inventoryDataArr.item_pack_med = inventoryData.item_pack_med;    
            inventoryDataArr.item_pack_large = inventoryData.item_pack_large;
            inventoryDataArr.item_pack_layflat = inventoryData.item_pack_layflat;
            inventoryDataArr.item_pack_wardrobe = inventoryData.item_pack_wardrobe;    
            inventoryDataArr.item_pack_picturepack = inventoryData.item_pack_picturepack;
            inventoryDataArr.item_pack_golfbox = inventoryData.item_pack_golfbox;
            inventoryDataArr.item_pack_bikebox = inventoryData.item_pack_bikebox;    
            inventoryDataArr.item_pack_mattressbagsingle = inventoryData.item_pack_mattressbagsingle;
            inventoryDataArr.item_pack_mattressbagdouble = inventoryData.item_pack_mattressbagdouble;
            inventoryDataArr.item_pack_mattressbagking = inventoryData.item_pack_mattressbagking;    
            inventoryDataArr.item_pack_mattressbagsuperking = inventoryData.item_pack_mattressbagsuperking;
            inventoryDataArr.item_pack_tvbox24 = inventoryData.item_pack_tvbox24;
            inventoryDataArr.item_pack_tvbox32 = inventoryData.item_pack_tvbox32;    
            inventoryDataArr.item_pack_tvbox36 = inventoryData.item_pack_tvbox36;
            inventoryDataArr.item_pack_tvbox43 = inventoryData.item_pack_tvbox43;
            inventoryDataArr.item_pack_tvbox48 = inventoryData.item_pack_tvbox48;    
            inventoryDataArr.item_pack_tvbox55 = inventoryData.item_pack_tvbox55;
            inventoryDataArr.item_pack_tvbox70 = inventoryData.item_pack_tvbox70;
            inventoryDataArr.item_pack_tvbox85 = inventoryData.item_pack_tvbox85;    
            inventoryDataArr.item_width_cm = inventoryData.item_width_cm;    
            inventoryDataArr.item_height_cm = inventoryData.item_height_cm;    
            inventoryDataArr.item_depth_cm = inventoryData.item_depth_cm;    
            inventoryDataArr.item_width_inch = inventoryData.item_width_inch;    
            inventoryDataArr.item_height_inch = inventoryData.item_height_inch;    
            inventoryDataArr.item_depth_inch = inventoryData.item_depth_inch;    
            inventoryDataArr.item_cube_cm = inventoryData.item_cube_cm;    
            inventoryDataArr.item_cube_ft = inventoryData.item_cube_ft;    
            inventoryDataArr.item_cube_dismantled_cm = inventoryData.item_cube_dismantled_cm;    
            inventoryDataArr.item_cube_dismantled_ft = inventoryData.item_cube_dismantled_ft;    
            inventoryDataArr.item_percentage_full = inventoryData.item_percentage_full;

            updateInventory.data.property.to.plan.floors[floorIndex].floor_rooms[roomIndex].inventory[inventoryIndex]= inventoryDataArr;
            
            const requestInventory = cursor2.update(updateInventory);
            requestInventory.onsuccess = function() {
              console.log('Inventory item updated');
              resolve({success :true});
            };  
            requestInventory.onerror = function(err:any) {
              Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
              reject(err);
            }; 
          }
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }



  updateOverviewFormData(overviewData:any, currentSurvey:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateOverview = cursor2.value;          
          const updateData = cursor2.value.data;             
         
          updateData.survey_name= overviewData.survey_name;
          updateData.survey_date= overviewData.survey_date;
          updateData.survey_time= overviewData.survey_time;
          updateData.proposed_move_date= overviewData.proposed_move_date;
          updateData.move_requires_storage= overviewData.move_requires_storage;
          updateData.storage_requirements= overviewData.storage_requirements;
          updateData.contact_primary= overviewData.contact_primary;
          updateData.contact_secondary= overviewData.contact_secondary;
         
         // updateOverviewList= updateData;           
          const requestOverview = cursor2.update(updateOverview);
          requestOverview.onsuccess = function() {
            console.log('Overview updated');
            resolve({success :true});
          };  
          requestOverview.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };
          
                          
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  editImageNote(imageData:any, surveyPayload:any, imageIndex:number){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updateNotes = cursor2.value;
          var updateImageList = cursor2.value.data.images[imageIndex]; 

          updateImageList.image_note =imageData.image_note;
          updateImageList.image_time =imageData.image_time;
          updateNotes.data.images[imageIndex]= updateImageList;
          
          const requestImage = cursor2.update(updateNotes);
          requestImage.onsuccess = function() {
            console.log('image note updated');
            resolve({success :true});
          };  
          requestImage.onerror = function(err:any) {
            reject(err);
          };                 
        } else {
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  getItemsBasement(surveyPayload:any, formType:any, basementIndex:number){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
           
            var updateDataArr=  updateInventoryList.from.plan.basements[basementIndex]; 
            resolve({data:updateDataArr});
            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }


  updatePackingMaterialBasement(PackingData:any, currentSurvey:any, basementIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updatePacking = cursor2.value;          
          var updatePackingList = cursor2.value.data.property;                 
          const updateData=  updatePackingList.from.plan.basements[basementIndex];
          updateData.packing_materials= PackingData;                    
          const requestParking = cursor2.update(updatePacking);
          requestParking.onsuccess = function() {
            console.log('Packing Material updated');
            resolve({success :true});
          };  
          requestParking.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }



  getItemsLofts(surveyPayload:any, formType:any, loftIndex:number){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
           
            var updateDataArr=  updateInventoryList.from.plan.lofts[loftIndex]; 
            resolve({data:updateDataArr});
            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }

  
  updatePackingMaterialLoft(PackingData:any, currentSurvey:any, loftIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updatePacking = cursor2.value;          
          var updatePackingList = cursor2.value.data.property;                 
          const updateData=  updatePackingList.from.plan.lofts[loftIndex];
          updateData.packing_materials= PackingData;                    
          const requestParking = cursor2.update(updatePacking);
          requestParking.onsuccess = function() {
            console.log('Packing Material updated');
            resolve({success :true});
          };  
          requestParking.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }


  getItemsArea(surveyPayload:any, formType:any, areaIndex:number){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
           
            var updateDataArr=  updateInventoryList.from.plan.other_areas[areaIndex]; 
            resolve({data:updateDataArr});
            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }

  
  updatePackingMaterialArea(PackingData:any, currentSurvey:any, areaIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updatePacking = cursor2.value;          
          var updatePackingList = cursor2.value.data.property;                 
          const updateData=  updatePackingList.from.plan.other_areas[areaIndex];
          updateData.packing_materials= PackingData;                    
          const requestParking = cursor2.update(updatePacking);
          requestParking.onsuccess = function() {
            console.log('Packing Material updated');
            resolve({success :true});
          };  
          requestParking.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }
  
  
  getItemsRoom(surveyPayload:any, formType:any, floorIndex:number, roomIndex:any){
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(surveyPayload)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {
          var updateData = cursor2.value;  
          var updateInventoryList = cursor2.value.data.property;
          if(formType==''){ 
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
          }
           
            var updateDataArr=  updateInventoryList.from.plan.floors[floorIndex].floor_rooms[roomIndex]; 
            resolve({data:updateDataArr});
            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    }); 
  }

  
  updatePackingMaterialRoom(PackingData:any, currentSurvey:any, floorIndex:any, roomIndex:any){        
    const request = indexedDB.open(this.databaseName, this.version);
    return new Promise((resolve,reject)=> request.onsuccess = function(event:any) {
      
      var cursor = event.target.result;      
      var transaction = cursor.transaction(["survey-data"], 'readwrite');
      var objectStore0 = transaction.objectStore("survey-data");
      objectStore0.openCursor(IDBKeyRange.only(currentSurvey)).onsuccess = function(event:any) {        
        var cursor2 = event.target.result;        
        if (cursor2) {              
          var updatePacking = cursor2.value;          
          var updatePackingList = cursor2.value.data.property;                 
          const updateData=  updatePackingList.from.plan.floors[floorIndex].floor_rooms[roomIndex];
          updateData.packing_materials= PackingData;                    
          const requestParking = cursor2.update(updatePacking);
          requestParking.onsuccess = function() {
            console.log('Packing Material updated');
            resolve({success :true});
          };  
          requestParking.onerror = function(err:any) {
            Swal.fire('Error', 'Something went wrong. Try again later.', 'error');
            reject(err);
          };            
        } else {
          //status="error";
          Swal.fire('Error', 'Something went wrong. Try again later.', 'error');          
        }          
      }
      
    });    
    
  }

  

}



