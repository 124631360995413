import { Component, OnInit, Input, ViewChildren, QueryList, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";
import { EventEmitterService } from '../model/event-emitter.service'; 
import { IndexedDBService } from '../services/indexed-db.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-basement',
  templateUrl: './basement.component.html',
  styleUrls: ['./basement.component.css']
})
export class BasementComponent implements OnInit {
  dataItemArr:any;
  surveyId: any;
  reportId!: any;
  @Input() ImportSurveyList: any;
  @Input() loader: any;
  // @Input() inventoryModal: any;
  @Input() currentTabTypeid: any;
  isLoadingResult: boolean = false;   
  itemList:any;
  itemListFiltered:any;
  itemListUnFiltered:any;
  itemTypeList:any;
  keyword = 'item_name';
  keywordType = 'type_name';
  selectedItemTypeId:any;
  closeResult!: string;
  submittedBasement = false;
  submittedBasementTo = false;
  submittedPackingFrom = false;
  submittedPackingTo = false;
  submittedMaterialFrom = false;
  submittedMaterialTo = false;
  submittedInventoryForm = false;
  submittedInventoryFormManual = false;
  basementForm = new FormGroup({});   
  packingMaterialFrom = new FormGroup({});
  packingMaterialTo = new FormGroup({});
  InventoryItemFormFrom = new FormGroup({});
  InventoryItemFormManual = new FormGroup({});
  InventoryDataEdit:any; 
  defaultUnits:any;

  @ViewChildren('basementUpdateForm')
  public basementUpdateForm!: QueryList<any>;

  @ViewChildren('inventoryUpdateForm')
  public basementInventoryUpdateForm!: QueryList<any>;
  
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private apiService: ApiService, private _Activatedroute:ActivatedRoute, private eventEmitterService: EventEmitterService, private IndexedDBService: IndexedDBService) { 

   
  }

  ngOnInit(): void {
    this.currentTabTypeid='from';
    this.getCategoryList();
    this.getItemList();
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");
    
    /**add basement modal box value reset */
    this.resetFormValues();
    
    this.getDefaultsUnits();
 
  }

getItemList(){
  this.IndexedDBService.getInventoryItems()
    .then((data:any) => {       
      if(data!=undefined){
        this.itemList = data;
        this.itemListUnFiltered = data;
        this.isLoadingResult = false;
      }else{         
        // call if data not available in indexedDB
        this.apiService.getItemList().subscribe(  
          (res)=>{         
          
          this.IndexedDBService
          .addInventoryItems(res.data)
          //.then()  
          this.itemList = res.data; 
          this.itemListUnFiltered = res.data;
          this.isLoadingResult = false;            
        },
        (err)=>{  
          console.log("fail");      
        });
      }       
      
    });  
}


  tabTypeClickFunction(tabType:any){    
    this.eventEmitterService.ontabTypeClick(tabType);    
  }

  resetFormValues(){
    this.basementForm = this.formBuilder.group({      
      basement_name : ['', Validators.required],    
      basement_packing : [false],
      basement_ntg : ['TG', Validators.required],
      basement_seen : [true],
      basement_fully_lit : [false],
      basement_box_estimate : ['0'],
      move_light_fittings : [false],
      move_curtains_blinds : [false],
      move_carpets : [false]  
    });
  }

  
  open(content: any) { 
    this.resetItemFormValue();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'Inventorymodel',
    beforeDismiss: () => {
      if (!this.basementForm.pristine && !this.submittedBasement) {
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false;     
        
      }else if((!this.InventoryItemFormFrom.pristine  || this.InventoryItemFormFrom.dirty) && !this.submittedInventoryForm){
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false;
      }else if(!this.InventoryItemFormManual.pristine && !this.submittedInventoryFormManual){
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false;
      }else {                
        return true;
      }
    } 
  }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
      this.resetFormValues();
      this.basementForm.markAsUntouched();
      this.InventoryItemFormFrom.markAsUntouched();
      this.InventoryItemFormFrom.reset();
      this.InventoryItemFormManual.markAsUntouched();
      this.InventoryItemFormManual.reset();
      this.submittedBasement=false;
      this.submittedInventoryForm=false;
      this.submittedInventoryFormManual=false;
    });
  }

  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }



  get basementFormControl() {
    return this.basementForm.controls;
  }


  onSubmitBasement() {
    this.submittedBasement = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.basementForm.valid) {
      const BasementPayload = {
        basement_name : this.basementForm.controls.basement_name.value,    
        basement_packing : this.basementForm.controls.basement_packing.value,
        basement_ntg : this.basementForm.controls.basement_ntg.value,
        basement_seen : this.basementForm.controls.basement_seen.value,
        basement_fully_lit : this.basementForm.controls.basement_fully_lit.value,
        basement_box_estimate : this.basementForm.controls.basement_box_estimate.value,
        move_light_fittings : this.basementForm.controls.move_light_fittings.value,
        move_curtains_blinds : this.basementForm.controls.move_curtains_blinds.value,
        move_carpets : this.basementForm.controls.move_carpets.value,
        packing_materials:{
          "small": 0,
          "med": 0,
          "large": 0,
          "layflat": 0,	
          "wardrobe": 0,	
          "picturepack": 0,	
          "golfbox": 0,	
          "bikebox": 0,	
          "mattressbagsingle": 0,	
          "mattressbagdouble": 0,	
          "mattressbagking": 0,	
          "mattressbagsuperking": 0,	
          "tvbox24": 0,	
          "tvbox32": 0,	
          "tvbox36": 0,	
          "tvbox43": 0,	
          "tvbox48": 0,
          "tvbox55": 0,	
          "tvbox70": 0,	
          "tvbox85": 0
        },
        inventory:[]       
      }
       console.log(this.currentTabTypeid);
      
      this.IndexedDBService.addBasementFormData(BasementPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Added', 'Basement added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            this.basementForm.reset();
            this.basementForm.markAsUntouched();
            this.submittedBasement=false; 
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }

  setBasementIndex(basement_index:any){
    console.log(basement_index);
    this.InventoryItemFormFrom.patchValue({
      basementIndex: basement_index   
    }); 
    this.InventoryItemFormManual.patchValue({
      basementIndex: basement_index   
    }); 
  }


  onSubmitBasementUpdate(basementUpdateForm:NgForm) {
    this.submittedBasement = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
      
    if (basementUpdateForm.valid) {
      let basementRow= basementUpdateForm.value.basement_row;
      const BasementPayload = {
        basement_name : basementUpdateForm.value.basement_name,    
        basement_packing : basementUpdateForm.value.basement_packing,
        basement_ntg : basementUpdateForm.value.basement_ntg,
        basement_seen : basementUpdateForm.value.basement_seen,
        basement_fully_lit : basementUpdateForm.value.basement_fully_lit,
        basement_box_estimate : basementUpdateForm.value.basement_box_estimate,
        move_light_fittings : basementUpdateForm.value.move_light_fittings,
        move_curtains_blinds : basementUpdateForm.value.move_curtains_blinds,
        move_carpets : basementUpdateForm.value.move_carpets                
      }
      //console.log(BasementPayload);
      this.IndexedDBService.updateBasementFormData(BasementPayload, surveyPayload, this.currentTabTypeid, basementRow).then((response: any) => {                   
        if(response) {
          Swal.fire('Updated', 'Basement updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.submittedBasement=false;           
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }

  

  onSubmitPackingFrom() {
    this.submittedPackingFrom = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.packingMaterialFrom.valid) {
      const PackingPayload = {
        small : this.packingMaterialFrom.controls.small.value, 
        med : this.packingMaterialFrom.controls.med.value,
        large : this.packingMaterialFrom.controls.large.value,
        layflat : this.packingMaterialFrom.controls.layflat.value,
        wardrobe : this.packingMaterialFrom.controls.wardrobe.value,
        picturepack : this.packingMaterialFrom.controls.picturepack.value,
        golfbox : this.packingMaterialFrom.controls.golfbox.value,
        bikebox : this.packingMaterialFrom.controls.bikebox.value,
        mattressbagsingle : this.packingMaterialFrom.controls.mattressbagsingle.value,
        mattressbagdouble : this.packingMaterialFrom.controls.mattressbagdouble.value,
        mattressbagking : this.packingMaterialFrom.controls.mattressbagking.value,
        mattressbagsuperking : this.packingMaterialFrom.controls.mattressbagsuperking.value,
        tvbox24 : this.packingMaterialFrom.controls.tvbox24.value,
        tvbox32 : this.packingMaterialFrom.controls.tvbox32.value,
        tvbox36 : this.packingMaterialFrom.controls.tvbox36.value,
        tvbox43 : this.packingMaterialFrom.controls.tvbox43.value,
        tvbox48 : this.packingMaterialFrom.controls.tvbox48.value,
        tvbox55 : this.packingMaterialFrom.controls.tvbox55.value,
        tvbox70 : this.packingMaterialFrom.controls.tvbox70.value,
        tvbox85 : this.packingMaterialFrom.controls.tvbox85.value,
      }
          
      this.IndexedDBService.updatePackingMaterialFormData(PackingPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Updated', 'Packing Material succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.packingMaterialFrom.markAsUntouched();
            this.submittedPackingFrom=false;           
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }

  onSubmitPackingTo() {
    this.submittedPackingTo = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.packingMaterialTo.valid) {
      const PackingPayload = {
        small : this.packingMaterialTo.controls.small.value, 
        med : this.packingMaterialTo.controls.med.value,
        large : this.packingMaterialTo.controls.large.value,
        layflat : this.packingMaterialTo.controls.layflat.value,
        wardrobe : this.packingMaterialTo.controls.wardrobe.value,
        picturepack : this.packingMaterialTo.controls.picturepack.value,
        golfbox : this.packingMaterialTo.controls.golfbox.value,
        bikebox : this.packingMaterialTo.controls.bikebox.value,
        mattressbagsingle : this.packingMaterialTo.controls.mattressbagsingle.value,
        mattressbagdouble : this.packingMaterialTo.controls.mattressbagdouble.value,
        mattressbagking : this.packingMaterialTo.controls.mattressbagking.value,
        mattressbagsuperking : this.packingMaterialTo.controls.mattressbagsuperking.value,
        tvbox24 : this.packingMaterialTo.controls.tvbox24.value,
        tvbox32 : this.packingMaterialTo.controls.tvbox32.value,
        tvbox36 : this.packingMaterialTo.controls.tvbox36.value,
        tvbox43 : this.packingMaterialTo.controls.tvbox43.value,
        tvbox48 : this.packingMaterialTo.controls.tvbox48.value,
        tvbox55 : this.packingMaterialTo.controls.tvbox55.value,
        tvbox70 : this.packingMaterialTo.controls.tvbox70.value,
        tvbox85 : this.packingMaterialTo.controls.tvbox85.value,
      }
          
      this.IndexedDBService.updatePackingMaterialFormData(PackingPayload, surveyPayload, this.currentTabTypeid).then((response: any) => {                   
        if(response) {
          Swal.fire('Updated', 'Packing Material updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.packingMaterialTo.markAsUntouched();
            this.submittedPackingTo=false;           
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }


  onChangeSearch(str: string){   
    
    this.InventoryItemFormFrom.markAsDirty();
    this.isLoadingResult = true;
    this.IndexedDBService.getInventoryItems()
    .then((data:any) => {       
      if(data!=undefined){
        this.itemList = data;
        this.isLoadingResult = false;
      }else{         
        // call if data not available in indexedDB
        this.apiService.getItemList().subscribe(  
          (res)=>{         
          
          this.IndexedDBService
          .addInventoryItems(res.data)
          //.then()  
          this.itemList = res.data; 
          this.isLoadingResult = false;            
        },
        (err)=>{  
          console.log("fail");      
        });
      }       
      
    });      
   
  }  

  selectEvent(item: any) {
    
    this.InventoryItemFormFrom.patchValue({
      item_name: item.item_name, 
      item_id: item.item_id, 
      type_id: item.type_id, 
      item_weight: item.item_weight, 
      item_carry_people: item.item_carry_people, 
      type_name: item.type_name,
      item_do_dismantle : item.item_show_dismantle,
      item_dismantle_time : item.item_dismantle_time,    
      item_do_reassemble : item.item_do_reassemble,    
      item_reassemble_time : item.item_reassemble_time,
      item_typical_value : item.item_typical_value,
      item_provides_storage : item.item_provides_storage,    
      item_to_go : item.item_to_go,
      item_pack_small : item.item_pack_small,
      item_pack_med : item.item_pack_med,    
      item_pack_large : item.item_pack_large,
      item_pack_layflat : item.item_pack_layflat,
      item_pack_wardrobe : item.item_pack_wardrobe,    
      item_pack_picturepack : item.item_pack_picturepack,
      item_pack_golfbox : item.item_pack_golfbox,
      item_pack_bikebox : item.item_pack_bikebox,    
      item_pack_mattressbagsingle : item.item_pack_mattressbagsingle,
      item_pack_mattressbagdouble : item.item_pack_mattressbagdouble,
      item_pack_mattressbagking : item.item_pack_mattressbagking,    
      item_pack_mattressbagsuperking : item.item_pack_mattressbagsuperking,
      item_pack_tvbox24 : item.item_pack_tvbox24,
      item_pack_tvbox32 : item.item_pack_tvbox32,    
      item_pack_tvbox36 : item.item_pack_tvbox36,
      item_pack_tvbox43 : item.item_pack_tvbox43,
      item_pack_tvbox48 : item.item_pack_tvbox48,    
      item_pack_tvbox55 : item.item_pack_tvbox55,
      item_pack_tvbox70 : item.item_pack_tvbox70,
      item_pack_tvbox85 : item.item_pack_tvbox85,    
      item_width_cm : item.item_width_cm,    
      item_height_cm : item.item_height_cm,    
      item_depth_cm : item.item_depth_cm,    
      item_width_inch : item.item_width_inch,    
      item_height_inch : item.item_height_inch,    
      item_depth_inch : item.item_depth_inch,    
      item_cube_cm : item.item_cube_cm,    
      item_cube_ft : item.item_cube_ft,    
      item_cube_dismantled_cm : item.item_cube_dismantled_cm,    
      item_cube_dismantled_ft : item.item_cube_dismantled_ft,    
      item_percentage_full : item.item_percentage_full
            
    });
    
  }

  onFocused(e:any){    
    //this.itemList=[];
    // here we can write our code for doing something when input is focused
  }

  onCleared(e:any) {      
    this.itemFormClear();
  }

  itemFormClear(){ 
    this.InventoryItemFormFrom.patchValue({     
      item_name: '',
      total_items:'1', 
      item_id: '', 
      type_id: '', 
      item_weight: '', 
      item_carry_people: '', 
      type_name: '',
      item_do_dismantle : false,
      item_dismantle_time : '',    
      item_do_reassemble : false,    
      item_reassemble_time : '',
      item_typical_value : '',
      item_provides_storage : false,    
      item_to_go : 'TG',
      item_pack_small : '',
      item_pack_med : '',    
      item_pack_large : '',
      item_pack_layflat : '',
      item_pack_wardrobe : '',    
      item_pack_picturepack : '',
      item_pack_golfbox : '',
      item_pack_bikebox : '',    
      item_pack_mattressbagsingle : '',
      item_pack_mattressbagdouble : '',
      item_pack_mattressbagking : '',    
      item_pack_mattressbagsuperking : '',
      item_pack_tvbox24 : '',
      item_pack_tvbox32 : '',    
      item_pack_tvbox36 : '',
      item_pack_tvbox43 : '',
      item_pack_tvbox48 : '',    
      item_pack_tvbox55 : '',
      item_pack_tvbox70 : '',
      item_pack_tvbox85 : '',    
      item_width_cm : '',    
      item_height_cm : '',    
      item_depth_cm : '',    
      item_width_inch : '',    
      item_height_inch : '',    
      item_depth_inch : '',    
      item_cube_cm : '',    
      item_cube_ft : '',    
      item_cube_dismantled_cm : '',    
      item_cube_dismantled_ft : '',    
      item_percentage_full : ''
    });

    this.InventoryItemFormManual.patchValue({      
      item_name: '', 
      total_items:'1',
      item_id: '', 
      type_id: '', 
      item_weight: '', 
      item_carry_people: '', 
      type_name: '',
      item_do_dismantle : false,
      item_dismantle_time : '0',    
      item_do_reassemble : false,    
      item_reassemble_time : '0',
      item_typical_value : '0',
      item_provides_storage : false,    
      item_to_go : 'TG',
      item_pack_small : '0',
      item_pack_med : '0',    
      item_pack_large : '0',
      item_pack_layflat : '0',
      item_pack_wardrobe : '0',    
      item_pack_picturepack : '0',
      item_pack_golfbox : '0',
      item_pack_bikebox : '0',    
      item_pack_mattressbagsingle : '0',
      item_pack_mattressbagdouble : '0',
      item_pack_mattressbagking : '0',    
      item_pack_mattressbagsuperking : '0',
      item_pack_tvbox24 : '0',
      item_pack_tvbox32 : '0',    
      item_pack_tvbox36 : '0',
      item_pack_tvbox43 : '0',
      item_pack_tvbox48 : '0',    
      item_pack_tvbox55 : '0',
      item_pack_tvbox70 : '0',
      item_pack_tvbox85 : '0',    
      item_width_cm : '',    
      item_height_cm : '',    
      item_depth_cm : '',    
      item_width_inch : '',    
      item_height_inch : '',    
      item_depth_inch : '',    
      item_cube_cm : '0',    
      item_cube_ft : '0',    
      item_cube_dismantled_cm : '0',    
      item_cube_dismantled_ft : '0',    
      item_percentage_full : '0'
    });
  }

  
  resetItemFormValue(){
    this.itemFormClear();
    this.selectedItemTypeId=undefined;    
    this.InventoryItemFormFrom.patchValue({
      item_type_search:'', 
      item_name_search:'',
      item_type:'', 
    });
    
    this.InventoryItemFormFrom.markAsUntouched();
    this.InventoryItemFormManual.markAsUntouched();
    this.submittedInventoryForm=false;
    this.submittedInventoryFormManual=false;
  }

  get InventoryFormManualControl() {
    return this.InventoryItemFormManual.controls;
  }

  get InventoryFormFromControl() {
    return this.InventoryItemFormFrom.controls;
  }

  onSubmitInventory(){
    this.submittedInventoryForm = true;    
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    let basementNameIndex= this.InventoryItemFormFrom.controls.basementIndex.value;
    let total_items= this.InventoryItemFormFrom.controls.total_items.value;
    const InventoryPayload = {
      item_name: this.InventoryItemFormFrom.controls.item_name.value, 
      item_id: this.InventoryItemFormFrom.controls.item_id.value, 
      type_id: this.InventoryItemFormFrom.controls.type_id.value, 
      item_weight: this.InventoryItemFormFrom.controls.item_weight.value, 
      item_carry_people: this.InventoryItemFormFrom.controls.item_carry_people.value, 
      type_name: this.InventoryItemFormFrom.controls.type_name.value,
      item_do_dismantle : this.InventoryItemFormFrom.controls.item_do_dismantle.value,
      item_dismantle_time : this.InventoryItemFormFrom.controls.item_dismantle_time.value,    
      item_do_reassemble : this.InventoryItemFormFrom.controls.item_do_reassemble.value,    
      item_reassemble_time : this.InventoryItemFormFrom.controls.item_reassemble_time.value,
      item_typical_value : this.InventoryItemFormFrom.controls.item_typical_value.value,
      item_provides_storage : this.InventoryItemFormFrom.controls.item_provides_storage.value,    
      item_to_go : this.InventoryItemFormFrom.controls.item_to_go.value,
      item_pack_small : this.InventoryItemFormFrom.controls.item_pack_small.value,
      item_pack_med : this.InventoryItemFormFrom.controls.item_pack_med.value,    
      item_pack_large : this.InventoryItemFormFrom.controls.item_pack_large.value,
      item_pack_layflat : this.InventoryItemFormFrom.controls.item_pack_layflat.value,
      item_pack_wardrobe : this.InventoryItemFormFrom.controls.item_pack_wardrobe.value,    
      item_pack_picturepack : this.InventoryItemFormFrom.controls.item_pack_picturepack.value,
      item_pack_golfbox : this.InventoryItemFormFrom.controls.item_pack_golfbox.value,
      item_pack_bikebox : this.InventoryItemFormFrom.controls.item_pack_bikebox.value,    
      item_pack_mattressbagsingle : this.InventoryItemFormFrom.controls.item_pack_mattressbagsingle.value,
      item_pack_mattressbagdouble : this.InventoryItemFormFrom.controls.item_pack_mattressbagdouble.value,
      item_pack_mattressbagking : this.InventoryItemFormFrom.controls.item_pack_mattressbagking.value,    
      item_pack_mattressbagsuperking : this.InventoryItemFormFrom.controls.item_pack_mattressbagsuperking.value,
      item_pack_tvbox24 : this.InventoryItemFormFrom.controls.item_pack_tvbox24.value,
      item_pack_tvbox32 : this.InventoryItemFormFrom.controls.item_pack_tvbox32.value,    
      item_pack_tvbox36 : this.InventoryItemFormFrom.controls.item_pack_tvbox36.value,
      item_pack_tvbox43 : this.InventoryItemFormFrom.controls.item_pack_tvbox43.value,
      item_pack_tvbox48 : this.InventoryItemFormFrom.controls.item_pack_tvbox48.value,    
      item_pack_tvbox55 : this.InventoryItemFormFrom.controls.item_pack_tvbox55.value,
      item_pack_tvbox70 : this.InventoryItemFormFrom.controls.item_pack_tvbox70.value,
      item_pack_tvbox85 : this.InventoryItemFormFrom.controls.item_pack_tvbox85.value,    
      item_width_cm : this.InventoryItemFormFrom.controls.item_width_cm.value,    
      item_height_cm : this.InventoryItemFormFrom.controls.item_height_cm.value,    
      item_depth_cm : this.InventoryItemFormFrom.controls.item_depth_cm.value,    
      item_width_inch : this.InventoryItemFormFrom.controls.item_width_inch.value,    
      item_height_inch : this.InventoryItemFormFrom.controls.item_height_inch.value,    
      item_depth_inch : this.InventoryItemFormFrom.controls.item_depth_inch.value,    
      item_cube_cm : this.InventoryItemFormFrom.controls.item_cube_cm.value,    
      item_cube_ft : this.InventoryItemFormFrom.controls.item_cube_ft.value,    
      item_cube_dismantled_cm : this.InventoryItemFormFrom.controls.item_cube_dismantled_cm.value,    
      item_cube_dismantled_ft : this.InventoryItemFormFrom.controls.item_cube_dismantled_ft.value,    
      item_percentage_full : this.InventoryItemFormFrom.controls.item_percentage_full.value
    }
    
    if (this.InventoryItemFormFrom.valid) {
      this.IndexedDBService.addInventoryFormData(InventoryPayload, surveyPayload, this.currentTabTypeid, basementNameIndex, total_items).then((response: any) => {                   
        if(response) {
          this.getUpdatePackingMaterials(surveyPayload, 'from', basementNameIndex);
          Swal.fire('Added', 'Inventory Item added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);  

            this.modalService.dismissAll('Dismissed modal box after saving data');       
            this.InventoryItemFormFrom.markAsUntouched();
            this.submittedInventoryForm=false;  
                   
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        }
      });
    }
  }



  onSubmitInventoryManual(){
    this.submittedInventoryFormManual = true;    
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    let basementNameIndex= this.InventoryItemFormManual.controls.basementIndex.value;    
    
    if (this.InventoryItemFormManual.valid) {
        let item_width_inch:any;
        let item_height_inch:any;
        let item_depth_inch:any;
        let item_width_cm:any;
        let item_height_cm:any;
        let item_depth_cm:any;
        let item_cube_cm:any;
        let item_cube_dismantled_cm:any;
        let item_cube_ft:any;
        let item_cube_dismantled_ft:any;
      if(this.defaultUnits?.linear_units=='cm') {
        item_width_inch = Number(this.InventoryItemFormManual.controls.item_width_cm.value)*0.39370;
        item_height_inch = Number(this.InventoryItemFormManual.controls.item_height_cm.value)*0.39370;
        item_depth_inch = Number(this.InventoryItemFormManual.controls.item_depth_cm.value)*0.39370;
        item_width_cm= this.InventoryItemFormManual.controls.item_width_cm.value;
        item_height_cm= this.InventoryItemFormManual.controls.item_height_cm.value;
        item_depth_cm= this.InventoryItemFormManual.controls.item_depth_cm.value;
        item_width_inch= parseFloat(item_width_inch).toFixed(2);
        item_height_inch= parseFloat(item_height_inch).toFixed(2);
        item_depth_inch= parseFloat(item_depth_inch).toFixed(2);
      }else{
        item_width_cm= Number(this.InventoryItemFormManual.controls.item_width_inch.value)/0.39370;
        item_height_cm= Number(this.InventoryItemFormManual.controls.item_height_inch.value)/0.39370;
        item_depth_cm= Number(this.InventoryItemFormManual.controls.item_depth_inch.value)/0.39370;
        item_width_inch = this.InventoryItemFormManual.controls.item_width_inch.value;
        item_height_inch = this.InventoryItemFormManual.controls.item_height_inch.value;
        item_depth_inch = this.InventoryItemFormManual.controls.item_depth_inch.value;
        item_width_cm= parseFloat(item_width_cm).toFixed(2);
        item_height_cm= parseFloat(item_height_cm).toFixed(2);
        item_depth_cm= parseFloat(item_depth_cm).toFixed(2);

      }

      if(this.defaultUnits?.cubic_units=='cm') {
        item_cube_ft = Number(this.InventoryItemFormManual.controls.item_cube_cm.value)*0.032808;
        item_cube_dismantled_ft = Number(this.InventoryItemFormManual.controls.item_cube_dismantled_cm.value)*0.032808;
        item_cube_cm= this.InventoryItemFormManual.controls.item_cube_cm.value;
        item_cube_dismantled_cm= this.InventoryItemFormManual.controls.item_cube_dismantled_cm.value;
        item_cube_ft= parseFloat(item_cube_ft).toFixed(2);
        item_cube_dismantled_ft= parseFloat(item_cube_dismantled_ft).toFixed(2);
      }else{
        item_cube_cm = Number(this.InventoryItemFormManual.controls.item_cube_ft.value)/0.032808;
        item_cube_dismantled_cm = Number(this.InventoryItemFormManual.controls.item_cube_dismantled_cm.value)/0.032808;
        item_cube_ft= this.InventoryItemFormManual.controls.item_cube_dismantled_ft.value;
        item_cube_dismantled_ft= this.InventoryItemFormManual.controls.item_cube_dismantled_ft.value;
        item_cube_cm= parseFloat(item_cube_cm).toFixed(2);
        item_cube_dismantled_cm= parseFloat(item_cube_dismantled_cm).toFixed(2);
      }
      let total_items= this.InventoryItemFormManual.controls.total_items.value;
      const InventoryPayload = {
        item_name: this.InventoryItemFormManual.controls.item_name.value, 
        item_id: this.InventoryItemFormManual.controls.item_id.value, 
        type_id: this.InventoryItemFormManual.controls.type_id.value, 
        item_weight: this.InventoryItemFormManual.controls.item_weight.value, 
        item_carry_people: this.InventoryItemFormManual.controls.item_carry_people.value, 
        type_name: this.InventoryItemFormManual.controls.type_name.value,
        item_do_dismantle : this.InventoryItemFormManual.controls.item_do_dismantle.value,
        item_dismantle_time : this.InventoryItemFormManual.controls.item_dismantle_time.value,    
        item_do_reassemble : this.InventoryItemFormManual.controls.item_do_reassemble.value,    
        item_reassemble_time : this.InventoryItemFormManual.controls.item_reassemble_time.value,
        item_typical_value : this.InventoryItemFormManual.controls.item_typical_value.value,
        item_provides_storage : this.InventoryItemFormManual.controls.item_provides_storage.value,    
        item_to_go : this.InventoryItemFormManual.controls.item_to_go.value,
        item_pack_small : this.InventoryItemFormManual.controls.item_pack_small.value,
        item_pack_med : this.InventoryItemFormManual.controls.item_pack_med.value,    
        item_pack_large : this.InventoryItemFormManual.controls.item_pack_large.value,
        item_pack_layflat : this.InventoryItemFormManual.controls.item_pack_layflat.value,
        item_pack_wardrobe : this.InventoryItemFormManual.controls.item_pack_wardrobe.value,    
        item_pack_picturepack : this.InventoryItemFormManual.controls.item_pack_picturepack.value,
        item_pack_golfbox : this.InventoryItemFormManual.controls.item_pack_golfbox.value,
        item_pack_bikebox : this.InventoryItemFormManual.controls.item_pack_bikebox.value,    
        item_pack_mattressbagsingle : this.InventoryItemFormManual.controls.item_pack_mattressbagsingle.value,
        item_pack_mattressbagdouble : this.InventoryItemFormManual.controls.item_pack_mattressbagdouble.value,
        item_pack_mattressbagking : this.InventoryItemFormManual.controls.item_pack_mattressbagking.value,    
        item_pack_mattressbagsuperking : this.InventoryItemFormManual.controls.item_pack_mattressbagsuperking.value,
        item_pack_tvbox24 : this.InventoryItemFormManual.controls.item_pack_tvbox24.value,
        item_pack_tvbox32 : this.InventoryItemFormManual.controls.item_pack_tvbox32.value,    
        item_pack_tvbox36 : this.InventoryItemFormManual.controls.item_pack_tvbox36.value,
        item_pack_tvbox43 : this.InventoryItemFormManual.controls.item_pack_tvbox43.value,
        item_pack_tvbox48 : this.InventoryItemFormManual.controls.item_pack_tvbox48.value,    
        item_pack_tvbox55 : this.InventoryItemFormManual.controls.item_pack_tvbox55.value,
        item_pack_tvbox70 : this.InventoryItemFormManual.controls.item_pack_tvbox70.value,
        item_pack_tvbox85 : this.InventoryItemFormManual.controls.item_pack_tvbox85.value,    
        item_width_cm : item_width_cm,    
        item_height_cm : item_height_cm,    
        item_depth_cm : item_depth_cm,    
        item_width_inch : item_width_inch,    
        item_height_inch : item_height_inch,    
        item_depth_inch : item_depth_inch,    
        item_cube_cm : item_cube_cm,    
        item_cube_ft : item_cube_ft,    
        item_cube_dismantled_cm : item_cube_dismantled_cm,    
        item_cube_dismantled_ft : item_cube_dismantled_ft,    
        item_percentage_full : this.InventoryItemFormManual.controls.item_percentage_full.value
      }
      console.log(InventoryPayload);
      this.IndexedDBService.addInventoryFormData(InventoryPayload, surveyPayload, this.currentTabTypeid, basementNameIndex, total_items).then((response: any) => {                   
        if(response) {
          this.getUpdatePackingMaterials(surveyPayload, 'from', basementNameIndex);
          Swal.fire('Added', 'Inventory Item added succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);  

            this.modalService.dismissAll('Dismissed modal box after saving data');       
            this.InventoryItemFormManual.markAsUntouched();
            this.submittedInventoryFormManual=false;           
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        }
      });
    }
  }


  getUpdatePackingMaterials(surveyPayload:any, type:any, basementNameIndex:any){
    this.IndexedDBService.getItemsBasement(surveyPayload, type, basementNameIndex).then((getResponse: any) => { 
      if(getResponse.data){
        var inventoryItems=getResponse.data.inventory;
        var packingMaterials={
          "small": 0,
          "med": 0,
          "large": 0,
          "layflat": 0,	
          "wardrobe": 0,	
          "picturepack": 0,	
          "golfbox": 0,	
          "bikebox": 0,          	
          "mattressbagsingle": 0,	
          "mattressbagdouble": 0,	
          "mattressbagking": 0,	
          "mattressbagsuperking": 0,	
          "tvbox24": 0,	
          "tvbox32": 0,	
          "tvbox36": 0,	
          "tvbox43": 0,	
          "tvbox48": 0,
          "tvbox55": 0,	
          "tvbox70": 0,	
          "tvbox85": 0
        }
        
        if(inventoryItems.length>0){
          inventoryItems.forEach((value :any) => {
            packingMaterials.small= Number(packingMaterials.small)+Number(value.item_pack_small);
            packingMaterials.med= Number(packingMaterials.med)+Number(value.item_pack_med);
            packingMaterials.large= Number(packingMaterials.large)+Number(value.item_pack_large);
            packingMaterials.layflat= Number(packingMaterials.layflat)+Number(value.item_pack_layflat);	
            packingMaterials.wardrobe= Number(packingMaterials.wardrobe)+Number(value.item_pack_wardrobe);	
            packingMaterials.picturepack= Number(packingMaterials.picturepack)+Number(value.item_pack_picturepack);	
            packingMaterials.golfbox= Number(packingMaterials.golfbox)+Number(value.item_pack_golfbox);	
            packingMaterials.bikebox= Number(packingMaterials.bikebox)+Number(value.item_pack_bikebox);	
            packingMaterials.mattressbagsingle= Number(packingMaterials.mattressbagsingle)+Number(value.item_pack_mattressbagsingle);	
            packingMaterials.mattressbagdouble= Number(packingMaterials.mattressbagdouble)+Number(value.item_pack_mattressbagdouble);	
            packingMaterials.mattressbagking= Number(packingMaterials.mattressbagking)+Number(value.item_pack_mattressbagking);	
            packingMaterials.mattressbagsuperking= Number(packingMaterials.mattressbagsuperking)+Number(value.item_pack_mattressbagsuperking);	
            packingMaterials.tvbox24= Number(packingMaterials.tvbox24)+Number(value.item_pack_tvbox24);	
            packingMaterials.tvbox32= Number(packingMaterials.tvbox32)+Number(value.item_pack_tvbox32);	
            packingMaterials.tvbox36= Number(packingMaterials.tvbox36)+Number(value.item_pack_tvbox36);	
            packingMaterials.tvbox43= Number(packingMaterials.tvbox43)+Number(value.item_pack_tvbox43);	
            packingMaterials.tvbox48= Number(packingMaterials.tvbox48)+Number(value.item_pack_tvbox48);
            packingMaterials.tvbox55= Number(packingMaterials.tvbox55)+Number(value.item_pack_tvbox55);	
            packingMaterials.tvbox70= Number(packingMaterials.tvbox70)+Number(value.item_pack_tvbox70);	
            packingMaterials.tvbox85= Number(packingMaterials.tvbox85)+Number(value.item_pack_tvbox85);
          });
        }
          this.IndexedDBService.updatePackingMaterialBasement(packingMaterials, surveyPayload, basementNameIndex).then((response: any) => {                   
            if(response) {
              this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
                this.eventEmitterService.surveyImportData(getResponse.data);
               
                setTimeout(() => {
                  this.setAccordionOpen(basementNameIndex);
                }, 500);
                 
              });          
            } 
          }); 
        
      }          
    }); 
  
  }


  deleteInventoryItem(basementIndex:any, itemIndex:any){    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove it!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {       

        let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
        this.IndexedDBService.deleteBasementItem(surveyPayload, this.currentTabTypeid, basementIndex, itemIndex).then((response: any) => {                   
          if(response) {
            this.getUpdatePackingMaterials(surveyPayload, 'from', basementIndex);
            swalWithBootstrapButtons.fire(
              'Deleted!',
              'Item has been deleted.',
              'success'
            )
            this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                              
              this.eventEmitterService.surveyImportData(getResponse.data);
            });
            
          }else{
            Swal.fire('Error', 'Something went wrong!', 'error');
          }

        });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }


  deleteBasement(basementIndex:any){   
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove it!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {       

        let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
        this.IndexedDBService.deleteBasement(surveyPayload, this.currentTabTypeid, basementIndex).then((response: any) => {                   
          if(response) {
            swalWithBootstrapButtons.fire(
              'Deleted!',
              'Item has been deleted.',
              'success'
            )
            this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                              
              this.eventEmitterService.surveyImportData(getResponse.data);
            });
            
          }else{
            Swal.fire('Error', 'Something went wrong!', 'error');
          }

        });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }



  selectEventItem(item: any, basementRow:any, inventoryIndex:any) {
    let DataArr:any;
    if(this.currentTabTypeid=='from'){
      DataArr= this.ImportSurveyList.property.from.plan.basements;
    }else{
      DataArr= this.ImportSurveyList.property.to.plan.basements;
    }
    
    let InventoryDataEditArr= DataArr[basementRow].inventory[inventoryIndex];   
    InventoryDataEditArr.item_id=  item.item_id;    
    InventoryDataEditArr.type_id=  item.type_id;    
    InventoryDataEditArr.item_name=  item.item_name;    
    InventoryDataEditArr.item_weight=  item.item_weight;    
    InventoryDataEditArr.item_carry_people= item.item_carry_people; 
    InventoryDataEditArr.type_name= item.type_name;
    InventoryDataEditArr.item_do_dismantle = item.item_show_dismantle;
    InventoryDataEditArr.item_dismantle_time = item.item_dismantle_time;    
    InventoryDataEditArr.item_do_reassemble = item.item_do_reassemble;   
    InventoryDataEditArr.item_reassemble_time = item.item_reassemble_time;
    InventoryDataEditArr.item_typical_value = item.item_typical_value;
    InventoryDataEditArr.item_provides_storage = item.item_provides_storage;    
    InventoryDataEditArr.item_to_go = item.item_to_go;
    InventoryDataEditArr.item_pack_small = item.item_pack_small;
    InventoryDataEditArr.item_pack_med = item.item_pack_med;    
    InventoryDataEditArr.item_pack_large = item.item_pack_large;
    InventoryDataEditArr.item_pack_layflat = item.item_pack_layflat;
    InventoryDataEditArr.item_pack_wardrobe = item.item_pack_wardrobe;    
    InventoryDataEditArr.item_pack_picturepack = item.item_pack_picturepack;
    InventoryDataEditArr.item_pack_golfbox = item.item_pack_golfbox;
    InventoryDataEditArr.item_pack_bikebox = item.item_pack_bikebox,    
    InventoryDataEditArr.item_pack_mattressbagsingle = item.item_pack_mattressbagsingle,
    InventoryDataEditArr.item_pack_mattressbagdouble = item.item_pack_mattressbagdouble,
    InventoryDataEditArr.item_pack_mattressbagking = item.item_pack_mattressbagking,    
    InventoryDataEditArr.item_pack_mattressbagsuperking = item.item_pack_mattressbagsuperking,
    InventoryDataEditArr.item_pack_tvbox24 = item.item_pack_tvbox24;
    InventoryDataEditArr.item_pack_tvbox32 = item.item_pack_tvbox32;    
    InventoryDataEditArr.item_pack_tvbox36 = item.item_pack_tvbox36;
    InventoryDataEditArr.item_pack_tvbox43 = item.item_pack_tvbox43;
    InventoryDataEditArr.item_pack_tvbox48 = item.item_pack_tvbox48;    
    InventoryDataEditArr.item_pack_tvbox55 = item.item_pack_tvbox55;
    InventoryDataEditArr.item_pack_tvbox70 = item.item_pack_tvbox70;
    InventoryDataEditArr.item_pack_tvbox85 = item.item_pack_tvbox85;    
    InventoryDataEditArr.item_width_cm = item.item_width_cm;    
    InventoryDataEditArr.item_height_cm = item.item_height_cm;    
    InventoryDataEditArr.item_depth_cm = item.item_depth_cm;    
    InventoryDataEditArr.item_width_inch = item.item_width_inch;    
    InventoryDataEditArr.item_height_inch = item.item_height_inch;    
    InventoryDataEditArr.item_depth_inch = item.item_depth_inch;    
    InventoryDataEditArr.item_cube_cm = item.item_cube_cm;    
    InventoryDataEditArr.item_cube_ft = item.item_cube_ft;    
    InventoryDataEditArr.item_cube_dismantled_cm = item.item_cube_dismantled_cm;    
    InventoryDataEditArr.item_cube_dismantled_ft = item.item_cube_dismantled_ft;    
    InventoryDataEditArr.item_percentage_full = item.item_percentage_full;
    DataArr=InventoryDataEditArr; 
    this.InventoryDataEdit= DataArr;
  }

  onClearedItem(e:any, basementRow:any, inventoryIndex:any) {
    let DataArr:any;
    if(this.currentTabTypeid=='from'){
      DataArr= this.ImportSurveyList.property.from.plan.basements;
    }else{
      DataArr= this.ImportSurveyList.property.to.plan.basements;
    }
    
    let InventoryDataEditArr= DataArr[basementRow].inventory[inventoryIndex];   
    InventoryDataEditArr.item_id=  '';    
    InventoryDataEditArr.type_id=  '';    
    //InventoryDataEditArr.item_name=  '';    
    InventoryDataEditArr.item_weight=  undefined;    
    InventoryDataEditArr.item_carry_people= undefined; 
    InventoryDataEditArr.type_name= '';
    InventoryDataEditArr.item_do_dismantle = false;
    InventoryDataEditArr.item_dismantle_time = '';    
    InventoryDataEditArr.item_do_reassemble = false;   
    InventoryDataEditArr.item_reassemble_time = '';
    InventoryDataEditArr.item_typical_value = undefined;
    InventoryDataEditArr.item_provides_storage = false;    
    InventoryDataEditArr.item_to_go = 'TG';
    InventoryDataEditArr.item_pack_small = undefined;
    InventoryDataEditArr.item_pack_med = undefined;    
    InventoryDataEditArr.item_pack_large = undefined;
    InventoryDataEditArr.item_pack_layflat = undefined;
    InventoryDataEditArr.item_pack_wardrobe = undefined;    
    InventoryDataEditArr.item_pack_picturepack = undefined;
    InventoryDataEditArr.item_pack_golfbox = undefined;
    InventoryDataEditArr.item_pack_bikebox = undefined;    
    InventoryDataEditArr.item_pack_mattressbagsingle = undefined;
    InventoryDataEditArr.item_pack_mattressbagdouble = undefined;
    InventoryDataEditArr.item_pack_mattressbagking = undefined;    
    InventoryDataEditArr.item_pack_mattressbagsuperking = undefined;
    InventoryDataEditArr.item_pack_tvbox24 = undefined;
    InventoryDataEditArr.item_pack_tvbox32 = undefined;    
    InventoryDataEditArr.item_pack_tvbox36 = undefined;
    InventoryDataEditArr.item_pack_tvbox43 = undefined;
    InventoryDataEditArr.item_pack_tvbox48 = undefined;    
    InventoryDataEditArr.item_pack_tvbox55 = undefined;
    InventoryDataEditArr.item_pack_tvbox70 = undefined;
    InventoryDataEditArr.item_pack_tvbox85 = undefined;    
    InventoryDataEditArr.item_width_cm = undefined;    
    InventoryDataEditArr.item_height_cm = undefined;    
    InventoryDataEditArr.item_depth_cm = undefined;    
    InventoryDataEditArr.item_width_inch = undefined;    
    InventoryDataEditArr.item_height_inch = undefined;    
    InventoryDataEditArr.item_depth_inch = undefined;    
    InventoryDataEditArr.item_cube_cm = undefined;    
    InventoryDataEditArr.item_cube_ft = undefined;    
    InventoryDataEditArr.item_cube_dismantled_cm = undefined;    
    InventoryDataEditArr.item_cube_dismantled_ft = undefined;    
    InventoryDataEditArr.item_percentage_full = undefined;
    DataArr=InventoryDataEditArr; 
    this.InventoryDataEdit= DataArr;

  }

  onSubmitInventoryUpdate(inventoryUpdateForm:NgForm) {  
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    if(inventoryUpdateForm.valid){
      let basementRow= inventoryUpdateForm.value.basement_row;
      let inventoryRow= inventoryUpdateForm.value.inventory_row;
      let formData= inventoryUpdateForm.value;
      
        let item_width_inch:any;
        let item_height_inch:any;
        let item_depth_inch:any;
        let item_width_cm:any;
        let item_height_cm:any;
        let item_depth_cm:any;
        let item_cube_cm:any;
        let item_cube_dismantled_cm:any;
        let item_cube_ft:any;
        let item_cube_dismantled_ft:any;
      if(this.defaultUnits?.linear_units=='cm') {
        item_width_inch = Number (formData['item_width_cm'])*0.39370;
        item_height_inch = Number (formData['item_height_cm'])*0.39370;
        item_depth_inch = Number (formData['item_depth_cm'])*0.39370;
        item_width_cm= formData['item_width_cm'];
        item_height_cm= formData['item_height_cm'];
        item_depth_cm= formData['item_depth_cm'];
        item_width_inch= parseFloat(item_width_inch).toFixed(2);
        item_height_inch= parseFloat(item_height_inch).toFixed(2);
        item_depth_inch= parseFloat(item_depth_inch).toFixed(2);
      }else{
        item_width_cm= Number (formData['item_width_inch'])/0.39370;
        item_height_cm= Number (formData['item_height_inch'])/0.39370;
        item_depth_cm= Number (formData['item_depth_inch'])/0.39370;
        item_width_inch = formData['item_width_inch'];
        item_height_inch = formData['item_height_inch'];
        item_depth_inch = formData['item_depth_inch'];
        item_width_cm= parseFloat(item_width_cm).toFixed(2);
        item_height_cm= parseFloat(item_height_cm).toFixed(2);
        item_depth_cm= parseFloat(item_depth_cm).toFixed(2);

      }
      if(this.defaultUnits?.cubic_units=='cm') {
        item_cube_ft = Number(formData['item_cube_cm'])*0.032808;
        item_cube_dismantled_ft = Number( formData['item_cube_dismantled_cm'])*0.032808;
        item_cube_cm= formData['item_cube_cm'];
        item_cube_dismantled_cm=  formData['item_cube_dismantled_cm'];
        item_cube_ft= parseFloat(item_cube_ft).toFixed(2);
        item_cube_dismantled_ft= parseFloat(item_cube_dismantled_ft).toFixed(2);
      }else{
        item_cube_cm = Number(formData['item_cube_ft'])/0.032808;
        item_cube_dismantled_cm = Number( formData['item_cube_dismantled_cm'])/0.032808;
        item_cube_ft=  formData['item_cube_dismantled_ft'];
        item_cube_dismantled_ft=  formData['item_cube_dismantled_ft'];
        item_cube_cm= parseFloat(item_cube_cm).toFixed(2);
        item_cube_dismantled_cm= parseFloat(item_cube_dismantled_cm).toFixed(2);
      }
      
      const inventoryPayload = {
        item_id:  formData['item_id'],    
        type_id:  formData['type_id'],
        item_name: formData['item_name_auto'], 
        item_weight: formData['item_weight'], 
        item_carry_people: formData['item_carry_people'], 
        type_name: formData['type_name'],
        item_do_dismantle : formData['item_do_dismantle'],
        item_dismantle_time : formData['item_dismantle_time'],    
        item_do_reassemble : formData['item_do_reassemble'],
        item_reassemble_time : formData['item_reassemble_time'],
        item_typical_value : formData['item_typical_value'],
        item_provides_storage : formData['item_provides_storage'],    
        item_to_go : formData['item_to_go'],
        item_pack_small : formData['item_pack_small'],
        item_pack_med : formData['item_pack_med'],    
        item_pack_large : formData['item_pack_large'],
        item_pack_layflat : formData['item_pack_layflat'],
        item_pack_wardrobe : formData['item_pack_wardrobe'],    
        item_pack_picturepack : formData['item_pack_picturepack'],
        item_pack_golfbox : formData['item_pack_golfbox'],
        item_pack_bikebox : formData['item_pack_bikebox'],    
        item_pack_mattressbagsingle : formData['item_pack_mattressbagsingle'],
        item_pack_mattressbagdouble : formData['item_pack_mattressbagdouble'],
        item_pack_mattressbagking : formData['item_pack_mattressbagking'],    
        item_pack_mattressbagsuperking : formData['item_pack_mattressbagsuperking'],
        item_pack_tvbox24 : formData['item_pack_tvbox24'],
        item_pack_tvbox32 : formData['item_pack_tvbox32'],    
        item_pack_tvbox36 : formData['item_pack_tvbox36'],
        item_pack_tvbox43 : formData['item_pack_tvbox43'],
        item_pack_tvbox48 : formData['item_pack_tvbox48'],    
        item_pack_tvbox55 : formData['item_pack_tvbox55'],
        item_pack_tvbox70 : formData['item_pack_tvbox70'],
        item_pack_tvbox85 : formData['item_pack_tvbox85'],    
        item_width_cm : item_width_cm,    
        item_height_cm : item_height_cm,    
        item_depth_cm : item_depth_cm,    
        item_width_inch : item_width_inch,    
        item_height_inch : item_height_inch,    
        item_depth_inch : item_depth_inch,    
        item_cube_cm : item_cube_cm,    
        item_cube_ft : item_cube_ft,    
        item_cube_dismantled_cm : item_cube_dismantled_cm,    
        item_cube_dismantled_ft : item_cube_dismantled_ft,    
        item_percentage_full : formData['item_percentage_full']
      }   
      
      this.IndexedDBService.updateBasementInventoryData(inventoryPayload, surveyPayload, this.currentTabTypeid, basementRow,inventoryRow).then((response: any) => {                   
        if(response) {
          this.getUpdatePackingMaterials(surveyPayload, 'from', basementRow);
          Swal.fire('Updated', 'Item updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            console.log(getResponse);                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.modalService.dismissAll('Dismissed modal box after saving data');
            inventoryUpdateForm.form.markAsPristine();
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });      
    }
  }


  getCategoryList(){ 
    this.IndexedDBService.getCateogries()
    .then((data:any) => {
      if(data!=undefined){
        this.itemTypeList = data;
      }else{
        // call if data not available in indexedDB
        this.apiService.getCategoryList().subscribe(  
          (res)=>{         
          this.IndexedDBService
          .addCategories(res.data)
          //.then() 
          this.itemTypeList = res.data;
        },
        (err)=>{  
          console.log("fail");      
        });

      }
    });  
  }


  getDefaultsUnits(){
    this.IndexedDBService.getDefaultUnits().then((getResponse: any) => {  
      if(getResponse){        
          this.defaultUnits=getResponse;
          
      }else{
        const unitsPayload = {
          linear_units : 'cm',    
          cubic_units : 'cm'             
        }  
        this.IndexedDBService.addMeasurementUnits(unitsPayload).then((response: any) => {                   
          if(response) {
            this.defaultUnits= { linear_units : 'cm', cubic_units : 'cm'}    
          }
        })
      }

      this.setInventoryFormValidation();
      
    });
    
  }


  setInventoryFormValidation(){
    this.InventoryItemFormFrom = this.formBuilder.group({
      basementIndex : new FormControl(),    
      item_name_search : new FormControl(''),
      item_type_search : new FormControl(''),
      item_name : new FormControl('', Validators.required),
      //item_type : new FormControl('', Validators.required),
      item_type : new FormControl(''),
      total_items : new FormControl('1',  Validators.compose([Validators.required, Validators.min(1)])),
      item_id : new FormControl(''),
      type_id : new FormControl(''),
      item_weight : new FormControl(''),
      item_carry_people : new FormControl(''),    
      type_name : new FormControl(''),
      item_do_dismantle : new FormControl(''),
      item_dismantle_time : new FormControl(''),    
      item_do_reassemble : new FormControl(''),    
      item_reassemble_time : new FormControl(''),
      item_typical_value : new FormControl(''),
      item_provides_storage : new FormControl(''),    
      item_to_go : new FormControl('TG', Validators.required),
      item_pack_small : new FormControl(''),
      item_pack_med : new FormControl(''),    
      item_pack_large : new FormControl(''),
      item_pack_layflat : new FormControl(''),
      item_pack_wardrobe : new FormControl(''),    
      item_pack_picturepack : new FormControl(''),
      item_pack_golfbox : new FormControl(''),
      item_pack_bikebox : new FormControl(''),    
      item_pack_mattressbagsingle : new FormControl(''),
      item_pack_mattressbagdouble : new FormControl(''),
      item_pack_mattressbagking : new FormControl(''),    
      item_pack_mattressbagsuperking : new FormControl(''),
      item_pack_tvbox24 : new FormControl(''),
      item_pack_tvbox32 : new FormControl(''),    
      item_pack_tvbox36 : new FormControl(''),
      item_pack_tvbox43 : new FormControl(''),
      item_pack_tvbox48 : new FormControl(''),    
      item_pack_tvbox55 : new FormControl(''),
      item_pack_tvbox70 : new FormControl(''),
      item_pack_tvbox85 : new FormControl(''),    
      item_width_cm : new FormControl(''),    
      item_height_cm : new FormControl(''),    
      item_depth_cm : new FormControl(''),    
      item_width_inch : new FormControl(''),    
      item_height_inch : new FormControl(''),    
      item_depth_inch : new FormControl(''),    
      item_cube_cm : new FormControl(''),    
      item_cube_ft : new FormControl(''),    
      item_cube_dismantled_cm : new FormControl(''),    
      item_cube_dismantled_ft : new FormControl(''),    
      item_percentage_full : new FormControl('')        
    });
    
    this.InventoryItemFormManual = this.formBuilder.group({
      basementIndex : new FormControl(),    
      item_name : new FormControl('', Validators.required),
      total_items : new FormControl('1',  Validators.compose([Validators.required, Validators.min(1)])),
      item_id : new FormControl(''),
      type_id : new FormControl(''),
      item_weight : new FormControl(''),
      item_carry_people : new FormControl(''),    
      type_name : new FormControl(''),
      item_do_dismantle : new FormControl(''),
      item_dismantle_time : new FormControl(''),    
      item_do_reassemble : new FormControl(''),    
      item_reassemble_time : new FormControl(''),
      item_typical_value : new FormControl(''),
      item_provides_storage : new FormControl(''),    
      item_to_go : new FormControl('TG', Validators.required),
      item_pack_small : new FormControl(''),
      item_pack_med : new FormControl(''),    
      item_pack_large : new FormControl(''),
      item_pack_layflat : new FormControl(''),
      item_pack_wardrobe : new FormControl(''),    
      item_pack_picturepack : new FormControl(''),
      item_pack_golfbox : new FormControl(''),
      item_pack_bikebox : new FormControl(''),    
      item_pack_mattressbagsingle : new FormControl(''),
      item_pack_mattressbagdouble : new FormControl(''),
      item_pack_mattressbagking : new FormControl(''),    
      item_pack_mattressbagsuperking : new FormControl(''),
      item_pack_tvbox24 : new FormControl(''),
      item_pack_tvbox32 : new FormControl(''),    
      item_pack_tvbox36 : new FormControl(''),
      item_pack_tvbox43 : new FormControl(''),
      item_pack_tvbox48 : new FormControl(''),    
      item_pack_tvbox55 : new FormControl(''),
      item_pack_tvbox70 : new FormControl(''),
      item_pack_tvbox85 : new FormControl(''),    
      item_width_cm : new FormControl(''),    
      item_height_cm : new FormControl(''),    
      item_depth_cm : new FormControl(''),    
      item_width_inch : new FormControl(''),    
      item_height_inch : new FormControl(''),    
      item_depth_inch : new FormControl(''),    
      item_cube_cm : new FormControl(''),    
      item_cube_ft : new FormControl(''),    
      item_cube_dismantled_cm : new FormControl(''),    
      item_cube_dismantled_ft : new FormControl(''),    
      item_percentage_full : new FormControl('')        
    });

    if(this.defaultUnits?.linear_units=='cm') {
      this.InventoryItemFormFrom.controls.item_width_cm.setValidators([Validators.required]);
      this.InventoryItemFormFrom.controls.item_height_cm.setValidators([Validators.required]);
      this.InventoryItemFormFrom.controls.item_depth_cm.setValidators([Validators.required]); 

      this.InventoryItemFormManual.controls.item_width_cm.setValidators([Validators.required]);
      this.InventoryItemFormManual.controls.item_height_cm.setValidators([Validators.required]);
      this.InventoryItemFormManual.controls.item_depth_cm.setValidators([Validators.required]);        
    } else {
      this.InventoryItemFormFrom.controls.item_width_inch.setValidators(Validators.required);
      this.InventoryItemFormFrom.controls.item_height_inch.setValidators(Validators.required);
      this.InventoryItemFormFrom.controls.item_depth_inch.setValidators(Validators.required);

      this.InventoryItemFormManual.controls.item_width_inch.setValidators(Validators.required);
      this.InventoryItemFormManual.controls.item_height_inch.setValidators(Validators.required);
      this.InventoryItemFormManual.controls.item_depth_inch.setValidators(Validators.required);
    }
  }
  

  onChangeSearchType(str: string){   
    
    this.InventoryItemFormFrom.markAsDirty();
    this.isLoadingResult = true;
    this.IndexedDBService.getCateogries()
    .then((data:any) => {       
      if(data!=undefined){
        this.itemTypeList = data;
        this.isLoadingResult = false;
      }else{         
        // call if data not available in indexedDB
        this.apiService.getCategoryList().subscribe(  
          (res)=>{         
          
          this.IndexedDBService
          .addCategories(res.data)
          //.then()  
          this.itemTypeList = res.data; 
          this.isLoadingResult = false;            
        },
        (err)=>{  
          console.log("fail");      
        });
      }       
      
    });      
   
  }  

  selectEventType(item: any) {
   
    this.selectedItemTypeId=item.type_id;
    this.itemListFiltered = (this.itemList).filter(function( obj:any ) {
      return obj.type_id == item.type_id;
    });
    this.InventoryItemFormFrom.patchValue({
      item_type:item.type_name, 
    });
    
  }

  onFocusedType(e:any){    
    this.itemTypeList;
  }
  onClearedType(e:any) {   
    this.selectedItemTypeId=undefined;      
    this.itemFormClear();
    this.InventoryItemFormFrom.patchValue({
      item_type:'', 
      item_name_search:'',
    });
    
  }

  
  setAccordionOpen(floorRow:any){    
    $('a[aria-controls = "collapseBasement_fb_'+floorRow+'"]').removeClass('collapsed');   
    $("#collapseBasement_fb_"+floorRow).addClass("show");

    $('a[aria-controls = "collapseTwo_fb_'+floorRow+'"]').removeClass('collapsed');   
    $("#collapseTwo_fb_"+floorRow).addClass("show");
    $('a[aria-controls = "collapseTwo_fb_'+floorRow+'"]').focus(); 

    /*****sidebar menu active*****/
    $(".basement_list li").eq(floorRow).addClass("active-floor");  
      
  }


  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {  
    
      if (!this.basementForm.pristine || !this.InventoryItemFormFrom.pristine || this.InventoryItemFormFrom.dirty || !this.InventoryItemFormManual.pristine) {
          $event.returnValue = true;
      }
  }
}
