import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-upload-survey',
  templateUrl: './upload-survey.component.html',
  styleUrls: ['./upload-survey.component.css']
})
export class UploadSurveyComponent implements OnInit { 
  
  constructor() {}

  ngOnInit(): void {   
         
  }


}
