<ul class="sidebar_nav navbar-nav bg-gradient-primary sidebar sidebar-dark accordion pt-3 menu-open"
  id="accordionSidebar">
  <button class="toggle-btn" (click)="sidebarTogggle()"><i class="fa fa-bars" aria-hidden="true"></i></button>
  <!-- Nav Item - Dashboard -->
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/dashboard"><img src="../assets/img/Open.svg"><span>Dashboard</span></a>
  </li>
<!--
  <hr class="sidebar-divider">

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/survey-import-list"> <img src="../assets/img/import.svg"><span>Import</span></a>
  </li>

  <hr class="sidebar-divider">

  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/property-information"><img src="../assets/img/pen-tool.svg"><span>New</span></a>
  </li>
-->
  <hr class="sidebar-divider">

  <div class="menu_buttonclose w-100">
     <button class="btn w-100 border-0  rounded-0 text-white p-3 text-left" id="close_nav" type="button" data-toggle="collapse" aria-expanded="false">
       Collapsable Menu <img src="../assets/img/right-icon.svg">
     </button>

  </div>
</ul>
