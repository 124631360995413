import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription'; 

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeTabTypeClickFunction = new EventEmitter();
  //invokeTabClickFunction = new EventEmitter();
  invokeSurveyImportFunction = new EventEmitter();
  currentTabTypeid!: Subscription;
  ImportSurveyList!: Subscription;
  //currentTabid!: Subscription;

  constructor() { }

  ontabTypeClick(typeId:any) {    
    this.invokeTabTypeClickFunction.emit(typeId);    
  } 
   
/*
  ontabClick(tabId:any) {    
    this.invokeTabClickFunction.emit(tabId);    
  } */


  surveyImportData(data:any) {    
    this.invokeSurveyImportFunction.emit(data);    
  }

}