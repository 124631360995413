import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {ApiService} from "../services/api.service";
import { IndexedDBService } from '../services/indexed-db.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  deferredPrompt: any;
  showButton = false;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: { preventDefault: () => void; }) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }
  
  message='';
  twoFactorMessage='';
  twoFactorToken='';
  invalidLogin: boolean = false;
  submitted = false;
  submittedCode = false;
  loginForm= new FormGroup({
    username : new FormControl(),
    password : new FormControl()    
  });
  
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private IndexedDBService: IndexedDBService) { }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: { outcome: string; }) => {
        if (choiceResult.outcome === 'accepted') {
          this.showButton = false;
          console.log('User accepted the A2HS prompt');
        } else {
          this.showButton = true;
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  isAppInstall(){
    return this.showButton; 
  }

  ngOnInit(): void {

    if (window.localStorage.getItem('token')) {      
      this.router.navigate(['dashboard']);             
    }else{
      window.localStorage.removeItem('token');
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required],
      code: [''],
      token: [''],
    });   
  }


  get loginFormControl() {
    return this.loginForm.controls;
  }

 
  onSubmit() {
    this.submitted = true;
    const loginPayload = {
      email: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
      code: this.loginForm.controls.code.value,
      '2factortoken': this.loginForm.controls.token.value,
    }

    if (this.loginForm.valid) {      
      this.apiService.login(loginPayload).subscribe(data => {
       
        if(data.status === true) {
          if(data.data.twofactortoken==null){
            window.localStorage.setItem('token', data.data.token);          
            this.router.navigate(['dashboard']);
            //this.message=data.message;          
            this.apiService.getSurveyorDetails().subscribe(  
              (res)=>{         
              console.log(res);
              if(res.data!=null){
                const ProfilePayload = {
                  surveyor_id : res.data.surveyor_id,                           
                  surveyor_email : res.data.surveyor_email,                           
                  surveyor_first_name : res.data.surveyor_first_name,                           
                  surveyor_last_name : res.data.surveyor_last_name,                           
                  surveyor_mob : res.data.surveyor_mob,                           
                  surveyor_profile_img : res.data.surveyor_profile_img,                           
                }
                this.IndexedDBService.addProfileDetails(ProfilePayload).then((response: any) => {                   
                  console.log(response);
                });  
              }
            },
            (err)=>{  
              console.log("fail");                    
            });
          }else{
            this.twoFactorMessage=data.message;
            this.twoFactorToken=data.data.twofactortoken;
            
          }
        }else {
          this.invalidLogin = true;
          this.message=data.message;
          //alert(data.message);
        }
      },error => {      
        if(error.status==401){
          this.message=error.error.message;
        }
      });      
    }
   // this.invalidLogin = true;
  }


}
