<section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
  <nav class="mb-4">
    <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'from' ? 'active' : ''}}" id="nav-overview-tab"
        data-toggle="tab" href="#nav-from-otherarea" role="tab" aria-controls="nav-Info" aria-selected="true"
        (click)="tabTypeClickFunction('from');">From</a>
      <!-- <a class="nav-item nav-link border-0 {{currentTabTypeid == 'to' ? 'active' : ''}}" id="nav-profile-tab"
        data-toggle="tab" href="#nav-to-otherarea" role="tab" aria-controls="nav-Parking" aria-selected="false"
        (click)="tabTypeClickFunction('to');">To</a> -->
    </div>
  </nav>

  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade {{currentTabTypeid == 'from' ? 'show active' : ''}}" id="nav-from-otherarea"
      role="tabpanel" aria-labelledby="nav-overview-tab">

      <p class="text-center"
        *ngIf="(ImportSurveyList.property?.from.plan.other_areas?.length <=0) && !loader">No other areas added!
      </p>

      <span class="w-100 d-inline-block"><button
          class="btn btn-primary text-white float-right" (click)="open(addAreaModal)">Add
          Areas</button>
      </span>

      <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

      

      <div id="accordion" role="tablist" class="o-accordion"
        *ngIf="(ImportSurveyList.property?.from.plan.other_areas?.length > 0)">
        <div class="card mt-2 mb-2"
          *ngFor="let area of ImportSurveyList.property.from.plan.other_areas; let areaRow=index;">
          <div role="tab" id="Areainner{{areaRow}}" class="card-header">
            <h5 class="mb-0"><a data-toggle="collapse" data-parent="#accordion" href="#collapseArea_fa_{{areaRow}}"
                aria-expanded="false" [attr.aria-controls]="'collapseArea_fa_'+areaRow"
                class="w-100 d-inline-block floor-accordion collapsed">{{
                area.area_name }} <i class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
              </a>
              <span class="delete-icon" (click)="deleteArea(areaRow)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
            </h5>
          </div>
          <div id="collapseArea_fa_{{areaRow}}" role="tabpanel" [attr.aria-labelledby]="'Areainner'+areaRow"
            class="collapse other-aria-tab plan-content-section">
            <div class="card-block p-4">
              <form (ngSubmit)="onSubmitAreaUpdate(areaUpdateForm)" #areaUpdateForm="ngForm">
                <input type="hidden" name="area_row" [ngModel]="areaRow" />
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="exampleInputSurvey">Area name</label>
                      <input type="text" class="form-control" placeholder="Area Name" autocomplete="off"
                        name="area_name" [ngModel]="area.area_name" #area_name="ngModel" required>

                      <div class="text-danger" *ngIf="!area_name.value">
                        Area name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="exampleInputSurvey">Are we moving the contents of this area? </label>
                      <select class="form-control form-control-custom" name="area_ntg" [ngModel]="area.area_ntg"
                        #area_ntg="ngModel" required>
                        <option value="" [selected]="(area.area_ntg==null || area.area_ntg=='') ? true:false">Select
                        </option>
                        <option value="TG" [selected]="(area.area_ntg=='TG') ? true:false">To Go</option>
                        <option value="NTG" [selected]="(area.area_ntg=='NTG') ? true:false">Not To Go</option>
                        <option value="MTG" [selected]="(area.area_ntg=='MTG') ? true:false">Maybe To Go</option>
                      </select>

                      <div class="text-danger" *ngIf="!area_ntg.value">
                        Area NTG is Required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this area </label>
                      <input type="number" class="form-control" placeholder="Area Box Estimate" min="0"
                        autocomplete="off" name="area_box_estimate" [ngModel]="area.area_box_estimate"
                        #area_box_estimate="ngModel">
                    </div>
                  </div>
                </div>
 
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this area?
                      </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.area_packing==true"
                              name="area_packing" [value]=true [(ngModel)]="area.area_packing">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.area_packing==false"
                              name="area_packing" [value]=false [(ngModel)]="area.area_packing">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Have we been able to see the area? </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.area_seen==true"
                              name="area_seen" [value]=true [(ngModel)]="area.area_seen">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.area_seen==false"
                              name="area_seen" [value]=false [(ngModel)]="area.area_seen">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this area?
                      </label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.move_carpets==true"
                              name="move_carpets" [value]=true [(ngModel)]="area.move_carpets">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.move_carpets==false"
                              name="move_carpets" [value]=false [(ngModel)]="area.move_carpets">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group one-line-flex">
                      <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains/blinds in this area?</label>
                      <div class="answer-boxed">
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.move_curtains_blinds==true"
                              name="move_curtains_blinds" [value]=true [(ngModel)]="area.move_curtains_blinds">Yes
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" [checked]="area.move_curtains_blinds==false"
                              name="move_curtains_blinds" [value]=false [(ngModel)]="area.move_curtains_blinds">No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer border-0 sumit_popup">
                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                </div>
              </form>

              <div id="accordion" role="tablist" class="o-accordion">
                <!----packing material start-->
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingOne_p">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                        data-parent="#accordion" href="#collapseOne_p{{areaRow}}" aria-expanded="false"
                        aria-controls="collapseOne">Packing Materials <i class="fa fa-chevron-down arrow-down"
                          aria-hidden="true"></i>
                      </a>
                    </h5>
                  </div>

                  <div id="collapseOne_p{{areaRow}}" class="collapse" role="tabpanel"
                    [attr.aria-labelledby]="'collapseOne_p'+areaRow">
                    <div class="card-block p-4">

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Small</label>
                            <input type="number" class="form-control" placeholder="Small" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.small }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Medium</label>
                            <input type="number" class="form-control" placeholder="Medium" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.med }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Large</label>
                            <input type="number" class="form-control" placeholder="Large" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.large }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Layflat</label>
                            <input type="number" class="form-control" placeholder="Layflat" autocomplete="off"
                              value="{{ area.packing_materials?.layflat }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Wardrobe</label>
                            <input type="number" class="form-control" placeholder="Wardrobe" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.wardrobe }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Picture Pack</label>
                            <input type="number" class="form-control" placeholder="Picture Pack" min="0"
                              autocomplete="off" value="{{ area.packing_materials?.picturepack }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Golf Box</label>
                            <input type="number" class="form-control" placeholder="Golf Box" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.golfbox }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Bike Box</label>
                            <input type="number" class="form-control" placeholder="Bike Box" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.bikebox }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Single</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag Single" min="0"
                              autocomplete="off" value="{{ area.packing_materials?.mattressbagsingle }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Double</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag Double" min="0"
                              autocomplete="off" value="{{ area.packing_materials?.mattressbagdouble }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag King</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag King" min="0"
                              autocomplete="off" value="{{ area.packing_materials?.mattressbagking }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Super King</label>
                            <input type="number" class="form-control" placeholder="Mattress Bag Super King" min="0"
                              autocomplete="off" value="{{ area.packing_materials?.mattressbagsuperking }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 24</label>
                            <input type="number" class="form-control" placeholder="Tv Box 24" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox24 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 32</label>
                            <input type="number" class="form-control" placeholder="Tv Box 32" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox32 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 36</label>
                            <input type="number" class="form-control" placeholder="Tv Box 36" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox36 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 43</label>
                            <input type="number" class="form-control" placeholder="Tv Box 43" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox43 }}">
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 48</label>
                            <input type="number" class="form-control" placeholder="Tv Box 48" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox48 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 55</label>
                            <input type="number" class="form-control" placeholder="Tv Box 55" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox55 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 70</label>
                            <input type="number" class="form-control" placeholder="Tv Box 70" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox70 }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 85</label>
                            <input type="number" class="form-control" placeholder="Tv Box 85" min="0" autocomplete="off"
                              value="{{ area.packing_materials?.tvbox85 }}">
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
                <!----packing material end-->
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingTwo">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                        data-parent="#accordion" href="#collapseTwo_fa_{{areaRow}}" aria-expanded="false"
                        [attr.aria-controls]="'collapseTwo_fa_'+areaRow"> Inventory <i class="fa fa-chevron-down arrow-down"
                          aria-hidden="true"></i>
                      </a>
                      <!-- <span class="delete-icon">
                                              <i class="fa fa-trash" aria-hidden="true"></i>
                                          </span> -->
                    </h5>
                  </div>
                  <div id="collapseTwo_fa_{{areaRow}}" class="collapse" role="tabpanel"
                    [attr.aria-labelledby]="'headingTwo'+areaRow">
                    <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">

                      <div class="text-center mt-4" *ngIf="area?.inventory==''|| area?.inventory==null">No inventory items added</div>

                      <span class="w-100 d-inline-block"><button
                          class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold"
                          (click)="open(inventoryModal); setAreaIndex(areaRow)">Add Item</button></span>

                      

                      <div *ngIf="area?.inventory!='' || area?.inventory!=null">
                        <div class="card multi  mt-0 mb-3 ml-3 mr-3 "
                          *ngFor="let AreaInventory of area.inventory; let inventoryRow=index">
                          <div class="card-header" role="tab" id="headingOne-1">
                            <h5 class="mb-0">
                              <a class="w-100 floor-accordion d-inline-block collapsed" data-toggle="collapse"
                                data-parent="#accordion" href="#collapseOneinnner_fa_{{areaRow}}{{inventoryRow}}"
                                aria-expanded="true"
                                [attr.aria-controls]="'collapseOneinnner_fa_'+areaRow+inventoryRow">
                                {{AreaInventory.item_name}} <i class="fa fa-chevron-down arrow-down mx-3"
                                  aria-hidden="true"></i>
                              </a>
                              <span class="delete-icon" (click)="deleteInventoryItem(areaRow, inventoryRow)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </h5>
                          </div>

                          <div id="collapseOneinnner_fa_{{areaRow}}{{inventoryRow}}" class="collapse" role="tabpanel"
                            aria-labelledby="headingOne-1">
                            <div class="card-block p-4">
                              <form (ngSubmit)="onSubmitInventoryUpdate(inventoryUpdateForm)"
                                #inventoryUpdateForm="ngForm">
                                <input type="hidden" name="area_row" [ngModel]="areaRow" />
                                <input type="hidden" name="inventory_row" [ngModel]="inventoryRow" />
                                <input type="hidden" name="item_name" [ngModel]="AreaInventory.item_name"
                                  #item_name="ngModel">
                                <input type="hidden" value="{{AreaInventory.item_id}}" name="item_id"
                                  [(ngModel)]="AreaInventory.item_id">
                                <input type="hidden" value="{{AreaInventory.type_id}}" name="type_id"
                                  [(ngModel)]="AreaInventory.type_id">
                                
                                  <div class="row inventory-item-row">
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Item Name</label>
                                        <ng-template #itemTemplate let-item>
                                          <a [innerHTML]="item.item_name"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplate let-notFound>
                                          <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                        <ng-autocomplete [data]="itemList" [searchKeyword]="keyword"
                                          (inputChanged)='onChangeSearch($event)'
                                          (selected)='selectEventItem($event, areaRow,inventoryRow)'
                                          (inputCleared)="onClearedItem($event, areaRow,inventoryRow)"
                                          [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                          placeholder="Item Name" [isLoading]="isLoadingResult" [minQueryLength]="1"
                                          name="item_name_auto" [ngModel]="AreaInventory.item_name"
                                          #item_name_auto="ngModel" required>
                                        </ng-autocomplete>
  
                                        <div class="text-danger" *ngIf="!item_name_auto.value">
                                          Item Name is Required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item
                                          ?</label>
                                        <select class="form-control form-control-custom" name="item_to_go"
                                          [ngModel]="AreaInventory.item_to_go" #item_to_go="ngModel" required>
                                          <option value="" [selected]="(AreaInventory.item_to_go=='') ? true:false">
                                            Select</option>
                                          <option value="TG"
                                            [selected]="(AreaInventory.item_to_go=='TG') ? true:false">To Go</option>
                                          <option value="NTG"
                                            [selected]="(AreaInventory.item_to_go=='NTG') ? true:false">Not To Go
                                          </option>
                                          <option value="MTG"
                                            [selected]="(AreaInventory.item_to_go=='MTG') ? true:false">Maybe To Go
                                          </option>
                                        </select>
                                        <div class="text-danger" *ngIf="!item_to_go.value">
                                          Item To Go is Required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Width Cm</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Width Cm"
                                          autocomplete="off" value="{{ AreaInventory.item_width_cm }}"
                                          name="item_width_cm" [(ngModel)]="AreaInventory.item_width_cm"
                                          #item_width_cm="ngModel" ng-required="defaultUnits?.linear_units=='cm'">
  
                                        <div class="text-danger" *ngIf="item_width_cm.value==undefined">
                                          Width is required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Height Cm</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Height Cm"
                                          autocomplete="off" value="{{ AreaInventory.item_height_cm }}"
                                          name="item_height_cm" [(ngModel)]="AreaInventory.item_height_cm"
                                          #item_height_cm="ngModel" ng-required="defaultUnits?.linear_units=='cm'">
  
                                        <div class="text-danger" *ngIf="item_height_cm.value==undefined">
                                          Height is required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Depth Cm</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Depth Cm"
                                          autocomplete="off" value="{{ AreaInventory.item_depth_cm }}"
                                          name="item_depth_cm" [(ngModel)]="AreaInventory.item_depth_cm"
                                          #item_depth_cm="ngModel" ng-required="defaultUnits?.linear_units=='cm'">
  
                                        <div class="text-danger" *ngIf="item_depth_cm.value==undefined">
                                          Depth is required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Width Inch</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Width Inch"
                                          autocomplete="off" value="{{ AreaInventory.item_width_inch }}"
                                          name="item_width_inch" [(ngModel)]="AreaInventory.item_width_inch"
                                          #item_width_inch="ngModel" ng-required="defaultUnits?.linear_units=='inch'">
  
                                        <div class="text-danger" *ngIf="item_width_inch.value==undefined">
                                          Width is required
                                        </div>
                                      </div>
                                    </div>
                                    
                                  
                                    <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Height Inch</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Height Inch"
                                          autocomplete="off" value="{{ AreaInventory.item_height_inch }}"
                                          name="item_height_inch" [(ngModel)]="AreaInventory.item_height_inch"
                                          #item_height_inch="ngModel" ng-required="defaultUnits?.linear_units=='inch'">
  
                                        <div class="text-danger" *ngIf="item_height_inch.value==undefined">
                                          Height is required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Depth Inch</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Depth Inch"
                                          autocomplete="off" value="{{ AreaInventory.item_depth_inch }}"
                                          name="item_depth_inch" [(ngModel)]="AreaInventory.item_depth_inch"
                                          #item_depth_inch="ngModel" ng-required="defaultUnits?.linear_units=='inch'">
  
                                        <div class="text-danger" *ngIf="item_depth_inch.value==undefined">
                                          Depth is required
                                        </div>
                                      </div>
                                    </div>
                                    <div class="d-none">
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Weight</label>
                                        <input type="number" min="0" class="form-control" placeholder="Item Weight"
                                          autocomplete="off" value="{{AreaInventory.item_weight }}" name="item_weight"
                                          [(ngModel)]="AreaInventory.item_weight" #item_weight="ngModel" >
                                        
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">People required to carry item</label>
                                        <input type="number" min="0" class="form-control" placeholder="People required to carry item"
                                          autocomplete="off" name="item_carry_people"
                                          [(ngModel)]="AreaInventory.item_carry_people" #item_carry_people="ngModel">
                                       
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Category</label>
                                        <input type="text" class="form-control" placeholder="Category" autocomplete="off"
                                          name="type_name" [(ngModel)]="AreaInventory.type_name" #type_name="ngModel">
                                        
                                      </div>
                                    </div>
                                    
                                    <div class="col {{defaultUnits?.cubic_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Cm</label>
                                        <input type="number" min="0" class="form-control" placeholder="Cubic Cm"
                                          autocomplete="off" value="{{ AreaInventory.item_cube_cm }}"
                                          name="item_cube_cm" [(ngModel)]="AreaInventory.item_cube_cm"
                                          #item_cube_cm="ngModel">
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.cubic_units=='cm' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Cm dismantled</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Cubic Cm dismantled" autocomplete="off"
                                          value="{{ AreaInventory.item_cube_dismantled_cm }}"
                                          name="item_cube_dismantled_cm"
                                          [(ngModel)]="AreaInventory.item_cube_dismantled_cm"
                                          #item_cube_dismantled_cm="ngModel">
                                      </div>
                                    </div>
                                    <div class="col {{defaultUnits?.cubic_units=='ft' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Ft</label>
                                        <input type="number" min="0" class="form-control" placeholder="Cubic Ft"
                                          autocomplete="off" value="{{ AreaInventory.item_cube_ft }}"
                                          name="item_cube_ft" [(ngModel)]="AreaInventory.item_cube_ft"
                                          #item_cube_ft="ngModel">
                                      </div>
                                    </div>
                                    
                                    <div class="col {{defaultUnits?.cubic_units=='ft' ? '' : 'd-none'}}">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Cubic Ft dismantled</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Cubic Ft dismantled" autocomplete="off"
                                          value="{{ AreaInventory.item_cube_dismantled_ft }}"
                                          name="item_cube_dismantled_ft"
                                          [(ngModel)]="AreaInventory.item_cube_dismantled_ft"
                                          #item_cube_dismantled_ft="ngModel">
                                      </div>
                                    </div>
  
                                    <div class="col">
                                      <div class="form-group ">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Dismantling required</label>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_dismantle"
                                              [checked]="AreaInventory.item_do_dismantle==true" [value]=true
                                              [(ngModel)]="AreaInventory.item_do_dismantle">Yes
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_dismantle"
                                              [checked]="AreaInventory.item_do_dismantle==false" [value]=false
                                              [(ngModel)]="AreaInventory.item_do_dismantle">No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Time required to dismantle</label>
                                        <input type="text" class="form-control" placeholder="Time required to dismantle"
                                          autocomplete="off" value="{{ AreaInventory.item_dismantle_time }}"
                                          name="item_dismantle_time" [(ngModel)]="AreaInventory.item_dismantle_time"
                                          #item_dismantle_time="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group ">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Reassembly required</label>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_reassemble"
                                              [checked]="AreaInventory.item_do_reassemble==true" [value]=true
                                              [(ngModel)]="AreaInventory.item_do_reassemble">Yes
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_do_reassemble"
                                              [checked]="AreaInventory.item_do_reassemble==false" [value]=false
                                              [(ngModel)]="AreaInventory.item_do_reassemble">No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Reassemble time required</label>
                                        <input type="text" class="form-control" placeholder="Reassemble time required"
                                          autocomplete="off" value="{{ AreaInventory.item_reassemble_time }}"
                                          name="item_reassemble_time" [(ngModel)]="AreaInventory.item_reassemble_time"
                                          #item_reassemble_time="ngModel">
  
                                        
                                      </div>
                                    </div>
                                 
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Typical financial value</label>
                                        <input type="number" min="0" class="form-control" placeholder="Typical financial value"
                                          autocomplete="off" value="{{ AreaInventory.item_typical_value }}"
                                          name="item_typical_value" [(ngModel)]="AreaInventory.item_typical_value"
                                          #item_typical_value="ngModel" >
  
                                       
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group ">
                                        <label for="exampleInputSurvey" class="w-100 mb-4">Provides storage?</label>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_provides_storage"
                                              [checked]="AreaInventory.item_provides_storage==true" [value]=true
                                              [(ngModel)]="AreaInventory.item_provides_storage">Yes
                                          </label>
                                        </div>
                                        <div class="form-check-inline">
                                          <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="item_provides_storage"
                                              [checked]="AreaInventory.item_provides_storage==false" [value]=false
                                              [(ngModel)]="AreaInventory.item_provides_storage">No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. small boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. small boxes"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_small }}"
                                          name="item_pack_small" [(ngModel)]="AreaInventory.item_pack_small"
                                          #item_pack_small="ngModel" >
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. medium boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. medium boxes"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_med }}"
                                          name="item_pack_med" [(ngModel)]="AreaInventory.item_pack_med"
                                          #item_pack_med="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. large boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. large boxes"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_large }}"
                                          name="item_pack_large" [(ngModel)]="AreaInventory.item_pack_large"
                                          #item_pack_large="ngModel" >
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. layflat boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. layflat boxes"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_layflat }}"
                                          name="item_pack_layflat" [(ngModel)]="AreaInventory.item_pack_layflat"
                                          #item_pack_layflat="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. wardrobe boxes</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. wardrobe boxes"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_wardrobe }}"
                                          name="item_pack_wardrobe" [(ngModel)]="AreaInventory.item_pack_wardrobe"
                                          #item_pack_wardrobe="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. picturepack</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. picturepack" autocomplete="off"
                                          value="{{ AreaInventory.item_pack_picturepack }}"
                                          name="item_pack_picturepack"
                                          [(ngModel)]="AreaInventory.item_pack_picturepack"
                                          #item_pack_picturepack="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. golfbox</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. golfbox"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_golfbox }}"
                                          name="item_pack_golfbox" [(ngModel)]="AreaInventory.item_pack_golfbox"
                                          #item_pack_golfbox="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. bikebox</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. bikebox"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_bikebox }}"
                                          name="item_pack_bikebox" [(ngModel)]="AreaInventory.item_pack_bikebox"
                                          #item_pack_bikebox="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. single mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. single mattress bag" autocomplete="off"
                                          value="{{ AreaInventory.item_pack_mattressbagsingle }}"
                                          name="item_pack_mattressbagsingle"
                                          [(ngModel)]="AreaInventory.item_pack_mattressbagsingle"
                                          #item_pack_mattressbagsingle="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. double mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. double mattress bag" autocomplete="off"
                                          value="{{ AreaInventory.item_pack_mattressbagdouble }}"
                                          name="item_pack_mattressbagdouble"
                                          [(ngModel)]="AreaInventory.item_pack_mattressbagdouble"
                                          #item_pack_mattressbagdouble="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. king mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. king mattress bag" autocomplete="off"
                                          value="{{ AreaInventory.item_pack_mattressbagking }}"
                                          name="item_pack_mattressbagking"
                                          [(ngModel)]="AreaInventory.item_pack_mattressbagking"
                                          #item_pack_mattressbagking="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. super king mattress bag</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Inc. super king mattress bag" autocomplete="off"
                                          value="{{ AreaInventory.item_pack_mattressbagsuperking }}"
                                          name="item_pack_mattressbagsuperking"
                                          [(ngModel)]="AreaInventory.item_pack_mattressbagsuperking"
                                          #item_pack_mattressbagsuperking="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 24</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 24"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox24 }}"
                                          name="item_pack_tvbox24" [(ngModel)]="AreaInventory.item_pack_tvbox24"
                                          #item_pack_tvbox24="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 32</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 32"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox32 }}"
                                          name="item_pack_tvbox32" [(ngModel)]="AreaInventory.item_pack_tvbox32"
                                          #item_pack_tvbox32="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 36</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 36"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox36 }}"
                                          name="item_pack_tvbox36" [(ngModel)]="AreaInventory.item_pack_tvbox36"
                                          #item_pack_tvbox36="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 43</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 43"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox43 }}"
                                          name="item_pack_tvbox43" [(ngModel)]="AreaInventory.item_pack_tvbox43"
                                          #item_pack_tvbox43="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 48</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 48"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox48 }}"
                                          name="item_pack_tvbox48" [(ngModel)]="AreaInventory.item_pack_tvbox48"
                                          #item_pack_tvbox48="ngModel">
                                      </div>
                                    </div>
                                  
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 55</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 55"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox55 }}"
                                          name="item_pack_tvbox55" [(ngModel)]="AreaInventory.item_pack_tvbox55"
                                          #item_pack_tvbox55="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 70</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 70"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox70 }}"
                                          name="item_pack_tvbox70" [(ngModel)]="AreaInventory.item_pack_tvbox70"
                                          #item_pack_tvbox70="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Inc. TV box 85</label>
                                        <input type="number" min="0" class="form-control" placeholder="Inc. TV box 85"
                                          autocomplete="off" value="{{ AreaInventory.item_pack_tvbox85 }}"
                                          name="item_pack_tvbox85" [(ngModel)]="AreaInventory.item_pack_tvbox85"
                                          #item_pack_tvbox85="ngModel">
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="form-group">
                                        <label for="exampleInputSurvey">Percentage full (If provides storage)</label>
                                        <input type="number" min="0" class="form-control"
                                          placeholder="Percentage full" autocomplete="off"
                                          value="{{ AreaInventory.item_percentage_full }}" name="item_percentage_full"
                                          [(ngModel)]="AreaInventory.item_percentage_full"
                                          #item_percentage_full="ngModel">
                                      </div>
                                    </div>
                                    
                                  </div>
                                  </div>
                                  
                                <div class="modal-footer border-0 sumit_popup">
                                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!----end ---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade {{currentTabTypeid == 'to' ? 'show active' : ''}}" id="nav-to-otherarea" role="tabpanel"
      aria-labelledby="nav-overview-tab">
      <span class="w-100 d-inline-block">
        <button
          class="btn btn-primary border-0 text-white float-right font-weight-bold" (click)="open(addAreaModal)">Add
          Areas
        </button>
        </span>

      <div class="alert alert-danger text-center" *ngIf="(ImportSurveyList.property?.to.plan.other_areas?.length <=0)">
        No Areas are added</div>

      <div id="accordion" role="tablist" class="o-accordion"
        *ngIf="(ImportSurveyList.property?.to.plan.other_areas?.length > 0)">
        <div class="card mt-2 mb-2"
          *ngFor="let area of ImportSurveyList.property.to.plan.other_areas; let areaRow=index;">
          <div role="tab" id="Areainner{{areaRow}}" class="card-header">
            <h5 class="mb-0"><a data-toggle="collapse" data-parent="#accordion" href="#collapseArea{{areaRow}}"
                aria-expanded="false" [attr.aria-controls]="'collapseArea'+areaRow"
                class="w-100 d-inline-block floor-accordion collapsed">{{
                area.area_name }} <i class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
              </a>
              <span class="delete-icon" (click)="deleteArea(areaRow)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
            </h5>
          </div>
          <div id="collapseArea{{areaRow}}" role="tabpanel" [attr.aria-labelledby]="'Areainner'+areaRow"
            class="collapse">
            <div class="card-block p-4">
              <form (ngSubmit)="onSubmitAreaUpdate(areaUpdateFormTo)" #areaUpdateFormTo="ngForm">
                <input type="hidden" name="area_row" [ngModel]="areaRow" />
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputSurvey">Area Name</label>
                      <input type="text" class="form-control" placeholder="Area Name" autocomplete="off"
                        name="area_name" [ngModel]="area.area_name" #area_name="ngModel" required>

                      <div class="text-danger" *ngIf="!area_name.value">
                        Area Name is Required
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputSurvey">Area NTG</label>
                      <select class="form-control form-control-custom" name="area_ntg" [ngModel]="area.area_ntg"
                        #area_ntg="ngModel" required>
                        <option value="" *ngIf="(area.area_ntg==null || area.area_ntg=='') ? true:false">Select</option>
                        <option value="TG" [selected]="(area.area_ntg=='TG') ? true:false">To Go</option>
                        <option value="NTG" [selected]="(area.area_ntg=='NTG') ? true:false">Not To Go</option>
                        <option value="MTG" [selected]="(area.area_ntg=='MTG') ? true:false">Maybe To Go</option>
                      </select>

                      <div class="text-danger" *ngIf="!area_ntg.value">
                        Area NTG is Required
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <label for="exampleInputSurvey">Area Box Estimate</label>
                    <input type="number" class="form-control" placeholder="Area Box Estimate" min="0" autocomplete="off"
                      name="area_box_estimate" [ngModel]="area.area_box_estimate" #area_box_estimate="ngModel" required>
                    <div class="text-danger" *ngIf="area_box_estimate.value==undefined">
                      Area Box Estimate is Required
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group ">
                      <label class="custom-check-mark">Area Packing
                        <input type="checkbox" name="area_packing_{{areaRow}}" [checked]="area.area_packing==true"
                          [ngModel]="area.area_packing" #area_packing="ngModel">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group ">
                      <label class="custom-check-mark">Area Seen
                        <input type="checkbox" name="area_seen_{{areaRow}}" [checked]="area.area_seen==true"
                          [ngModel]="area.area_seen">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer border-0 sumit_popup">
                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                </div>
              </form>

              <div id="accordion" role="tablist" class="o-accordion">
                
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingTwo">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                        data-parent="#accordion" href="#collapseTwo_ta_{{areaRow}}" aria-expanded="false"
                        aria-controls="collapseTwo"> Inventory <i class="fa fa-chevron-down arrow-down"
                          aria-hidden="true"></i>
                      </a>
                      <!-- <span class="delete-icon">
                                              <i class="fa fa-trash" aria-hidden="true"></i>
                                          </span> -->
                    </h5>
                  </div>
                  <div id="collapseTwo_ta_{{areaRow}}" class="collapse" role="tabpanel"
                    [attr.aria-labelledby]="'headingTwo_ta_'+areaRow">
                    <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">

                      <span class="w-100 d-inline-block"><button
                          class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold"
                          (click)="open(inventoryModal); setAreaIndex(areaRow)">Add Item</button></span>

                      <div class="alert alert-danger text-center" *ngIf="area.inventory==''">No
                        Inventory Items Added</div>

                      <div *ngIf="area?.inventory!='' || area?.inventory!=null">
                        <div class="card multi  mt-0 mb-3 ml-3 mr-3 "
                          *ngFor="let AreaInventory of area.inventory; let inventoryRow=index">
                          <div class="card-header" role="tab" id="headingOne-1">
                            <h5 class="mb-0">
                              <a class="w-100 floor-accordion d-inline-block collapsed" data-toggle="collapse"
                                data-parent="#accordion" href="#collapseOneinnner_ta_{{areaRow}}{{inventoryRow}}"
                                aria-expanded="true"
                                [attr.aria-controls]="'collapseOneinnner_ta_'+areaRow+inventoryRow">
                                {{AreaInventory.item_name}} <i class="fa fa-chevron-down arrow-down mx-3"
                                  aria-hidden="true"></i>
                              </a>
                              <span class="delete-icon" (click)="deleteInventoryItem(areaRow, inventoryRow)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </h5>
                          </div>

                          <div id="collapseOneinnner_ta_{{areaRow}}{{inventoryRow}}" class="collapse" role="tabpanel"
                            aria-labelledby="headingOne-1">
                            <div class="card-block p-4">
                              <form (ngSubmit)="onSubmitInventoryUpdate(inventoryUpdateForm)"
                                #inventoryUpdateForm="ngForm">
                                <input type="hidden" name="area_row" [ngModel]="areaRow" />
                                <input type="hidden" name="inventory_row" [ngModel]="inventoryRow" />
                                <input type="hidden" name="item_name" [ngModel]="AreaInventory.item_name"
                                  #item_name="ngModel">
                                <input type="hidden" value="{{AreaInventory.item_id}}" name="item_id"
                                  [(ngModel)]="AreaInventory.item_id">
                                <input type="hidden" value="{{AreaInventory.type_id}}" name="type_id"
                                  [(ngModel)]="AreaInventory.type_id">
                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Name</label>
                                      <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.item_name"></a>
                                      </ng-template>
                                      <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                      </ng-template>
                                      <ng-autocomplete [data]="itemList" [searchKeyword]="keyword"
                                        (inputChanged)='onChangeSearch($event)'
                                        (selected)='selectEventItem($event, areaRow,inventoryRow)'
                                        (inputCleared)="onClearedItem($event, areaRow,inventoryRow)"
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                        placeholder="Item Name" [isLoading]="isLoadingResult" [minQueryLength]="1"
                                        name="item_name_auto" [ngModel]="AreaInventory.item_name"
                                        #item_name_auto="ngModel" required>
                                      </ng-autocomplete>

                                      <div class="text-danger" *ngIf="!item_name_auto.value">
                                        Item Name is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Weight</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Weight"
                                        autocomplete="off" value="{{AreaInventory.item_weight }}" name="item_weight"
                                        [(ngModel)]="AreaInventory.item_weight" #item_weight="ngModel" required>
                                      <div class="text-danger" *ngIf="item_weight.value==undefined">
                                        Item Weight is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Carry People</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Carry People"
                                        autocomplete="off" name="item_carry_people"
                                        [(ngModel)]="AreaInventory.item_carry_people" #item_carry_people="ngModel"
                                        required>
                                      <div class="text-danger" *ngIf="item_carry_people.value==undefined">
                                        Item Carry People is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Type Name</label>
                                      <input type="text" class="form-control" placeholder="Type Name" autocomplete="off"
                                        name="type_name" [(ngModel)]="AreaInventory.type_name" #type_name="ngModel"
                                        required>
                                      <div class="text-danger" *ngIf="!type_name.value">
                                        Type Name is Required
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group ">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Dismantle</label>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_dismantle"
                                            [checked]="AreaInventory.item_do_dismantle==true" [value]=true
                                            [(ngModel)]="AreaInventory.item_do_dismantle">Yes
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_dismantle"
                                            [checked]="AreaInventory.item_do_dismantle==false" [value]=false
                                            [(ngModel)]="AreaInventory.item_do_dismantle">No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Dismantle Time</label>
                                      <input type="text" class="form-control" placeholder="Item Dismantle Time"
                                        autocomplete="off" value="{{ AreaInventory.item_dismantle_time }}"
                                        name="item_dismantle_time" [(ngModel)]="AreaInventory.item_dismantle_time"
                                        #item_dismantle_time="ngModel" required>

                                      <div class="text-danger" *ngIf="item_dismantle_time.value===''">
                                        Item Dismantle Time is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group ">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Reassemble</label>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_reassemble"
                                            [checked]="AreaInventory.item_do_reassemble==true" [value]=true
                                            [(ngModel)]="AreaInventory.item_do_reassemble">Yes
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_do_reassemble"
                                            [checked]="AreaInventory.item_do_reassemble==false" [value]=false
                                            [(ngModel)]="AreaInventory.item_do_reassemble">No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Reassemble Time</label>
                                      <input type="text" class="form-control" placeholder="Item Reassemble Time"
                                        autocomplete="off" value="{{ AreaInventory.item_reassemble_time }}"
                                        name="item_reassemble_time" [(ngModel)]="AreaInventory.item_reassemble_time"
                                        #item_reassemble_time="ngModel" required>

                                      <div class="text-danger" *ngIf="item_reassemble_time.value===''">
                                        Item Reassemble Time is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Typical Value</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Typical Value"
                                        autocomplete="off" value="{{ AreaInventory.item_typical_value }}"
                                        name="item_typical_value" [(ngModel)]="AreaInventory.item_typical_value"
                                        #item_typical_value="ngModel" required>

                                      <div class="text-danger" *ngIf="item_typical_value.value==undefined">
                                        Item Typical Value is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group ">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item Provides Storage</label>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_provides_storage"
                                            [checked]="AreaInventory.item_provides_storage==true" [value]=true
                                            [(ngModel)]="AreaInventory.item_provides_storage">Yes
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input type="radio" class="form-check-input" name="item_provides_storage"
                                            [checked]="AreaInventory.item_provides_storage==false" [value]=false
                                            [(ngModel)]="AreaInventory.item_provides_storage">No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey" class="w-100 mb-4">Item To Go</label>
                                      <select class="form-control form-control-custom" name="item_to_go"
                                        [ngModel]="AreaInventory.item_to_go" #item_to_go="ngModel" required>
                                        <option value="" [selected]="(AreaInventory.item_to_go=='') ? true:false">Select
                                        </option>
                                        <option value="TG" [selected]="(AreaInventory.item_to_go=='TG') ? true:false">To
                                          Go</option>
                                        <option value="NTG" [selected]="(AreaInventory.item_to_go=='NTG') ? true:false">
                                          Not To Go</option>
                                        <option value="MTG" [selected]="(AreaInventory.item_to_go=='MTG') ? true:false">
                                          Maybe To Go</option>
                                      </select>
                                      <div class="text-danger" *ngIf="!item_to_go.value">
                                        Item To Go is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Small</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Small"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_small }}"
                                        name="item_pack_small" [(ngModel)]="AreaInventory.item_pack_small"
                                        #item_pack_small="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_small.value==undefined">
                                        Item Pack Small is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Medium</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Medium"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_med }}"
                                        name="item_pack_med" [(ngModel)]="AreaInventory.item_pack_med"
                                        #item_pack_med="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_med.value==undefined">
                                        Item Pack Medium is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Large</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Large"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_large }}"
                                        name="item_pack_large" [(ngModel)]="AreaInventory.item_pack_large"
                                        #item_pack_large="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_large.value==undefined">
                                        Item Pack Large is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Layflat</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Layflat"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_layflat }}"
                                        name="item_pack_layflat" [(ngModel)]="AreaInventory.item_pack_layflat"
                                        #item_pack_layflat="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_layflat.value==undefined">
                                        Item Pack Layflat is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Wardrobe"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_wardrobe }}"
                                        name="item_pack_wardrobe" [(ngModel)]="AreaInventory.item_pack_wardrobe"
                                        #item_pack_wardrobe="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_wardrobe.value==undefined">
                                        Item Pack Wardrobe is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Picturepack</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Picturepack" autocomplete="off"
                                        value="{{ AreaInventory.item_pack_picturepack }}" name="item_pack_picturepack"
                                        [(ngModel)]="AreaInventory.item_pack_picturepack"
                                        #item_pack_picturepack="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_picturepack.value==undefined">
                                        Item Pack Picturepack is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Golfbox</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Golfbox"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_golfbox }}"
                                        name="item_pack_golfbox" [(ngModel)]="AreaInventory.item_pack_golfbox"
                                        #item_pack_golfbox="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_golfbox.value==undefined">
                                        Item Pack Golfbox is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Bikebox</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Bikebox"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_bikebox }}"
                                        name="item_pack_bikebox" [(ngModel)]="AreaInventory.item_pack_bikebox"
                                        #item_pack_bikebox="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_bikebox.value==undefined">
                                        Item Pack Bikebox is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagsingle" autocomplete="off"
                                        value="{{ AreaInventory.item_pack_mattressbagsingle }}"
                                        name="item_pack_mattressbagsingle"
                                        [(ngModel)]="AreaInventory.item_pack_mattressbagsingle"
                                        #item_pack_mattressbagsingle="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagsingle.value==undefined">
                                        Item Pack Mattressbagsingle is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagdouble" autocomplete="off"
                                        value="{{ AreaInventory.item_pack_mattressbagdouble }}"
                                        name="item_pack_mattressbagdouble"
                                        [(ngModel)]="AreaInventory.item_pack_mattressbagdouble"
                                        #item_pack_mattressbagdouble="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagdouble.value==undefined">
                                        Item Pack Mattressbagdouble is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagking" autocomplete="off"
                                        value="{{ AreaInventory.item_pack_mattressbagking }}"
                                        name="item_pack_mattressbagking"
                                        [(ngModel)]="AreaInventory.item_pack_mattressbagking"
                                        #item_pack_mattressbagking="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagking.value==undefined">
                                        Item Pack Mattressbagking is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Pack Mattressbagsuperking" autocomplete="off"
                                        value="{{ AreaInventory.item_pack_mattressbagsuperking }}"
                                        name="item_pack_mattressbagsuperking"
                                        [(ngModel)]="AreaInventory.item_pack_mattressbagsuperking"
                                        #item_pack_mattressbagsuperking="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_mattressbagsuperking.value==undefined">
                                        Item Pack Mattressbagsuperking is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox24"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox24 }}"
                                        name="item_pack_tvbox24" [(ngModel)]="AreaInventory.item_pack_tvbox24"
                                        #item_pack_tvbox24="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox24.value==undefined">
                                        Item Pack Tvbox24 is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox32"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox32 }}"
                                        name="item_pack_tvbox32" [(ngModel)]="AreaInventory.item_pack_tvbox32"
                                        #item_pack_tvbox32="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox32.value==undefined">
                                        Item Pack Tvbox32 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox36"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox36 }}"
                                        name="item_pack_tvbox36" [(ngModel)]="AreaInventory.item_pack_tvbox36"
                                        #item_pack_tvbox36="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox36.value==undefined">
                                        Item Pack Tvbox36 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox43"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox43 }}"
                                        name="item_pack_tvbox43" [(ngModel)]="AreaInventory.item_pack_tvbox43"
                                        #item_pack_tvbox43="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox43.value==undefined">
                                        Item Pack Tvbox43 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox48</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox48"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox48 }}"
                                        name="item_pack_tvbox48" [(ngModel)]="AreaInventory.item_pack_tvbox48"
                                        #item_pack_tvbox48="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox48.value==undefined">
                                        Item Pack Tvbox48 is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox55"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox55 }}"
                                        name="item_pack_tvbox55" [(ngModel)]="AreaInventory.item_pack_tvbox55"
                                        #item_pack_tvbox55="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox55.value==undefined">
                                        Item Pack Tvbox55 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox70"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox70 }}"
                                        name="item_pack_tvbox70" [(ngModel)]="AreaInventory.item_pack_tvbox70"
                                        #item_pack_tvbox70="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox70.value==undefined">
                                        Item Pack Tvbox70 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Pack Tvbox85"
                                        autocomplete="off" value="{{ AreaInventory.item_pack_tvbox85 }}"
                                        name="item_pack_tvbox85" [(ngModel)]="AreaInventory.item_pack_tvbox85"
                                        #item_pack_tvbox85="ngModel" required>

                                      <div class="text-danger" *ngIf="item_pack_tvbox85.value==undefined">
                                        Item Pack Tvbox85 is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Width Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Width Cm"
                                        autocomplete="off" value="{{ AreaInventory.item_width_cm }}"
                                        name="item_width_cm" [(ngModel)]="AreaInventory.item_width_cm"
                                        #item_width_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_width_cm.value==undefined">
                                        Item Width Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Height Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Height Cm"
                                        autocomplete="off" value="{{ AreaInventory.item_height_cm }}"
                                        name="item_height_cm" [(ngModel)]="AreaInventory.item_height_cm"
                                        #item_height_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_height_cm.value==undefined">
                                        Item Height Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Depth Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Depth Cm"
                                        autocomplete="off" value="{{ AreaInventory.item_depth_cm }}"
                                        name="item_depth_cm" [(ngModel)]="AreaInventory.item_depth_cm"
                                        #item_depth_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_depth_cm.value==undefined">
                                        Item Depth Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Width Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Width Inch"
                                        autocomplete="off" value="{{ AreaInventory.item_width_inch }}"
                                        name="item_width_inch" [(ngModel)]="AreaInventory.item_width_inch"
                                        #item_width_inch="ngModel" required>

                                      <div class="text-danger" *ngIf="item_width_inch.value==undefined">
                                        Item Width Inch is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Height Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Height Inch"
                                        autocomplete="off" value="{{ AreaInventory.item_height_inch }}"
                                        name="item_height_inch" [(ngModel)]="AreaInventory.item_height_inch"
                                        #item_height_inch="ngModel" required>

                                      <div class="text-danger" *ngIf="item_height_inch.value==undefined">
                                        Item Height Inch is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Depth Inch</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Depth Inch"
                                        autocomplete="off" value="{{ AreaInventory.item_depth_inch }}"
                                        name="item_depth_inch" [(ngModel)]="AreaInventory.item_depth_inch"
                                        #item_depth_inch="ngModel" required>

                                      <div class="text-danger" *ngIf="item_depth_inch.value==undefined">
                                        Item Depth Inch is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Cm</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Cube Cm"
                                        autocomplete="off" value="{{ AreaInventory.item_cube_cm }}" name="item_cube_cm"
                                        [(ngModel)]="AreaInventory.item_cube_cm" #item_cube_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_cm.value==undefined">
                                        Item Cube Cm is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Ft</label>
                                      <input type="number" min="0" class="form-control" placeholder="Item Cube Ft"
                                        autocomplete="off" value="{{ AreaInventory.item_cube_ft }}" name="item_cube_ft"
                                        [(ngModel)]="AreaInventory.item_cube_ft" #item_cube_ft="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_ft.value==undefined">
                                        Item Cube Ft is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Cube Dismantled Cm" autocomplete="off"
                                        value="{{ AreaInventory.item_cube_dismantled_cm }}"
                                        name="item_cube_dismantled_cm"
                                        [(ngModel)]="AreaInventory.item_cube_dismantled_cm"
                                        #item_cube_dismantled_cm="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_dismantled_cm.value==undefined">
                                        Item Cube Dismantled is Required
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Cube Dismantled Ft" autocomplete="off"
                                        value="{{ AreaInventory.item_cube_dismantled_ft }}"
                                        name="item_cube_dismantled_ft"
                                        [(ngModel)]="AreaInventory.item_cube_dismantled_ft"
                                        #item_cube_dismantled_ft="ngModel" required>

                                      <div class="text-danger" *ngIf="item_cube_dismantled_ft.value==undefined">
                                        Item Cube Dismantled Ft is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="form-group">
                                      <label for="exampleInputSurvey">Item Percentage Full</label>
                                      <input type="number" min="0" class="form-control"
                                        placeholder="Item Percentage Full" autocomplete="off"
                                        value="{{ AreaInventory.item_percentage_full }}" name="item_percentage_full"
                                        [(ngModel)]="AreaInventory.item_percentage_full" #item_percentage_full="ngModel"
                                        required>

                                      <div class="text-danger" *ngIf="item_percentage_full.value==undefined">
                                        Item Percentage Full is Required
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col"></div>
                                  <div class="col"></div>
                                </div>
                                <div class="modal-footer border-0 sumit_popup">
                                  <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!----end ---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<!--add area modal box start-->
<ng-template #addAreaModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form [formGroup]="areaFormFrom" (ngSubmit)="onSubmitAreaFrom()">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputSurvey">Area Name</label>
            <input type="text" class="form-control" placeholder="Area Name" autocomplete="off" value=""
              formControlName="area_name">
            <div class="text-danger"
              *ngIf="(areaFormFromControl.area_name.touched || submittedArea) && areaFormFromControl.area_name.errors?.required">
              Area Name is required
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputSurvey">Are we moving the contents of this area? </label>
            <div class="form-check-inline w-100">
              <select class="form-control  border" formControlName="area_ntg">
                <option value="">--Select--</option>
                <option value="TG">To Go</option>
                <option value="NTG">Not To Go</option>
                <option value="MTG">Maybe To Go</option>
              </select>
            </div>
            <div class="text-danger"
              *ngIf="(areaFormFromControl.area_ntg.touched || submittedArea) && areaFormFromControl.area_ntg.errors?.required">
              Area ntg is required
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this area
            </label>
            <input type="number" class="form-control" placeholder="Area Box Estimate" min="0" autocomplete="off"
              value="" formControlName="area_box_estimate">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this area? </label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="area_packing">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="area_packing">No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Have we been able to see the area?</label>
            <div class="answer-boxed">
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="area_seen">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="area_seen">No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Are we moving the light carpets in this area? </label>
            <div class="answer-boxed">
            <div class="form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" [value]="true" [checked]="true" formControlName="move_carpets">Yes
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" [value]="false" [checked]="false" formControlName="move_carpets">No
              </label>
            </div>
            </div>            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group one-line-flex">
            <label for="exampleInputSurvey" class="mb-4">Are we moving the light curtains or blinds in this area? </label>
            <div class="answer-boxed">
            <div class="form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" [value]="true" [checked]="true" formControlName="move_curtains_blinds">Yes
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" [value]="false" [checked]="false" formControlName="move_curtains_blinds">No
              </label>
            </div>
            </div>            
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
      </div>
    </form>
  </div>
</ng-template>
<!--add area details modal box end-->

<!-----add inventory modal  box start---->
<ng-template #inventoryModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">    
      <div class="card-block p-4 project-tab">
        <!-- tabs create -->
        <ul class="nav nav-tabs popup-inside-tabs" id="popup-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="catalogue-search-tab" data-toggle="tab" href="#cataloguesearch" role="tab" (click)="resetItemFormValue()">Search Catalogue</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="catalogue-tab" data-toggle="tab" href="#catalogue" role="tab" (click)="resetItemFormValue()">Browse Catalogue</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="manual-tab" data-toggle="tab" href="#manual" role="tab" (click)="resetItemFormValue()">Custom Item</a>
          </li>
        </ul>
        <!-- tabs creqte end-->
        <!-- tabs-content start -->
        <div class="tab-content" id="popup-tabs">

          <div class="tab-pane fade show active" id="cataloguesearch" role="tabpanel">
          
            <form [formGroup]="InventoryItemForm" (ngSubmit)="onSubmitInventory()">
  
              <input type="hidden" value="" formControlName="item_name">
              <input type="hidden" value="" formControlName="item_id">
              <input type="hidden" value="" formControlName="item_type">
              <input type="hidden" value="" formControlName="item_type_search">
              <input type="hidden" value="" formControlName="type_id">
              <input type="hidden" value="" formControlName="areaIndex" />
  
              <div class="row">
                <div class="col">
  
                  <div class="form-group">
                    <label for="exampleInputSurvey">Search</label>
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.item_name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <ng-autocomplete [data]="itemListUnFiltered" [searchKeyword]="keyword" (inputChanged)='onChangeSearch($event)'
                    (selected)='selectEvent($event)' 
                    (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate" placeholder="Item Name" [isLoading]="isLoadingResult"
                    [minQueryLength]="1" formControlName="item_name_search">
                    </ng-autocomplete>
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_name.touched || submittedInventoryForm) && InventoryItemFormControl.item_name.errors?.required">
                      Item name is required
                    </div>
                  </div>
  
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Quantity</label>
                    <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                      min="1" formControlName="total_items">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.total_items.touched || submittedInventoryForm) && InventoryItemFormControl.total_items.errors?.required">
                      Number of items is required
                    </div>
                    <div class="text-danger" *ngIf="InventoryItemFormControl.total_items.errors?.min">
                      Number of items can not less than 1
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label for="exampleInputSurvey" class="w-100 mb-4">To go?</label>
                    <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                      <option value="">--Select--</option>
                      <option value="TG">Yes: To go</option>
                      <option value="NTG">No: Not to go</option>
                      <option value="MTG">Not sure: Maybe to go</option>
                    </select>
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_to_go.touched || submittedInventoryForm) && InventoryItemFormControl.item_to_go.errors?.required">
                      This field is required
                    </div>
                  </div>
                </div>
  
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Width Cm</label>
                    <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                      min="0" formControlName="item_width_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_width_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_cm.errors?.required">
                      Item width is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Height Cm</label>
                    <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                      min="0" formControlName="item_height_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_height_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_cm.errors?.required">
                      Item height is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Depth Cm</label>
                    <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                      min="0" formControlName="item_depth_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_depth_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_cm.errors?.required">
                      Item depth is required
                    </div>
                  </div>
                </div>
  
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Width Inch</label>
                    <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                      min="0" formControlName="item_width_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_width_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_inch.errors?.required">
                      Item width is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Height Inch</label>
                    <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                      min="0" formControlName="item_height_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_height_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_inch.errors?.required">
                      Item height is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Depth Inch</label>
                    <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                      min="0" formControlName="item_depth_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_depth_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_inch.errors?.required">
                      Item depth is required
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="modal-footer border-0">
                <button type="submit" class="btn btn-primary border-0 pl-4 pr-4"><i class="fas fa-plus-square"></i> Add</button>
              </div>
            </form>
          
          </div>

          <div class="tab-pane fade" id="catalogue" role="tabpanel">
            <form [formGroup]="InventoryItemForm" (ngSubmit)="onSubmitInventory()">
              <input type="hidden" value="" formControlName="item_name">
              <input type="hidden" value="" formControlName="item_id">
              <input type="hidden" value="" formControlName="item_type">
              <input type="hidden" value="" formControlName="type_id">
              <input type="hidden" value="" formControlName="areaIndex" />
              <div class="row inventory-item-row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Type</label>
                    <ng-template #itemTypeTemplate let-item>
                      <a [innerHTML]="item.type_name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <ng-autocomplete [data]="itemTypeList" [searchKeyword]="keywordType" (inputChanged)='onChangeSearchType($event)'
                      (selected)='selectEventType($event)' (inputFocused)='onFocusedType($event)' (inputCleared)="onClearedType($event)" [itemTemplate]="itemTypeTemplate"  [minQueryLength]="1"
                      [notFoundTemplate]="notFoundTemplate" placeholder="Type Name" [isLoading]="isLoadingResult" formControlName="item_type_search"
                      >
                    </ng-autocomplete>
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_type.touched || submittedInventoryForm) && InventoryItemFormControl.item_type.errors?.required">
                      Item Type is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Name</label>
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.item_name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                    <ng-autocomplete [data]="itemListFiltered" [searchKeyword]="keyword" (inputChanged)='onChangeSearch($event)'
                      (selected)='selectEvent($event)' 
                      (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="notFoundTemplate" placeholder="Item Name" [isLoading]="isLoadingResult"
                      [minQueryLength]="1"
                      [disabled]="!selectedItemTypeId ? true : false"
                      formControlName="item_name_search">
                    </ng-autocomplete>
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_name.touched || submittedInventoryForm) && InventoryItemFormControl.item_name.errors?.required">
                      Item Name is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Number of items </label>
                    <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                      min="1" formControlName="total_items">
                      <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.total_items.touched || submittedInventoryForm) && InventoryItemFormControl.total_items.errors?.required">
                      Number of items is required
                    </div>
                    <div class="text-danger" *ngIf="InventoryItemFormControl.total_items.errors?.min">
                      Number of items can not less than 1
                    </div> 
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item ?</label>
                    <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                      <option value="">--Select--</option>
                      <option value="TG">To Go</option>
                      <option value="NTG">Not To Go</option>
                      <option value="MTG">Maybe To Go</option>
                    </select>
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_to_go.touched || submittedInventoryForm) && InventoryItemFormControl.item_to_go.errors?.required">
                      Item To Go is required
                    </div>
                  </div>
                </div>

                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Width Cm</label>
                    <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                      min="0" formControlName="item_width_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_width_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_cm.errors?.required">
                      Item Width Cm is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Height Cm</label>
                    <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                      min="0" formControlName="item_height_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_height_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_cm.errors?.required">
                      Item Height Cm is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Depth Cm</label>
                    <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                      min="0" formControlName="item_depth_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_depth_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_cm.errors?.required">
                      Item Depth Cm is required
                    </div>
                  </div>
                </div>

                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Width Inch</label>
                    <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                      min="0" formControlName="item_width_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_width_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_inch.errors?.required">
                      Item Width Inch is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Height Inch</label>
                    <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                      min="0" formControlName="item_height_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_height_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_inch.errors?.required">
                      Item Height Inch is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Depth Inch</label>
                    <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                      min="0" formControlName="item_depth_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryItemFormControl.item_depth_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_inch.errors?.required">
                      Item Depth Inch is required
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="modal-footer border-0 sumit_popup">
                <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
              </div>
            </form>
            
          </div>
          <div class="tab-pane fade" id="manual" role="tabpanel">
            <form [formGroup]="InventoryItemFormManual" (ngSubmit)="onSubmitInventoryManual()">              
              <input type="hidden" value="" formControlName="item_id">
              <input type="hidden" value="" formControlName="type_id">
              <input type="hidden" value="" formControlName="areaIndex" />
              <div class="row inventory-item-row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Name</label>
                    <input type="text" class="form-control" placeholder="Item Name" autocomplete="off" value="" formControlName="item_name">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_name.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_name.errors?.required">
                      Item Name is required
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Number of items </label>
                    <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                      min="1" formControlName="total_items">
                      <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.total_items.touched || submittedInventoryFormManual) && InventoryFormManualControl.total_items.errors?.required">
                      Number of items is required
                    </div>
                    <div class="text-danger" *ngIf="InventoryFormManualControl.total_items.errors?.min">
                      Number of items can not less than 1
                    </div> 
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving this item ?</label>
                    <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                      <option value="">--Select--</option>
                      <option value="TG">To Go</option>
                      <option value="NTG">Not To Go</option>
                      <option value="MTG">Maybe To Go</option>
                    </select>
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_to_go.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_to_go.errors?.required">
                      Item To Go is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Width Cm</label>
                    <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                      min="0" formControlName="item_width_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_width_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_width_cm.errors?.required">
                      Item Width Cm is required
                    </div>
                  </div>
                </div>
              
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Height Cm</label>
                    <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                      min="0" formControlName="item_height_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_height_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_height_cm.errors?.required">
                      Item Height Cm is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Depth Cm</label>
                    <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                      min="0" formControlName="item_depth_cm">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_depth_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_depth_cm.errors?.required">
                      Item Depth Cm is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Width Inch</label>
                    <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                      min="0" formControlName="item_width_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_width_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_width_inch.errors?.required">
                      Item Width Inch is required
                    </div>
                  </div>
                </div>
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Height Inch</label>
                    <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                      min="0" formControlName="item_height_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_height_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_height_inch.errors?.required">
                      Item Height Inch is required
                    </div>
                  </div>
                </div>
              
                <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Item Depth Inch</label>
                    <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                      min="0" formControlName="item_depth_inch">
                    <div class="text-danger"
                      *ngIf="(InventoryFormManualControl.item_depth_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_depth_inch.errors?.required">
                      Item Depth Inch is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 sumit_popup">
                <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
              </div>
            </form>
          </div>
        </div>

        <!-- tabs content end -->

      </div>

    
  </div>
</ng-template>
<!-----add inventory modal  box end---->