import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-survey-import-list',
  templateUrl: './survey-import-list.component.html',
  styleUrls: ['./survey-import-list.component.css']
})
export class SurveyImportListComponent implements OnInit {
  
  constructor(private router: Router, private apiService: ApiService, private _Activatedroute:ActivatedRoute) { }

  ngOnInit(): void {    
  }

  
  

}
