<section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
  <nav class="mb-4">
    <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'from' ? 'active' : ''}}" id="nav-overview-tab"
        data-toggle="tab" href="#nav-from-floorroom" role="tab" aria-controls="nav-Info" aria-selected="true"
        (click)="tabTypeClickFunction('from');">From</a>
      <!-- <a class="nav-item nav-link border-0 {{currentTabTypeid == 'to' ? 'active' : ''}}" id="nav-profile-tab" data-toggle="tab" href="#nav-to-floorroom"
        role="tab" aria-controls="nav-Parking" aria-selected="false" (click)="tabTypeClickFunction('to');">To</a> -->
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade {{currentTabTypeid == 'from' ? 'show active' : ''}}" id="nav-from-floorroom"
      role="tabpanel" aria-labelledby="nav-overview-tab">
      <span class="w-100 d-inline-block">
        <button class="btn btn-primary border-0 text-white float-right font-weight-bold"
          (click)="openFloorModal(addFloorModal)">Add Floor
        </button>
      </span>

      <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

      <div class="alert alert-danger text-center"
        *ngIf="(ImportSurveyList.property?.from.plan.floors?.length <=0) && !loader">
        No Floors are added
      </div>

      <div id="accordion" role="tablist" class="o-accordion"
        *ngIf="(ImportSurveyList.property?.from.plan.floors?.length > 0)">
        <div class="card mt-2 mb-2"
          *ngFor="let floorNameFrom of ImportSurveyList.property.from.plan.floors;let floorRow=index;">
          <div role="tab" id="groundfloorone{{floorRow}}" class="card-header floor-section">
            <h5 class="mb-0 accordion-name"><a data-toggle="collapse" data-parent="#accordion"
                href="#collapsegroundone{{floorRow}}" aria-expanded="false"
                [attr.aria-controls]="'collapsegroundone'+floorRow"
                class="w-100 d-inline-block floor-accordion collapsed">{{floorNameFrom.floor_name}}
                <i class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
              </a>
              <span class="accroding-icon">
                <span class="delete-icon-floor delete-icon" (click)="deleteFloor(floorRow)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </span>
                <span class="edit-icon"
                  (click)="openFloorModal(editFloorModal); setEditFloorValue(floorRow,floorNameFrom.floor_name)"><i
                    class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
              </span>
            </h5>
          </div>
          <div id="collapsegroundone{{floorRow}}" role="tabpanel" [attr.aria-labelledby]="'groundfloorone'+floorRow"
            class="collapse floor-data-section plan-content-section">
            <div class="card-block p-4">
              <span class="w-100 d-inline-block">
                <button class="btn btn-primary border-0 text-white float-right font-weight-bold"
                  (click)="open(roomModal); setFloorIndexValue(floorRow)">Add Room
                </button>
              </span>
              <div class="alert alert-danger text-center" *ngIf="floorNameFrom.floor_rooms==''">No Rooms are Added</div>

              <div class="card mt-2 mb-3" *ngFor="let floorRoom of floorNameFrom.floor_rooms; let roomRow=index;">
                <div role="tab" id="groundfloorinner_f_{{roomRow}}" class="card-header">
                  <h5 class="mb-0 accordion-name"><a data-toggle="collapse" data-parent="#accordion"
                      href="#collapsegroundinner_f_{{floorRow}}{{roomRow}}" aria-expanded="false"
                      [attr.aria-controls]="'collapsegroundinner_f_'+floorRow+roomRow"
                      class="w-100 d-inline-block floor-accordion room-accordion collapsed">{{ floorRoom.room_name }} <i
                        class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
                    </a>
                    <span class="delete-icon" (click)="deleteRoom(floorRow, roomRow)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </h5>
                </div>
                <div id="collapsegroundinner_f_{{floorRow}}{{roomRow}}" role="tabpanel"
                  [attr.aria-labelledby]="'groundfloorinner_f_'+roomRow" class="room-content-section collapse">
                  <div class="card-block p-4">
                    <form (ngSubmit)="onSubmitRoomUpdate(roomUpdateForm)" #roomUpdateForm="ngForm">
                      <input type="hidden" [ngModel]="floorRow" name="floor_row" />
                      <input type="hidden" [ngModel]="roomRow" name="room_row" />
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Room Name</label>
                            <input type="text" class="form-control" placeholder="Room Name" autocomplete="off"
                              value="{{ floorRoom.room_name }}" name="room_name" [ngModel]="floorRoom.room_name"
                              #room_name="ngModel" required>

                            <div class="text-danger" *ngIf="!room_name.value">
                              Room Name is Required
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="exampleInputSurvey">What type of room is this?</label>
                            <div class="form-check-inline w-100">
                              <select class="form-control  border" id="exampleFormControlSelect2" name="room_type"
                                [ngModel]="floorRoom.room_type" #room_type="ngModel" required>
                                <option value="" [selected]="(floorRoom.room_type=='') ? true:false">Select</option>
                                <option value="bedroom" [selected]="(floorRoom.room_type)=='bedroom' ? true:false">
                                  Bedroom</option>
                                <option value="living room"
                                  [selected]="(floorRoom.room_type)=='living room' ? true:false">Living Room</option>
                                <option value="bathroom" [selected]="(floorRoom.room_type)=='bathroom' ? true:false">
                                  Bathroom</option>
                                <option value="kitchen" [selected]="(floorRoom.room_type)=='kitchen' ? true:false">
                                  Kitchen</option>
                                <option value="dining room"
                                  [selected]="(floorRoom.room_type)=='dining room' ? true:false">Dining Room</option>
                                <option value="office" [selected]="(floorRoom.room_type)=='office' ? true:false">Office
                                </option>
                                <option value="gym" [selected]="(floorRoom.room_type)=='gym' ? true:false">Gym</option>
                                <option value="other" [selected]="(floorRoom.room_type)=='other' ? true:false">Other
                                </option>
                              </select>

                            </div>
                            <div class="text-danger" *ngIf="!room_type.value">
                              Room Type is Required
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group ">
                            <label for="exampleInputSurvey" class="w-100">Are we moving the contents of this
                              room?</label>
                            <div class="form-check-inline w-100">
                              <select class="form-control form-control-custom" id="exampleFormControlSelect2"
                                name="room_ntg" [ngModel]="floorRoom.room_ntg" #room_ntg="ngModel" required>
                                <option value="" [selected]="(floorRoom.room_ntg==null) ? true:false">Select</option>
                                <option value="TG" [selected]="(floorRoom.room_ntg=='TG') ? true:false">To Go</option>
                                <option value="NTG" [selected]="(floorRoom.room_ntg=='NTG') ? true:false">Not To Go
                                </option>
                                <option value="MTG" [selected]="(floorRoom.room_ntg=='MTG') ? true:false">Maybe To Go
                                </option>
                              </select>
                            </div>
                            <div class="text-danger" *ngIf="!room_ntg.value">
                              Room ntg is Required
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in
                              this room </label>
                            <input type="number" min="0" class="form-control" placeholder="Room Box Estimate"
                              autocomplete="off" value="{{ floorRoom.room_box_estimate }}" name="room_box_estimate"
                              [ngModel]="floorRoom.room_box_estimate" #room_box_estimate="ngModel">

                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group one-line-flex">
                            <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this
                              room?</label>
                            <div class="answer-boxed">
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="room_packing"
                                    [checked]="floorRoom.room_packing==true" [value]=true
                                    [(ngModel)]="floorRoom.room_packing">Yes
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="room_packing"
                                    [checked]="floorRoom.room_packing==false" [value]=false
                                    [(ngModel)]="floorRoom.room_packing">No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group one-line-flex">
                            <label for="exampleInputSurvey" class="mb-4">Have we been able to see the room?</label>
                            <div class="answer-boxed">
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="room_seen"
                                    [checked]="floorRoom.room_seen==true" [value]=true
                                    [(ngModel)]="floorRoom.room_seen">Yes
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="room_seen"
                                    [checked]="floorRoom.room_seen==false" [value]=false
                                    [(ngModel)]="floorRoom.room_seen">No
                                </label>
                              </div>
                            </div>
                          </div>


                        </div>

                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group one-line-flex">
                            <label for="exampleInputSurvey" class="mb-4">Room Light Fittings</label>
                            <div class="answer-boxed">
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="move_light_fittings"
                                    [checked]="floorRoom.move_light_fittings==true" [value]=true
                                    [(ngModel)]="floorRoom.move_light_fittings">Yes
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="move_light_fittings"
                                    [checked]="floorRoom.move_light_fittings==false" [value]=false
                                    [(ngModel)]="floorRoom.move_light_fittings">No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group one-line-flex">
                            <label for="exampleInputSurvey" class="mb-4">Are we moving the light curtains or blinds in
                              this room? </label>
                            <div class="answer-boxed">
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="move_curtains_blinds"
                                    [checked]="floorRoom.move_curtains_blinds==true" [value]=true
                                    [(ngModel)]="floorRoom.move_curtains_blinds">Yes
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="move_curtains_blinds"
                                    [checked]="floorRoom.move_curtains_blinds==false" [value]=false
                                    [(ngModel)]="floorRoom.move_curtains_blinds">No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group one-line-flex">
                            <label for="exampleInputSurvey" class="mb-4">Are we moving the light carpets in this
                              room?</label>
                            <div class="answer-boxed">
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="move_carpets"
                                    [checked]="floorRoom.move_carpets==true" [value]=true
                                    [(ngModel)]="floorRoom.move_carpets">Yes
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="move_carpets"
                                    [checked]="floorRoom.move_carpets==false" [value]=false
                                    [(ngModel)]="floorRoom.move_carpets">No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0 sumit_popup">
                        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                      </div>
                    </form>

                    <div id="accordion" role="tablist" class="o-accordion">
                      <!----packing material start-->
                      <div class="card mt-2 mb-2">
                        <div class="card-header" role="tab" id="headingOne_p">
                          <h5 class="mb-0">
                            <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                              data-parent="#accordion" href="#collapseOne_p{{floorRow}}{{roomRow}}"
                              aria-expanded="false" aria-controls="collapseOne">Packing Materials <i
                                class="fa fa-chevron-down arrow-down" aria-hidden="true"></i>
                            </a>
                          </h5>
                        </div>

                        <div id="collapseOne_p{{floorRow}}{{roomRow}}" class="collapse" role="tabpanel"
                          [attr.aria-labelledby]="'collapseOne_p'+floorRow+roomRow">
                          <div class="card-block p-4">

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Small</label>
                                  <input type="number" class="form-control" placeholder="Small" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.small }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Medium</label>
                                  <input type="number" class="form-control" placeholder="Medium" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.med }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Large</label>
                                  <input type="number" class="form-control" placeholder="Large" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.large }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Layflat</label>
                                  <input type="number" class="form-control" placeholder="Layflat" autocomplete="off"
                                    value="{{ floorRoom.packing_materials?.layflat }}">
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Wardrobe</label>
                                  <input type="number" class="form-control" placeholder="Wardrobe" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.wardrobe }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Picture Pack</label>
                                  <input type="number" class="form-control" placeholder="Picture Pack" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.picturepack }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Golf Box</label>
                                  <input type="number" class="form-control" placeholder="Golf Box" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.golfbox }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Bike Box</label>
                                  <input type="number" class="form-control" placeholder="Bike Box" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.bikebox }}">
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Mattress Bag Single</label>
                                  <input type="number" class="form-control" placeholder="Mattress Bag Single" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.mattressbagsingle }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Mattress Bag Double</label>
                                  <input type="number" class="form-control" placeholder="Mattress Bag Double" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.mattressbagdouble }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Mattress Bag King</label>
                                  <input type="number" class="form-control" placeholder="Mattress Bag King" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.mattressbagking }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Mattress Bag Super King</label>
                                  <input type="number" class="form-control" placeholder="Mattress Bag Super King"
                                    min="0" autocomplete="off"
                                    value="{{ floorRoom.packing_materials?.mattressbagsuperking }}">
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 24</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 24" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox24 }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 32</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 32" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox32 }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 36</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 36" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox36 }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 43</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 43" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox43 }}">
                                </div>
                              </div>

                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 48</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 48" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox48 }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 55</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 55" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox55 }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 70</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 70" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox70 }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Tv Box 85</label>
                                  <input type="number" class="form-control" placeholder="Tv Box 85" min="0"
                                    autocomplete="off" value="{{ floorRoom.packing_materials?.tvbox85 }}">
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                      <!----packing material end-->
                      <!---Ground Floor Room inventory section start-->
                      <div class="card mt-2 mb-2">
                        <div class="card-header" role="tab" id="headingTwo{{roomRow}}">
                          <h5 class="mb-0">
                            <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                              data-parent="#accordion" href="#collapseTwo_ff_{{floorRow}}{{roomRow}}"
                              aria-expanded="false" [attr.aria-controls]="'collapseTwo_ff_'+floorRow+roomRow"> Inventory
                              <i class="fa fa-chevron-down arrow-down" aria-hidden="true"></i>
                            </a>
                            <!-- <span class="delete-icon">
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </span> -->
                          </h5>
                        </div>

                        <div id="collapseTwo_ff_{{floorRow}}{{roomRow}}" class="collapse" role="tabpanel"
                          [attr.aria-labelledby]="'headingTwo'+roomRow">
                          <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">
                            <span class="w-100 d-inline-block"><button
                                class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold"
                                (click)="open(inventoryModal); setFloorRoomIndex(floorRow, roomRow)">Add
                                Item</button></span>

                            <div class="alert alert-danger text-center"
                              *ngIf="floorRoom?.inventory=='' || floorRoom?.inventory==null">No
                              Inventory Items Added</div>

                            <div *ngIf="floorRoom?.inventory!='' || floorRoom?.inventory!=null">
                              <div class="card multi  mt-0 mb-3 ml-3 mr-3 "
                                *ngFor="let roomInventory of floorRoom.inventory; let inventoryRow=index">
                                <div class="card-header" role="tab" id="headingFrom{{roomRow}}{{inventoryRow}}">
                                  <h5 class="mb-0">
                                    <a class="w-100 floor-accordion d-inline-block collapsed" data-toggle="collapse"
                                      data-parent="#accordion" href="#collapseOneinnner{{roomRow}}{{inventoryRow}}"
                                      aria-expanded="true"
                                      [attr.aria-controls]="'collapseOneinnner'+roomRow+inventoryRow">
                                      {{roomInventory.item_name}} <i class="fa fa-chevron-down arrow-down mx-3"
                                        aria-hidden="true"></i>
                                    </a>
                                    <span class="delete-icon"
                                      (click)="deleteInventoryItem(floorRow, roomRow, inventoryRow)">
                                      <i class="fa fa-trash" aria-hidden="true"></i>
                                    </span>
                                  </h5>
                                </div>
                                <div id="collapseOneinnner{{roomRow}}{{inventoryRow}}" class="collapse" role="tabpanel"
                                  [attr.aria-labelledby]="'headingFrom'+roomRow+inventoryRow">
                                  <div class="card-block p-4">
                                    <form (ngSubmit)="onSubmitInventoryUpdate(inventoryUpdateForm)"
                                      #inventoryUpdateForm="ngForm">
                                      <input type="hidden" name="floor_row" [ngModel]="floorRow" />
                                      <input type="hidden" name="room_row" [ngModel]="roomRow" />
                                      <input type="hidden" name="inventory_row" [ngModel]="inventoryRow" />
                                      <input type="hidden" name="item_name" [ngModel]="roomInventory.item_name"
                                        #item_name="ngModel">
                                      <input type="hidden" value="{{roomInventory.item_id}}" name="item_id"
                                        [(ngModel)]="roomInventory.item_id">
                                      <input type="hidden" value="{{roomInventory.type_id}}" name="type_id"
                                        [(ngModel)]="roomInventory.type_id">

                                      <div class="row inventory-item-row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item</label>
                                            <ng-template #itemTemplate let-item>
                                              <a [innerHTML]="item.item_name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplate let-notFound>
                                              <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                            <ng-autocomplete [data]="itemList" [searchKeyword]="keyword"
                                              (inputChanged)='onChangeSearch($event)'
                                              (selected)='selectEventItem($event, floorRow, roomRow,inventoryRow)'
                                              (inputCleared)="onClearedItem($event, floorRow, roomRow,inventoryRow)"
                                              [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                              placeholder="Item Name" [isLoading]="isLoadingResult" [minQueryLength]="1"
                                              name="item_name_auto" [ngModel]="roomInventory.item_name"
                                              #item_name_auto="ngModel" required>
                                            </ng-autocomplete>

                                            <div class="text-danger" *ngIf="!item_name_auto.value">
                                              Item Name is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey" class="w-100 mb-4">To go?</label>
                                            <select class="form-control form-control-custom" name="item_to_go"
                                              [ngModel]="roomInventory.item_to_go" #item_to_go="ngModel" required>
                                              <option value="" [selected]="(roomInventory.item_to_go=='') ? true:false">
                                                Select</option>
                                              <option value="TG"
                                                [selected]="(roomInventory.item_to_go=='TG') ? true:false">Yes: To go
                                              </option>
                                              <option value="NTG"
                                                [selected]="(roomInventory.item_to_go=='NTG') ? true:false">No: Not to go
                                              </option>
                                              <option value="MTG"
                                                [selected]="(roomInventory.item_to_go=='MTG') ? true:false">Not sure: Maybe to go
                                              </option>
                                            </select>
                                            <div class="text-danger" *ngIf="!item_to_go.value">
                                              This field is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Width Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Width Cm" autocomplete="off"
                                              value="{{ roomInventory.item_width_cm }}" name="item_width_cm"
                                              [(ngModel)]="roomInventory.item_width_cm" #item_width_cm="ngModel"
                                              ng-required="defaultUnits?.linear_units=='cm'">

                                            <div class="text-danger" *ngIf="item_width_cm.value==undefined">
                                              Width is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Height Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Height Cm" autocomplete="off"
                                              value="{{ roomInventory.item_height_cm }}" name="item_height_cm"
                                              [(ngModel)]="roomInventory.item_height_cm" #item_height_cm="ngModel"
                                              ng-required="defaultUnits?.linear_units=='cm'">

                                            <div class="text-danger" *ngIf="item_height_cm.value==undefined">
                                              Height is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col {{defaultUnits?.linear_units=='cm' ? '' : 'd-none'}}">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Depth Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Depth Cm" autocomplete="off"
                                              value="{{ roomInventory.item_depth_cm }}" name="item_depth_cm"
                                              [(ngModel)]="roomInventory.item_depth_cm" #item_depth_cm="ngModel"
                                              ng-required="defaultUnits?.linear_units=='cm'">

                                            <div class="text-danger" *ngIf="item_depth_cm.value==undefined">
                                              Depth is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Width Inch</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Width Inch" autocomplete="off"
                                              value="{{ roomInventory.item_width_inch }}" name="item_width_inch"
                                              [(ngModel)]="roomInventory.item_width_inch" #item_width_inch="ngModel"
                                              ng-required="defaultUnits?.linear_units=='inch'">

                                            <div class="text-danger" *ngIf="item_width_inch.value==undefined">
                                              Width is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Height Inch</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Height Inch" autocomplete="off"
                                              value="{{ roomInventory.item_height_inch }}" name="item_height_inch"
                                              [(ngModel)]="roomInventory.item_height_inch" #item_height_inch="ngModel"
                                              ng-required="defaultUnits?.linear_units=='inch'">

                                            <div class="text-danger" *ngIf="item_height_inch.value==undefined">
                                              Height is required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col {{defaultUnits?.linear_units=='inch' ? '' : 'd-none'}}">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Depth Inch</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Depth Inch" autocomplete="off"
                                              value="{{ roomInventory.item_depth_inch }}" name="item_depth_inch"
                                              [(ngModel)]="roomInventory.item_depth_inch" #item_depth_inch="ngModel"
                                              ng-required="defaultUnits?.linear_units=='inch'">

                                            <div class="text-danger" *ngIf="item_depth_inch.value==undefined">
                                              Depth is required
                                            </div>
                                          </div>
                                        </div>

                                        <div class="d-none">
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Weight</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Item Weight" autocomplete="off"
                                                value="{{roomInventory.item_weight }}" name="item_weight"
                                                [(ngModel)]="roomInventory.item_weight" #item_weight="ngModel">

                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">People required to carry</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Item Carry People" autocomplete="off"
                                                name="item_carry_people" [(ngModel)]="roomInventory.item_carry_people"
                                                #item_carry_people="ngModel">

                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Category</label>
                                              <input type="text" class="form-control" placeholder="Type Name"
                                                autocomplete="off" name="type_name"
                                                [(ngModel)]="roomInventory.type_name" #type_name="ngModel">

                                            </div>
                                          </div>

                                          <div class="col {{defaultUnits?.cubic_units=='cm' ? '' : 'd-none'}}">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Cubic Cm</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Cubic Cm" autocomplete="off"
                                                value="{{ roomInventory.item_cube_cm }}" name="item_cube_cm"
                                                [(ngModel)]="roomInventory.item_cube_cm" #item_cube_cm="ngModel">
                                            </div>
                                          </div>
                                          <div class="col {{defaultUnits?.cubic_units=='cm' ? '' : 'd-none'}}">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Cubic Cm dismantled</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Cubic Cm dismantled" autocomplete="off"
                                                value="{{ roomInventory.item_cube_dismantled_cm }}"
                                                name="item_cube_dismantled_cm"
                                                [(ngModel)]="roomInventory.item_cube_dismantled_cm"
                                                #item_cube_dismantled_cm="ngModel">
                                            </div>
                                          </div>
                                          <div class="col {{defaultUnits?.cubic_units=='ft' ? '' : 'd-none'}}">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Cubic Ft</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Cubic Ft" autocomplete="off"
                                                value="{{ roomInventory.item_cube_ft }}" name="item_cube_ft"
                                                [(ngModel)]="roomInventory.item_cube_ft" #item_cube_ft="ngModel">
                                            </div>
                                          </div>

                                          <div class="col {{defaultUnits?.cubic_units=='ft' ? '' : 'd-none'}}">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Cubic Ft dismantled</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Cubic Ft dismantled" autocomplete="off"
                                                value="{{ roomInventory.item_cube_dismantled_ft }}"
                                                name="item_cube_dismantled_ft"
                                                [(ngModel)]="roomInventory.item_cube_dismantled_ft"
                                                #item_cube_dismantled_ft="ngModel">
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group ">
                                              <label for="exampleInputSurvey" class="w-100 mb-4">Dismantling required?</label>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" name="item_do_dismantle"
                                                    [checked]="roomInventory.item_do_dismantle==true" [value]=true
                                                    [(ngModel)]="roomInventory.item_do_dismantle">Yes
                                                </label>
                                              </div>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" name="item_do_dismantle"
                                                    [checked]="roomInventory.item_do_dismantle==false" [value]=false
                                                    [(ngModel)]="roomInventory.item_do_dismantle">No
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Dismantling time required</label>
                                              <input type="text" class="form-control" placeholder="Item Dismantle Time"
                                                autocomplete="off" value="{{ roomInventory.item_dismantle_time }}"
                                                name="item_dismantle_time"
                                                [(ngModel)]="roomInventory.item_dismantle_time"
                                                #item_dismantle_time="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group ">
                                              <label for="exampleInputSurvey" class="w-100 mb-4">Reassembling required?</label>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" name="item_do_reassemble"
                                                    [checked]="roomInventory.item_do_reassemble==true" [value]=true
                                                    [(ngModel)]="roomInventory.item_do_reassemble">Yes
                                                </label>
                                              </div>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input" name="item_do_reassemble"
                                                    [checked]="roomInventory.item_do_reassemble==false" [value]=false
                                                    [(ngModel)]="roomInventory.item_do_reassemble">No
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Reassembling time required</label>
                                              <input type="text" class="form-control" placeholder="Item Reassemble Time"
                                                autocomplete="off" value="{{ roomInventory.item_reassemble_time }}"
                                                name="item_reassemble_time"
                                                [(ngModel)]="roomInventory.item_reassemble_time"
                                                #item_reassemble_time="ngModel">


                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Typical financial value</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Item Typical Value" autocomplete="off"
                                                value="{{ roomInventory.item_typical_value }}" name="item_typical_value"
                                                [(ngModel)]="roomInventory.item_typical_value"
                                                #item_typical_value="ngModel">


                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group ">
                                              <label for="exampleInputSurvey" class="w-100 mb-4">Item provides
                                                storage?</label>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input"
                                                    name="item_provides_storage"
                                                    [checked]="roomInventory.item_provides_storage==true" [value]=true
                                                    [(ngModel)]="roomInventory.item_provides_storage">Yes
                                                </label>
                                              </div>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input type="radio" class="form-check-input"
                                                    name="item_provides_storage"
                                                    [checked]="roomInventory.item_provides_storage==false" [value]=false
                                                    [(ngModel)]="roomInventory.item_provides_storage">No
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. small boxes</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. small boxes" autocomplete="off"
                                                value="{{ roomInventory.item_pack_small }}" name="item_pack_small"
                                                [(ngModel)]="roomInventory.item_pack_small" #item_pack_small="ngModel">
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. medium boxes</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. medium boxes" autocomplete="off"
                                                value="{{ roomInventory.item_pack_med }}" name="item_pack_med"
                                                [(ngModel)]="roomInventory.item_pack_med" #item_pack_med="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. large boxes</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. large boxes" autocomplete="off"
                                                value="{{ roomInventory.item_pack_large }}" name="item_pack_large"
                                                [(ngModel)]="roomInventory.item_pack_large" #item_pack_large="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. layflat boxes</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. layflat boxes" autocomplete="off"
                                                value="{{ roomInventory.item_pack_layflat }}" name="item_pack_layflat"
                                                [(ngModel)]="roomInventory.item_pack_layflat"
                                                #item_pack_layflat="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. wardrobe boxes</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. wardrobe boxes" autocomplete="off"
                                                value="{{ roomInventory.item_pack_wardrobe }}" name="item_pack_wardrobe"
                                                [(ngModel)]="roomInventory.item_pack_wardrobe"
                                                #item_pack_wardrobe="ngModel">
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. picturepack</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. picturepack" autocomplete="off"
                                                value="{{ roomInventory.item_pack_picturepack }}"
                                                name="item_pack_picturepack"
                                                [(ngModel)]="roomInventory.item_pack_picturepack"
                                                #item_pack_picturepack="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. golfbox</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. golfbox" autocomplete="off"
                                                value="{{ roomInventory.item_pack_golfbox }}" name="item_pack_golfbox"
                                                [(ngModel)]="roomInventory.item_pack_golfbox"
                                                #item_pack_golfbox="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. bikebox</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. bikebox" autocomplete="off"
                                                value="{{ roomInventory.item_pack_bikebox }}" name="item_pack_bikebox"
                                                [(ngModel)]="roomInventory.item_pack_bikebox"
                                                #item_pack_bikebox="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. single mattress bag</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. single mattress bag" autocomplete="off"
                                                value="{{ roomInventory.item_pack_mattressbagsingle }}"
                                                name="item_pack_mattressbagsingle"
                                                [(ngModel)]="roomInventory.item_pack_mattressbagsingle"
                                                #item_pack_mattressbagsingle="ngModel">
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. double mattress bag</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. double mattress bag" autocomplete="off"
                                                value="{{ roomInventory.item_pack_mattressbagdouble }}"
                                                name="item_pack_mattressbagdouble"
                                                [(ngModel)]="roomInventory.item_pack_mattressbagdouble"
                                                #item_pack_mattressbagdouble="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. king mattress bag</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. king mattress bag" autocomplete="off"
                                                value="{{ roomInventory.item_pack_mattressbagking }}"
                                                name="item_pack_mattressbagking"
                                                [(ngModel)]="roomInventory.item_pack_mattressbagking"
                                                #item_pack_mattressbagking="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. super king mattress bag</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. super king mattress bag" autocomplete="off"
                                                value="{{ roomInventory.item_pack_mattressbagsuperking }}"
                                                name="item_pack_mattressbagsuperking"
                                                [(ngModel)]="roomInventory.item_pack_mattressbagsuperking"
                                                #item_pack_mattressbagsuperking="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 24</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 24" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox24 }}" name="item_pack_tvbox24"
                                                [(ngModel)]="roomInventory.item_pack_tvbox24"
                                                #item_pack_tvbox24="ngModel">
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 32</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 32" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox32 }}" name="item_pack_tvbox32"
                                                [(ngModel)]="roomInventory.item_pack_tvbox32"
                                                #item_pack_tvbox32="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 36</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 36" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox36 }}" name="item_pack_tvbox36"
                                                [(ngModel)]="roomInventory.item_pack_tvbox36"
                                                #item_pack_tvbox36="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 43</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 43" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox43 }}" name="item_pack_tvbox43"
                                                [(ngModel)]="roomInventory.item_pack_tvbox43"
                                                #item_pack_tvbox43="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 48</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 48" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox48 }}" name="item_pack_tvbox48"
                                                [(ngModel)]="roomInventory.item_pack_tvbox48"
                                                #item_pack_tvbox48="ngModel">
                                            </div>
                                          </div>

                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 55</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Item Pack Tvbox55" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox55 }}" name="item_pack_tvbox55"
                                                [(ngModel)]="roomInventory.item_pack_tvbox55"
                                                #item_pack_tvbox55="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 70</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 70" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox70 }}" name="item_pack_tvbox70"
                                                [(ngModel)]="roomInventory.item_pack_tvbox70"
                                                #item_pack_tvbox70="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Inc. TV box 85</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Inc. TV box 85" autocomplete="off"
                                                value="{{ roomInventory.item_pack_tvbox85 }}" name="item_pack_tvbox85"
                                                [(ngModel)]="roomInventory.item_pack_tvbox85"
                                                #item_pack_tvbox85="ngModel">
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="form-group">
                                              <label for="exampleInputSurvey">Percentage full (If provides storage)</label>
                                              <input type="number" min="0" class="form-control"
                                                placeholder="Percentage full" autocomplete="off"
                                                value="{{ roomInventory.item_percentage_full }}"
                                                name="item_percentage_full"
                                                [(ngModel)]="roomInventory.item_percentage_full"
                                                #item_percentage_full="ngModel">
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                      <div class="modal-footer border-0 sumit_popup">
                                        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--Ground Floor Room inventory section end-->
                    </div>
                    <!-- End inner-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade {{currentTabTypeid == 'to' ? 'show active' : ''}}" id="nav-to-floorroom" role="tabpanel"
      aria-labelledby="nav-overview-tab">
      <span class="w-100 d-inline-block">
        <button class="btn btn-primary border-0 text-white float-right font-weight-bold"
          (click)="openFloorModal(addFloorModal)">Add Floor
        </button>
      </span>

      <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>
      <div class="alert alert-danger text-center" *ngIf="(ImportSurveyList.property?.to.plan.floors?.length <=0)">No
        Floors are added</div>

      <div id="accordion" role="tablist" class="o-accordion"
        *ngIf="(ImportSurveyList.property?.to.plan.floors?.length > 0)">
        <div class="card mt-2 mb-2"
          *ngFor="let floorNameTo of ImportSurveyList.property.to.plan.floors;let floorRow=index;">
          <div role="tab" id="groundfloorone{{floorRow}}" class="card-header floor-section">
            <h5 class="mb-0"><a data-toggle="collapse" data-parent="#accordion"
                href="#collapsegroundoneFrom{{floorRow}}" aria-expanded="false"
                [attr.aria-controls]="'collapsegroundoneFrom'+floorRow"
                class="w-100 d-inline-block floor-accordion collapsed">{{floorNameTo.floor_name}} <i
                  class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
              </a>
              <span class="delete-icon" (click)="deleteFloor(floorRow)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
              <span class="edit-icon"
                (click)="openFloorModal(editFloorModal); setEditFloorValue(floorRow,floorNameTo.floor_name)"><img
                  src="../assets/img/edit-icon.png" alt="edit-icon"></span>
            </h5>
          </div>
          <div id="collapsegroundoneFrom{{floorRow}}" role="tabpanel" [attr.aria-labelledby]="'groundfloorone'+floorRow"
            class="collapse">
            <div class="card-block p-4">
              <span class="w-100 d-inline-block">
                <button class="btn btn-primary border-0 text-white float-right font-weight-bold"
                  (click)="open(roomModal); setFloorIndexValue(floorRow)">Add Room
                </button>
              </span>
              <div class="alert alert-danger text-center" *ngIf="floorNameTo.floor_rooms==''">No Rooms are Added</div>

              <div class="card mt-2 mb-3" *ngFor="let floorRoom of floorNameTo.floor_rooms; let roomRow=index;">
                <div role="tab" id="groundfloorinner{{roomRow}}" class="card-header">
                  <h5 class="mb-0 accordion-name"><a data-toggle="collapse" data-parent="#accordion"
                      href="#collapsegroundinner{{roomRow}}" aria-expanded="false"
                      [attr.aria-controls]="'collapsegroundinner'+roomRow"
                      class="w-100 d-inline-block floor-accordion collapsed">{{ floorRoom.room_name }} <i
                        class="fa fa-chevron-down arrow-down mx-3" aria-hidden="true"></i>
                    </a>
                    <span class="delete-icon" (click)="deleteRoom(floorRow, roomRow)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </h5>
                </div>
                <div id="collapsegroundinner{{roomRow}}" role="tabpanel"
                  [attr.aria-labelledby]="'groundfloorinner'+roomRow" class="collapse">
                  <div class="card-block p-4">
                    <form (ngSubmit)="onSubmitRoomUpdate(roomUpdateForm)" #roomUpdateForm="ngForm">
                      <input type="hidden" [ngModel]="floorRow" name="floor_row" />
                      <input type="hidden" [ngModel]="roomRow" name="room_row" />
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Room Name</label>
                            <input type="text" class="form-control" placeholder="Room Name" autocomplete="off"
                              value="{{ floorRoom.room_name }}" name="room_name" [ngModel]="floorRoom.room_name"
                              #room_name="ngModel" required>

                            <div class="text-danger" *ngIf="!room_name.value">
                              Room Name is Required
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Room Type</label>
                            <div class="form-check-inline w-100">
                              <select class="form-control  border" id="exampleFormControlSelect2" name="room_type"
                                [ngModel]="floorRoom.room_type" #room_type="ngModel" required>
                                <option value="" [selected]="(floorRoom.room_type=='') ? true:false">Select</option>
                                <option value="bedroom" [selected]="(floorRoom.room_type)=='bedroom' ? true:false">
                                  Bedroom</option>
                                <option value="living room"
                                  [selected]="(floorRoom.room_type)=='living room' ? true:false">Living Room</option>
                                <option value="bathroom" [selected]="(floorRoom.room_type)=='bathroom' ? true:false">
                                  Bathroom</option>
                                <option value="kitchen" [selected]="(floorRoom.room_type)=='kitchen' ? true:false">
                                  Kitchen</option>
                                <option value="dining room"
                                  [selected]="(floorRoom.room_type)=='dining room' ? true:false">Dining Room</option>
                                <option value="office" [selected]="(floorRoom.room_type)=='office' ? true:false">Office
                                </option>
                                <option value="gym" [selected]="(floorRoom.room_type)=='gym' ? true:false">Gym</option>
                                <option value="other" [selected]="(floorRoom.room_type)=='other' ? true:false">Other
                                </option>
                              </select>

                            </div>
                            <div class="text-danger" *ngIf="!room_type.value">
                              Room Type is Required
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group ">
                            <label for="exampleInputSurvey" class="w-100">Room ntg</label>
                            <div class="form-check-inline w-100">
                              <select class="form-control form-control-custom" id="exampleFormControlSelect2"
                                name="room_ntg" [ngModel]="floorRoom.room_ntg" #room_ntg="ngModel" required>
                                <option value="" [selected]="(floorRoom.room_ntg==null) ? true:false">Select</option>
                                <option value="TG" [selected]="(floorRoom.room_ntg=='TG') ? true:false">To Go</option>
                                <option value="NTG" [selected]="(floorRoom.room_ntg=='NTG') ? true:false">Not To Go
                                </option>
                                <option value="MTG" [selected]="(floorRoom.room_ntg=='MTG') ? true:false">Maybe To Go
                                </option>
                              </select>
                            </div>
                            <div class="text-danger" *ngIf="!room_ntg.value">
                              Room ntg is Required
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group ">
                            <div class="form-check-inline">
                              <label class="custom-check-mark">Room Packing
                                <input type="checkbox" name="room_packing" [checked]="floorRoom.room_packing==true"
                                  [ngModel]="floorRoom.room_packing">
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group ">
                            <div class="form-check-inline">
                              <label class="custom-check-mark">Room Seen
                                <input type="checkbox" [checked]="floorRoom.room_seen==true" name="room_seen"
                                  [checked]="floorRoom.room_seen==true" [ngModel]="floorRoom.room_seen">
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Room Box Estimate</label>
                            <input type="number" min="0" class="form-control" placeholder="Room Box Estimate"
                              autocomplete="off" value="{{ floorRoom.room_box_estimate }}" name="room_box_estimate"
                              [ngModel]="floorRoom.room_box_estimate" #room_box_estimate="ngModel" required>
                            <div class="text-danger" *ngIf="room_box_estimate.value==undefined">
                              Room Box Estimate is Required
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group ">
                            <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving the light fittings in this room?</label>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="move_light_fittings"
                                  [checked]="floorRoom.move_light_fittings==true" [value]=true
                                  [(ngModel)]="floorRoom.move_light_fittings">Yes
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="move_light_fittings"
                                  [checked]="floorRoom.move_light_fittings==false" [value]=false
                                  [(ngModel)]="floorRoom.move_light_fittings">No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group ">
                            <label for="exampleInputSurvey" class="w-100 mb-4">Move Curtains Blinds</label>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="move_curtains_blinds"
                                  [checked]="floorRoom.move_curtains_blinds==true" [value]=true
                                  [(ngModel)]="floorRoom.move_curtains_blinds">Yes
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="move_curtains_blinds"
                                  [checked]="floorRoom.move_curtains_blinds==false" [value]=false
                                  [(ngModel)]="floorRoom.move_curtains_blinds">No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group ">
                            <label for="exampleInputSurvey" class="w-100 mb-4">Move Carpets</label>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="move_carpets"
                                  [checked]="floorRoom.move_carpets==true" [value]=true
                                  [(ngModel)]="floorRoom.move_carpets">Yes
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="move_carpets"
                                  [checked]="floorRoom.move_carpets==false" [value]=false
                                  [(ngModel)]="floorRoom.move_carpets">No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0 sumit_popup">
                        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                      </div>
                    </form>

                    <div id="accordion" role="tablist" class="o-accordion">
                      <!---Ground Floor Room inventory section start-->
                      <div class="card mt-2 mb-2">
                        <div class="card-header" role="tab" id="headingTwo{{roomRow}}">
                          <h5 class="mb-0">
                            <a class="collapsed w-100 floor-accordion d-inline-block" data-toggle="collapse"
                              data-parent="#accordion" href="#collapseTwo{{floorRow}}{{roomRow}}" aria-expanded="false"
                              [attr.aria-controls]="'collapseTwo'+floorRow+roomRow"> Inventory <i
                                class="fa fa-chevron-down arrow-down" aria-hidden="true"></i>
                            </a>
                            <!-- <span class="delete-icon">
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </span> -->
                          </h5>
                        </div>
                        <div id="collapseTwo{{floorRow}}{{roomRow}}" class="collapse" role="tabpanel"
                          [attr.aria-labelledby]="'headingTwo'+roomRow">

                          <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">
                            <span class="w-100 d-inline-block"><button
                                class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold"
                                (click)="open(inventoryModal); setFloorRoomIndex(floorRow, roomRow)">Add
                                Item</button></span>

                            <div class="alert alert-danger text-center"
                              *ngIf="floorRoom?.inventory=='' || floorRoom?.inventory==null">No
                              Inventory Items Added</div>

                            <div *ngIf="floorRoom?.inventory!='' || floorRoom?.inventory!=null">
                              <div class="card multi  mt-0 mb-3 ml-3 mr-3 "
                                *ngFor="let roomInventory of floorRoom.inventory; let inventoryRow=index">
                                <div class="card-header" role="tab" id="headingFrom{{roomRow}}{{inventoryRow}}">
                                  <h5 class="mb-0">
                                    <a class="w-100 floor-accordion d-inline-block collapsed" data-toggle="collapse"
                                      data-parent="#accordion" href="#collapseOneinnner{{roomRow}}{{inventoryRow}}"
                                      aria-expanded="true"
                                      [attr.aria-controls]="'collapseOneinnner'+roomRow+inventoryRow">
                                      {{roomInventory.item_name}} <i class="fa fa-chevron-down arrow-down mx-3"
                                        aria-hidden="true"></i>
                                    </a>
                                    <span class="delete-icon"
                                      (click)="deleteInventoryItem(floorRow, roomRow, inventoryRow)">
                                      <i class="fa fa-trash" aria-hidden="true"></i>
                                    </span>
                                  </h5>
                                </div>
                                <div id="collapseOneinnner{{roomRow}}{{inventoryRow}}" class="collapse" role="tabpanel"
                                  [attr.aria-labelledby]="'headingFrom'+roomRow+inventoryRow">
                                  <div class="card-block p-4">
                                    <form (ngSubmit)="onSubmitInventoryUpdate(inventoryUpdateForm)"
                                      #inventoryUpdateForm="ngForm">
                                      <input type="hidden" name="floor_row" [ngModel]="floorRow" />
                                      <input type="hidden" name="room_row" [ngModel]="roomRow" />
                                      <input type="hidden" name="inventory_row" [ngModel]="inventoryRow" />
                                      <input type="hidden" name="item_name" [ngModel]="roomInventory.item_name"
                                        #item_name="ngModel">
                                      <input type="hidden" value="{{roomInventory.item_id}}" name="item_id"
                                        [(ngModel)]="roomInventory.item_id">
                                      <input type="hidden" value="{{roomInventory.type_id}}" name="type_id"
                                        [(ngModel)]="roomInventory.type_id">
                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Name</label>
                                            <ng-template #itemTemplate let-item>
                                              <a [innerHTML]="item.item_name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplate let-notFound>
                                              <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                            <ng-autocomplete [data]="itemList" [searchKeyword]="keyword"
                                              (inputChanged)='onChangeSearch($event)'
                                              (selected)='selectEventItem($event, floorRow, roomRow,inventoryRow)'
                                              (inputCleared)="onClearedItem($event, floorRow, roomRow,inventoryRow)"
                                              [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                              placeholder="Item Name" [isLoading]="isLoadingResult" [minQueryLength]="1"
                                              name="item_name_auto" [ngModel]="roomInventory.item_name"
                                              #item_name_auto="ngModel" required>
                                            </ng-autocomplete>

                                            <div class="text-danger" *ngIf="!item_name_auto.value">
                                              Item Name is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Weight</label>
                                            <input type="number" min="0" class="form-control" placeholder="Item Weight"
                                              autocomplete="off" value="{{roomInventory.item_weight }}"
                                              name="item_weight" [(ngModel)]="roomInventory.item_weight"
                                              #item_weight="ngModel" required>
                                            <div class="text-danger" *ngIf="item_weight.value==undefined">
                                              Item Weight is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Carry People</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Carry People" autocomplete="off"
                                              name="item_carry_people" [(ngModel)]="roomInventory.item_carry_people"
                                              #item_carry_people="ngModel" required>
                                            <div class="text-danger" *ngIf="item_carry_people.value==undefined">
                                              Item Carry People is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Type Name</label>
                                            <input type="text" class="form-control" placeholder="Type Name"
                                              autocomplete="off" name="type_name" [(ngModel)]="roomInventory.type_name"
                                              #type_name="ngModel" required>
                                            <div class="text-danger" *ngIf="!type_name.value">
                                              Type Name is Required
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group ">
                                            <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Dismantle</label>
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="item_do_dismantle"
                                                  [checked]="roomInventory.item_do_dismantle==true" [value]=true
                                                  [(ngModel)]="roomInventory.item_do_dismantle">Yes
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="item_do_dismantle"
                                                  [checked]="roomInventory.item_do_dismantle==false" [value]=false
                                                  [(ngModel)]="roomInventory.item_do_dismantle">No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Dismantle Time</label>
                                            <input type="text" class="form-control" placeholder="Item Dismantle Time"
                                              autocomplete="off" value="{{ roomInventory.item_dismantle_time }}"
                                              name="item_dismantle_time" [(ngModel)]="roomInventory.item_dismantle_time"
                                              #item_dismantle_time="ngModel" required>

                                            <div class="text-danger" *ngIf="item_dismantle_time.value===''">
                                              Item Dismantle Time is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group ">
                                            <label for="exampleInputSurvey" class="w-100 mb-4">Item Do
                                              Reassemble</label>
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="item_do_reassemble"
                                                  [checked]="roomInventory.item_do_reassemble==true" [value]=true
                                                  [(ngModel)]="roomInventory.item_do_reassemble">Yes
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="item_do_reassemble"
                                                  [checked]="roomInventory.item_do_reassemble==false" [value]=false
                                                  [(ngModel)]="roomInventory.item_do_reassemble">No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Reassemble Time</label>
                                            <input type="text" class="form-control" placeholder="Item Reassemble Time"
                                              autocomplete="off" value="{{ roomInventory.item_reassemble_time }}"
                                              name="item_reassemble_time"
                                              [(ngModel)]="roomInventory.item_reassemble_time"
                                              #item_reassemble_time="ngModel" required>

                                            <div class="text-danger" *ngIf="item_reassemble_time.value===''">
                                              Item Reassemble Time is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Typical Value</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Typical Value" autocomplete="off"
                                              value="{{ roomInventory.item_typical_value }}" name="item_typical_value"
                                              [(ngModel)]="roomInventory.item_typical_value"
                                              #item_typical_value="ngModel" required>

                                            <div class="text-danger" *ngIf="item_typical_value.value==undefined">
                                              Item Typical Value is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group ">
                                            <label for="exampleInputSurvey" class="w-100 mb-4">Item Provides
                                              Storage</label>
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input type="radio" class="form-check-input"
                                                  name="item_provides_storage"
                                                  [checked]="roomInventory.item_provides_storage==true" [value]=true
                                                  [(ngModel)]="roomInventory.item_provides_storage">Yes
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <label class="form-check-label">
                                                <input type="radio" class="form-check-input"
                                                  name="item_provides_storage"
                                                  [checked]="roomInventory.item_provides_storage==false" [value]=false
                                                  [(ngModel)]="roomInventory.item_provides_storage">No
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey" class="w-100 mb-4">Item To Go</label>
                                            <select class="form-control form-control-custom" name="item_to_go"
                                              [ngModel]="roomInventory.item_to_go" #item_to_go="ngModel" required>
                                              <option value="" [selected]="(roomInventory.item_to_go=='') ? true:false">
                                                Select</option>
                                              <option value="TG"
                                                [selected]="(roomInventory.item_to_go=='TG') ? true:false">To Go
                                              </option>
                                              <option value="NTG"
                                                [selected]="(roomInventory.item_to_go=='NTG') ? true:false">Not To Go
                                              </option>
                                              <option value="MTG"
                                                [selected]="(roomInventory.item_to_go=='MTG') ? true:false">Maybe To Go
                                              </option>
                                            </select>
                                            <div class="text-danger" *ngIf="!item_to_go.value">
                                              Item To Go is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Small</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Small" autocomplete="off"
                                              value="{{ roomInventory.item_pack_small }}" name="item_pack_small"
                                              [(ngModel)]="roomInventory.item_pack_small" #item_pack_small="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_small.value==undefined">
                                              Item Pack Small is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Medium</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Medium" autocomplete="off"
                                              value="{{ roomInventory.item_pack_med }}" name="item_pack_med"
                                              [(ngModel)]="roomInventory.item_pack_med" #item_pack_med="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_med.value==undefined">
                                              Item Pack Medium is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Large</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Large" autocomplete="off"
                                              value="{{ roomInventory.item_pack_large }}" name="item_pack_large"
                                              [(ngModel)]="roomInventory.item_pack_large" #item_pack_large="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_large.value==undefined">
                                              Item Pack Large is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Layflat</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Layflat" autocomplete="off"
                                              value="{{ roomInventory.item_pack_layflat }}" name="item_pack_layflat"
                                              [(ngModel)]="roomInventory.item_pack_layflat" #item_pack_layflat="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_layflat.value==undefined">
                                              Item Pack Layflat is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Wardrobe" autocomplete="off"
                                              value="{{ roomInventory.item_pack_wardrobe }}" name="item_pack_wardrobe"
                                              [(ngModel)]="roomInventory.item_pack_wardrobe"
                                              #item_pack_wardrobe="ngModel" required>

                                            <div class="text-danger" *ngIf="item_pack_wardrobe.value==undefined">
                                              Item Pack Wardrobe is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Picturepack</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Picturepack" autocomplete="off"
                                              value="{{ roomInventory.item_pack_picturepack }}"
                                              name="item_pack_picturepack"
                                              [(ngModel)]="roomInventory.item_pack_picturepack"
                                              #item_pack_picturepack="ngModel" required>

                                            <div class="text-danger" *ngIf="item_pack_picturepack.value==undefined">
                                              Item Pack Picturepack is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Golfbox</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Golfbox" autocomplete="off"
                                              value="{{ roomInventory.item_pack_golfbox }}" name="item_pack_golfbox"
                                              [(ngModel)]="roomInventory.item_pack_golfbox" #item_pack_golfbox="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_golfbox.value==undefined">
                                              Item Pack Golfbox is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Bikebox</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Bikebox" autocomplete="off"
                                              value="{{ roomInventory.item_pack_bikebox }}" name="item_pack_bikebox"
                                              [(ngModel)]="roomInventory.item_pack_bikebox" #item_pack_bikebox="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_bikebox.value==undefined">
                                              Item Pack Bikebox is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Mattressbagsingle" autocomplete="off"
                                              value="{{ roomInventory.item_pack_mattressbagsingle }}"
                                              name="item_pack_mattressbagsingle"
                                              [(ngModel)]="roomInventory.item_pack_mattressbagsingle"
                                              #item_pack_mattressbagsingle="ngModel" required>

                                            <div class="text-danger"
                                              *ngIf="item_pack_mattressbagsingle.value==undefined">
                                              Item Pack Mattressbagsingle is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Mattressbagdouble" autocomplete="off"
                                              value="{{ roomInventory.item_pack_mattressbagdouble }}"
                                              name="item_pack_mattressbagdouble"
                                              [(ngModel)]="roomInventory.item_pack_mattressbagdouble"
                                              #item_pack_mattressbagdouble="ngModel" required>

                                            <div class="text-danger"
                                              *ngIf="item_pack_mattressbagdouble.value==undefined">
                                              Item Pack Mattressbagdouble is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Mattressbagking" autocomplete="off"
                                              value="{{ roomInventory.item_pack_mattressbagking }}"
                                              name="item_pack_mattressbagking"
                                              [(ngModel)]="roomInventory.item_pack_mattressbagking"
                                              #item_pack_mattressbagking="ngModel" required>

                                            <div class="text-danger" *ngIf="item_pack_mattressbagking.value==undefined">
                                              Item Pack Mattressbagking is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Mattressbagsuperking" autocomplete="off"
                                              value="{{ roomInventory.item_pack_mattressbagsuperking }}"
                                              name="item_pack_mattressbagsuperking"
                                              [(ngModel)]="roomInventory.item_pack_mattressbagsuperking"
                                              #item_pack_mattressbagsuperking="ngModel" required>

                                            <div class="text-danger"
                                              *ngIf="item_pack_mattressbagsuperking.value==undefined">
                                              Item Pack Mattressbagsuperking is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox24" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox24 }}" name="item_pack_tvbox24"
                                              [(ngModel)]="roomInventory.item_pack_tvbox24" #item_pack_tvbox24="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox24.value==undefined">
                                              Item Pack Tvbox24 is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox32" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox32 }}" name="item_pack_tvbox32"
                                              [(ngModel)]="roomInventory.item_pack_tvbox32" #item_pack_tvbox32="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox32.value==undefined">
                                              Item Pack Tvbox32 is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox36" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox36 }}" name="item_pack_tvbox36"
                                              [(ngModel)]="roomInventory.item_pack_tvbox36" #item_pack_tvbox36="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox36.value==undefined">
                                              Item Pack Tvbox36 is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox43" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox43 }}" name="item_pack_tvbox43"
                                              [(ngModel)]="roomInventory.item_pack_tvbox43" #item_pack_tvbox43="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox43.value==undefined">
                                              Item Pack Tvbox43 is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox48</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox48" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox48 }}" name="item_pack_tvbox48"
                                              [(ngModel)]="roomInventory.item_pack_tvbox48" #item_pack_tvbox48="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox48.value==undefined">
                                              Item Pack Tvbox48 is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox55" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox55 }}" name="item_pack_tvbox55"
                                              [(ngModel)]="roomInventory.item_pack_tvbox55" #item_pack_tvbox55="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox55.value==undefined">
                                              Item Pack Tvbox55 is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox70" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox70 }}" name="item_pack_tvbox70"
                                              [(ngModel)]="roomInventory.item_pack_tvbox70" #item_pack_tvbox70="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox70.value==undefined">
                                              Item Pack Tvbox70 is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Pack Tvbox85" autocomplete="off"
                                              value="{{ roomInventory.item_pack_tvbox85 }}" name="item_pack_tvbox85"
                                              [(ngModel)]="roomInventory.item_pack_tvbox85" #item_pack_tvbox85="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_pack_tvbox85.value==undefined">
                                              Item Pack Tvbox85 is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Width Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Width Cm" autocomplete="off"
                                              value="{{ roomInventory.item_width_cm }}" name="item_width_cm"
                                              [(ngModel)]="roomInventory.item_width_cm" #item_width_cm="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_width_cm.value==undefined">
                                              Item Width Cm is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Height Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Height Cm" autocomplete="off"
                                              value="{{ roomInventory.item_height_cm }}" name="item_height_cm"
                                              [(ngModel)]="roomInventory.item_height_cm" #item_height_cm="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_height_cm.value==undefined">
                                              Item Height Cm is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Depth Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Depth Cm" autocomplete="off"
                                              value="{{ roomInventory.item_depth_cm }}" name="item_depth_cm"
                                              [(ngModel)]="roomInventory.item_depth_cm" #item_depth_cm="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_depth_cm.value==undefined">
                                              Item Depth Cm is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Width Inch</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Width Inch" autocomplete="off"
                                              value="{{ roomInventory.item_width_inch }}" name="item_width_inch"
                                              [(ngModel)]="roomInventory.item_width_inch" #item_width_inch="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_width_inch.value==undefined">
                                              Item Width Inch is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Height Inch</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Height Inch" autocomplete="off"
                                              value="{{ roomInventory.item_height_inch }}" name="item_height_inch"
                                              [(ngModel)]="roomInventory.item_height_inch" #item_height_inch="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_height_inch.value==undefined">
                                              Item Height Inch is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Depth Inch</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Depth Inch" autocomplete="off"
                                              value="{{ roomInventory.item_depth_inch }}" name="item_depth_inch"
                                              [(ngModel)]="roomInventory.item_depth_inch" #item_depth_inch="ngModel"
                                              required>

                                            <div class="text-danger" *ngIf="item_depth_inch.value==undefined">
                                              Item Depth Inch is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Cube Cm</label>
                                            <input type="number" min="0" class="form-control" placeholder="Item Cube Cm"
                                              autocomplete="off" value="{{ roomInventory.item_cube_cm }}"
                                              name="item_cube_cm" [(ngModel)]="roomInventory.item_cube_cm"
                                              #item_cube_cm="ngModel" required>

                                            <div class="text-danger" *ngIf="item_cube_cm.value==undefined">
                                              Item Cube Cm is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Cube Ft</label>
                                            <input type="number" min="0" class="form-control" placeholder="Item Cube Ft"
                                              autocomplete="off" value="{{ roomInventory.item_cube_ft }}"
                                              name="item_cube_ft" [(ngModel)]="roomInventory.item_cube_ft"
                                              #item_cube_ft="ngModel" required>

                                            <div class="text-danger" *ngIf="item_cube_ft.value==undefined">
                                              Item Cube Ft is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Cube Dismantled Cm" autocomplete="off"
                                              value="{{ roomInventory.item_cube_dismantled_cm }}"
                                              name="item_cube_dismantled_cm"
                                              [(ngModel)]="roomInventory.item_cube_dismantled_cm"
                                              #item_cube_dismantled_cm="ngModel" required>

                                            <div class="text-danger" *ngIf="item_cube_dismantled_cm.value==undefined">
                                              Item Cube Dismantled is Required
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Cube Dismantled Ft" autocomplete="off"
                                              value="{{ roomInventory.item_cube_dismantled_ft }}"
                                              name="item_cube_dismantled_ft"
                                              [(ngModel)]="roomInventory.item_cube_dismantled_ft"
                                              #item_cube_dismantled_ft="ngModel" required>

                                            <div class="text-danger" *ngIf="item_cube_dismantled_ft.value==undefined">
                                              Item Cube Dismantled Ft is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="form-group">
                                            <label for="exampleInputSurvey">Item Percentage Full</label>
                                            <input type="number" min="0" class="form-control"
                                              placeholder="Item Percentage Full" autocomplete="off"
                                              value="{{ roomInventory.item_percentage_full }}"
                                              name="item_percentage_full"
                                              [(ngModel)]="roomInventory.item_percentage_full"
                                              #item_percentage_full="ngModel" required>

                                            <div class="text-danger" *ngIf="item_percentage_full.value==undefined">
                                              Item Percentage Full is Required
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col"></div>
                                        <div class="col"></div>
                                      </div>
                                      <div class="modal-footer border-0 sumit_popup">
                                        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--Ground Floor Room inventory section end-->
                    </div>
                    <!-- End inner-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--add new floor modal box start-->
<ng-template #addFloorModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="floorForm" (ngSubmit)="onSubmitAddFloor()">
    <div class="modal-body survey_popup">
      <label for="exampleInputEmail1">Floor Name</label>
      <input type="text" class="form-control mb-0" id="floorName" placeholder="Floor Name" autocomplete="off"
        formControlName="floor_name">
      <div class="text-danger"
        *ngIf="(floorFormControl.floor_name.touched || submittedFloor) && floorFormControl.floor_name.errors?.required">
        Floor Name is required
      </div>
    </div>
    <div class="modal-footer border-0 sumit_popup">
      <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
    </div>
  </form>
</ng-template>
<!--add new floor modal box end-->

<!-----add room modal box start---->
<ng-template #roomModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form [formGroup]="roomFormFrom" (ngSubmit)="onSubmitRoomFrom()">
      <input type="hidden" value="" formControlName="floorIndex" />
      <div class="card-block p-4">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="exampleInputSurvey">Room Name</label>
              <input type="text" class="form-control" placeholder="Room Name" autocomplete="off" value=""
                formControlName="room_name">
              <div class="text-danger"
                *ngIf="(roomFormFromControl.room_name.touched || submittedRoom) && roomFormFromControl.room_name.errors?.required">
                Room Name is required
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="exampleInputSurvey">What type of room is this?</label>
              <div class="form-check-inline w-100">
                <select class="form-control border" id="room_type_new" formControlName="room_type">
                  <option value="">--Select--</option>
                  <!-- <option *ngFor="let cat of categoryList" [value]="cat.type_name">{{cat.type_name}}</option> -->
                  <option value="bedroom">Bedroom</option>
                  <option value="living room">Living Room</option>
                  <option value="bathroom">Bathroom</option>
                  <option value="kitchen">Kitchen</option>
                  <option value="dining room">Dining Room</option>
                  <option value="office">Office</option>
                  <option value="gym">Gym</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="text-danger"
                *ngIf="(roomFormFromControl.room_type.touched || submittedRoom) && roomFormFromControl.room_type.errors?.required">
                Room Type is required
              </div>
            </div>
          </div>


          <div class="col-lg-6">
            <div class="form-group ">
              <label for="exampleInputSurvey" class="w-100">Are we moving the contents of this room? </label>
              <div class="form-check-inline w-100">
                <select class="form-control  border" id="exampleFormControlSelect2" formControlName="room_ntg">
                  <option value="">--Select--</option>
                  <option value="TG">Yes: To Go</option>
                  <option value="NTG">No: Not To Go</option>
                  <option value="MTG">Not Sure: Maybe To Go</option>
                </select>
              </div>
              <div class="text-danger"
                *ngIf="(roomFormFromControl.room_ntg.touched || submittedRoom) && roomFormFromControl.room_ntg.errors?.required">
                Room ntg is required
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this room
              </label>
              <input type="number" class="form-control" placeholder="Room Box Estimate" autocomplete="off" value=""
                formControlName="room_box_estimate">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group one-line-flex">
              <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this room? </label>
              <div class="answer-boxed">
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="room_packing" [value]="true"
                      [checked]="true">Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="room_packing" [value]="false"
                      [checked]="false">No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group one-line-flex">
              <label for="exampleInputSurvey" class="mb-4">Have we been able to see the room? </label>
              <div class="answer-boxed">
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="room_seen" [value]="true"
                      [checked]="true">Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="room_seen" [value]="false"
                      [checked]="false">No
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group one-line-flex">
              <label for="exampleInputSurvey" class="mb-4">Are we moving the light fittings in this room?</label>
              <div class="answer-boxed">
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="move_light_fittings" [value]="true"
                      [checked]="true">Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="move_light_fittings" [value]="false"
                      [checked]="false">No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group one-line-flex">
              <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains/blinds in this room?
              </label>
              <div class="answer-boxed">
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="move_curtains_blinds" [value]="true"
                      [checked]="true">Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="move_curtains_blinds" [value]="false"
                      [checked]="false">No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group one-line-flex">
              <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this room? </label>
              <div class="answer-boxed">
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="move_carpets" [value]="true"
                      [checked]="true">Yes
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" formControlName="move_carpets" [value]="false"
                      [checked]="false">No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
      </div>
    </form>
  </div>

</ng-template>
<!-----add room modal box end---->

<!-----add inventory modal  box start---->
<ng-template #inventoryModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <div class="card-block p-4 project-tab">
      <!-- tabs create -->
      <ul class="nav nav-tabs popup-inside-tabs" id="popup-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="catalogue-search-tab" data-toggle="tab" href="#cataloguesearch" role="tab"
            (click)="resetItemFormValue()">Search Catalogue</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="catalogue-tab" data-toggle="tab" href="#catalogue" role="tab"
            (click)="resetItemFormValue()">Browse Catalogue</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="manual-tab" data-toggle="tab" href="#manual" role="tab"
            (click)="resetItemFormValue()">Custom Item </a>
        </li>
      </ul>
      <!-- tabs creqte end-->
      <!-- tabs-content start -->
      <div class="tab-content" id="popup-tabs">
        <div class="tab-pane fade show active" id="cataloguesearch" role="tabpanel">
          
          <form [formGroup]="InventoryItemForm" (ngSubmit)="onSubmitInventory()">

            <input type="hidden" value="" formControlName="item_name">
            <input type="hidden" value="" formControlName="item_id">
            <input type="hidden" value="" formControlName="item_type">
            <input type="hidden" value="" formControlName="item_type_search">
            <input type="hidden" value="" formControlName="type_id">
            <input type="hidden" value="" formControlName="floorIndex" />
            <input type="hidden" value="" formControlName="roomIndex" />

            <div class="row">
              <div class="col">

                <div class="form-group">
                  <label for="exampleInputSurvey">Search</label>
                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.item_name"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <ng-autocomplete [data]="itemListUnFiltered" [searchKeyword]="keyword"
                    (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                    (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate" placeholder="Search catalogue" [isLoading]="isLoadingResult"
                    [minQueryLength]="1" formControlName="item_name_search">
                  </ng-autocomplete>
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_name.touched || submittedInventoryForm) && InventoryItemFormControl.item_name.errors?.required">
                    Item name is required
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <div class="form-group">
                  <label for="exampleInputSurvey">Quantity</label>
                  <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                    min="1" formControlName="total_items">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.total_items.touched || submittedInventoryForm) && InventoryItemFormControl.total_items.errors?.required">
                    Number of items is required
                  </div>
                  <div class="text-danger" *ngIf="InventoryItemFormControl.total_items.errors?.min">
                    Number of items can not less than 1
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">To go?</label>
                  <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                    <option value="">--Select--</option>
                    <option value="TG">Yes: To go</option>
                    <option value="NTG">No: Not to go</option>
                    <option value="MTG">Not sure: Maybe to go</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_to_go.touched || submittedInventoryForm) && InventoryItemFormControl.item_to_go.errors?.required">
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Cm</label>
                  <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                    min="0" formControlName="item_width_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_width_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_cm.errors?.required">
                    Item width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Cm</label>
                  <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                    min="0" formControlName="item_height_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_height_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_cm.errors?.required">
                    Item height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Cm</label>
                  <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                    min="0" formControlName="item_depth_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_depth_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_cm.errors?.required">
                    Item depth is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Inch</label>
                  <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                    min="0" formControlName="item_width_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_width_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_inch.errors?.required">
                    Item width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Inch</label>
                  <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                    min="0" formControlName="item_height_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_height_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_inch.errors?.required">
                    Item height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Inch</label>
                  <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                    min="0" formControlName="item_depth_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_depth_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_inch.errors?.required">
                    Item depth is required
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer border-0">
              <button type="submit" class="btn btn-primary border-0 pl-4 pr-4"><i class="fas fa-plus-square"></i> Add</button>
            </div>
          </form>
        
        </div>

        <div class="tab-pane fade" id="catalogue" role="tabpanel">
          
          <form [formGroup]="InventoryItemForm" (ngSubmit)="onSubmitInventory()">
            <input type="hidden" value="" formControlName="item_name">
            <input type="hidden" value="" formControlName="item_id">
            <input type="hidden" value="" formControlName="item_type">
            <input type="hidden" value="" formControlName="type_id">
            <input type="hidden" value="" formControlName="floorIndex" />
            <input type="hidden" value="" formControlName="roomIndex" />

            <div class="row inventory-item-row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Category</label>
                  <ng-template #itemTypeTemplate let-item>
                    <a [innerHTML]="item.type_name"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <ng-autocomplete [data]="itemTypeList" [searchKeyword]="keywordType"
                    (inputChanged)='onChangeSearchType($event)' (selected)='selectEventType($event)'
                    (inputFocused)='onFocusedType($event)' (inputCleared)="onClearedType($event)"
                    [itemTemplate]="itemTypeTemplate" [minQueryLength]="1" [notFoundTemplate]="notFoundTemplate"
                    placeholder="Category" [isLoading]="isLoadingResult" formControlName="item_type_search">
                  </ng-autocomplete>
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_type.touched || submittedInventoryForm) && InventoryItemFormControl.item_type.errors?.required">
                    Category is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item</label>
                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.item_name"></a>
                  </ng-template>
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                  <ng-autocomplete [data]="itemListFiltered" [searchKeyword]="keyword"
                    (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                    (inputCleared)="onCleared($event)" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate" placeholder="Item Name" [isLoading]="isLoadingResult"
                    [minQueryLength]="1" [disabled]="!selectedItemTypeId ? true : false"
                    formControlName="item_name_search">
                  </ng-autocomplete>
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_name.touched || submittedInventoryForm) && InventoryItemFormControl.item_name.errors?.required">
                    Item name is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Quantity</label>
                  <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                    min="1" formControlName="total_items">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.total_items.touched || submittedInventoryForm) && InventoryItemFormControl.total_items.errors?.required">
                    Quantity is required
                  </div>
                  <div class="text-danger" *ngIf="InventoryItemFormControl.total_items.errors?.min">
                    Quantity can not be less than 1
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">To go?</label>
                  <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                    <option value="">--Select--</option>
                    <option value="TG">Yes: To go</option>
                    <option value="NTG">No: Not to go</option>
                    <option value="MTG">Not sure: Maybe to go</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_to_go.touched || submittedInventoryForm) && InventoryItemFormControl.item_to_go.errors?.required">
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Cm</label>
                  <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                    min="0" formControlName="item_width_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_width_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_cm.errors?.required">
                    Width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Cm</label>
                  <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                    min="0" formControlName="item_height_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_height_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_cm.errors?.required">
                    Height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Cm</label>
                  <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                    min="0" formControlName="item_depth_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_depth_cm.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_cm.errors?.required">
                    Depth is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Inch</label>
                  <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                    min="0" formControlName="item_width_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_width_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_width_inch.errors?.required">
                    Width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Inch</label>
                  <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                    min="0" formControlName="item_height_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_height_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_height_inch.errors?.required">
                    Height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Inch</label>
                  <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                    min="0" formControlName="item_depth_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryItemFormControl.item_depth_inch.touched || submittedInventoryForm) && InventoryItemFormControl.item_depth_inch.errors?.required">
                    Depth is required
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer border-0">
              <button type="submit" class="btn btn-primary border-0 pl-4 pr-4"><i class="fas fa-plus-square"></i> Add</button>
            </div>
          </form>
        
        </div>

        <!---manual add item section-->
        <div class="tab-pane fade" id="manual" role="tabpanel">
          <form [formGroup]="InventoryItemFormManual" (ngSubmit)="onSubmitInventoryManual()">
            <input type="hidden" value="" formControlName="item_name">
            <input type="hidden" value="" formControlName="item_id">
            <input type="hidden" value="" formControlName="type_id">
            <input type="hidden" value="" formControlName="floorIndex" />
            <input type="hidden" value="" formControlName="roomIndex" />

            <div class="row inventory-item-row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Name</label>
                  <input type="text" class="form-control" placeholder="Item Name" autocomplete="off" value=""
                    formControlName="item_name">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_name.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_name.errors?.required">
                    Item name is required
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Quantity</label>
                  <input type="number" class="form-control" placeholder="Number of items" autocomplete="off" value=""
                    min="1" formControlName="total_items">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.total_items.touched || submittedInventoryFormManual) && InventoryFormManualControl.total_items.errors?.required">
                    Quantity is required
                  </div>
                  <div class="text-danger" *ngIf="InventoryFormManualControl.total_items.errors?.min">
                    Quantity can not be less than 1
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">To go?</label>
                  <select class="form-control  border" id="exampleFormControlSelect2" formControlName="item_to_go">
                    <option value="">--Select--</option>
                    <option value="TG">Yes: To go</option>
                    <option value="NTG">No: Not to go</option>
                    <option value="MTG">Not sure: Maybe to go</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_to_go.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_to_go.errors?.required">
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Cm</label>
                  <input type="number" class="form-control" placeholder="Item Width Cm" autocomplete="off" value=""
                    min="0" formControlName="item_width_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_width_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_width_cm.errors?.required">
                    Width is required
                  </div>
                </div>
              </div>

              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Height Cm</label>
                  <input type="number" class="form-control" placeholder="Item Height Cm" autocomplete="off" value=""
                    min="0" formControlName="item_height_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_height_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_height_cm.errors?.required">
                    Height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Depth Cm</label>
                  <input type="number" class="form-control" placeholder="Item Depth Cm" autocomplete="off" value=""
                    min="0" formControlName="item_depth_cm">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_depth_cm.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_depth_cm.errors?.required">
                    Depth is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Width Inch</label>
                  <input type="number" class="form-control" placeholder="Item Width Inch" autocomplete="off" value=""
                    min="0" formControlName="item_width_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_width_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_width_inch.errors?.required">
                    Width is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Height Inch</label>
                  <input type="number" class="form-control" placeholder="Item Height Inch" autocomplete="off" value=""
                    min="0" formControlName="item_height_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_height_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_height_inch.errors?.required">
                    Height is required
                  </div>
                </div>
              </div>
              <div class="col {{defaultUnits.linear_units=='inch' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Depth Inch</label>
                  <input type="number" class="form-control" placeholder="Item Depth Inch" autocomplete="off" value=""
                    min="0" formControlName="item_depth_inch">
                  <div class="text-danger"
                    *ngIf="(InventoryFormManualControl.item_depth_inch.touched || submittedInventoryFormManual) && InventoryFormManualControl.item_depth_inch.errors?.required">
                    Depth is required
                  </div>
                </div>
              </div>

              <!-- <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Weight</label>
                  <input type="number" class="form-control" placeholder="Item Weight" autocomplete="off" value=""
                    min="0" formControlName="item_weight">
                  
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Carry People</label>
                  <input type="number" class="form-control" placeholder="Item Carry People" autocomplete="off" value=""
                    min="0" formControlName="item_carry_people">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Type Name</label>
                  <select class="form-control border" id="room_type_new" formControlName="type_name">
                    <option value="">--Select--</option>
                    <option *ngFor="let cat of itemTypeList" [value]="cat.type_name">{{cat.type_name}}</option>                      
                  </select>
                </div>
              </div>
              
              <div class="col {{defaultUnits.cubic_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Cube Cm</label>
                  <input type="number" class="form-control" placeholder="Item Cube Cm" autocomplete="off" value=""
                    min="0" formControlName="item_cube_cm">

                </div>
              </div>                
              <div class="col {{defaultUnits.cubic_units=='cm' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                  <input type="number" class="form-control" placeholder="Item Cube Dismantled Cm" autocomplete="off"
                    value="" min="0" formControlName="item_cube_dismantled_cm">

                </div>
              </div>
              <div class="col {{defaultUnits.cubic_units=='ft' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Cube Ft</label>
                  <input type="number" class="form-control" placeholder="Item Cube Ft" autocomplete="off" value=""
                    min="0" formControlName="item_cube_ft">

                </div>
              </div>
              <div class="col {{defaultUnits.cubic_units=='ft' ? '' : 'd-none'}}">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                  <input type="number" class="form-control" placeholder="Item Cube Dismantled Ft" autocomplete="off"
                    value="" min="0" formControlName="item_cube_dismantled_ft">

                </div>
              </div>

              <div class="col">
                <div class="form-group ">
                  <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Dismantle</label>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" formControlName="item_do_dismantle" [value]=true>Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" formControlName="item_do_dismantle" [value]=false>No
                    </label>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Dismantle Time</label>
                  <input type="text" class="form-control" placeholder="Item Dismantle Time" autocomplete="off" value=""
                    min="0" formControlName="item_dismantle_time">

                </div>
              </div>
              <div class="col">
                <div class="form-group ">
                  <label for="exampleInputSurvey" class="w-100 mb-4">Item Do Reassemble</label>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" formControlName="item_do_reassemble" [value]=true>Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" formControlName="item_do_reassemble" [value]=false>No
                    </label>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Reassemble Time</label>
                  <input type="text" class="form-control" placeholder="Item Reassemble Time" autocomplete="off" value=""
                    min="0" formControlName="item_reassemble_time">

                </div>
              </div>
            
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Typical Value</label>
                  <input type="number" class="form-control" placeholder="Item Typical Value" autocomplete="off" value=""
                    min="0" formControlName="item_typical_value">

                </div>
              </div>
              
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey" class="w-100 mb-4">Item Provides Storage</label>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" formControlName="item_provides_storage"
                        [value]=true>Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" formControlName="item_provides_storage"
                        [value]=false>No
                    </label>
                  </div>
                </div>
              </div>
              
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Small</label>
                  <input type="number" class="form-control" placeholder="Item Pack Small" autocomplete="off" value=""
                    min="0" formControlName="item_pack_small">

                </div>
              </div>
            
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Medium</label>
                  <input type="number" class="form-control" placeholder="Item Pack Medium" autocomplete="off" value=""
                    min="0" formControlName="item_pack_med">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Large</label>
                  <input type="number" class="form-control" placeholder="Item Pack Large" autocomplete="off" value=""
                    min="0" formControlName="item_pack_large">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Layflat</label>
                  <input type="number" class="form-control" placeholder="Item Pack Layflat" autocomplete="off" value=""
                    min="0" formControlName="item_pack_layflat">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                  <input type="number" class="form-control" placeholder="Item Pack Wardrobe" autocomplete="off" value=""
                    min="0" formControlName="item_pack_wardrobe">

                </div>
              </div>
            
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Picturepack</label>
                  <input type="number" class="form-control" placeholder="Item Pack Picturepack" autocomplete="off"
                    value="" min="0" formControlName="item_pack_picturepack">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Golfbox</label>
                  <input type="number" class="form-control" placeholder="Item Pack Golfbox" autocomplete="off" value=""
                    min="0" formControlName="item_pack_golfbox">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Bikebox</label>
                  <input type="number" class="form-control" placeholder="Item Pack Bikebox" autocomplete="off" value=""
                    min="0" formControlName="item_pack_bikebox">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                  <input type="number" class="form-control" placeholder="Item Pack Mattressbagsingle" autocomplete="off"
                    value="" min="0" formControlName="item_pack_mattressbagsingle">

                </div>
              </div>
            
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                  <input type="number" class="form-control" placeholder="Item Pack Mattressbagdouble" autocomplete="off"
                    value="" min="0" formControlName="item_pack_mattressbagdouble">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                  <input type="number" class="form-control" placeholder="Item Pack Mattressbagking" autocomplete="off"
                    value="" min="0" formControlName="item_pack_mattressbagking">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                  <input type="number" class="form-control" placeholder="Item Pack Mattressbagsuperking"
                    autocomplete="off" value="" min="0" formControlName="item_pack_mattressbagsuperking">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox24" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox24">

                </div>
              </div>
            
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox32" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox32">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox36" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox36">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox43" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox43">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox48</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox48" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox48">

                </div>
              </div>
            
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox55" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox55">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox70" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox70">

                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                  <input type="number" class="form-control" placeholder="Item Pack Tvbox85" autocomplete="off" value=""
                    min="0" formControlName="item_pack_tvbox85">
                </div>
              </div>                
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputSurvey">Item Percentage Full</label>
                  <input type="number" class="form-control" placeholder="Item Percentage Full" autocomplete="off"
                    value="" min="0" formControlName="item_percentage_full">

                </div>
              </div> -->

            </div>
            <div class="modal-footer border-0">
              <button type="submit" class="btn btn-primary border-0 pl-4 pr-4"><i class="fas fa-plus-square"></i> Add</button>
            </div>
          </form>
        </div>
        <!-- last-div -->
      </div>

    </div>
  </div>
</ng-template>
<!-----add inventory modal  box end---->

<ng-template #editFloorModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="floorForm" (ngSubmit)="onSubmitEditFloor()">
    <input type="hidden" value="{{floorIndex}}" formControlName="floorIndex" />
    <div class="modal-body survey_popup">
      <label for="exampleInputEmail1">Floor Name</label>
      <input type="text" class="form-control mb-0" id="floorName" placeholder="Floor Name" autocomplete="off"
        formControlName="floor_name">
      <div class="text-danger"
        *ngIf="(floorFormControl.floor_name.touched || submittedFloor) && floorFormControl.floor_name.errors?.required">
        Floor Name is required
      </div>
    </div>
    <div class="modal-footer border-0 sumit_popup">
      <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
    </div>
  </form>
</ng-template>