<app-sidebar></app-sidebar>

<app-header-inner></app-header-inner>


<div class="table_list project-tab">
  <!-- hidden by bjw becasue i dont think it works or is useful -->
  <!--
  <div class="calendar-box position-relative mt-5 pt-4">
    <div class="form-group w-25 ml-auto d-flex align-items-center">
      <div class="input-group border {{surveyTabList == true ? '' : ''}}">
        <input class="form-control" placeholder="yyyy-mm-dd"
              name="date" [(ngModel)]="todayDate" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="onDateChange($event)" (click)="d.toggle()" autocomplete="off">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><img src="../assets/img/calendar.png" alt="calender" style="max-width:23px;"></button>
        </div>
      </div>
    </div>
  </div>
-->

  <div class="position-relative mt-1 pt-1">
    <div class="btn-made">
      <button type="button" class="btn btn-primery btn-custom" (click)="open(newSurveyModal)">Create Survey</button>
    </div>
  </div>


  <!-- tabs custom start -->

  <ul class="nav nav-tabs nav-tabs-made" id="made-custom-tabs" role="tablist">
    <li class="nav-item" (click)="selectedSurveyTab('local-survey')">
      <a class="nav-link active" id="your-survey-tab" data-toggle="tab" href="#your-surveys" role="tab">Your Surveys</a>
    </li>

    <li class="nav-item" (click)="selectedSurveyTab('imported-survey')">
      <a class="nav-link" id="home-tab" data-toggle="tab" href="#survey-imported" role="tab">Import from CRM</a>
    </li>
  </ul>

  <div class="tab-content" id="made-custom-tabs">
    <div class="tab-pane fade" id="survey-imported" role="tabpanel">
      <ul class="list_survey pt-0 pb-0 pl-3 pr-3" *ngIf="surveyList?.length > 0">
        <li class="font-weight-bold">Survey</li>
        <li class="font-weight-bold">Address</li>
        <li class="font-weight-bold">Date</li>
        <li class="font-weight-bold">Active</li>
      </ul>
      <ul class="inner_list pl-0 mb-2 bg-white w-100 d-inline-block">
        <li class="w-100 text-center list-unstyled" *ngIf="loader">Loading Data...</li>
        <li class="w-100 text-center list-unstyled alert alert-danger" *ngIf="message!=''">{{message}}</li>
      </ul>
      <ng-container *ngIf="surveyList!=undefined">
        <!--
        <ul class="inner_list mb-2 bg-white w-100 d-inline-block"
          *ngIf="!(surveyList?.length > 0) && !loader && message==''">
          <li class="w-100 text-center list-unstyled" *ngIf="!(surveyList?.length > 0)">No surveys found</li>
        </ul>
      -->
      <p class="text-center mt-4 w-100 d-inline-block" *ngIf="!(surveyList?.length > 0) && !loader && message==''">
          There are no surveys available for import!<br><small>Please ask an office administrator to assign you a survey.</small>
      </p>
      </ng-container>
      <ul class="list_survey inner_list pt-3 pb-3 pl-3 pr-3 mb-2 w-100 d-inline-block card-header" *ngFor="let survey of surveyList | paginate : {
      itemsPerPage: pageSize,
      currentPage: page,
      totalItems: count
     }; let i = index">


        <div class="accordion" id="accordionExample{{i}}">
          <div class="card">
            <div data-toggle="collapse" [attr.data-target]="'#collapseOne' + i" aria-expanded="false"
              class="floor-accordion collapsed">
              <li class="">{{survey.survey_name}}</li>
              <li class="">{{survey.survey_line_address}}</li>
              <li class="">{{survey.survey_date_f}}</li>
              <span class="accicon"><i aria-hidden="true" class="fa fa-chevron-down arrow-down"></i></span>
            </div>
            <div id="collapseOne{{i}}" class="collapse" [attr.data-parent]="'#accordionExample' + i">
              <div class="card-body w-100 d-inline-block">
                <!-- <h6 class="border-bottom pt-3 pb-2">Surveys already imported</h6> -->
                <div class="heading-box-sec">
                  <li class="font-weight-bold text-uppercase">survey name</li>
                  <li class="font-weight-bold text-uppercase">surveyor name</li>
                  <li class="font-weight-bold text-uppercase">survey Uploaded Date</li>
                  <li class="font-weight-bold text-uppercase last-li">Action</li>
                </div>
                <div class="w-100 d-inline-block even-odd-bg"
                  *ngFor="let surveyImport of survey.survey_imports_available">
                  <ng-container>
                    <li>{{surveyImport.survey_name}}</li>
                    <li>{{surveyImport.surveyor_full_name}}</li>
                    <li>{{surveyImport.survey_uploaded_f}}</li>
                    <li class="last-li">
                      <!--
                      <a *ngIf="surveyImport.report_id!=null"
                        routerLink="/survey-details/{{surveyImport.survey_id}}/{{surveyImport.report_id!=null ? surveyImport.report_id : '0'}}"
                        [queryParams]="{tid: 'overview',type: 'from'}">
                        <span class="eys-icon"><img src="../assets/img/eyes.png"
                            *ngIf="surveyImport.report_id!=null"></span>
                      </a>
                      -->
                      <span class="copy-btn" *ngIf="surveyImport.report_id!=null"
                        (click)="copySurvey(surveyImport.survey_id, surveyImport.report_id, surveyImport);">
                        <i class="fa fa-clone" aria-hidden="true"></i>
                      </span>
                      <a *ngIf="surveyImport.report_id==null"
                        routerLink="/survey-details/{{surveyImport.survey_id}}/{{surveyImport.report_id!=null ? surveyImport.report_id : '0'}}"
                        [queryParams]="{tid: 'overview',type: 'from'}">
                        <img src="../assets/img/pluseroom.svg"
                          *ngIf="surveyImport.report_id==null || surveyImport.report_id==''">
                      </a>

                    </li>
                  </ng-container>
                  <!-- <ng-container *ngIf="survey.survey_imports_available.length<=1" >
                  <li class="w-100 text-center justify-content-center">No surveys imported</li>                 
                </ng-container>              -->

                </div>
              </div>
            </div>
          </div>
        </div>

      </ul>
      <div class="row pagination mt-3" *ngIf="surveyList?.length > 0">
        <div class="col-md-8">
          <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
            (pageChange)="handlePageChange($event)"></pagination-controls>
        </div>
        <div class="col-md-4">
          <div class="items float-right">
            Items per Page:
            <select (change)="handlePageSizeChange($event)">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade show active" id="your-surveys" role="tabpanel">
      <div class="tab-pane fade show active" id="survey-imported" role="tabpanel">

        <!--
      <ul class="list_survey pt-0 pb-0 pl-3 pr-3">
        <li class="font-weight-bold w-50">Survey Name</li>
        <li class="font-weight-bold">Survey Time</li>
        <li class="font-weight-bold">&nbsp;</li>
      </ul>
    -->

        <ul class="inner_list pl-0 mb-2 bg-white w-100 d-inline-block" *ngIf="messageLocalSurvey!=''">
          <li class="w-100 text-center list-unstyled" *ngIf="loader">Loading Data...</li>
          <li class="w-100 text-center list-unstyled alert alert-danger" *ngIf="messageLocalSurvey!=''">{{message}}</li>
        </ul>

        <p class="text-center mt-4 w-100 d-inline-block"
          *ngIf="!(ImportSurveyListLocal?.length > 0) && !loader && messageLocalSurvey==''">
          There are no surveys on this device!<br><small>You can create a new survey or import an existing survey from the CRM. Surveys saved this device will be shown here.</small>
        </p>

        <!--
      <ul class="list_survey inner_list pt-3 pb-3 pl-3 pr-3 mb-2 w-100 d-inline-block" *ngFor="let surveyLocal of ImportSurveyListLocal | paginate : {
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
       }; let i = index">
        <li class="w-50">{{surveyLocal.data.survey_name}}</li>
        <li class="">{{surveyLocal.data.survey_created_time}}</li>
        <li class="last-li">
          <a routerLink="/survey-details/{{surveyLocal.survey_id}}/{{surveyLocal.report_id}}" [queryParams]="{tid: 'overview',type: 'from'}">
            <span class="eys-icon open-according"><img src="../assets/img/eyes.png"></span>
          </a>
          <span (click)="deleteSurveyLocal(surveyLocal.survey_id, surveyLocal.report_id, surveyLocal.survey_format_version, surveyLocal.surveyor_id)">
            <i class="fas fa-trash"></i>
          </span>
        </li>
      </ul>
      -->
        <div class="w-100 d-inline-block mb-2">
          <div class="table-responsive" *ngIf="ImportSurveyListLocal?.length > 0">
            <table class="table table-striped w-100">
              <thead>
                <tr>
                  <th class="w-50">Survey Name</th>
                  <th class="w-40">Survey Time</th>
                  <th class="w-10">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let surveyLocal of ImportSurveyListLocal | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: count
           }; let i = index">
                  <td routerLink="/survey-details/{{surveyLocal.survey_id}}/{{surveyLocal.report_id}}" [queryParams]="{tid: 'overview',type: 'from'}" style="cursor:pointer">{{surveyLocal.data.survey_name}}</td>
                  <td routerLink="/survey-details/{{surveyLocal.survey_id}}/{{surveyLocal.report_id}}" [queryParams]="{tid: 'overview',type: 'from'}" style="cursor:pointer">{{surveyLocal.data.survey_created_time | date :'EE. MMM. d, y, h:mm a'}}</td>
                  <td>
                    <a routerLink="/survey-details/{{surveyLocal.survey_id}}/{{surveyLocal.report_id}}"
                      [queryParams]="{tid: 'overview',type: 'from'}">
                      <i class="fas fa-edit mr-3 h4"></i>
                    </a>
                    <span
                      (click)="deleteSurveyLocal(surveyLocal.survey_id, surveyLocal.report_id, surveyLocal.survey_format_version, surveyLocal.surveyor_id)">
                      <i class="fas fa-trash h4" style="cursor:pointer"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <!-- <div class="row pagination mt-3" *ngIf="ImportSurveyListLocal?.length > 0">
      <div class="col-md-8">
        <pagination-controls
          previousLabel="Prev"
          nextLabel="Next"
          [responsive]="true"
          (pageChange)="handlePageChangeLocal($event)"
        ></pagination-controls>
      </div>
      <div class="col-md-4">
        <div class="items float-right">
        Items per Page:
        <select (change)="handlePageSizeChangeLocal($event)">
          <option *ngFor="let size of pageSizes" [ngValue]="size">
            {{ size }}
          </option>
        </select>
        </div>
      </div>
    </div> -->
    </div>

  </div>


  <!-- tabs custom end -->




</div>


<ng-template #newSurveyModal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form (ngSubmit)="onSubmitNewSurvey(newSurveyForm)" #newSurveyForm="ngForm">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="survey_name">Survey Name</label>
            <input type="text" class="form-control mb-0" placeholder="Survey Name" autocomplete="off" name="survey_name"
              [ngModel]="" #survey_name="ngModel" required>

            <div class="text-danger" *ngIf="!survey_name.value && submittedNewSurvey">
              Survey Name is Required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleInputSurvey">Survey Date</label>
            <input type="hidden" value="{{todayDateSurvey}}" name="survey_date" [(ngModel)]="todayDateSurvey"
              #survey_date="ngModel" />
            <div class="made-calender-icon">
              <input class="form-control" placeholder="yyyy-mm-dd" name="survey_datePicker" value="{{todayDateSurvey}}"
                ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateChangeNewSurvey($event)" (click)="d.toggle()"
                autocomplete="off">
              <span class="date-span" (click)="d.toggle()"><img src="../assets/img/calendar.png" alt="calender"
                  style="max-width:23px;"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
      </div>

    </form>
  </div>

</ng-template>