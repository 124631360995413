
<div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

<div class="form-group files alert alert-danger text-center" *ngIf="(ImportSurveyList.notes?.length <= 0) && !loader">
  No Notes Available
</div>

<div *ngIf="(ImportSurveyList.notes?.length > 0)">
  <div class="navbar justify-content-between notes p-4 mb-3 rounded notes"
    *ngFor="let note of ImportSurveyList.notes;let noteRow=index;">
    <form novalidate="" class="form-inline ng-untouched ng-pristine ng-valid justify-content-between w-100">
    <h6><b>{{note.note_time}}</b></h6>
   <div class="notes-icon">
      <!-- <img src="../assets/img/notesrefresh.svg"> -->
      <img src="../assets/img/edit-icon.png" class="btn" (click)="open(Notesmodal); setEditNoteValue(noteRow,note.note)" >
      <img src="../assets/img/notesdelet.png" class="btn" (click)="deleteNote(noteRow)">
    </div>
    <p class="w-100 mt-2">{{note.note}} </p>
  </form>
  </div>
</div>


<ng-template #Notesmodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    
    <form [formGroup]="NotesForm" (ngSubmit)="editNote()">
      <input type="hidden" value="{{noteIndex}}" formControlName="noteIndex" />
      <label for="exampleInputEmail1">Note Details</label>
      <textarea class="form-control mb-0 border" rows="5" placeholder="Write Note..." formControlName="note"> </textarea>
      <div class="text-danger"
        *ngIf="(NotesFormControl.note.touched || submittedNotes) && NotesFormControl.note.errors?.required">
        Note is required
      </div>
      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
      </div>
    </form>
  </div>
  
</ng-template>