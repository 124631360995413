import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {  
  constructor(private authService: AuthService, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return new Promise(res => {
      this.authService.isValidLogin.subscribe(
          (data) => {            
            if (data.status === true && this.authService.isLoggedIn) {
                $('body').removeClass('without-login');
                res(true);
            } else {
              localStorage.removeItem('token');
              this.router.navigate(['/login']);
              $('body').addClass('without-login');
              res(false);
            }
          },
          (error) => {
            // localStorage.removeItem('token');
            // this.router.navigate(['/login']);
            // $('body').addClass('without-login');
            $('body').removeClass('without-login');
            res(true);
          }
      );
    });
    
  }
  
  
}
