<section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
  <nav class="mb-4 nav-pills">
    <div class="nav nav-tabs nav-pills nav-fill border-0" id="nav-tab" role="tablist">
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'from' ? 'active' : ''}}" id="nav-home-tab"
        data-toggle="tab" href="#nav-from-access" role="tab" aria-controls="nav-Info" aria-selected="true"
        (click)="tabTypeClickFunction('from');">From</a>
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'to' ? 'active' : ''}}" id="nav-profile-tab"
        data-toggle="tab" href="#nav-to-access" role="tab" aria-controls="nav-Parking" aria-selected="false"
        (click)="tabTypeClickFunction('to');">To</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

    <div class="tab-pane fade {{currentTabTypeid == 'from' ? 'show active' : ''}}" id="nav-from-access" role="tabpanel"
      aria-labelledby="nav-home-tab" *ngIf="ImportSurveyList.property?.from">

      

    <form [formGroup]="accessParkingForm" (ngSubmit)="onSubmitAccess()">
      <div class="access_prking12">
        <h6><b>Is there a driveway?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway" [value]="true" [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway" [value]="false" [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Select driveway type</b></h6>
        <div class="form-check form-check-inline w-25 access_prking-select">
          <select class="form-control form-control-custom" formControlName="driveway_type">
            <option value="">Select</option>
            <option value="brick">Brick</option>
            <option value="loose stones">Loose Stones</option>
            <option value="tarmac">Paved</option>
            <option value="tarmac">Tarmac</option>
            <option value="other">Other</option>
          </select>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Is the driveway shared?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway_shared" [value]="true"
            [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway_shared" [value]="false"
            [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Can we park directly outside the property?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_outside" [value]="true" [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_outside" [value]="false" [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Are there parking restrictions?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_restrictions" [value]="true"
            [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_restrictions" [value]="false"
            [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Are parking suspensions required?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_suspensions" [value]="true"
            [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_suspensions" [value]="false"
            [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <button type="submit" class="btn btn-primary mt-4 mb-4 text-white"><i class="far fa-save"></i>  Save Changes</button>
    </form>
  </div>

  <div class="tab-pane fade {{currentTabTypeid == 'to' ? 'show active' : ''}}" id="nav-to-access" role="tabpanel"
    aria-labelledby="nav-home-tab" *ngIf="ImportSurveyList.property?.to">
    <form [formGroup]="accessParkingFormTo" (ngSubmit)="onSubmitAccessTo()">
      <div class="access_prking12">
        <h6><b>Is there a driveway?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway" [value]="true" [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway" [value]="false" [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Select driveway type</b></h6>
        <div class="form-check form-check-inline w-25 access_prking-select">
          <select class="form-control form-control-custom" formControlName="driveway_type">
            <option value="">Select</option>
            <option value="brick">Brick</option>
            <option value="loose stones">Loose Stones</option>
            <option value="tarmac">Paved</option>
            <option value="tarmac">Tarmac</option>
            <option value="other">Other</option>
          </select>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Is the driveway shared?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway_shared" [value]="true"
            [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="driveway_shared" [value]="false"
            [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Can we park directly outside the property?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_outside" [value]="true" [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_outside" [value]="false" [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Are there parking restrictions?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_restrictions" [value]="true"
            [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_restrictions" [value]="false"
            [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <div class="access_prking12">
        <h6><b>Are there parking restrictions?</b></h6>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_suspensions" [value]="true"
            [checked]="true">
          <label class="form-check-label" for="inlineRadio1">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="park_suspensions" [value]="false"
            [checked]="false">
          <label class="form-check-label" for="inlineRadio2">No</label>
        </div>

        <hr>
      </div>
      <button type="submit" class="btn btn-primary mt-4 mb-4 text-white"><i class="far fa-save"></i>  Save Changes</button>
    </form>
  </div>
  </div>
</section>