import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule }   from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
import {ApiService} from "./services/api.service";
import { TokenInterceptor } from "./core/interceptor";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IndexedDBService } from './services/indexed-db.service';
import { DatePipe } from '@angular/common';
import { LightboxModule } from 'ngx-lightbox';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginCodeComponent } from './login-code/login-code.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SurveyInfoComponent } from './survey-info/survey-info.component';
import { HeaderInnerComponent } from './header-inner/header-inner.component';
import { SurveyImportListComponent } from './survey-import-list/survey-import-list.component';
import { PropertyInformationComponent } from './property-information/property-information.component';
import { SidebarSurveyComponent } from './sidebar-survey/sidebar-survey.component';
import { AccessParkingComponent } from './access-parking/access-parking.component';
import { NotesComponent } from './notes/notes.component';
import { UploadSurveyComponent } from './upload-survey/upload-survey.component';
import { GalleryComponent } from './gallery/gallery.component';
import { FloorComponent } from './floor/floor.component';
import { SettingComponent } from './setting/setting.component';
import { FloorNamesComponent } from './floor-names/floor-names.component';
import { FloorRoomsComponent } from './floor-rooms/floor-rooms.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { ReplacePipe } from './model/replace.pipe';
import { OverviewComponent } from './overview/overview.component';
import { BasementComponent } from './basement/basement.component';
import { LoftsComponent } from './lofts/lofts.component';
import { OtherAreasComponent } from './other-areas/other-areas.component';
import { PlanningComponent } from './planning/planning.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginCodeComponent,
    SidebarComponent,
    SurveyInfoComponent,
    HeaderInnerComponent,
    SurveyImportListComponent,
    PropertyInformationComponent,
    SidebarSurveyComponent,
    AccessParkingComponent,
    NotesComponent,
    UploadSurveyComponent,
    GalleryComponent,
    FloorComponent,
    SettingComponent,
    FloorNamesComponent,
    FloorRoomsComponent,
    SurveyDetailsComponent,
    ReplacePipe,
    OverviewComponent,
    BasementComponent,
    LoftsComponent,
    OtherAreasComponent,
    PlanningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule, 
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LightboxModule,
  ],
  providers: [ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,   
    },
    IndexedDBService,
    DatePipe
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
