import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingComponent } from './setting/setting.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';

const routes: Routes = [
  { path: '', component: LoginComponent, data: { title: 'Login' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password' } },  
  { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard' }, canActivate: [AuthGuard] },
  { path: 'setting', component: SettingComponent, data: { title: 'Settings' }, canActivate: [AuthGuard], canDeactivate:[CanDeactivateGuard]  },
  { path: 'survey-details', component: SurveyDetailsComponent, data: { title: 'Survey Details' }, canActivate: [AuthGuard]  },
  { path: 'survey-details/:sid', component: SurveyDetailsComponent, data: { title: 'Survey Details' }, canActivate: [AuthGuard]  },
  { path: 'survey-details/:sid/:rid', component: SurveyDetailsComponent, data: { title: 'Survey Details' }, canActivate: [AuthGuard], canDeactivate:[CanDeactivateGuard]},

];

@NgModule({
  providers:[AuthGuard,  CanDeactivateGuard],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
