<div class="login_account d-md-flex align-items-center justify-content-between">
  <div class="box-1 mt-md-0">
    <img src="../assets/img/login_left.svg">
    <div class="logo_overlay">
      <img src="../assets/img/logo_left.svg">
      <h4 class="text-center mt-3 mt-lg-5 mt-md-5"><b>Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is
          simply dummy</b> </h4>
    </div>
  </div>
  <div class=" box-2 d-flex flex-column">
    <img class="forgotten-pass" src="../assets/img/code.svg">
    <p class="h1 text-center font-weight-bold mb-4">Login Code </p>
    <h6 class="text-center mb-5">We have sent a login code to your mobile number, please enter that code below. <br>Your
      login code is valid for 5 minutes.</h6>
    <form>
      <div class="form-group user_name">
        <label for="exampleInputEmail1">Login Code</label>
        <img src="../assets/img/login-code.svg">
        <input type="text" class="form-control" id="username" placeholder="Login Code" autocomplete="off">
      </div>
      <button type="submit" class="btn btn-primary w-100 border-0 text-white font-weight-bold p-3">Save</button>
    </form>
  </div> <span class="fas fa-times"></span>
</div>