import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  message='';
  error_message='';  
  invalidLogin: boolean = false;
  submitted = false;
  forgotPasswordForm=  new FormGroup({
    email : new FormControl()
  }); 
  
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    if (window.localStorage.getItem('token')) {      
      this.router.navigate(['dashboard']);             
    }else{
      window.localStorage.removeItem('token');
    }

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]        
    });


  }

  get forgotPasswordFormControl() {
    return this.forgotPasswordForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    const passwordPayload = {
      email: this.forgotPasswordForm.controls.email.value,
      step: '1'      
    }

    this.apiService.forgotPassword(passwordPayload).subscribe(data => {
      //debugger;
      console.log(data);
      if(data.status === true) {        
        this.message=data.message;
        //this.forgotPasswordForm.reset();
      }else {
        this.invalidLogin = true;
        this.message=data.message;
      }
      
    },
    error => {      
      if(error.status==400){
        console.log(error.error.message);
        this.error_message=error.error.message;
      }
    });

    

  }


}
