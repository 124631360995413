import { Component, OnInit,ViewChild, TemplateRef, HostListener } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../model/event-emitter.service'; 
import { IndexedDBService } from '../services/indexed-db.service';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { DialogService } from '../services/dialog.service';

import { OverviewComponent } from '../overview/overview.component';
import { PropertyInformationComponent } from '../property-information/property-information.component';
import { AccessParkingComponent } from '../access-parking/access-parking.component';
import { PlanningComponent } from '../planning/planning.component';
import { BasementComponent } from '../basement/basement.component';
import { LoftsComponent } from '../lofts/lofts.component';
import { FloorComponent } from '../floor/floor.component';
import { OtherAreasComponent } from '../other-areas/other-areas.component';
import { GalleryComponent } from '../gallery/gallery.component';

import 'lodash';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';


declare var $: any;
declare var _:any;

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.css']
})
export class SurveyDetailsComponent implements OnInit {
  @ViewChild(BasementComponent)
  BasementComponent!: BasementComponent;
  @ViewChild(LoftsComponent)
  LoftsComponent!: LoftsComponent; 
  @ViewChild(OtherAreasComponent)
  OtherAreasComponent!: OtherAreasComponent; 
  @ViewChild(FloorComponent)
  FloorComponent!: FloorComponent; 
  @ViewChild(OverviewComponent)
  OverviewComponent!: OverviewComponent;
  @ViewChild(PropertyInformationComponent)
  PropertyInformationComponent!: PropertyInformationComponent;
  @ViewChild(AccessParkingComponent)
  AccessParkingComponent!: AccessParkingComponent;
  @ViewChild(PlanningComponent)
  PlanningComponent!: PlanningComponent;
  @ViewChild(GalleryComponent)
  GalleryComponent!: GalleryComponent;
  isFormChanged=false;
  @ViewChild('surveyUploadModal', {read: TemplateRef}) surveyUploadModal: TemplateRef<any> | undefined;
  surveyId: any;
  reportId!: any;
  ImportSurveyList: any;
  currentTabid: any;
  currentTabTypeid: any;
  message='';
  loader = false;
  closeResult!: string;
  userToken:any;
  itemList:any;
  categoryList:any;
  templateList:any;
  templateDetails:any;
  keyword = 'item_name';
  templateName = 'template_name';
  isLoadingResult: boolean = false;  
  submitted = false;
  submittedTemplate = false;
  surveyData:any;
  totalUploadImages!: number;
  processImages!: number;
  processImagesPercentage: any;
  jsonUploadedStatus:any;
  isImageUpload=false;
  noImageFromUpload=false;
  uploadSurveyMessage=false;
  InventoryItemForm = new FormGroup({
    item_name : new FormControl(),
    item_id : new FormControl(),
    type_id : new FormControl(),
    item_weight : new FormControl(),
    item_carry_people : new FormControl(),    
    type_name : new FormControl(),
    item_show_dismantle : new FormControl(),
    item_dismantle_time : new FormControl(),    
    item_reassemble_time : new FormControl(),
    item_typical_value : new FormControl(),
    item_provides_storage : new FormControl(),    
    item_to_go : new FormControl(),
    item_pack_small : new FormControl(),
    item_pack_med : new FormControl(),    
    item_pack_large : new FormControl(),
    item_pack_layflat : new FormControl(),
    item_pack_wardrobe : new FormControl(),    
    item_pack_picturepack : new FormControl(),
    item_pack_golfbox : new FormControl(),
    item_pack_bikebox : new FormControl(),    
    item_pack_mattressbagsingle : new FormControl(),
    item_pack_mattressbagdouble : new FormControl(),
    item_pack_mattressbagking : new FormControl(),    
    item_pack_mattressbagsuperking : new FormControl(),
    item_pack_tvbox24 : new FormControl(),
    item_pack_tvbox32 : new FormControl(),    
    item_pack_tvbox36 : new FormControl(),
    item_pack_tvbox43 : new FormControl(),
    item_pack_tvbox48 : new FormControl(),    
    item_pack_tvbox55 : new FormControl(),
    item_pack_tvbox70 : new FormControl(),
    item_pack_tvbox85 : new FormControl(),    
    item_width_cm : new FormControl(),    
    item_height_cm : new FormControl(),    
    item_depth_cm : new FormControl(),    
    item_width_inch : new FormControl(),    
    item_height_inch : new FormControl(),    
    item_depth_inch : new FormControl(),    
    item_cube_cm : new FormControl(),    
    item_cube_ft : new FormControl(),    
    item_cube_dismantled_cm : new FormControl(),    
    item_cube_dismantled_ft : new FormControl(),    
    item_percentage_full : new FormControl()        
  });

  basementForm = new FormGroup({});
  addTemplateForm = new FormGroup({});

  listLevel0=['planning','basement','floor','lofts', 'groundfloor','firstfloor', 'Lounge', 'kitchen','area', 'garage', 'gardenback'];
  listLevel1=['floor','groundfloor','firstfloor', 'Lounge', 'kitchen'];
  listLevel2=['groundfloor','Lounge', 'kitchen'];
  listLevel3=['firstfloor'];
  listLevelOther=['area', 'garage', 'gardenback'];

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private _Activatedroute:ActivatedRoute, private modalService: NgbModal, private eventEmitterService: EventEmitterService, private IndexedDBService: IndexedDBService, public dialogService: DialogService) { 

    if (window.localStorage.getItem('token')) {
      this.userToken= window.localStorage.getItem('token');
    }
    
    this.IndexedDBService.connectToDB();
  }  
  
  ngOnInit(): void {
    
    this.loader = true;
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");

    if(this.surveyId=='' || this.surveyId==undefined){
      this.router.navigate(['dashboard']);
    }
    
    this.getImportSurveyDetails();

    this._Activatedroute.queryParams.subscribe(params => {         
      this.currentTabid = params.tid;               
      this.currentTabTypeid = params.type;               
    });  
    this.currentTabid= this.currentTabid!=undefined ? this.currentTabid: 'overview';
    this.currentTabTypeid= this.currentTabTypeid!=undefined ? this.currentTabTypeid: 'from';

    
    setTimeout(() => {
      this.tabClick(this.currentTabid)
        //this.canDeactivate();
        this.checkCurrentPlanningTab(this.currentTabid);
        this.checkCurrentFloorTab(this.currentTabid);
        this.checkCurrentGroundTab(this.currentTabid);
        this.checkCurrentFirstFloorTab(this.currentTabid);
        this.checkCurrentOtherTab(this.currentTabid);
    }, 2000);
    

    if (this.eventEmitterService.currentTabTypeid==undefined) {    
      this.eventEmitterService.currentTabTypeid = this.eventEmitterService.    
      invokeTabTypeClickFunction.subscribe((typeid:any) => {    
        this.tabTypeClick(typeid);    
      });    
    }

    // if (this.eventEmitterService.currentTabid==undefined) {    
    //   this.eventEmitterService.currentTabid = this.eventEmitterService.    
    //   invokeTabClickFunction.subscribe((tabId:any) => {    
    //     this.tabClick(tabId);    
    //   });    
    // }
    
    this.eventEmitterService.ImportSurveyList = this.eventEmitterService.
    invokeSurveyImportFunction.subscribe((surveyData:any) => { 
      this.setSurveyImportData(surveyData);   
     // this.ImportSurveyList =surveyData;    
    });

    this.addTemplateForm = this.formBuilder.group({  
      template_id : new FormControl(''),
      //survey_type : new FormControl('', Validators.required),
      template_name : new FormControl('', Validators.required)
    });

 
  }

  setSurveyImportData(surveyData:any){
    this.ImportSurveyList= surveyData;
  }
  public checkCurrentPlanningTab(tabId:any){
    return _.includes(this.listLevel0, tabId)
  }

  public checkCurrentFloorTab(tabId:any){
    return _.includes(this.listLevel1, tabId)
  }
  public checkCurrentGroundTab(tabId:any){
    return _.includes(this.listLevel2, tabId)
  }
  public checkCurrentFirstFloorTab(tabId:any){
    return _.includes(this.listLevel3, tabId)
  }
  public checkCurrentOtherTab(tabId:any){
    return _.includes(this.listLevelOther, tabId)
  }


  public tabTypeClick(typeId: any) {
    const queryParams = { type: typeId }; 
    this.router.navigate(
      [], 
      {
        relativeTo: this._Activatedroute,
        queryParams: queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }); 

    this.currentTabTypeid = typeId;
   
  }

  public checkFormValueStatus(status:any, tabId: any){
    if (status) {
      //this.dialogService.confirm('You have unsaved changes! If you leave, your changes will be lost.');
      var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          this.resetFormValueComponent();
          $('#accordionSidebar').removeClass("slideMin");
          $('.inner-tab-made li').removeClass("active-floor");
          $(".plan-content-section").removeClass("show");
          $(".floor-accordion").addClass("collapsed");
          
          var newTabid = tabId.replace(' ', '_');
          newTabid= newTabid.toLowerCase();
          if(newTabid!='basement' && newTabid!='floor' && newTabid!='area' && newTabid!='lofts'){
            $(".inner_menubar ").removeClass("show");     
          }
          const queryParams = { tid: newTabid, type:this.currentTabTypeid };    
          this.router.navigate(
            [], 
            {
              relativeTo: this._Activatedroute,
              queryParams: queryParams, 
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            }); 

          this.currentTabid = newTabid;
        }
    }else{
      $('#accordionSidebar').removeClass("slideMin");
      $('.inner-tab-made li').removeClass("active-floor");
      $(".plan-content-section").removeClass("show");
      $(".floor-accordion").addClass("collapsed");
      
      var newTabid = tabId.replace(' ', '_');
      newTabid= newTabid.toLowerCase();
      if(newTabid!='basement' && newTabid!='floor' && newTabid!='area' && newTabid!='lofts'){
        $(".inner_menubar ").removeClass("show");     
      }
      const queryParams = { tid: newTabid, type:this.currentTabTypeid };    
      this.router.navigate(
        [], 
        {
          relativeTo: this._Activatedroute,
          queryParams: queryParams, 
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        }); 

      this.currentTabid = newTabid;
    }
  }

  public tabClick(tabId: any) {
    
    if(this.currentTabid=='overview'){
      let formStatus= this.getOverviewFormStatus();
      this.checkFormValueStatus(formStatus, tabId);

    }else if(this.currentTabid=='propertyinfo'){
      let formStatus= this.getPropertyFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);

    }else if(this.currentTabid=='access'){
      let formStatus= this.getAccessFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);

    }else if(this.currentTabid=='planning'){
      let formStatus= this.getPlanningFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);

    }else if(this.currentTabid=='basement'){
      let formStatus= this.getBasementFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);

    }else if(this.currentTabid=='floor'){
      let formStatus= this.getRoomFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);

    }else if(this.currentTabid=='area'){
      let formStatus= this.getAreaFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);
     
    }else if(this.currentTabid=='lofts'){
      let formStatus= this.getLoftsFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);
     
    }else if(this.currentTabid=='gallery'){
      let formStatus= this.getGalleryFormStatus(); 
      this.checkFormValueStatus(formStatus, tabId);
     
    }else{
      this.checkFormValueStatus(false, tabId);
    }
   
    
  };


  getImportSurveyDetails(){
    // console.log(this.surveyId);
    // console.log(this.reportId);
    const params={
      survey_id:this.surveyId,
      report_id:this.reportId 
    }
    this.ImportSurveyList =[];
    this.apiService.getSurveyImportDetails(params).subscribe(data => {      
      if(data.status === true) { 
        if( data.data!==null){        
          
          let surveyPayload=[Number(this.surveyId), Number(data.data.surveyor_id), Number(data.data.survey_format_version), Number(this.reportId)];
          this.IndexedDBService.addSurveyDetailsLocal(data.data, this.reportId).then((response: any) => {                        
            if(response) {
              this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                
                this.ImportSurveyList= getResponse.data;
                
                setTimeout(()=>{            
                  if(this.currentTabid=='basement' || this.currentTabid=='floor' || this.currentTabid=='area' || this.currentTabid=='lofts'){
                    $(".inner_menubar ").addClass("show");  
                  }else{
                    $(".inner_menubar ").removeClass("show");
                  }
                }, 100);
                

              });
              this.loader = false;
            } 
          });          
                                    
        }else{
          const surveyPayload1={
            survey_id:Number(this.surveyId),
            report_id:Number(this.reportId)
          }
          this.IndexedDBService.getSurveyDetailsLocalApiError(surveyPayload1).then((getResponse: any) => { 
            console.log(getResponse.data);
            if(getResponse.data!=undefined){
              this.ImportSurveyList= getResponse.data;
            }else{
              this.ImportSurveyList =[]; 
              this.message='Survey not found in local storage.';
            }               
              
            this.loader = false;
          });

          // this.ImportSurveyList =[];  
          // this.message=data.message; 
          this.loader = false;       
        }               
      }else {
        const surveyPayload1={
          survey_id:Number(this.surveyId),
          report_id:Number(this.reportId)
        }
        this.IndexedDBService.getSurveyDetailsLocalApiError(surveyPayload1).then((getResponse: any) => { 
          console.log(getResponse.data);
          if(getResponse.data!=undefined){
            this.ImportSurveyList= getResponse.data;
          }else{
            this.ImportSurveyList =[]; 
            this.message='Survey not found in local storage.';
          }               
            
          this.loader = false;
        });
        this.loader = false;      
      } 
      
    },
    error => {
      console.log(error); 
     // this.message=error.statusText;
      const surveyPayload1={
        survey_id:Number(this.surveyId),
        report_id:Number(this.reportId)
      }
      //let surveyPayload=[Number(this.surveyId), Number(this.reportId)];
      //get data from indexedDB when api failed
      this.IndexedDBService.getSurveyDetailsLocalApiError(surveyPayload1).then((getResponse: any) => { 
        console.log(getResponse.data);
        if(getResponse.data!=undefined){
          this.ImportSurveyList= getResponse.data;
        }else{
          this.message='Survey not found in local storage.';
        }               
          
        this.loader = false;
      });          
    });
  }


  openFloorModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',
   
   }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
      this.addTemplateForm.markAsUntouched();
      this.submitted=false;      
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }



  resetTemplateList(){    
    this.templateList=[];
  }

  get addTemplateFormControl() {
    return this.addTemplateForm.controls;
  }

  onChangeSearchTemplate(str: string){
    this.isLoadingResult = true;    
    this.IndexedDBService.getTemplateList()
    .then((data:any) => {       
      if(data!=undefined){
        this.templateList = data;
        this.isLoadingResult = false;
      }else{         
        // call if data not available in indexedDB
        this.apiService.getTemplateList().subscribe(  
          (res)=>{         
          console.log(res); 
          this.IndexedDBService
          .addTemplateList(res.data)
          //.then()  
          this.templateList = res.data; 
          this.isLoadingResult = false;            
        },
        (err)=>{  
          console.log("fail");      
        });
      }       
      
    });      
   
  }  

  selectEventTemplate(item: any) { 
    this.addTemplateForm.patchValue({
      template_id: item.template_id       
    });  
    
  }
  
  onFocusedTemplate(e:any){ 
    this.onChangeSearchTemplate(e);   
  }

  onClearedTemplate(e:any) {
    console.log("onClearedTemplate");
    //this.itemFormClear();
  }


  onSubmitTemplateForm() {
    this.submitted=true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    
    if (this.addTemplateForm.valid) {
      this.submittedTemplate = true;
      const templateDetailsPayload = {
        template_id : this.addTemplateForm.controls.template_id.value                          
      }
      this.apiService.getTemplateDetails(templateDetailsPayload).subscribe(  
        (res)=>{         
        this.templateDetails = res.data;
        if(res.data!=null){
          const templatePayload = {
            plan : res.data.plan                           
          }
          this.IndexedDBService.addTemplateData(templatePayload, surveyPayload, 'from').then((response: any) => {                   
            if(response) {
              this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
                console.log(getResponse);                     
                this.eventEmitterService.surveyImportData(getResponse.data);          
                this.modalService.dismissAll('Dismissed modal box after saving data');
                //this.addTemplateForm.reset();
                this.addTemplateForm.patchValue({
                  template_id: '',
                  //survey_type: '',
                  template_name: '',
                }); 
                this.addTemplateForm.markAsUntouched();
                this.submittedTemplate=false;           
                Swal.fire('Added', 'Template added succesfully!', 'success')
              });          
            }else{
              Swal.fire('Error', 'Template Not import', 'error');
              this.submittedTemplate=false;  
            } 
          });  
        }else{
          Swal.fire('Error', 'Template Details are not found', 'error');
          this.submittedTemplate=false;  
        }
      },
      (err)=>{  
        console.log("fail");
        this.submittedTemplate=false;        
      });
    }   
  }


  
  uploadSurey(){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure want to upload?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, upload!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.uploadSurveyMessage=true;
        let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
        
        this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                   
          
          if(getResponse){        
            this.surveyData= getResponse.data;
          // delete (this.surveyData.images); 
          
          /** removed image key from survey data */
            const {images, ...uploadSurveyJson} = this.surveyData;
            let uploadImagesData= this.surveyData.images; 
            this.processImages=0;    
            this.processImagesPercentage=0;            
            this.processImages=0; 
            this.processImagesPercentage=0;
           
            this.apiService.uploadSurveyData(uploadSurveyJson).subscribe(  
              (res)=>{ 
                console.log(res);
                if(res.data){
                  let uploadId=res.data.upload_id;
                  let step=2;                  
                  this.jsonUploadedStatus= res.message;
                  this.uploadSurveyMessage=false;
                  this.modalService.open(this.surveyUploadModal);
                  //Swal.fire('Success', res.message+"<br/>Please wait Images is being uploaded", 'success');
                  if(uploadImagesData){
                    this.isImageUpload=true;
                    this.totalUploadImages=uploadImagesData.length;
                    this.processImages=Number(this.processImages)+1;
                    uploadImagesData.forEach((value: string, key: number) => {
                      
                      let imagePayload = {
                        image_number : key+1,
                        step : step,                          
                        upload_id : uploadId,                          
                        image : uploadImagesData[key].image,                          
                        image_time : uploadImagesData[key].image_time,                          
                        image_note : uploadImagesData[key].image_note,                          
                      }
                      this.apiService.uploadSurveyImages(imagePayload).subscribe(  
                        (imageResponse)=>{
                          if(imageResponse.status==true){ 
                            this.processImagesPercentage= (this.processImages*100)/this.totalUploadImages;                              
                            if(this.processImages==this.totalUploadImages){
                              this.jsonUploadedStatus= imageResponse.message; 
                              this.isImageUpload=false;                             
                            }else{
                              this.processImages=Number(this.processImages)+1;
                            }                        
                          }else{
                            this.modalService.dismissAll('Dismissed modal box after saving data');
                            Swal.fire('Error', "Error in image upload", 'error');
                          }
                        }, 
                        (error)=>{  
                          console.log(error);
                          Swal.fire('Error', error.message, 'error');      
                      });
                    });
                
                  }else{
                    //this.modalService.dismissAll('Dismissed modal box after saving data');                     
                   // Swal.fire('Success', 'No Images for upload', 'info');
                    this.noImageFromUpload=true;
                  }
                }
              },
              (err)=>{  
                console.log(err);
                Swal.fire('Error', 'Survey not uploaded', 'error');      
            });
            
            
            //Swal.fire('Success', 'Survey uploaded successfully!', 'success');
          }else{
            Swal.fire('Error', 'Survey Data not available', 'error');
          }
          
        });

      }
    }); 
  }
  
  sidebarTogggle(){
    $("#accordionSidebar").toggleClass("slideMin");
  }

  sidebarSubMenu(tabId:any, event:any){    
   
    $('.inner-tab-made li').removeClass("active-floor");    
    $(".plan-content-section").removeClass("show");
    $(".room-content-section").removeClass("show");
    $(".floor-accordion").addClass("collapsed");
    $('a[aria-controls = '+tabId+']').removeClass('collapsed');
    event.target.classList.add('active-floor');
    $("#"+tabId).addClass("show");
  }

  sidebarSubMenuRoom(floorRow:any, roomRow:any, tabId:any){   
   
    $('.inner-tab-made li').removeClass("active-floor");
    setTimeout(() => {
      $(".room-content-section").removeClass("show");    
      $("#"+tabId).addClass("show");
      $(".room-accordion").addClass("collapsed");
      $('a[aria-controls = '+tabId+']').removeClass('collapsed');
      $('li[floor-row = floor_menu_'+floorRow+']').addClass('active-floor');
    }, 50); 
    
  }


  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {
    let formStatus= this.getBasementFormStatus();
    let formStatusOverview= this.getOverviewFormStatus();
    let formStatusProperty= this.getPropertyFormStatus();
    let formStatusAccess= this.getAccessFormStatus();
    let formStatusRoom= this.getRoomFormStatus();
    let formStatusArea= this.getAreaFormStatus();
    let formStatusLofts= this.getLoftsFormStatus();
    let formStatusGallery= this.getGalleryFormStatus();
    let formStatusPlanning= this.getPlanningFormStatus();
      if (formStatus || formStatusOverview || formStatusProperty || formStatusAccess || formStatusRoom || formStatusArea || formStatusLofts || formStatusGallery || formStatusPlanning ) {
          $event.returnValue = true;
      }
  }
  canDeactivate(): Observable<boolean> | boolean {   
    let formStatus= this.getBasementFormStatus();
    let formStatusOverview= this.getOverviewFormStatus();
    let formStatusProperty= this.getPropertyFormStatus();
    let formStatusAccess= this.getAccessFormStatus();
    let formStatusRoom= this.getRoomFormStatus();
    let formStatusArea= this.getAreaFormStatus();
    let formStatusLofts= this.getLoftsFormStatus();
    let formStatusGallery= this.getGalleryFormStatus();
    let formStatusPlanning= this.getPlanningFormStatus();
    if (formStatus || formStatusOverview || formStatusProperty || formStatusAccess || formStatusRoom || formStatusArea || formStatusLofts || formStatusGallery || formStatusPlanning ) {
        return this.dialogService.confirm('You have unsaved changes! If you leave, your changes will be lost.');
    }
    return true;
  }

  getBasementFormStatus(){
    this.isFormChanged=false;
    let formList= this.BasementComponent?.basementUpdateForm;   
    let formListInventory= this.BasementComponent?.basementInventoryUpdateForm; 
    
    if(formList){
      formList.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }

    if(formListInventory){
      formListInventory.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }
    return this.isFormChanged;
  }  
  
  getOverviewFormStatus(){
    this.isFormChanged=false;       
    let formOverview= this.OverviewComponent?.overviewUpdateForm; 
    if(formOverview){
      formOverview.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }
    return this.isFormChanged;
  }
 
 getPropertyFormStatus(){
    this.isFormChanged=false;
    let formPropertyFrom= this.PropertyInformationComponent?.propertyInfoForm;
    let formPropertyTo= this.PropertyInformationComponent?.propertyInfoFormTo; 
    
    if(typeof(formPropertyFrom) != "undefined" && formPropertyFrom.pristine==false){
      this.isFormChanged=true;
    }

    if(typeof(formPropertyTo) != "undefined" && formPropertyTo.pristine==false){
      this.isFormChanged=true;
    }
    return this.isFormChanged;
  }
 
 getAccessFormStatus(){
    this.isFormChanged=false;
    let formAccessFrom= this.AccessParkingComponent?.accessParkingForm;
    let formAccessTo= this.AccessParkingComponent?.accessParkingFormTo;
    
    if(typeof(formAccessFrom) != "undefined" && formAccessFrom.pristine==false){
      this.isFormChanged=true;
    }

    if(typeof(formAccessTo) != "undefined" && formAccessTo.pristine==false){
      this.isFormChanged=true;
    }
    return this.isFormChanged;
  }
 
 getRoomFormStatus(){
    this.isFormChanged=false;
    let formListRoom= this.FloorComponent?.roomUpdateForm;   
    let formListRoomInventory= this.FloorComponent?.roomInventoryUpdateForm;   
    
    if(formListRoom){
      formListRoom.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }

    if(formListRoomInventory){
      formListRoomInventory.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }
    return this.isFormChanged;
  }
 
 getAreaFormStatus(){
    this.isFormChanged=false;       
    let formListArea= this.OtherAreasComponent?.areaUpdateForm;   
    let formListAreaInventory= this.OtherAreasComponent?.areaInventoryUpdateForm;   
    
    if(formListArea){
      formListArea.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }

    if(formListAreaInventory){
      formListAreaInventory.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );  
    }  
    return this.isFormChanged;
  }
 getLoftsFormStatus(){
    this.isFormChanged=false;
    let formListLoft= this.LoftsComponent?.loftUpdateForm;   
    let formListLoftInventory= this.LoftsComponent?.loftInventoryUpdateForm;  

    if(formListLoft){
      formListLoft.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }
    if(formListLoftInventory){
      formListLoftInventory.toArray().forEach(
        (item) => {
          if(item.form.pristine==false){
            this.isFormChanged=true;
          }
        }
      );
    }
    return this.isFormChanged;
  }
  
 getGalleryFormStatus(){
    this.isFormChanged=false;
    let formGallery= this.GalleryComponent?.uploadForm;        
    
    if(typeof(formGallery) != "undefined" &&  formGallery.pristine==false){
      this.isFormChanged=true;
    }    
    return this.isFormChanged;
  }
 getPlanningFormStatus(){
    this.isFormChanged=false;    
    let formPlanningBasement= this.PlanningComponent?.basementForm;
    let formPlanningLoft= this.PlanningComponent?.areaFormFrom;
    let formPlanningFloor= this.PlanningComponent?.loftForm;
    let formPlanningArea= this.PlanningComponent?.floorForm;
    let formPlanningTemplate= this.PlanningComponent?.addTemplateForm;
	
    if(typeof(formPlanningBasement) != "undefined" &&  formPlanningBasement.pristine==false){
      this.isFormChanged=true;
    }
    if(typeof(formPlanningLoft) != "undefined" &&  formPlanningLoft.pristine==false){
      this.isFormChanged=true;
    }
    if(typeof(formPlanningFloor) != "undefined" &&  formPlanningFloor.pristine==false){
      this.isFormChanged=true;
    }
    if(typeof(formPlanningArea) != "undefined" &&  formPlanningArea.pristine==false){
      this.isFormChanged=true;
    }
    if(typeof(formPlanningTemplate) != "undefined" &&  formPlanningTemplate.pristine==false){
      this.isFormChanged=true;
    }   
    return this.isFormChanged;
  }
 
  resetFormValueComponent(){
    this.OverviewComponent.overviewUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );

    this.PropertyInformationComponent.propertyInfoForm.markAsPristine();
    this.PropertyInformationComponent.propertyInfoFormTo.markAsPristine();

    this.AccessParkingComponent.accessParkingForm.markAsPristine();
    this.AccessParkingComponent.accessParkingFormTo.markAsPristine();

    this.PlanningComponent.basementForm.markAsPristine();
    this.PlanningComponent.areaFormFrom.markAsPristine();
    this.PlanningComponent.loftForm.markAsPristine();
    this.PlanningComponent.floorForm.markAsPristine();
    this.PlanningComponent.addTemplateForm.markAsPristine();

    this.BasementComponent.basementUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );   
    this.BasementComponent.basementInventoryUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );
    
    this.FloorComponent.roomUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );   
    this.FloorComponent.roomInventoryUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );

    this.OtherAreasComponent.areaUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );   
    this.OtherAreasComponent.areaInventoryUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );

    this.LoftsComponent.loftUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    );   
    this.LoftsComponent.loftInventoryUpdateForm.toArray().forEach(
      (item) =>item.form.markAsPristine()
    ); 

    this.GalleryComponent.uploadForm.markAsPristine();

  }
  
}
