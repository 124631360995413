import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";
import { EventEmitterService } from '../model/event-emitter.service'; 
import { IndexedDBService } from '../services/indexed-db.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  @ViewChildren('overviewUpdateForm')
  public overviewUpdateForm!: QueryList<any>;
  surveyId: any;
  reportId!: any;
  @Input() ImportSurveyList: any;
  @Input() loader: any;
  date='';
  todayDate:any;
  submittedOverview = false;
  surveyDate:any;
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private apiService: ApiService, private _Activatedroute:ActivatedRoute, private eventEmitterService: EventEmitterService, private IndexedDBService: IndexedDBService, private datePipe: DatePipe) { 
    this.todayDate= new Date();
    this.todayDate = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    
  }
  
  ngOnInit(): void {
    
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");
    this.surveyDate= this.ImportSurveyList.survey_date!='' ? this.ImportSurveyList.survey_date : this.todayDate;
  }

  onDateChange(event: any){    
    if(event){      
      this.date= event['year']+'-'+event['month']+'-'+event['day'];  
      this.surveyDate= event['year']+'-'+event['month']+'-'+event['day']; 
      this.surveyDate= this.datePipe.transform(this.surveyDate, 'yyyy-MM-dd')
    }else{
      this.date='';
      this.surveyDate=this.ImportSurveyList.survey_date!='' ? this.ImportSurveyList.survey_date : this.todayDate;
    }    
  }


  onSubmitOverviewUpdate(overviewUpdateForm:NgForm) {
    this.submittedOverview = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];    
    if (overviewUpdateForm.valid) {      
     
      const overviewPayload = {
        survey_name : overviewUpdateForm.value.survey_name,    
        survey_date : overviewUpdateForm.value.survey_date,
        survey_time : overviewUpdateForm.value.survey_time,
        proposed_move_date : overviewUpdateForm.value.proposed_move_date,
        move_requires_storage : overviewUpdateForm.value.move_requires_storage,
        storage_requirements : overviewUpdateForm.value.storage_requirements,
        contact_primary:{
          title: overviewUpdateForm.value.contact_primary_title,
          first_name: overviewUpdateForm.value.first_name_p,
          last_name: overviewUpdateForm.value.last_name_p,
          tel: overviewUpdateForm.value.tel_p,
          mob: overviewUpdateForm.value.mob_p,
          email: overviewUpdateForm.value.email_p,
        },
        contact_secondary:{
          title: overviewUpdateForm.value.contact_secondary_title,
          first_name: overviewUpdateForm.value.first_name_s,
          last_name: overviewUpdateForm.value.last_name_s,
          tel: overviewUpdateForm.value.tel_s,
          mob: overviewUpdateForm.value.mob_s,
          email: overviewUpdateForm.value.email_s,
        }
      }
     
      this.IndexedDBService.updateOverviewFormData(overviewPayload, surveyPayload).then((response: any) => {                   
        if(response) {
          Swal.fire('Updated', 'Overview updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                     
            this.eventEmitterService.surveyImportData(getResponse.data);          
            this.submittedOverview=false; 
            overviewUpdateForm.form.markAsPristine();
                     
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });   
      
    }
   // this.invalidLogin = true;     
  }


}
