<app-header-inner [ImportSurveyList]="ImportSurveyList"></app-header-inner>
<ul id="accordionSidebar" class="sidebar pl-0" role="tablist">
  <button class="toggle-btn" (click)="sidebarTogggle()"><i class="fa fa-bars" aria-hidden="true"></i></button>
  <li class="sidebarscrool">
    <!---for empty data sidebar start-->
    <ul class="nav nav-tabs sidebar_nav navbar-nav bg-gradient-primary sidebar-dark accordion pt-3 menu-open"
      *ngIf="ImportSurveyList=='' && !loader">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/dashboard"><img src="../assets/img/Open.svg"><span>Dashboard</span></a>
      </li>    
      <hr class="sidebar-divider">

      <li class="nav-item" (click)="tabClick('overview');">
        <span class="nav-link active" role="tab" aria-controls="overview">
          <img src="../assets/img/property.svg"> 
          <span>Overview</span>
        </span>
      </li>
      <hr class="sidebar-divider">      
    </ul>

    <!---for empty data sidebar end-->
    <ul class="nav nav-tabs sidebar_nav navbar-nav bg-gradient-primary  sidebar-dark accordion pt-3 menu-open"
      *ngIf="ImportSurveyList!='' && !loader">

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/dashboard">
          <img src="../assets/img/Open.svg">
          <span>Dashboard</span></a>
      </li>    
      <hr class="sidebar-divider">
       
      <li class="nav-item" (click)="tabClick('overview');">
        <span class="nav-link {{currentTabid == 'overview' ? 'active' : ''}}"  role="tab" aria-controls="overview">
          <img src="../assets/img/global.svg"> 
          <span>Overview</span>
        </span>
      </li>
      <hr class="sidebar-divider">
      <li class="nav-item" (click)="tabClick('propertyinfo');">
        <span class="nav-link {{currentTabid == 'propertyinfo' ? 'active' : ''}}" role="tab" aria-controls="propertyinfo">
          <img src="../assets/img/property.svg"> 
          <span>Property Information</span>
      </span>
      </li>
      <hr class="sidebar-divider">
      <li class="nav-item" (click)="tabClick('access');">
        <span class="nav-link {{currentTabid == 'access' ? 'active' : ''}}" role="tab" aria-controls="access">
          <img src="../assets/img/parking.svg">
          <span>Access & Parking</span>
        </span>
      </li>
      <hr class="sidebar-divider">

      <li class="nav-item">
        <span class="nav-link {{ currentTabid=='planning'  ? 'active' : 'collapsed'}}" [attr.aria-expanded]="checkCurrentPlanningTab(currentTabid)" (click)="tabClick('planning');">
          <img src="../assets/img/floor.svg"> 
          <span>Floor Planning</span>
        </span>
        
        <hr class="sidebar-divider">
          <li class="nav-item">
            <span class="collapse-item w-100 d-inline-block nav-link {{currentTabid == 'basement' ? 'active' : ''}}"
            (click)="tabClick('basement');" role="tab" aria-controls="basement">            
              <img src="../assets/img/basement.png">
              <span>Basement</span>
            </span>
          <ul class="inner-tab-made basement_list" *ngIf="(ImportSurveyList.property?.from.plan.basements?.length > 0)">
            <li *ngFor="let basement of ImportSurveyList.property.from.plan.basements; let basementRow=index;" (click)="tabClick('basement');sidebarSubMenu('collapseBasement_fb_'+basementRow, $event);">{{ basement.basement_name }}</li>
          </ul>
        </li>
          <hr class="sidebar-divider">
          <li class="nav-item">
            <span class="nav-link collapse-item w-100 d-inline-block {{ checkCurrentFloorTab(currentTabid)==true  ? 'active' : ''}}" role="button" aria-controls="floor"
            (click)="tabClick('floor');">            
              <img src="../assets/img/floor.png">
              <span>Floor</span>
            </span>
          
            <ul class="inner-tab-made" *ngIf="(ImportSurveyList.property?.from.plan.floors?.length > 0)">
              <li class="floor_name_list{{floorRow}}" *ngFor="let floor of ImportSurveyList.property.from.plan.floors; let floorRow=index;" (click)="tabClick('floor');sidebarSubMenu('collapsegroundone'+floorRow, $event);" [attr.floor-row]="'floor_menu_'+floorRow">{{floor.floor_name}}

                <ul class="child-tab-room room_list" style="list-style:none;" *ngIf="(floor?.floor_rooms?.length > 0)">
                  <li class="mt-2 mb-0" *ngFor="let floorRoom of floor.floor_rooms; let roomRow=index;" (click)="sidebarSubMenuRoom(floorRow, roomRow, 'collapsegroundinner_f_'+floorRow+roomRow)">{{ floorRoom.room_name }}</li>
                </ul>

              </li>
            </ul>
          
          </li>
            <hr class="sidebar-divider">          

          <li class="nav-item">
            <span class="nav-link collapse-item w-100 d-inline-block {{ checkCurrentOtherTab(currentTabid)==true  ? 'active' : ''}}" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="area"
            (click)="tabClick('area');">            
              <img src="../assets/img/other-aria.png">
              <span>Other Areas</span> 
            </span>
            
            <ul class="inner-tab-made area_list" *ngIf="(ImportSurveyList.property?.from.plan.other_areas?.length > 0)">
              <li *ngFor="let area of ImportSurveyList.property.from.plan.other_areas; let areaRow=index;" (click)="tabClick('area');sidebarSubMenu('collapseArea_fa_'+areaRow, $event);">{{
                area.area_name }}</li>
            </ul>
          </li>
            <hr class="sidebar-divider">
         
          <li class="nav-item">
            <span class="collapse-item w-100 d-inline-block nav-link {{currentTabid == 'lofts' ? 'active' : ''}}"
            (click)="tabClick('lofts');" role="tab" aria-controls="lofts">
            <img src="../assets/img/loft.png">
            <span>Lofts</span>
            </span>
          <ul class="inner-tab-made loft_list" *ngIf="(ImportSurveyList.property?.from.plan.lofts?.length > 0)">
            <li *ngFor="let loft of ImportSurveyList.property.from.plan.lofts; let loftRow=index;" (click)="tabClick('lofts');sidebarSubMenu('collapseloft_fl_'+loftRow, $event);">{{
              loft.loft_name }}</li>
          </ul>


        </li>
        

      <hr class="sidebar-divider">
      <li class="nav-item" (click)="tabClick('gallery');">
        <span class="nav-link {{currentTabid == 'gallery' ? 'active' : ''}}" data-toggle="tab" href="#gallery" role="tab"
          aria-controls="Gallery">
          <img src="../assets/img/gallery.svg">
          <span>Gallery</span>
        </span>
      </li>

      <hr class="sidebar-divider">
      <li class="nav-item" (click)="tabClick('notes');">
        <span class="nav-link {{currentTabid == 'notes' ? 'active' : ''}}" role="tab"
          aria-controls="notes">
          <img src="../assets/img/notes.svg">
          <span>Notes</span>
        </span>
      </li>

      <hr class="sidebar-divider">
      <li class="nav-item">
        <span class="nav-link {{currentTabid == 'upload' ? 'active' : ''}}" (click)="uploadSurey()">
          <img src="../assets/img/uploadmenu.svg">
          <span>Upload</span>
        </span>
          
        <!-- <button class="btn btn-primary border-0 text-white font-weight-bold theme-bg" (click)="uploadSurey()">Upload Survey</button> -->
        <!-- <span class="text-danger d-block" *ngIf="uploadSurveyMessage">please wait...</span> -->
         
      </li>
    </ul>
  </li>

  <div class="menu_buttonclose w-100">
    <button class="btn w-100 border-0  rounded-0 text-white p-3 text-left" type="button">
      Collapsable Menu <img src="../assets/img/right-icon.svg">
    </button>
  </div>
</ul>
<!--   SideBar End-->

<!--   Tabs Inner Start-->
<div class="table_list">
  <div class="add-plan-box mb-4 mt-5 pt-4 d-flex justify-content-between align-content-center">
    <h6 class=""><u *ngIf="ImportSurveyList">{{ ImportSurveyList.survey_name }}</u></h6>
   
    
    <!-- <button class="btn btn-primary border-0 text-white float-right font-weight-bold theme-bg" (click)="openFloorModal(templatePlan)" *ngIf="ImportSurveyList.property?.from.plan.floors==null && ImportSurveyList.property?.from.plan.basements==null && ImportSurveyList.property?.from.plan.lofts==null && ImportSurveyList.property?.from.plan.other_areas==null">Floor Plan</button> -->
  </div>
  <div class="tab-content" *ngIf="ImportSurveyList=='' || ImportSurveyList==null">
    <div class="alert alert-danger" role="alert" *ngIf="message!=''">
      {{message}}
    </div>
  </div>
  <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>
  
  <div class="tab-content" *ngIf="(ImportSurveyList!='' || ImportSurveyList!=null) && message==''">
    <div class="tab-content">
      <div class="tab-pane {{currentTabid == 'overview' ? 'active' : ''}}" id="overview" role="tabpanel">        
        <app-overview [ImportSurveyList]="ImportSurveyList" [loader]="loader" *ngIf="ImportSurveyList.survey_id"></app-overview>
      </div>

      <div class="tab-pane {{currentTabid == 'propertyinfo' ? 'active' : ''}}" id="propertyinfo" role="tabpanel">
        <app-property-information [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-property-information>        
      </div>

      <div class="tab-pane {{currentTabid == 'floor' ? 'active' : ''}} ml-0" id="floor" role="tabpanel">        
        <app-floor [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-floor>
      </div>
    </div>

    <div class="tab-pane {{currentTabid == 'planning' ? 'active' : ''}}" id="planning" role="tabpanel">     
      <app-planning [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-planning>
    </div>

    <div class="tab-pane {{currentTabid == 'basement' ? 'active' : ''}}" id="basement" role="tabpanel">
      <app-basement [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-basement>
    </div>

    <div class="tab-pane {{currentTabid == 'access' ? 'active' : ''}}" id="access" role="tabpanel">
      <app-access-parking [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-access-parking>      
    </div>

    <div class="tab-pane {{currentTabid == 'groundfloor' ? 'active' : ''}}" id="Ground" role="tabpanel">Ground Floor
    </div>

    <div class="tab-pane {{currentTabid == 'Lounge' ? 'active' : ''}}" id="Lounge" role="tabpanel">
      <section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
        <nav class="mb-4">
          <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active border-0" id="nav-overview-tab" data-toggle="tab" href="#nav-from-floorroom"
              role="tab" aria-controls="nav-Info" aria-selected="true">From</a>
            <a class="nav-item nav-link border-0" id="nav-profile-tab" data-toggle="tab" href="#nav-to-floorroom"
              role="tab" aria-controls="nav-Parking" aria-selected="false">To</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-from-floorroom" role="tabpanel" aria-labelledby="nav-overview-tab"
            *ngIf="ImportSurveyList.property?.from">
            <form>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room Name</label>
                    <input type="text" class="form-control" placeholder="Room Name" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.add1 }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room Type</label>
                    <input type="text" class="form-control" placeholder="Room Type" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.add2 }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room Packing</label>
                    <input type="text" class="form-control" placeholder="Room Packing" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.add3 }}">
                  </div>
                  <label class="custom-check-mark">Room Packing
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                  

                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room ntg</label>
                    <div class="form-check-inline w-100">
                      <select class="form-control border" id="exampleFormControlSelect2">
                        <option>Select</option>
                        <option>To Go</option>
                        <option>Not To Go</option>
                        <option>Maybe To Go</option>
                        </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room Seen</label>
                    <input type="text" class="form-control" placeholder="Room Seen" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.postcode }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room Box Estimate</label>
                    <input type="text" class="form-control" placeholder="Room Box Estimate" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.country }}">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Room Light Fittings</label>
                    <input type="text" class="form-control" placeholder="Room Light Fittings" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.propert_type }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Move Curtains Blinds</label>
                    <input type="text" class="form-control" placeholder="Move Curtains Blinds" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.bedroom_count }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Move Carpets</label>
                    <input type="text" class="form-control" placeholder="Move Carpets" autocomplete="off"
                      value="{{ ImportSurveyList.property.from.property_floor }}">
                  </div>
                </div>
              </div>

              <div id="accordion" role="tablist" class="o-accordion">
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingOne">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 d-inline-block" data-toggle="collapse" data-parent="#accordion"
                        href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Packing Materials
                      </a>
                    </h5>
                  </div>

                  <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div class="card-block p-4">
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Small</label>
                            <input type="text" class="form-control" placeholder="Small" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Medium</label>
                            <input type="text" class="form-control" placeholder="Medium" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Large</label>
                            <input type="text" class="form-control" placeholder="Large" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">LayFlat</label>
                            <input type="text" class="form-control" placeholder="LayFlat" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Wardrobe</label>
                            <input type="text" class="form-control" placeholder="Wardrobe" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Picture Pack</label>
                            <input type="text" class="form-control" placeholder="Picture Pack" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Golf Box</label>
                            <input type="text" class="form-control" placeholder="Golf Box" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Bike Box</label>
                            <input type="text" class="form-control" placeholder="Bike Box" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Small</label>
                            <input type="text" class="form-control" placeholder="Mattress Bag Small" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Large</label>
                            <input type="text" class="form-control" placeholder="Mattress Bag Large" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Single</label>
                            <input type="text" class="form-control" placeholder="Mattress Bag Single" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Double</label>
                            <input type="text" class="form-control" placeholder="Mattress Bag Double" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag King</label>
                            <input type="text" class="form-control" placeholder="Mattress Bag King" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Mattress Bag Super King</label>
                            <input type="text" class="form-control" placeholder="Mattress Bag Super King"
                              autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 24</label>
                            <input type="text" class="form-control" placeholder="Tv Box 24" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 32</label>
                            <input type="text" class="form-control" placeholder="Tv Box 32" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 36</label>
                            <input type="text" class="form-control" placeholder="Tv Box 36" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 43</label>
                            <input type="text" class="form-control" placeholder="Tv Box 43" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 48</label>
                            <input type="text" class="form-control" placeholder="Tv Box 48" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 55</label>
                            <input type="text" class="form-control" placeholder="Tv Box 55" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 70</label>
                            <input type="text" class="form-control" placeholder="Tv Box 70" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleInputSurvey">Tv Box 85</label>
                            <input type="text" class="form-control" placeholder="Tv Box 85" autocomplete="off"
                              value="{{ ImportSurveyList.property.from.property_floor }}">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="card mt-2 mb-2">
                  <div class="card-header" role="tab" id="headingTwo">
                    <h5 class="mb-0">
                      <a class="collapsed w-100 d-inline-block" data-toggle="collapse" data-parent="#accordion"
                        href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Inventory
                      </a>
                    </h5>
                  </div>
                  <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">

                      <span class="w-100 d-inline-block"><button 
                          class="btn btn-primary m-3 border-0 text-white float-right font-weight-bold">Add</button></span>
                      <div class="card multi  mt-0 mb-3 ml-3 mr-3 ">
                        <div class="card-header" role="tab" id="headingOne-1">
                          <h5 class="mb-0">
                            <a class="w-100 d-inline-block" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseOneinnner" aria-expanded="true" aria-controls="collapseOneinnner">
                              Inventory 1
                            </a>
                          </h5>
                        </div>

                        <div id="collapseOneinnner" class="collapse" role="tabpanel" aria-labelledby="headingOne-1">
                          <div class="card-block p-4">
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Name</label>
                                  <input type="text" class="form-control" placeholder="Item Name" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Weight</label>
                                  <input type="text" class="form-control" placeholder="Item Weight" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Carry People</label>
                                  <input type="text" class="form-control" placeholder="Item Carry People"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group stateownerageent">
                                  <label for="exampleInputSurvey" class="w-100 mb-4">Item ShoW Dismantle</label>
                                  <div class="form-check-inline">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="item_shoW_dismantle"
                                        [checked]="ImportSurveyList.property.from.plan.floors ?.item_shoW_dismantle==true">Yes
                                    </label>
                                  </div>
                                  <div class="form-check-inline">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="item_shoW_dismantle"
                                        [checked]="ImportSurveyList.property.from.plan.floors ?.item_shoW_dismantle==false || ImportSurveyList.property.from.plan.floors ?.item_shoW_dismantle==null">No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Dismantle Time</label>
                                  <input type="text" class="form-control" placeholder="Item Dismantle Time"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Reassemble Time</label>
                                  <input type="text" class="form-control" placeholder="Item Reassemble Time"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Typical Value</label>
                                  <input type="text" class="form-control" placeholder="Item Typical Value"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Provides Storage</label>
                                  <input type="text" class="form-control" placeholder="Item Provides Storage"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item To Go</label>
                                  <input type="text" class="form-control" placeholder="Item To Go" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Small</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Small"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Medium</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Medium"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Mattress Bag Single</label>
                                  <input type="text" class="form-control" placeholder="Mattress Bag Single"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Large</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Large"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Layflat</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Layflat"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Wardrobe"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Picturepack</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Picturepack"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Golfbox</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Golfbox"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Bikebox</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Bikebox"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattress Bag single</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattress Bag single"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattress Bag Double</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattress Bag Double"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattress Bag king</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattress Bag king"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattress Bag Superking</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattress Bag Superking"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox24"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox32"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox36"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox43"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox55"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox70"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox85"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Type Name</label>
                                  <input type="text" class="form-control" placeholder="Type Name" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Width Cm</label>
                                  <input type="text" class="form-control" placeholder="Item Width Cm" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Height Cm</label>
                                  <input type="text" class="form-control" placeholder="Item Height Cm"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Depth Cm</label>
                                  <input type="text" class="form-control" placeholder="Item Depth Cm" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Width Inch</label>
                                  <input type="text" class="form-control" placeholder="Item Width Inch"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Height Inch</label>
                                  <input type="text" class="form-control" placeholder="Item Height Inch"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Depth Inch</label>
                                  <input type="text" class="form-control" placeholder="Item Depth Inch"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Cm</label>
                                  <input type="text" class="form-control" placeholder="Item Cube Cm" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Ft</label>
                                  <input type="text" class="form-control" placeholder="Item Cube Ft" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                                  <input type="text" class="form-control" placeholder="Item Cube Dismantled Cm"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                                  <input type="text" class="form-control" placeholder="Item Cube Dismantled Ft"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Percentage Full</label>
                                  <input type="text" class="form-control" placeholder="Item Percentage Full"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="card multi m-3">
                        <div class="card-header" role="tab" id="headingTwo-1">
                          <h5 class="mb-0">
                            <a class="collapsed w-100 d-inline-block" data-toggle="collapse" data-parent="#accordion"
                              href="#collapseTwo-1" aria-expanded="false" aria-controls="collapseTwo-1"> Inventory 2
                            </a>
                          </h5>
                        </div>
                        <div id="collapseTwo-1" class="collapse" role="tabpanel" aria-labelledby="headingTwo-1">
                          <div class="card-block p-4">
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Name</label>
                                  <input type="text" class="form-control" placeholder="Item Name" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Weight</label>
                                  <input type="text" class="form-control" placeholder="Item Weight" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Carry People</label>
                                  <input type="text" class="form-control" placeholder="Item Carry People"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group stateownerageent">
                                  <label for="exampleInputSurvey" class="w-100 mb-4">Item ShoW Dismantle</label>
                                  <div class="form-check-inline">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="item_shoW_dismantle"
                                        [checked]="ImportSurveyList.property.from.plan.floors ?.item_shoW_dismantle==true">Yes
                                    </label>
                                  </div>
                                  <div class="form-check-inline">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="item_shoW_dismantle"
                                        [checked]="ImportSurveyList.property.from.plan.floors ?.item_shoW_dismantle==false || ImportSurveyList.property.from.plan.floors ?.item_shoW_dismantle==null">No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Dismantle Time</label>
                                  <input type="text" class="form-control" placeholder="Item Dismantle Time"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Reassemble Time</label>
                                  <input type="text" class="form-control" placeholder="Item Reassemble Time"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Typical Value</label>
                                  <input type="text" class="form-control" placeholder="Item Typical Value"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Provides Storage</label>
                                  <input type="text" class="form-control" placeholder="Item Provides Storage"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item To Go</label>
                                  <input type="text" class="form-control" placeholder="Item To Go" autocomplete="off"
                                    value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Small</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Small"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Medium</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Medium"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Mattress Bag Single</label>
                                  <input type="text" class="form-control" placeholder="Mattress Bag Single"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Large</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Large"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Layflat</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Layflat"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Wardrobe</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Wardrobe"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Picturepack</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Picturepack"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Golfbox</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Golfbox"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Bikebox</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Bikebox"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>

                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattressbagsingle</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattressbagsingle"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattressbagdouble</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattressbagdouble"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattressbagking</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattressbagking"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Mattressbagsuperking</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Mattressbagsuperking"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox24</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox24"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox32</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox32"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox36</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox36"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox43</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox43"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox55</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox55"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox70</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox70"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Pack Tvbox85</label>
                                  <input type="text" class="form-control" placeholder="Item Pack Tvbox85"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Type Name</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Width Cm</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Height Cm</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Depth Cm</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Width Inch</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Height Inch</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Depth Inch</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Cm</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Ft</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Dismantled Cm</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Cube Dismantled Ft</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label for="exampleInputSurvey">Item Percentage Full</label>
                                  <input type="text" class="form-control" placeholder="Property Floor"
                                    autocomplete="off" value="{{ ImportSurveyList.property.from.property_floor }}">
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button 
                class="btn btn-primary mt-4  mb-4 border-0 text-white font-weight-bold">Save</button>
            </form>
          </div>

          <div class="tab-pane fade" id="nav-to-floorroom" role="tabpanel" aria-labelledby="nav-profile-tab"
            *ngIf="ImportSurveyList.property?.to">
            <form>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Address 1</label>
                    <input type="text" class="form-control" placeholder="Address 1" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.add1 }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Address 2</label>
                    <input type="text" class="form-control" placeholder="Address 2" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.add2 }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Address 3</label>
                    <input type="text" class="form-control" placeholder="Address 3" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.add3 }}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Address 4</label>
                    <input type="text" class="form-control" placeholder="Address 4" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.add4 }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Postcode</label>
                    <input type="text" class="form-control" placeholder="Postcode" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.postcode }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Country</label>
                    <input type="text" class="form-control" placeholder="Country" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.country }}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Property Type</label>
                    <input type="text" class="form-control" placeholder="Property Type" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.propert_type }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Bedroom Count</label>
                    <input type="text" class="form-control" placeholder="Bedroom Count" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.bedroom_count }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Property Floor</label>
                    <input type="text" class="form-control" placeholder="Property Floor" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.property_floor }}">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Ownership</label>
                    <input type="text" class="form-control" placeholder="Ownership" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.ownership }}">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group stateownerageent">
                    <label for="exampleInputSurvey" class="w-100 mb-4">Estate Agent</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="to_estate_agent"
                          [checked]="ImportSurveyList.property.to.estate_agent==true">Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="to_estate_agent"
                          [checked]="ImportSurveyList.property.to.estate_agent==false || ImportSurveyList.property.to.estate_agent==null">No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="exampleInputSurvey">Estate Agent Name</label>
                    <input type="text" class="form-control" placeholder="Property Floor" autocomplete="off"
                      value="{{ ImportSurveyList.property.to.property_floor }}">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group stateownerageent">
                    <label for="exampleInputSurvey" class="w-100 mb-4">New Build</label>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="to_new_build"
                          [checked]="ImportSurveyList.property.to.new_build==true">Yes
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="to_new_build"
                          [checked]="ImportSurveyList.property.to.new_build==false || ImportSurveyList.property.to.new_build==null">No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button 
                class="btn btn-primary mt-4  mb-4 border-0 text-white font-weight-bold">Save</button>
            </form>
          </div>
        </div>
      </section>
    </div>

    <div class="tab-pane {{currentTabid == 'kitchen' ? 'active' : ''}}" id="kitchen" role="tabpanel">Kitchen
    </div>

    <div class="tab-pane {{currentTabid == 'firstfloor' ? 'active' : ''}}" id="firstfloor" role="tabpanel"> First Floor
    </div>

    <div class="tab-pane {{currentTabid == 'area' ? 'active' : ''}}" id="area" role="tabpanel">
      <app-other-areas [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-other-areas>
    </div>

    <div class="tab-pane {{currentTabid == 'gardenblack' ? 'active' : ''}}" id="gardenblack" role="tabpanel"> Garden
      black
    </div>

    <div class="tab-pane {{currentTabid == 'garage' ? 'active' : ''}}" id="garage" role="tabpanel"> Garage
    </div>

    <div class="tab-pane {{currentTabid == 'lofts' ? 'active' : ''}}" id="lofts" role="tabpanel">
      <app-lofts [ImportSurveyList]="ImportSurveyList" [loader]="loader" [currentTabTypeid]="currentTabTypeid" *ngIf="ImportSurveyList.property"></app-lofts>
    </div>

    <div class="tab-pane {{currentTabid == 'gallery' ? 'active' : ''}}" id="gallery" role="tabpanel">
      <app-gallery [ImportSurveyList]="ImportSurveyList" [loader]="loader" *ngIf="ImportSurveyList.survey_id"></app-gallery>
    </div>

    <div class="tab-pane {{currentTabid == 'notes' ? 'active' : ''}}" id="notes" role="tabpanel">
      <app-notes [ImportSurveyList]="ImportSurveyList" [loader]="loader" *ngIf="ImportSurveyList.survey_id"></app-notes>
    </div>

    <!-- <div class="tab-pane {{currentTabid == 'upload' ? 'active' : ''}}" id="upload" role="tabpanel">
      <app-upload-survey [ImportSurveyList]="ImportSurveyList" [loader]="loader" *ngIf="ImportSurveyList.survey_id"></app-upload-survey>
    </div> -->

  </div>

 

  <!--add floor template modal box start-->
  <ng-template #templatePlan let-modal>
    <div class="modal-header border-0">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="addTemplateForm" (ngSubmit)="onSubmitTemplateForm()">
      <input type="hidden" value="" formControlName="template_id">
      <div class="modal-body survey_popup">
        <h6>Floor Plan</h6> 
        <!-- <div class="row">
          <div class="col-12">
            <div class="form-group"><label for="exampleInputSurvey">Survey Type</label>
              <select id="title_primary_contact" class="form-control form-control-custom" (change)='resetTemplateList()' formControlName="survey_type">              
                <option value="">Select</option>
                <option value="from">From</option>
                <option value="to">To</option>
              </select>
            </div>
            <div class="text-danger"
              *ngIf="(addTemplateFormControl.survey_type.touched || submitted) && addTemplateFormControl.survey_type.errors?.required">
              Survey type is required
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="exampleInputSurvey">Select Template</label>
              <ng-template #itemTemplateList let-item>
                <a [innerHTML]="item.template_name"></a>
              </ng-template>
              <ng-template #notFoundTemplateList let-notFoundTemplate> 
                <div [innerHTML]="notFoundTemplate"></div>
              </ng-template>
              <ng-autocomplete 
                [data]="templateList" 
                [searchKeyword]="templateName" 
                (inputChanged)='onChangeSearchTemplate($event)' 
                (selected)='selectEventTemplate($event)' 
                (inputCleared)="onClearedTemplate($event)"
                (inputFocused)="onFocusedTemplate($event)"
                [itemTemplate]="itemTemplateList" 
                [notFoundTemplate]="notFoundTemplateList" 
                placeholder="Template Name"
                [isLoading]="isLoadingResult"
                formControlName="template_name" 
                >
              </ng-autocomplete>
              <div class="text-danger"
                *ngIf="(addTemplateFormControl.template_name.touched || submitted) && addTemplateFormControl.template_name.errors?.required">
                Template name is required
              </div>
            </div>
          </div>        
        </div>
      </div>
      <div class="modal-footer border-0 sumit_popup">
        <span class="float-left alert-danger" *ngIf="submittedTemplate">please wait...</span>
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>        
      </div>
    </form>
  </ng-template>
  <!--add floor template modal box end-->

  <ng-template #surveyUploadModal let-modal>
    <div class="modal-header border-0">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body survey_popup"> 
      <div class="row">
        <div class="col-12 mt-2">
          <div class="alert alert-success" role="alert" *ngIf="jsonUploadedStatus!=''">
            {{jsonUploadedStatus}} <span *ngIf="isImageUpload">Please wait Images is being uploaded.</span>
          </div>
          <div class="alert alert-success" role="alert" *ngIf="noImageFromUpload">
            No images in survey for upload.
          </div>
          <div class="progress" *ngIf="isImageUpload">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{'width': processImagesPercentage+'%'}"  [attr.aria-valuenow]="processImagesPercentage" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <span class="text-center text-alert d-block" *ngIf="isImageUpload">uploading image {{processImages}} out of {{totalUploadImages}}</span>
          
        </div>
      </div>
    </div>
    <div class="modal-footer border-0 sumit_popup">
      <button type="button" class="btn border-0 text-white pl-4 pr-4" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </ng-template>