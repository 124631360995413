<ul class="sidebar_nav navbar-nav bg-gradient-primary sidebar sidebar-dark accordion pt-3 menu-open"
  id="accordionSidebar">

  <!-- Nav Item - Dashboard -->
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link collapsed" routerLink="/property-information" data-toggle="collapse" data-target="#collapseUtilities"
      aria-expanded="false"
      aria-controls="collapseUtilities"><img src="../assets/img/property.svg"> <span>Property Information
    </span>
    </a>
  </li>
  <hr class="sidebar-divider">
  <li class="nav-item" routerLinkActive="active">
  <a class="nav-link" routerLink="/access-parking"> <img src="../assets/img/parking.svg"><span>Access
      parking</span></a>
  </li>
  <hr class="sidebar-divider">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link collapsed" href="#" data-toggle="collapse"
      data-target="#collapseUtilities" aria-expanded="false" aria-controls="collapseUtilities"><img
        src="../assets/img/floor.svg"> <span>Floor Planning
      </span>
    </a>
    <div id="collapseUtilities" class="collapse inner_menubar" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
      <div class=" py-2 collapse-inner basement">
        <a class="collapse-item w-100 d-inline-block" href="#">Basement</a>
        <a class="collapse-item w-100 d-inline-block" data-toggle="collapse" href="#Floor" role="button" aria-expanded="false" aria-controls="Floor">Floor </a>

        <div class="collapse" id="Floor">
          <a class="collapse-item w-100 d-inline-block" data-toggle="collapse" href="#Ground" role="button"
            aria-expanded="false" aria-controls="Ground">Ground Floor</a>
        </div>

        <div class="collapse" id="Ground">
          <a class="collapse-item w-100 d-inline-block" href="#">Lounge</a>
          <a class="collapse-item w-100 d-inline-block" href="#">Kitchen</a>
        </div>

        <div class="collapse" id="Floor">
          <a class="collapse-item w-100 d-inline-block" href="#" >Other Area</a>
        </div>
      </div>
    </div>
  </li>

  <hr class="sidebar-divider">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/gallery"><img src="../assets/img/gallery.svg"><span>Gallery</span></a>
  </li>

  <hr class="sidebar-divider">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/notes"><img src="../assets/img/notes.svg"><span>Notes</span></a>
  </li>

  <hr class="sidebar-divider">
  <li class="nav-item" routerLinkActive="active">
    <a class="nav-link" routerLink="/upload-survey"><img src="../assets/img/uploadmenu.svg"><span>Upload</span></a>
  </li>
  
  <hr class="sidebar-divider">
  <div class="menu_buttonclose w-100">
    <button class="btn w-100 border-0  rounded-0 text-white p-3 text-left" type="button">
      Collapsable Menu <img src="../assets/img/right-icon.svg">
    </button>
  </div>
</ul>
