import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { IndexedDBService } from '../services/indexed-db.service';
import { EventEmitterService } from '../model/event-emitter.service'; 
import Swal from 'sweetalert2';

@Component({
  selector: 'app-access-parking',
  templateUrl: './access-parking.component.html',
  styleUrls: ['./access-parking.component.css']
})
export class AccessParkingComponent implements OnInit {
  @Input() ImportSurveyList: any;
  @Input() loader: any;
  @Input() currentTabTypeid: any;
  surveyId: any;
  reportId!: any;
  submitted = false;
  submittedTo = false;
  accessParkingForm = new FormGroup({});
  accessParkingFormTo = new FormGroup({});
  constructor(private formBuilder: FormBuilder, private router: Router, private _Activatedroute:ActivatedRoute, private eventEmitterService: EventEmitterService, private IndexedDBService: IndexedDBService) { }

  ngOnInit(): void {
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");   
    
    this._Activatedroute.queryParams.subscribe(params => {         
      this.currentTabTypeid = params.type;               
    }); 

    /***accessParking From form value assign */
    this.accessParkingForm = this.formBuilder.group({   
      driveway : new FormControl(this.ImportSurveyList.property.from.access.driveway==null ? false:this.ImportSurveyList.property.from.access.driveway),  
      driveway_type: new FormControl(this.ImportSurveyList.property.from.access.driveway_type==null ? '':this.ImportSurveyList.property.from.access.driveway_type),   
      driveway_shared : new FormControl(this.ImportSurveyList.property.from.access.driveway_shared==null ? false:this.ImportSurveyList.property.from.access.driveway_shared), 
      park_outside : new FormControl(this.ImportSurveyList.property.from.access.park_outside==null ? false:this.ImportSurveyList.property.from.access.park_outside), 
      park_restrictions : new FormControl(this.ImportSurveyList.property.from.access.park_restrictions==null ? false:this.ImportSurveyList.property.from.access.park_restrictions), 
      park_suspensions : new FormControl(this.ImportSurveyList.property.from.access.park_suspensions==null ? false:this.ImportSurveyList.property.from.access.park_suspensions)      
      
    });


    /***accessParking To form value assign */
    this.accessParkingFormTo = this.formBuilder.group({    
      driveway : new FormControl(this.ImportSurveyList.property.to.access.driveway==null ? false:this.ImportSurveyList.property.to.access.driveway),  
      driveway_type: new FormControl(this.ImportSurveyList.property.to.access.driveway_type==null ? '':this.ImportSurveyList.property.to.access.driveway_type),   
      driveway_shared : new FormControl(this.ImportSurveyList.property.to.access.driveway_shared==null ? false:this.ImportSurveyList.property.to.access.driveway_shared), 
      park_outside : new FormControl(this.ImportSurveyList.property.to.access.park_outside==null ? false:this.ImportSurveyList.property.to.access.park_outside), 
      park_restrictions : new FormControl(this.ImportSurveyList.property.to.access.park_restrictions==null ? false:this.ImportSurveyList.property.to.access.park_restrictions), 
      park_suspensions : new FormControl(this.ImportSurveyList.property.to.access.park_suspensions==null ? false:this.ImportSurveyList.property.to.access.park_suspensions) 
    });  

  }

  get accessParkingFormControl() {
    return this.accessParkingForm.controls;
  }

  get accessParkingFormToControl() {
    return this.accessParkingFormTo.controls;
  }


  onSubmitAccess() {
    this.submitted = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];

    if (this.accessParkingForm.valid) {
      const parkingFormPayload = {
        formType: this.currentTabTypeid,
        driveway: this.accessParkingForm.controls.driveway.value,
        driveway_type: this.accessParkingForm.controls.driveway_type.value,
        driveway_shared: this.accessParkingForm.controls.driveway_shared.value,
        park_outside: this.accessParkingForm.controls.park_outside.value,
        park_restrictions: this.accessParkingForm.controls.park_restrictions.value,
        park_suspensions: this.accessParkingForm.controls.park_suspensions.value       
      }       
     
      this.IndexedDBService.addAccessParkingFormData(parkingFormPayload, surveyPayload).then((response: any) => {             
            
        if(response) {
          Swal.fire('Updated', 'Access Parking updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            this.eventEmitterService.surveyImportData(getResponse.data); 
            this.accessParkingForm.markAsPristine();            
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });  
    }
   // this.invalidLogin = true;     
  }


  onSubmitAccessTo() {
    this.submittedTo = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];

    if (this.accessParkingFormTo.valid) {
      const parkingFormPayload = {
        formType: this.currentTabTypeid,
        driveway: this.accessParkingFormTo.controls.driveway.value,
        driveway_type: this.accessParkingFormTo.controls.driveway_type.value,
        driveway_shared: this.accessParkingFormTo.controls.driveway_shared.value,
        park_outside: this.accessParkingFormTo.controls.park_outside.value,
        park_restrictions: this.accessParkingFormTo.controls.park_restrictions.value,
        park_suspensions: this.accessParkingFormTo.controls.park_suspensions.value            
      }
       
      this.IndexedDBService.addAccessParkingFormData(parkingFormPayload, surveyPayload).then((response: any) => {             
            
        if(response) {
          Swal.fire('Updated', 'Access Parking updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            this.eventEmitterService.surveyImportData(getResponse.data);    
            this.accessParkingFormTo.markAsPristine();        
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });  
    }
   // this.invalidLogin = true;     
  }



  tabTypeClickFunction(tabType:any){    
    this.eventEmitterService.ontabTypeClick(tabType);    
  }
  
}
