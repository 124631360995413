<div class="d-flex flex-column header_top">

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar -->
      <nav class="navbar navbar-expand navbar-light topbar static-top pl-4">
        <!-- Topbar Search -->
        <h2 class="text-white"><a routerLink="/dashboard" ><img class="img-profile" src="../assets/img/logo.png"></a></h2>
        <!--<button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
          <i class="fa fa-bars"></i>
        </button>-->

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">         
          <!-- Nav Item - Messages -->
          <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" routerLink="/setting" id="messagesDropdown" role="button"
              data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <!-- <img class="img-profile rounded-circle" src="../assets/img/setting.svg"> -->
              <i class="fas fa-cog text-white"></i>
            </a>            
          </li>

          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-user text-white mr-2"></i>
              <span class="mr-2 d-none d-lg-inline text-white" *ngIf="surveyorName">{{surveyorName}}</span>
              <i class="fas fa-caret-down text-white"></i>             
              <!--<img class="img-profile rounded-circle" src="{{surveyorImage}}" *ngIf="surveyorImage" width="48px"> -->
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
              <!-- <a class="dropdown-item" href="#">
                Profile
              </a> 
              <div class="dropdown-divider"></div>-->
              <button class="dropdown-item" (click)="onLogout()">
                <i class="fas fa-sign-out-alt"></i> Logout
              </button>
            </div>
          </li>

        </ul>

      </nav>
      <!-- End of Topbar -->

    </div>

  </div>
