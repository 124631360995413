import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {ApiService} from "../services/api.service";
import { IndexedDBService } from '../services/indexed-db.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  surveyorName='';
  surveyorImage:any;
  message='';
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private IndexedDBService: IndexedDBService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.IndexedDBService.getProfileDetails()
      .then((data:any) => { 
        
        if(data!=undefined){
          this.surveyorName = data.surveyor_first_name +' '+ data.surveyor_last_name;
          if(data.surveyor_profile_img){
            this.surveyorImage = data.surveyor_profile_img;
          }else{
            this.surveyorImage = '../assets/img/user.png';
          }          
        }else{
          this.apiService.getSurveyorDetails().subscribe(  
            (res)=>{        
           
            if(res.data!=null){
              const ProfilePayload = {
                surveyor_id : res.data.surveyor_id,                           
                surveyor_email : res.data.surveyor_email,                           
                surveyor_first_name : res.data.surveyor_first_name,                           
                surveyor_last_name : res.data.surveyor_last_name,                           
                surveyor_mob : res.data.surveyor_mob,                           
                surveyor_profile_img : res.data.surveyor_profile_img,                           
              }
              this.IndexedDBService.addProfileDetails(ProfilePayload).then((response: any) => {                   
               
                if(response){
                  this.IndexedDBService.getProfileDetails()
                  .then((data:any) => {                     
                    if(data!=undefined){
                      this.surveyorName = data.surveyor_first_name +' '+ data.surveyor_last_name;
                      if(data.surveyor_profile_img){
                        this.surveyorImage = data.surveyor_profile_img;
                      }else{
                        this.surveyorImage = '../assets/img/user.png';
                      }          
                    }
                  });
                }
              });  
            }
          });
         
        }      
        
      }); 
    }, 300);
  }

  

  onLogout(){
    const logoutPayload={}

    this.apiService.logout(logoutPayload).subscribe(data => {
        console.log(data);
        if(data.status === true) {
          localStorage.removeItem('token');
          this.router.navigate(['login']);
          $('body').addClass('without-login');
          this.message=data.message;          
  
        }else {
          localStorage.removeItem('token');
          this.router.navigate(['login']);
          $('body').addClass('without-login');
          this.message=data.message;
          //alert(data.message);
        }


      },error => {      
        if(error.status==401){
          this.message=error.error.message;
        }
      });              
  }



}
