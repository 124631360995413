import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, EmailValidator } from '@angular/forms';
import {Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  step!: number;
  code!:string;
  email!:string;
  message='';
  error_message='';
  submitted = false;
  invalidLogin: boolean = false;
  resetPasswordForm= new FormGroup({
    password : new FormControl(),
    confirmPassword : new FormControl()    
  });  


  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (window.localStorage.getItem('token')) {      
      this.router.navigate(['dashboard']);             
    }else{
      window.localStorage.removeItem('token');
    }

    /*****get value from url parameters ******/
    this.route.queryParams.subscribe(params => {         
      this.step = params.step;
      this.code = params.code;
      this.email = params.email;         
    });

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],        
      confirmpassword: ['', Validators.required]   
    },
    { 
      validators: this.passwordMatchingValidator.bind(this)
    });
    
  }

  passwordMatchingValidator(resetPasswordForm: any) {
    const { value: password } = resetPasswordForm.get('password');
    const { value: confirmPassword } = resetPasswordForm.get('confirmpassword');
    if(confirmPassword!='' && password!=''){
      return password === confirmPassword ? null : { passwordNotMatch: true };
    }
    return null;
  }

  get resetPasswordFormControl() {
    return this.resetPasswordForm.controls;
  }

  
  onSubmit() {
    this.submitted = true;
    const passwordPayload = {
      email: this.email,
      code: this.code,
      step: this.step,
      password: this.resetPasswordForm.controls.password.value,
      password_confirm: this.resetPasswordForm.controls.confirmpassword.value            
    }     
    this.apiService.forgotPassword(passwordPayload).subscribe((data: { status: boolean; message: any; }) => {
      //debugger;
      
      if(data.status === true) {
        this.message=data.message        
      }else {
        this.invalidLogin = true;
        this.error_message=data.message
        
      }
     // this.resetPasswordForm.reset();
    },
    error => {      
      if(error.status==401){
        this.message=error.error.message;
      }
    });
    

  }



}
