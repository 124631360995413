<app-sidebar></app-sidebar>
<app-header-inner></app-header-inner>
<div class="table_list">
<section id="tabs" class="project-tab tabs_project bg-white p-3 border">
        <nav>
          <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active border-0" id="nav-home-tab" data-toggle="tab" href="#nav-Info" role="tab"
              aria-controls="nav-Info" aria-selected="true">Survey Info</a>
            <a class="nav-item nav-link border-0" id="nav-profile-tab" data-toggle="tab" href="#nav-Access" role="tab"
              aria-controls="nav-Parking" aria-selected="false">Access Parking</a>
            <a class="nav-item nav-link border-0" id="nav-contact-tab" data-toggle="tab" href="#nav-Planning" role="tab"
              aria-controls="nav-Gallery" aria-selected="false">Floor Planning</a>
            <a class="nav-item nav-link border-0" id="nav-contact-tab" data-toggle="tab" href="#nav-gallery" role="tab"
              aria-controls="nav-Gallery" aria-selected="false">Gallery</a>
            <a class="nav-item nav-link border-0" id="nav-contact-tab" data-toggle="tab" href="#nav-Notes" role="tab"
              aria-controls="nav-Notes" aria-selected="false">Notes</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-Info" role="tabpanel" aria-labelledby="nav-home-tab">
            <h6 class="pl-0 pt-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
              into electronic typesetting, remaining essentially unchanged.<br><br>

              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.</h6>
          </div>
          <div class="tab-pane fade" id="nav-Access" role="tabpanel" aria-labelledby="nav-profile-tab">
            <h6 class="pl-0 pt-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the
              industry's standard dummy text ever since the 1500s,</h6>
          </div>
          <div class="tab-pane fade" id="nav-Planning" role="tabpanel" aria-labelledby="nav-contact-tab">
            <h6 class="pl-0 pt-4">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.</h6>
          </div>

          <div class="tab-pane fade" id="nav-gallery" role="tabpanel" aria-labelledby="nav-contact-tab">
            <h6 class="pl-0 pt-4">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a
              galley of type and
              scrambled it to make a type specimen book.</h6>
          </div>

          <div class="tab-pane fade" id="nav-Notes" role="tabpanel" aria-labelledby="nav-contact-tab">
            <h6 class="pl-0 pt-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and
              scrambled it to make a type specimen book.</h6>
          </div>
        </div>
</section>

</div>

