<div class="login_account d-md-flex align-items-center justify-content-between">
  <div class="box-1 mt-md-0">
    <img src="../assets/img/bg.jpg" alt="bg">
   
  </div>
  <div class=" box-2 d-flex flex-column">
    <img class="forgotten-pass" src="../assets/img/Forgotten.svg">
    <p class="h1 text-center font-weight-bold mb-4">Reset You Password? </p>
    <h6 class="text-center mb-5">Please enter and confirm your new password below</h6>
    <div class="alert alert-success py-2" *ngIf="message!==''">
      {{message}}
    </div>

    <div class="alert alert-danger py-2" *ngIf="error_message!==''">
      <span [innerHTML]="error_message"></span>
    </div> 
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <div class="error-msg-box position-relative">
        <div class="text-danger" *ngIf="(resetPasswordFormControl.password.touched || submitted) && resetPasswordFormControl.password.errors?.required">
          Password is required
        </div>
        <div class="text-danger" *ngIf="(resetPasswordFormControl.confirmpassword.touched || submitted) && resetPasswordFormControl.confirmpassword.errors?.required">
          Confirm password is required
        </div>
        <div class="text-danger" *ngIf="resetPasswordForm.errors">
          Password not matched
        </div> 
      </div>
      <div class="form-group password_name">
        <label for="exampleInputPassword1">Password</label>
         <div class="input-custom-icon">
        <img src="../assets/img/password.svg">
        <input type="password" class="form-control" formControlName="password" id="password" placeholder="Password" autocomplete="off">
        </div>
      </div>

      <div class="form-group password_name">
        <label for="exampleInputPassword1">Confirm Password</label>
         <div class="input-custom-icon">
        <img src="../assets/img/password.svg">
        <input type="password" class="form-control" formControlName="confirmpassword" id="confirmpassword" placeholder="Confirm Password" autocomplete="off">
       </div>
        
        
      </div>
      <button type="submit" class="btn btn-primary w-100 border-0 text-white font-weight-bold p-3" [disabled]="resetPasswordForm.invalid">Save</button>
    </form>
  </div> 
</div>