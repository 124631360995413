import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openNav() {
    $("#accordionSidebar").addClass("menu-open");
    $("#accordionSidebar").removeClass("menu-close");
    
  }

  closeNav() {
    $("#accordionSidebar").addClass("menu-close"); 
    $("#accordionSidebar").removeClass("menu-open");

  }
  sidebarTogggle(){
    $("#accordionSidebar").toggleClass("slideMin");
  }
}
