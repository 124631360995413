import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {Router} from "@angular/router";
import {ApiService} from "../services/api.service";
import { IndexedDBService } from '../services/indexed-db.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  host: { 'class': 'main-class' }
})
export class DashboardComponent implements OnInit {
  message='';
  messageLocalSurvey='';
  closeResult!: string;
  surveyList: any;
  loader = false;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 20, 50, 100, 300];
  date='';
  after='';
  todayDate:any;
  todayDateSurvey:any;
  newSurveyName='';
  ImportSurveyListLocal:any;
  surveyTabList=true;
  submittedNewSurvey=false;
  // newSurveyForm = new FormGroup({});
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private apiService: ApiService, private IndexedDBService: IndexedDBService, private datePipe: DatePipe) {
    this.todayDate= new Date();
    this.todayDateSurvey = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.todayDate = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');    
    this.IndexedDBService.connectToDB();
   }

  selectedSurveyTab(surveyTab:any){
    if(surveyTab=='local-survey'){
      this.surveyTabList=false;
    }else{
      this.surveyTabList=true;
    }
  }


  ngOnInit(): void {
    this.getSurveyImportList();
    this.getSurveyImportListLocal();   
    

  }

    
  getRequestParams(page: number, pageSize: number, date:any, after:any): any {
    // tslint:disable-next-line:prefer-const
    let params: any = {};
    
    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`limit`] = pageSize;
    }

    if (date) {
      params[`date`] = date;
    }

    if (after) {
      params[`after`] = after;
    }
    return params;
  }

  onDateChange(event: any){
    //console.log(event);
    if(event){
      this.date= event['year']+'-'+event['month']+'-'+event['day'];          
    }else{
      this.date='';
    }
    this.getSurveyImportList();
  }
  

  getSurveyImportList() { 
    this.loader = true;
    const params = this.getRequestParams(this.page, this.pageSize, this.date, this.after );    
    this.apiService.surveyImportList(params).subscribe(data => {
      if(data.status === true) { 
        if( data.data!==null){        
          this.surveyList = data.data; 
          this.count = data.metadata.total;  
          this.loader = false; 
        }else{
          this.surveyList =[];  
          this.loader = false;        
        }               
      }else {
        this.surveyList =[];   
        this.loader = false;     
      }
             
    },
    error => {
      console.log(error); 
      this.loader = false;   
      this.message=error.statusText;   
    });
    
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getSurveyImportList();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getSurveyImportList();
  } 
  handlePageChangeLocal(event: number): void {
    this.page = event;
    this.getSurveyImportListLocal();
  }

  handlePageSizeChangeLocal(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getSurveyImportListLocal();
  }

  
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
      this.submittedNewSurvey= false;
      this.todayDateSurvey= new Date();
      this.todayDateSurvey = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }



  getSurveyImportListLocal(){
    this.IndexedDBService.getSurveyListLocal().then((getResponse: any) => { 
      if(getResponse!=undefined){
        this.ImportSurveyListLocal= getResponse;
      }else{
        this.messageLocalSurvey='Survey not found in local storage.';
      }               
        
      this.loader = false;
    }); 
  }


  deleteSurveyLocal(survey_id:any, report_id:any, format_version:any, surveyor_id:any){
    let surveyPayload=[Number(survey_id), Number(surveyor_id), Number(format_version), Number(report_id)];
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove it!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {   
        this.IndexedDBService.deleteSurveyLocal(surveyPayload).then((response: any) => {                  
          if(response) {
            Swal.fire('Deleted', 'Survey has been deleted!', 'success');            
            this.getSurveyImportListLocal();           
          }else{
            Swal.fire('Error', 'Something went wrong!', 'error');
          }
        });

      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }

  copySurvey(survey_id:any, report_id:any, surveyData:any) {
    
    const params={
      survey_id:survey_id,
      report_id:report_id 
    }
    var randomSurveyId:any;    
    randomSurveyId= Math.floor(100000 + Math.random() * 900000);
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure want to copy survey?',
      text: "It will be add in your local storage!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Copy it!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

        this.apiService.getSurveyImportDetails(params).subscribe(data => {      
          if(data.status === true) { 
            if( data.data!==null){        
              let ImportSurveyDetailsData= data.data;
              ImportSurveyDetailsData.survey_id=randomSurveyId;
              console.log(ImportSurveyDetailsData);
              
              this.IndexedDBService.addSurveyDetailsLocal(ImportSurveyDetailsData, 0).then((response: any) => {                        
                if(response) {
                  swalWithBootstrapButtons.fire(
                    'Copied!',
                    'Survey has been copied.',
                    'success'
                  )
                  this.getSurveyImportListLocal();
                  this.loader = false;
                } 
              });          
                                        
            }else{           
              this.message=data.message;        
            }               
          }else {        
            this.message=data.message;        
          } 
          // this.loader = false;
        },
        error => {
          console.log(error); 
          this.getSurveyImportListLocal();
        });
        
      } else if (       
        result.dismiss === Swal.DismissReason.cancel
      ) {}
    })

  }


  onDateChangeNewSurvey(event: any){      
    if(event){
      this.todayDateSurvey=event['year']+'-'+event['month']+'-'+event['day'];     
    }else{
      this.todayDateSurvey='';
    }   
  }

  onSubmitNewSurvey(newSurveyForm:NgForm) {
    this.submittedNewSurvey = true;
    let todayDate1= new Date();    
    let currentTime= todayDate1.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',second: 'numeric',hour12: false });    
    
    if (newSurveyForm.valid) {      
      let surveyName = newSurveyForm.value.survey_name;
      let surveyDate = newSurveyForm.value.survey_date;
           
      var randomSurveyId:any;    
      randomSurveyId= Math.floor(100000 + Math.random() * 900000);

      const SurveyDataJson={      
        "survey_id": randomSurveyId,
        "client_id": 0,
        "report_id": 0,
        "surveyor_id": 0,
        "survey_name": surveyName,
        "survey_created_time": surveyDate+' '+currentTime,
        "survey_edited_time": surveyDate+' '+currentTime,
        "survey_format_version": 0,
        "survey_date": surveyDate,
        "survey_time": null,
        "survey_type": null,
        "proposed_move_date": null,
        "move_requires_storage": null,
        "storage_requirements": null,
        "contact_primary": {
          "title": null,
          "first_name": null,
          "last_name": null,
          "tel": null,
          "mob": null,
          "email": null
        },
        "contact_secondary": {
          "title": null,
          "first_name": null,
          "last_name": null,
          "tel": null,
          "mob": null,
          "email": null
        },
        "property": {
          "from": {
            "add1": null,
            "add2": null,
            "add3": null,
            "add4": null,
            "postcode": null,
            "country": null,
            "property_type": null,
            "bedroom_count": null,
            "property_floor": null,
            "ownership": null,
            "estate_agent": false,
            "estate_agent_name": null,
            "new_build": false,
            "access": {
              "driveway": null,
              "driveway_type": null,
              "driveway_shared": null,
              "park_outside": null,
              "park_restrictions": null,
              "park_suspensions": null
            },
            "plan": {
              "floors": null,
              "basements": null,
              "lofts": null,
              "other_areas": null
            }
          },
          "to": {
            "add1": null,
            "add2": null,
            "add3": null,
            "add4": null,
            "postcode": null,
            "country": null,
            "property_type": null,
            "bedroom_count": null,
            "property_floor": null,
            "ownership": null,
            "estate_agent": false,
            "estate_agent_name": null,
            "new_build": false,
            "access": {
              "driveway": null,
              "driveway_type": null,
              "driveway_shared": null,
              "park_outside": null,
              "park_restrictions": null,
              "park_suspensions": null
            },
            "plan": {
              "floors": null,
              "basements": null,
              "lofts": null,
              "other_areas": null
            }
          }
        },
        "notes": [
          {
            "note": "This survey was new created",
            "note_time": surveyDate+' '+currentTime
          }
        ],
        "images": null
      }    
     console.log(SurveyDataJson);
      this.IndexedDBService.addSurveyDetailsLocal(SurveyDataJson, 0).then((response: any) => {                        
        if(response) {
          this.getSurveyImportListLocal();
          this.submittedNewSurvey=false;
          this.modalService.dismissAll('Dismissed modal box after saving data');

        }else{
          this.message= "Something went wrong!"
        } 
      });  
    
    }

  }

}
