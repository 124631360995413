import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-floor-rooms',
  templateUrl: './floor-rooms.component.html',
  styleUrls: ['./floor-rooms.component.css']
})
export class FloorRoomsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
