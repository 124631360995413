import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { IndexedDBService } from '../services/indexed-db.service';
import { EventEmitterService } from '../model/event-emitter.service'; 
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  @Input() ImportSurveyList: any;
  @Input() loader: any;
  surveyId: any;
  reportId!: any;
  closeResult!: string;
  submittedNotes = false;
  NotesForm = new FormGroup({
    note : new FormControl(),    
  });
  noteIndex!:number;
  editNotesSubmitted=false;
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, private _Activatedroute: ActivatedRoute, private IndexedDBService: IndexedDBService, public datepipe: DatePipe, private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");
    
    this.NotesForm = this.formBuilder.group({      
      note: ['', Validators.required],      
      noteIndex: ['', Validators.required],      
    });

  }

  setEditNoteValue(note_index:any, note_text:any){
    this.noteIndex=note_index;
    this.NotesForm.patchValue({
      noteIndex: note_index,
      note: note_text,
    }); 
  }

  deleteNote(noteIndex:any){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove it!',
      cancelButtonText: 'No, Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {       

        let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
        this.IndexedDBService.deleteNoteSingle(noteIndex, surveyPayload).then((response: any) => {                   
          if(response) {
            swalWithBootstrapButtons.fire(
              'Deleted!',
              'Note has been deleted.',
              'success'
            )
            this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                              
              this.eventEmitterService.surveyImportData(getResponse.data);
            });
            
          }else{
            Swal.fire('Error', 'Something went wrong!', 'error');
          }

        });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })    
      
  }


  get NotesFormControl() {
    return this.NotesForm.controls;
  }

  editNote(){   
    this.editNotesSubmitted=true;
    let currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd HH:mm:ss');
    let noteIndex=this.NotesForm.controls.noteIndex.value;
    const uploadPayload = {
      note: this.NotesForm.controls.note.value, 
      note_time: currentDateTime    
    }

    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];
    this.IndexedDBService.editNoteSingle(uploadPayload, surveyPayload, noteIndex).then((response: any) => {                   
      if(response) {
        //Swal.fire('Updated', 'Basement updated succesfully!', 'success')
        this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {                              
          this.eventEmitterService.surveyImportData(getResponse.data);
          this.modalService.dismissAll('Dismissed modal box after saving data');
        });
        
      }else{
        Swal.fire('Error', 'Something went wrong!', 'error');
      }
      this.editNotesSubmitted=true;
    }); 
      
  }



  open(content: any) {   
    this.NotesForm.reset();    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',
    beforeDismiss: () => {
      if (!this.NotesForm.pristine && !this.editNotesSubmitted) {
        var isConfirmed = confirm('You have unsaved changes! If you leave, your changes will be lost.'); 
        if(isConfirmed){
          return true;
        }         
        return false; 
      }else {                
        return true;
      }
    } 
   }).result.then((result) => {      
      this.closeResult = 'Closed with: ${result}';            
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
      //this.NotesForm.markAsPristine();
      this.NotesForm.markAsUntouched();
      this.submittedNotes=false;     
    });
  }

  private getDismissReason(reason: any): string {    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }


  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {      
    if (!this.NotesForm.pristine) {
        $event.returnValue = true;
    }
  }
  

}
