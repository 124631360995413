<div class="app-install-bar py-2 d-flex align-items-center justify-content-center theme-bg" *ngIf="isAppInstall()">
  <p class="mb-0 text-white pe-2">Add to home screen</p>
  <button  class="btn btn-outline-secondary btn-sm text-center text-white" (click)="addToHomeScreen()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="15px" viewBox="0 0 122.876 107.16" enable-background="new 0 0 122.876 107.16" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M32.716,32.108h16.566V0l23.702,0v32.107l17.176,0l-28.7,30.32L32.716,32.108 L32.716,32.108z M113.705,32.244l7.733,34.271c0.021,0.071,0.034,0.146,0.044,0.223c0.678,5.731,1.177,10.726,1.338,15.138 c0.164,4.449-0.023,8.255-0.726,11.545c-0.014,0.063-0.03,0.123-0.052,0.183c-0.647,7.566-7.043,13.558-14.77,13.558h-92.12 c-7.736,0-14.138-6.006-14.772-13.585c-0.021-0.094-0.032-0.19-0.034-0.29l-0.012-0.533l-0.002-0.079 c-0.031-1.248-0.076-2.625-0.123-4.06c-0.181-5.508-0.39-11.875,0.058-17.388l-0.016,0.021l0.093-0.892 c0.027-0.294,0.059-0.584,0.091-0.872l7.736-37.239h10.213l-1.297,41.397h88.252l-0.849-41.397H113.705L113.705,32.244z M93.92,84.625c3.247,0,5.879,2.633,5.879,5.879c0,3.247-2.632,5.879-5.879,5.879s-5.879-2.632-5.879-5.879 C88.041,87.258,90.673,84.625,93.92,84.625L93.92,84.625z M17.253,85.941h22.783v8.092H17.253V85.941L17.253,85.941z"/></g></svg>
    Install
  </button> 
</div>

<div class="login_account d-md-flex align-items-center justify-content-between">
    <div class="box-1 mt-md-0">
         <img src="../assets/img/bg.jpg" alt="bg"> 
         
    </div>
    <div class=" box-2 d-flex flex-column contact-form">
        <p class="h1 text-center font-weight-bold mb-4">Surveyor log in</p>
   
    <div class="alert alert-danger" role="alert" *ngIf="message!==''">
      {{message}}
    </div> 
    <div class="alert alert-success" role="alert" *ngIf="twoFactorMessage!==''">
      2factor login code has been sent to your email Id. <br>
      {{twoFactorMessage}}
    </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
          <div class="error-msg-box position-relative">
          <div class="text-danger"
          *ngIf="(loginFormControl.username.touched || submitted) && loginFormControl.username.errors?.required">
          Email is required
        </div>
        <div class="text-danger"
        *ngIf="(loginFormControl.password.touched || submitted) && loginFormControl.password.errors?.required">
        Password is required
      </div>
    </div>
          <div class="form-group user_name">
            <label for="exampleInputEmail1">Email</label>
            <div class="input-custom-icon">
            <img src="../assets/img/user.svg">
            <input type="text" class="form-control" formControlName="username" id="username" placeholder="Email"
              autocomplete="off">
           </div>
          </div>
          <div class="form-group password_name">
            <label for="exampleInputPassword1">Password</label>
            <div class="input-custom-icon">
            <img src="../assets/img/password.svg"> 
            <input type="password" class="form-control" formControlName="password"
              id="password" placeholder="Password" autocomplete="off">
            </div>
           </div>
           <div class="form-group" *ngIf="twoFactorToken">
            <label for="otp-label">2factor login code</label>
            <input type="text" class="form-control my-2" id="otp-number" placeholder="2factor login code"  formControlName="code" autocomplete="off">
            </div>
          <button type="submit" class="btn btn-primary w-100 border-0 text-white font-weight-bold p-3" [disabled]="loginForm.invalid">Log in</button>
          <div class="form-group text-right w-100 d-inline-block">
            <a routerLink="/forgot-password" class="float-right mt-4">
              <p class="mb-0">Reset my password </p>
            </a>
          </div>
        </form>
    </div>
  </div>