<section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
  <nav class="mb-4 nav-pills">
    <div class="nav nav-tabs nav-pills nav-fill border-0 d-inline-flex" id="nav-tab" role="tablist">
      <a class="nav-item nav-link border-0 active" id="nav-profile-tab" data-toggle="tab" href="#nav-to-access"
        role="tab" aria-controls="nav-Parking" aria-selected="false">Build floor plan</a>
      <a class="nav-item nav-link border-0 " id="nav-home-tab" data-toggle="tab" href="#nav-from-access" role="tab"
        aria-controls="nav-Info" aria-selected="true">Create plan from template</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

    <div class="tab-pane fade" id="nav-from-access" role="tabpanel" aria-labelledby="nav-home-tab">
      <div>
        <ng-container
          *ngIf="(ImportSurveyList.property?.from.plan.floors==null || ImportSurveyList.property?.from.plan.floors?.length<=0) && (ImportSurveyList.property?.from.plan.basements==null || ImportSurveyList.property?.from.plan.basements?.length<=0) && (ImportSurveyList.property?.from.plan.lofts==null || ImportSurveyList.property?.from.plan.lofts?.length<=0) && (ImportSurveyList.property?.from.plan.other_areas==null || ImportSurveyList.property?.from.plan.other_areas?.length<=0)">
          <form [formGroup]="addTemplateForm" (ngSubmit)="onSubmitTemplateForm()">
            <input type="hidden" value="" formControlName="template_id">
            <div class="form-group">
              <label for="exampleInputSurvey">Select Template</label>
              <ng-template #itemTemplateList let-item>
                <a [innerHTML]="item.template_name"></a>
              </ng-template>
              <ng-template #notFoundTemplateList let-notFoundTemplate>
                <div [innerHTML]="notFoundTemplate"></div>
              </ng-template>
              <ng-autocomplete [data]="templateList" [searchKeyword]="templateName"
                (inputChanged)='onChangeSearchTemplate($event)' (selected)='selectEventTemplate($event)'
                (inputCleared)="onClearedTemplate($event)" (inputFocused)="onFocusedTemplate($event)"
                [itemTemplate]="itemTemplateList" [notFoundTemplate]="notFoundTemplateList"
                placeholder="Property template" [isLoading]="isLoadingResult" formControlName="template_name">
              </ng-autocomplete>
              <div class="text-danger mt-3"
                *ngIf="(addTemplateFormControl.template_name.touched || submittedTemplate) && addTemplateFormControl.template_name.errors?.required">
                You must select a template
              </div>
            </div>
            <span class="float-left alert-danger" *ngIf="submittedTemplate">please wait...</span>
            <button type="submit" class="btn border-0 text-white pl-4 pr-4"><i class="fas fa-folder-plus"></i> Create
              floor plan</button>
          </form>
        </ng-container>

        <ng-container
          *ngIf="(ImportSurveyList.property?.from.plan.floors?.length>0) || (ImportSurveyList.property?.from.plan.basements?.length>0) || (ImportSurveyList.property?.from.plan.lofts?.length>0) || (ImportSurveyList.property?.from.plan.other_areas?.length>0)">
          <div class="text-center">Floor plan has aready been created, templates are only available when the floor plan
            is empty.</div>
        </ng-container>
      </div>
    </div>

    <div class="tab-pane fade show active" id="nav-to-access" role="tabpanel" aria-labelledby="nav-home-tab">
      <div class="survey_popup">
        <div class="form-group row">
          <div class="col-lg-6">
            <label for="exampleInputSurvey">Add to floor plan</label>
            <select class="form-control form-control-custom" name="plan_name" #planName
              (change)='onPlanSelect(planName.value)'>
              <option value="">Select</option>
              <option value="basements">Basement</option>
              <option value="floors">Floor</option>
              <option value="lofts">Loft</option>
              <option value="other_areas">Other Area</option>
            </select>
          </div>
        </div>

        <form [formGroup]="basementForm" (ngSubmit)="onSubmitBasement()" *ngIf="selectedPlan=='basements'">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group test2">
                <label for="exampleInputSurvey">Basement Name</label>
                <input type="text" class="form-control" placeholder="Basement Name" autocomplete="off" value=""
                  formControlName="basement_name">
                <div class="text-danger"
                  *ngIf="(basementFormControl.basement_name.touched || submittedBasement) && basementFormControl.basement_name.errors?.required">
                  Basement Name is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group ">
                <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving the contents of this basement?</label>
                <select class="form-control  border" id="exampleFormControlSelect2" formControlName="basement_ntg">
                  <option value="">--Select--</option>
                  <option value="TG">Yes: To Go</option>
                  <option value="NTG">No: Not To Go</option>
                  <option value="MTG">Not sure: Maybe To Go</option>
                </select>
                <div class="text-danger"
                  *ngIf="(basementFormControl.basement_ntg.touched || submittedBasement) && basementFormControl.basement_ntg.errors?.required">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this
                  basement
                </label>
                <input type="number" class="form-control" placeholder="Basement Box Estimate" min="0" autocomplete="off"
                  value="" formControlName="basement_box_estimate">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label class="mb-4">Are we packing the contents of this basement?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="basement_packing">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="basement_packing">No
                    </label>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label class="mb-4">Have we been able to see the basement?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="basement_seen">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="basement_seen">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the light fittings in this basement? </label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_light_fittings">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_light_fittings">No
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this basement? </label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_carpets">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_carpets">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains / blinds in this basement?
                </label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_curtains_blinds">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_curtains_blinds">No
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Is this basement fully lit?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="basement_fully_lit">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="basement_fully_lit">No
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer border-0 sumit_popup">
            <button type="submit" class="btn border-0 text-white pl-4 pr-4"><i class="fas fa-plus-square"></i> Add to
              floor plan</button>
          </div>
        </form>

        <form [formGroup]="floorForm" (ngSubmit)="onSubmitAddFloor()" *ngIf="selectedPlan=='floors'">
          <div class="row">
            <div class="col-lg-6">
              <label for="floorName">Floor Name</label>
              <input type="text" class="form-control mb-0" id="floorName" placeholder="Floor Name" autocomplete="off"
                formControlName="floor_name">
              <div class="text-danger"
                *ngIf="(floorFormControl.floor_name.touched || submittedBasement) && floorFormControl.floor_name.errors?.required">
                Floor name is required
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="modal-footer border-0">
                <button type="submit" class="btn border-0 text-white mt-4"><i class="fas fa-plus-square"></i> Add to
                floor plan</button>
              </div>
            </div>
          </div>
        </form>

        <form [formGroup]="areaFormFrom" (ngSubmit)="onSubmitAreaFrom()" *ngIf="selectedPlan=='other_areas'">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="exampleInputSurvey">Area Name</label>
                <input type="text" class="form-control" placeholder="Area Name" autocomplete="off" value=""
                  formControlName="area_name">
                <div class="text-danger"
                  *ngIf="(areaFormFromControl.area_name.touched || submittedBasement) && areaFormFromControl.area_name.errors?.required">
                  Area name is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="exampleInputSurvey">Are we moving the contents of this area?</label>
                <div class="form-check-inline w-100">
                  <select class="form-control  border" formControlName="area_ntg">
                    <option value="">--Select--</option>
                    <option value="TG">Yes: To Go</option>
                    <option value="NTG">No: Not To Go</option>
                    <option value="MTG">Not sure: Maybe To Go</option>
                  </select>
                </div>
                <div class="text-danger"
                  *ngIf="(areaFormFromControl.area_ntg.touched || submittedBasement) && areaFormFromControl.area_ntg.errors?.required">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this area
                </label>
                <input type="number" class="form-control" placeholder="Area Box Estimate" min="0" autocomplete="off"
                  value="" formControlName="area_box_estimate">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this area?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="area_packing">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="area_packing">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Have we been able to see the area?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="area_seen">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="area_seen">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this area? </label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_carpets">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_carpets">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains/blinds in this area? </label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_curtains_blinds">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_curtains_blinds">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 sumit_popup">
            <button type="submit" class="btn border-0 text-white pl-4 pr-4"><i class="fas fa-plus-square"></i> Add to
              floor plan</button>
          </div>
        </form>

        <form [formGroup]="loftForm" (ngSubmit)="onSubmitLoft()" *ngIf="selectedPlan=='lofts'">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group test5">
                <label for="exampleInputSurvey">Loft Name</label>
                <input type="text" class="form-control" placeholder="Loft Name" autocomplete="off" value=""
                  formControlName="loft_name">
                <div class="text-danger"
                  *ngIf="(loftFormControl.loft_name.touched || submittedBasement) && loftFormControl.loft_name.errors?.required">
                  Loft name is required
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group ">
                <label for="exampleInputSurvey" class="w-100 mb-4">Are we moving the contents of this loft?</label>
                <select class="form-control  border" id="exampleFormControlSelect2" formControlName="loft_ntg">
                  <option value="">--Select--</option>
                  <option value="TG">Yes: To Go</option>
                  <option value="NTG">No: Not To Go</option>
                  <option value="MTG">Not sure: Maybe To Go</option>
                </select>
                <div class="text-danger"
                  *ngIf="(loftFormControl.loft_ntg.touched || submittedBasement) && loftFormControl.loft_ntg.errors?.required">
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="exampleInputSurvey">Estimate the number of boxes required for the loose items in this loft
                </label>
                <input type="number" class="form-control" placeholder="Loft Box Estimate" min="0" autocomplete="off"
                  value="" formControlName="loft_box_estimate">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we packing the contents of this loft?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="loft_packing">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="loft_packing">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Have we been able to see the loft?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="loft_seen">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="loft_seen">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Has the home owner agreed to empty this loft?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="loft_client_to_empty">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="loft_client_to_empty">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Has this loft got a fixed ladder for access?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="loft_fixed_ladders">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="loft_fixed_ladders">No
                    </label>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Is this loft fully boarded?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="loft_fully_boarded">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="loft_fully_boarded">No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Is this loft fully lit?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="loft_fully_lit">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="loft_fully_lit">No
                    </label>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the carpets in this loft?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_carpets">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_carpets">No
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="row">

            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the light fittings in this loft?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_light_fittings">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_light_fittings">No
                    </label>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group one-line-flex">
                <label for="exampleInputSurvey" class="mb-4">Are we moving the curtains/blinds in this loft?</label>
                <div class="answer-boxed">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                        formControlName="move_curtains_blinds">Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                        formControlName="move_curtains_blinds">No
                    </label>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="modal-footer border-0 sumit_popup">
            <button type="submit" class="btn border-0 text-white pl-4 pr-4"><i class="fas fa-plus-square"></i> Add to
              floor plan</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</section>