<form class="uploadfile12" [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
  <div class="row border mb-4 p-4 upload-images-notes">  
    <div class="col-lg-6">      
      <div class="form-group files text-center">
        <input type="file" accept="image/png, image/gif, image/jpeg" formControlName="image" (change)="onFileChange($event)">
        <div class="upload">
          <button  class="btn btn-primary mt-2  mb-2 border-0 text-white font-weight-bold">Upload File</button>
          <!-- <h6>Drag & drop multiple files to upload</h6> -->
        </div>
        <div class="text-danger"
        *ngIf="(submitted) && UploadFormControl.image.errors?.required">
        Upload Image
      </div>
      </div>      
    </div>
    <div class="col-lg-6">
      <div class="form-group mb-0">      
        <textarea class="form-control border mb-0" rows="5" placeholder="Image Notes" formControlName="image_note"></textarea>
        <!-- <div class="text-danger"
        *ngIf="(UploadFormControl.image_note.touched || submitted) && UploadFormControl.image_note.errors?.required">
        Image Note is required
      </div> -->
      </div>
    </div>
    <div class="row mt-2 w-100">  
      <div class="col-lg-12"> 
        <div class="show-demo">
        <img [src]="imageSrc" *ngIf="imageSrc">
      </div>
      <div class="col-lg-12 text-center mt-3">
        <button type="submit" class="btn my-2 my-sm-0 text-white theme-bg">Save</button>
      </div>
      </div>
    </div>
    
  </div>
</form>

<div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>
<div class="table-responsive">
<table class="w-100 img-data-table">
  <tr>
    <th>Image</th>
    <th>Note</th>
    <th>Time</th>
    <th style="text-align: right;">Action</th>
  </tr>
  <tr class="alert alert-danger text-center" *ngIf="(ImportSurveyList.images?.length <= 0) && !loader">
    <td colspan="4">No images Uploaded</td>    
  </tr>
  <ng-container *ngIf="(ImportSurveyList.images?.length > 0)">
    <tr *ngFor="let image of ImportSurveyList.images;let imageRow=index;" class="submit-img">
      <td class="submit-img-inner" style="width: 20%;"><img src="{{image.image}}&token={{userToken}}" *ngIf="!image.image_type" (click)="openGalleryModal(imageRow)"style="cursor: pointer;">
        <img src="{{image.image}}" *ngIf="image.image_type" (click)="openGalleryModal(imageRow)" style="cursor: pointer;"></td>
      <td style="width:40%;">{{image.image_note}}</td>
      <td style="width: 20%;">{{image.image_time}}</td>
      <td style="width: 30%; text-align:right;"><img src="../assets/img/eyes.png" class="eys-icon btn p-0" (click)="openGalleryModal(imageRow)">
        <img src="../assets/img/edit-icon.png" class="btn p-0 edit-icon" (click)="open(EditNotesmodal); setEditNoteValue(imageRow,image.image_note)">
        <img src="../assets/img/notesdelet.svg" class="btn p-0" (click)="deleteImage(imageRow)"></td>
    </tr>
  </ng-container>
  
</table>
</div>
<!-- <ul class="list-group" *ngIf="(ImportSurveyList.images?.length <= 0) && !loader">
  <li class="list-unstyled w-100 mb-2 alert alert-danger text-center">
    <span class="">No images Uploaded</span>
  </li>
</ul>


<ul class="list-group" *ngIf="(ImportSurveyList.images?.length > 0)">
  <li class="list-group-item shadow-sm d-flex justify-content-between align-items-center bg-white mb-2">
    <span class="font-weight-bold">Image</span>
    <span class="font-weight-bold">Note</span>
    <span class="font-weight-bold">Time</span>
    <span class="font-weight-bold">Action</span>
  </li>
  
  <li class="list-group-item shadow-sm d-flex justify-content-between align-items-center bg-white mb-2"
    *ngFor="let image of ImportSurveyList.images;let imageRow=index;">
    <img src="{{image.image}}&token={{userToken}}" *ngIf="!image.image_type">
    <img src="{{image.image}}" *ngIf="image.image_type">
    <span class="">{{image.image_note}}</span>
    <span class="">{{image.image_time}}</span>
    <span class="notes-icon">
      <img src="../assets/img/eyes.png" class="btn p-0" (click)="openGalleryModal(imageRow)">
      <img src="../assets/img/edit-icon.png" class="btn p-0 edit-icon" (click)="open(EditNotesmodal); setEditNoteValue(imageRow,image.image_note)">
      <img src="../assets/img/notesdelet.svg" class="btn p-0" (click)="deleteImage(imageRow)">
    </span>
  </li>
</ul> -->

<ng-template #EditNotesmodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    
    <form [formGroup]="NotesForm" (ngSubmit)="editNote()">
      <input type="hidden" value="{{imageIndex}}" formControlName="imageIndex" />
      <label for="exampleInputEmail1">Image Note</label>
      <textarea class="form-control mb-0 border" rows="5" placeholder="Write Note..." formControlName="image_note"> </textarea>
      
      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Update</button>
      </div>
    </form>
  </div>
  
</ng-template>