import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { IndexedDBService } from '../services/indexed-db.service';
import { EventEmitterService } from '../model/event-emitter.service'; 
import Swal from 'sweetalert2';

@Component({
  selector: 'app-property-information',
  templateUrl: './property-information.component.html',
  styleUrls: ['./property-information.component.css']
})
export class PropertyInformationComponent implements OnInit {
  @Input() ImportSurveyList: any;
  @Input() loader: any;
  @Input() currentTabTypeid: any;
  surveyId: any;
  reportId!: any;
  submitted = false;
  submittedTo = false;
  PropertyMessage="";
  propertyInfoForm = new FormGroup({});
  propertyInfoFormTo = new FormGroup({}); 
  isFlatSelectFrom=false;
  isFlatSelectTo=false;  
  constructor(private formBuilder: FormBuilder, private router: Router, private _Activatedroute:ActivatedRoute, private eventEmitterService: EventEmitterService, private IndexedDBService: IndexedDBService) { }

  
  ngOnInit(): void {  
    
    this.surveyId=this._Activatedroute.snapshot.paramMap.get("sid");
    this.reportId=this._Activatedroute.snapshot.paramMap.get("rid");   
    
    this._Activatedroute.queryParams.subscribe(params => {         
      this.currentTabTypeid = params.type;               
    }); 
    
    /***property From form value assign */
    this.propertyInfoForm = this.formBuilder.group({    
      add1: new FormControl(this.ImportSurveyList.property.from.add1),      
      property_type: new FormControl(this.ImportSurveyList.property.from.property_type==null ? '':this.ImportSurveyList.property.from.property_type),   
      property_floor : new FormControl(this.ImportSurveyList.property.from.property_floor),    
      add2 : new FormControl(this.ImportSurveyList.property.from.add2),    
      estate_agent : new FormControl(this.ImportSurveyList.property.from.estate_agent),    
      estate_agent_name : new FormControl(this.ImportSurveyList.property.from.estate_agent_name),    
      add3 : new FormControl(this.ImportSurveyList.property.from.add3),    
      bedroom_count : new FormControl(this.ImportSurveyList.property.from.bedroom_count),    
      ownership : new FormControl(this.ImportSurveyList.property.from.ownership),    
      add4 : new FormControl(this.ImportSurveyList.property.from.add4),    
      new_build : new FormControl(this.ImportSurveyList.property.from.new_build),    
      country : new FormControl(this.ImportSurveyList.property.from.country),    
      postcode : new FormControl(this.ImportSurveyList.property.from.postcode),      
    });

    if(this.ImportSurveyList.property.from.property_type=="flat"){
      this.isFlatSelectFrom=true;
    }
    

    /***property To form value assign */
    this.propertyInfoFormTo = this.formBuilder.group({    
      add1: new FormControl(this.ImportSurveyList.property.to.add1),      
      property_type: new FormControl(this.ImportSurveyList.property.to.property_type==null ? '':this.ImportSurveyList.property.to.property_type),   
      property_floor : new FormControl(this.ImportSurveyList.property.to.property_floor),    
      add2 : new FormControl(this.ImportSurveyList.property.to.add2),    
      estate_agent : new FormControl(this.ImportSurveyList.property.to.estate_agent),    
      estate_agent_name : new FormControl(this.ImportSurveyList.property.to.estate_agent_name),    
      add3 : new FormControl(this.ImportSurveyList.property.to.add3),    
      bedroom_count : new FormControl(this.ImportSurveyList.property.to.bedroom_count),    
      ownership : new FormControl(this.ImportSurveyList.property.to.ownership),    
      add4 : new FormControl(this.ImportSurveyList.property.to.add4),    
      new_build : new FormControl(this.ImportSurveyList.property.to.new_build),    
      country : new FormControl(this.ImportSurveyList.property.to.country),    
      postcode : new FormControl(this.ImportSurveyList.property.to.postcode),      
    });   
  }  
 

  get propertyInfoFormControl() {
    return this.propertyInfoForm.controls;
  }

  get propertyInfoFormToControl() {
    return this.propertyInfoFormTo.controls;
  }

  
  onSubmitProperty() {
    this.submitted = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];

    if (this.propertyInfoForm.valid) {
      const propertyFormPayload = {
        formType: this.currentTabTypeid,
        add1: this.propertyInfoForm.controls.add1.value,
        property_type: this.propertyInfoForm.controls.property_type.value,
        property_floor: this.propertyInfoForm.controls.property_floor.value,
        add2: this.propertyInfoForm.controls.add2.value,
        estate_agent: this.propertyInfoForm.controls.estate_agent.value,
        estate_agent_name: this.propertyInfoForm.controls.estate_agent_name.value,
        add3: this.propertyInfoForm.controls.add3.value,
        bedroom_count: this.propertyInfoForm.controls.bedroom_count.value,
        ownership: this.propertyInfoForm.controls.ownership.value,
        add4: this.propertyInfoForm.controls.add4.value,
        new_build: this.propertyInfoForm.controls.new_build.value,
        country: this.propertyInfoForm.controls.country.value,
        postcode: this.propertyInfoForm.controls.postcode.value,        
      }       
     
      this.IndexedDBService.addPropertyFormData(propertyFormPayload, surveyPayload).then((response: any) => {             
            
        if(response) {
          Swal.fire('Updated', 'Property updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            console.log(getResponse.data);         
            this.eventEmitterService.surveyImportData(getResponse.data); 
            this.propertyInfoForm.markAsPristine();                   
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });  
    }
   // this.invalidLogin = true;     
  }


  onSubmitPropertyTo() {
    this.submittedTo = true;
    let surveyPayload=[Number(this.ImportSurveyList.survey_id), Number(this.ImportSurveyList.surveyor_id), Number(this.ImportSurveyList.survey_format_version), Number(this.reportId)];

    if (this.propertyInfoFormTo.valid) {
      const propertyFormPayload = {
        formType: this.currentTabTypeid,
        add1: this.propertyInfoFormTo.controls.add1.value,
        property_type: this.propertyInfoFormTo.controls.property_type.value,
        property_floor: this.propertyInfoFormTo.controls.property_floor.value,
        add2: this.propertyInfoFormTo.controls.add2.value,
        estate_agent: this.propertyInfoFormTo.controls.estate_agent.value,
        estate_agent_name: this.propertyInfoFormTo.controls.estate_agent_name.value,
        add3: this.propertyInfoFormTo.controls.add3.value,
        bedroom_count: this.propertyInfoFormTo.controls.bedroom_count.value,
        ownership: this.propertyInfoFormTo.controls.ownership.value,
        add4: this.propertyInfoFormTo.controls.add4.value,
        new_build: this.propertyInfoFormTo.controls.new_build.value,
        country: this.propertyInfoFormTo.controls.country.value,
        postcode: this.propertyInfoFormTo.controls.postcode.value,        
      }
       
      this.IndexedDBService.addPropertyFormData(propertyFormPayload, surveyPayload).then((response: any) => {             
            
        if(response) {
          Swal.fire('Updated', 'Property updated succesfully!', 'success')
          this.IndexedDBService.getSurveyDetailsLocal(surveyPayload).then((getResponse: any) => {
            console.log(getResponse.data);         
            this.eventEmitterService.surveyImportData(getResponse.data);
            this.propertyInfoFormTo.markAsPristine();            
          });          
        }else{
          Swal.fire('Error', 'Something went wrong!', 'error');
        } 
      });  
    }
   // this.invalidLogin = true;     
  }


  tabTypeClickFunction(tabType:any){    
    this.eventEmitterService.ontabTypeClick(tabType);    
  }


  onPropertySelectedFrom(event:any) {    
    if(event.target.value=='flat'){
      this.propertyInfoForm.patchValue({
        property_floor : ''
      })
      this.isFlatSelectFrom=true;
      
    }else{
      this.isFlatSelectFrom=false;
    }
  }

  onPropertySelectedTo(event:any) {    
    if(event.target.value=='flat'){
      this.propertyInfoFormTo.patchValue({
        property_floor : ''
      })
      this.isFlatSelectTo=true;
      
    }else{
      this.isFlatSelectTo=false;
    }    
  }


}
