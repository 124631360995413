<app-sidebar-survey></app-sidebar-survey>

<app-header-inner></app-header-inner>



<div class="table_list floor_rooms12 ">
  <div class="survey12 w-100 d-inline-block">
    <h6 class="mb-4"><u>Survey Name > Floor > Ground floor > Lounge</u></h6>
    <button class="btn my-2 my-sm-0 text-white">Add Inventory</button>
  </div>
  <section id="tabs" class=" floorinner tabs_project bg-white p-4 property_info rounded-lg w-100 d-inline-block">
    <nav class="mb-4 w-100 d-inline-block">
      <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active border-0" id="nav-home-tab" data-toggle="tab" href="#nav-Inventory"
          role="tab"
          aria-controls="nav-Info" aria-selected="true">Inventory</a>
        <a class="nav-item nav-link border-0" id="nav-profile-tab" data-toggle="tab" href="#nav-Info" role="tab"
        aria-controls="nav-Parking" aria-selected="false">Room Info</a>
        <a class="nav-item nav-link border-0" id="nav-profile-tab" data-toggle="tab" href="#nav-Materials" role="tab"
        aria-controls="nav-Parking" aria-selected="false">Packing Materials</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-Inventory" role="tabpanel" aria-labelledby="nav-home-tab">
        <form class="roomsadd12">

          <div class="navbar justify-content-between p-3 mb-3 rounded notes">
            <h6 class="pt-2"><b>Washing Machine</b></h6>
            <form _ngcontent-jqx-c100="" novalidate="" class="form-inline ng-untouched ng-pristine ng-valid">
              <img src="../assets/img/notesedit.svg">
              <img src="../assets/img/notesdelet.svg">
            </form>
          </div>

          <div class="navbar justify-content-between p-3 mb-3 rounded notes">
            <h6 class="pt-2"><b>Microwave</b></h6>
            <form _ngcontent-jqx-c100="" novalidate="" class="form-inline ng-untouched ng-pristine ng-valid">
              <img src="../assets/img/notesedit.svg">
              <img src="../assets/img/notesdelet.svg">
            </form>
          </div>

          <div class="navbar justify-content-between p-3 mb-3 rounded notes">
            <h6 class="pt-2"><b>Dining chair</b></h6>
            <form _ngcontent-jqx-c100="" novalidate="" class="form-inline ng-untouched ng-pristine ng-valid">
              <img src="../assets/img/notesedit.svg">
              <img src="../assets/img/notesdelet.svg">
            </form>
          </div>

          <div class="navbar justify-content-between p-3 mb-3 rounded notes">
            <h6 class="pt-2"><b>Table</b></h6>
            <form _ngcontent-jqx-c100="" novalidate="" class="form-inline ng-untouched ng-pristine ng-valid">
              <img src="../assets/img/notesedit.svg">
              <img src="../assets/img/notesdelet.svg">
            </form>
          </div>

        </form>
        <form class="roomsadd12 add_items12lounge">
         <h6 class="mb-4"><b>Add Items</b></h6>

        <div class="main123">

          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search">
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button">Add Manual</button>
            </div>
          </div>

          <div class="Quantity w-100 d-inline-block mt-3">
            <input type="text" class="form-control Quantit12" placeholder="Quantity">

            <div class="radios_12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
              <label class="form-check-label" for="inlineRadio1">TG</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
              <label class="form-check-label" for="inlineRadio2">MTG</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option2">
              <label class="form-check-label" for="inlineRadio3">NTG</label>
            </div>
            </div>

          </div>

          <div class="dismissible w-100 d-inline-block">
            <h6>Dismantling required</h6>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
            
          </div>

           <div class="dismissible w-100 d-inline-block mt-3">
             <h6>Re-assemble required</h6>

             <div class="form-check form-check-inline">
               <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
               <label class="form-check-label" for="inlineRadio1">Yes</label>
             </div>
             <div class="form-check form-check-inline">
               <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
               <label class="form-check-label" for="inlineRadio2">No</label>
             </div>

           </div>

        </div>
         
        </form>

      </div>
        <div class="tab-pane fade" id="nav-Info" role="tabpanel" aria-labelledby="nav-profile-tab">
          <div class="dismissible w-100 d-inline-block mt-3">
            <h6>Is the carpet going ?</h6>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                value="option1">
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                value="option2">
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>

          </div>
        </div>

        <div class="tab-pane fade" id="nav-Materials" role="tabpanel" aria-labelledby="nav-profile-tab">
        
          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="SmallBoxes" checked>
          <label class="form-check-label" for="SmallBoxes">
          Small Boxes
          </label>
          </div>
          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="MediumBoxes" checked>
          <label class="form-check-label" for="MediumBoxes">
          Medium Boxes
          </label>
          </div>
          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="LargeBoxes" checked>
          <label class="form-check-label" for="LargeBoxes">
          Large Boxes
          </label>
          </div>
          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="LayFlatBoxes"
          checked>
          <label class="form-check-label" for="LayFlatBoxes">
          Lay Flat Boxes
          </label>
          </div>
          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="WardrobeBox"
          checked>
          <label class="form-check-label" for="WardrobeBox">
          Wardrobe Box
          </label>
          </div>

          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="MattressBagSmall" checked>
          <label class="form-check-label" for="MattressBagSmall">
          Mattress Bag Small
          </label>
          </div>

          <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="" id="MattressBagLarge" checked>
          <label class="form-check-label" for="MattressBagLarge">
          Mattress Bag Large
          </label>
          </div>


        </div>

    </div>
  </section>

</div>