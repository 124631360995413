<div class="login_account d-md-flex align-items-center justify-content-between">
  <div class="box-1 mt-md-0">
    <img src="../assets/img/bg.jpg">
    
  </div>
  <div class=" box-2 d-flex flex-column">
    <img class="forgotten-pass" src="../assets/img/Forgotten.svg">
    <p class="h1 text-center font-weight-bold mb-4">Forgotten You Password ?</p>
    <h6 class="text-center mb-5">Please enter you email below, and we <br>will send you reset link</h6>
    <div class="alert alert-success py-2" *ngIf="message!==''">
      {{message}}
    </div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <div class="error-msg-box position-relative">
        <div class="text-danger"
        *ngIf="(forgotPasswordFormControl.email.touched || submitted) && forgotPasswordFormControl.email.errors?.required">
        Email is required
      </div>
      <div class="alert alert-danger mt-2 py-2" *ngIf="error_message!==''">
        {{error_message}}
      </div>
        </div>
      <div class="form-group user_name">
        <label for="exampleInputEmail1">Email</label>
        <div class="input-custom-icon">
        <img src="../assets/img/mail.svg">
        <input type="text" class="form-control" formControlName="email" id="email" placeholder="Email"
          autocomplete="off">
      </div>
        
      </div>
      
      
      <button type="submit" class="btn btn-primary w-100 border-0 text-white font-weight-bold p-3" [disabled]="forgotPasswordForm.invalid">Submit</button>
      <div class="text-center mt-4 float-right">
         <a class="fw-bolder text-dark" routerLink="/login">Back to log in</a>
      </div>
    </form>
  </div> 
</div>