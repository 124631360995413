<app-sidebar-survey></app-sidebar-survey>

<app-header-inner></app-header-inner>



<div class="table_list floor_names">
  <div class="survey12 w-100 d-inline-block">
  <h6 class="mb-4"><u>Survey Name > Floor > Ground floor >Room List</u></h6>
  <button class="btn my-2 my-sm-0 text-white">Add Room</button>
  </div>
  <section id="tabs" class=" floorinner tabs_project bg-white p-4 property_info rounded-lg">
    <nav class="mb-4 w-100 d-inline-block">
      <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active border-0" id="nav-home-tab" data-toggle="tab" href="#nav-from" role="tab"
          aria-controls="nav-Info" aria-selected="true">From</a>
        <a class="nav-item nav-link border-0" id="nav-profile-tab" data-toggle="tab" href="#nav-to" role="tab"
          aria-controls="nav-Parking" aria-selected="false">To</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-from" role="tabpanel" aria-labelledby="nav-home-tab">
        <form>

          <div class="navbar justify-content-between p-3 mb-3 rounded notes">
            <h6 class="pt-2"><b>Lounge</b></h6>
            <form _ngcontent-jqx-c100="" novalidate="" class="form-inline ng-untouched ng-pristine ng-valid">
              <img src="../assets/img/pluseroom.svg">
              <img src="../assets/img/eyes.svg">
              <img src="../assets/img/notesedit.svg">
              <img src="../assets/img/notesdelet.svg">
            </form>
          </div>

          <div class="navbar justify-content-between p-3 mb-3 rounded notes">
            <h6 class="pt-2"><b>Kitchen</b></h6>
            <form _ngcontent-jqx-c100="" novalidate="" class="form-inline ng-untouched ng-pristine ng-valid">
              <img src="../assets/img/pluseroom.svg">
              <img src="../assets/img/eyes.svg">
              <img src="../assets/img/notesedit.svg">
              <img src="../assets/img/notesdelet.svg">
            </form>
          </div>

        </form>
      </div>
      <div class="tab-pane fade" id="nav-to" role="tabpanel" aria-labelledby="nav-profile-tab">
        <h6 class="pl-0 pt-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the 1500s,</h6>
      </div>

    </div>
  </section>

</div>