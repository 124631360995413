<section id="tabs" class="shadow project-tab tabs_project bg-white p-4 property_info rounded-lg">
  <nav class="mb-4">
    <div class="nav nav-tabs nav-fill border-0" id="nav-tab" role="tablist">
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'from' ? 'active' : ''}}" id="nav-home-tab"
        data-toggle="tab" href="#nav-from" role="tab" aria-controls="nav-Info" aria-selected="true"
        (click)="tabTypeClickFunction('from');">From</a>
      <a class="nav-item nav-link border-0 {{currentTabTypeid == 'to' ? 'active' : ''}}" id="nav-profile-tab"
        data-toggle="tab" href="#nav-to" role="tab" aria-controls="nav-Parking" aria-selected="false"
        (click)="tabTypeClickFunction('to');">To</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div class="w-100 mb-2 text-center" *ngIf="loader">Loading...</div>

    <div class="tab-pane fade {{currentTabTypeid == 'from' ? 'show active' : ''}}" id="nav-from" role="tabpanel"
      aria-labelledby="nav-home-tab" *ngIf="ImportSurveyList.property?.from">
      <form [formGroup]="propertyInfoForm" (ngSubmit)="onSubmitProperty()">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="add1">Address</label>
              <input type="text" class="form-control" placeholder="Address" autocomplete="off" value=""
                formControlName="add1">
            </div>
            <div class="form-group">
              <!--<label for="add2"></label>-->
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add2">
            </div>
            <div class="form-group">
              <!--<label for="add3"></label>-->
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add3">
            </div>
            <div class="form-group">
              <!--<label for="add4"></label>-->
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add4">
            </div>
            <div class="form-group">
              <label for="postcode">Postcode</label>
              <input type="text" class="form-control" placeholder="Postcode" autocomplete="off" value=""
                formControlName="postcode">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Property Type</label>
              <select class="form-control form-control-custom" formControlName="property_type"
                (change)='onPropertySelectedFrom($event)'>
                <option value="">Select</option>
                <option value="bungalow">Bungalow</option>
                <option value="dormer">Dormer</option>
                <option value="flat">Flat</option>
                <option value="house detached">House Detached</option>
                <option value="house semi detached">House Semi Detached</option>
                <option value="house terraced">House Terraced</option>
                <option value="house townhouse">House Townhouse</option>
                <option value="maisonette single story">Maisonette Single Story</option>
                <option value="maisonette multi story">Maisonette Multi Story</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="form-group" *ngIf="isFlatSelectFrom">
              <label for="exampleInputSurvey">Property Floor</label>
              <input type="number" min="0" class="form-control" placeholder="Property Floor" autocomplete="off" value=""
                formControlName="property_floor">
            </div>
            <div class="form-group">
              <label for="exampleInputSurvey">Bedroom Count</label>
              <input type="number" class="form-control" placeholder="Bedroom Count" autocomplete="off" value=""
                formControlName="bedroom_count">
            </div>
          </div>
          <div class="col">
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">New Build</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="true"
                    [checked]="true">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="false"
                    [checked]="false">No
                </label>
              </div>
            </div>
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">Estate Agent</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="estate_agent">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="estate_agent">No
                </label>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputSurvey">Estate Agent Name</label>
              <input type="text" class="form-control" placeholder="Estate Agent Name" autocomplete="off" value=""
                formControlName="estate_agent_name">
            </div>
          </div>
        </div>


        <!-- shit layour by Gurp-->
        <!--
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Address</label>
              <input type="text" class="form-control" placeholder="Address" autocomplete="off" value=""
                formControlName="add1">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Property Type</label>
              <select class="form-control form-control-custom" formControlName="property_type"
                (change)='onPropertySelectedFrom($event)'>
                <option value="">Select</option>
                <option value="bungalow">Bungalow</option>
                <option value="dormer">Dormer</option>
                <option value="flat">Flat</option>
                <option value="house detached">House Detached</option>
                <option value="house semi detached">House Semi Detached</option>
                <option value="house terraced">House Terraced</option>
                <option value="house townhouse">House Townhouse</option>
                <option value="maisonette single story">Maisonette Single Story</option>
                <option value="maisonette multi story">Maisonette Multi Story</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group" *ngIf="!isFlatSelectFrom">
              <label for="exampleInputSurvey">Property Floor</label>
              <input type="number" min="0" class="form-control" placeholder="Property Floor" autocomplete="off" value=""
                formControlName="property_floor">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey"></label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add2">
            </div>
          </div>

          <div class="col">
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">Estate Agent</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="estate_agent">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="estate_agent">No
                </label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Estate Agent Name</label>
              <input type="text" class="form-control" placeholder="Estate Agent Name" autocomplete="off" value=""
                formControlName="estate_agent_name">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey"></label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add3">
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Bedroom Count</label>
              <input type="number" class="form-control" placeholder="Bedroom Count" autocomplete="off" value=""
                formControlName="bedroom_count">
            </div>
          </div>
          <div class="col">
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">New Build</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="true"
                    [checked]="true">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="false"
                    [checked]="false">No
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey"></label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add4">
            </div>
          </div>

          <div class="col"></div>
          <div class="col"></div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Postcode</label>
              <input type="text" class="form-control" placeholder="Postcode" autocomplete="off" value=""
                formControlName="postcode">
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>

        </div>
      -->
        <button type="submit" class="btn btn-primary mt-4 mb-4 text-white"><i class="far fa-save"></i> Save Changes</button>
      </form>
    </div>

    <!---------------------form To start--------------------------------------------->
    <div class="tab-pane fade {{currentTabTypeid == 'to' ? 'show active' : ''}}" id="nav-to" role="tabpanel"
      aria-labelledby="nav-profile-tab" *ngIf="ImportSurveyList.property?.to">
      <form [formGroup]="propertyInfoFormTo" (ngSubmit)="onSubmitPropertyTo()">

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="add1">Address</label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add1">
            </div>
            <div class="form-group">
              <!--<label for="add2"></label>-->
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add2">
            </div>
            <div class="form-group">
              <!--<label for="add3"></label>-->
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add3">
            </div>
            <div class="form-group">
              <!--<label for="add4"></label>-->
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add4">
            </div>
            <div class="form-group">
              <label for="poscode">Postcode</label>
              <input type="text" class="form-control" placeholder="Postcode" autocomplete="off" value=""
                formControlName="postcode">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Property Type</label>
              <select class="form-control form-control-custom" formControlName="property_type"
                (change)='onPropertySelectedTo($event)'>
                <option value="">Select</option>
                <option value="bungalow">Bungalow</option>
                <option value="dormer">Dormer</option>
                <option value="flat">Flat</option>
                <option value="house detached">House Detached</option>
                <option value="house semi detached">House Semi Detached</option>
                <option value="house terraced">House Terraced</option>
                <option value="house townhouse">House Townhouse</option>
                <option value="maisonette single story">Maisonette Single Story</option>
                <option value="maisonette multi story">Maisonette Multi Story</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div class="form-group" *ngIf="isFlatSelectTo">
              <label for="exampleInputSurvey">Property Floor</label>
              <input type="number" min="0" class="form-control" placeholder="Property Floor" autocomplete="off" value=""
                formControlName="property_floor">
            </div>
            <div class="form-group">
              <label for="exampleInputSurvey">Bedroom Count</label>
              <input type="number" class="form-control" placeholder="Bedroom Count" autocomplete="off" value=""
                formControlName="bedroom_count">
            </div>
          </div>
          <div class="col">
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">New Build</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="true"
                    [checked]="true">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="false"
                    [checked]="false">No
                </label>
              </div>
            </div>
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">Estate Agent</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="estate_agent">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="estate_agent">No
                </label>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputSurvey">Estate Agent Name</label>
              <input type="text" class="form-control" placeholder="Estate Agent Name" autocomplete="off" value=""
                formControlName="estate_agent_name">
            </div>
          </div>
        </div>


        
        <!-- more bonkers layour by Gurp-->
        <!--
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Address</label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add1">
            </div>

          </div>
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Property Type</label>
              <select class="form-control form-control-custom" formControlName="property_type"
                (change)='onPropertySelectedTo($event)'>
                <option value="">Select</option>
                <option value="bungalow">Bungalow</option>
                <option value="dormer">Dormer</option>
                <option value="flat">Flat</option>
                <option value="house detached">House Detached</option>
                <option value="house semi detached">House Semi Detached</option>
                <option value="house terraced">House Terraced</option>
                <option value="house townhouse">House Townhouse</option>
                <option value="maisonette single story">Maisonette Single Story</option>
                <option value="maisonette multi story">Maisonette Multi Story</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group" *ngIf="!isFlatSelectTo">
              <label for="exampleInputSurvey">Property Floor</label>
              <input type="number" min="0" class="form-control" placeholder="Property Floor" autocomplete="off" value=""
                formControlName="property_floor">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey"></label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add2">
            </div>
          </div>

          <div class="col">
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">Estate Agent</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="true" [checked]="true"
                    formControlName="estate_agent">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" [value]="false" [checked]="false"
                    formControlName="estate_agent">No
                </label>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Estate Agent Name</label>
              <input type="text" class="form-control" placeholder="Estate Agent Name" autocomplete="off" value=""
                formControlName="estate_agent_name">
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey"></label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add3">
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Bedroom Count</label>
              <input type="number" class="form-control" placeholder="Bedroom Count" autocomplete="off" value=""
                formControlName="bedroom_count">
            </div>
          </div>
          <div class="col">
            <div class="form-group stateownerageent">
              <label for="exampleInputSurvey" class="w-100 mb-4">New Build</label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="true"
                    [checked]="true">Yes
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" formControlName="new_build" [value]="false"
                    [checked]="false">No
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey"></label>
              <input type="text" class="form-control" placeholder="" autocomplete="off" value="" formControlName="add4">
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="exampleInputSurvey">Postcode</label>
              <input type="text" class="form-control" placeholder="Postcode" autocomplete="off" value=""
                formControlName="postcode">
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
        -->
        <button type="submit" class="btn btn-primary mt-4 mb-4 text-white"><i class="far fa-save"></i>  Save Changes</button>
      </form>
    </div>
  </div>
</section>