<app-sidebar></app-sidebar>

<app-header-inner></app-header-inner>


<div class="table_list">

  <ul class="list_survey pt-0 pb-0 pl-3 pr-3">
    <li class="font-weight-bold">Survey</li>
    <li class="font-weight-bold">Date</li>
    <li class="font-weight-bold">Time</li>
    <li class="font-weight-bold">Action</li>
  </ul>
  <hr>

  <ul class="list_survey inner_list pt-3 pb-3 pl-3 pr-3 mb-2 bg-white w-100 d-inline-block">
    <li class="">Claire Smith</li>
    <li class="">Wednesday 13th October 2021</li>
    <li class="">Morning</li>
    <!-- <li class="">{{(ImportSurveyList.survey_type != null) ? ImportSurveyList.survey_type : '--'}}</li> -->
    <li class="">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle p-0" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="../assets/img/dropdown-dots.svg">
        </button>
        <div class="dropdown-menu p-0 border-0 rounded shadow bg-light" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#"><img src="../assets/img/importfile.svg">Import</a>
        </div>
      </div>
    </li>
  </ul>

  <ul class="list_survey inner_list pt-3 pb-3 pl-3 pr-3 mb-2 bg-white w-100 d-inline-block">
    <li class="">Claire Smith</li>
    <li class="">Wednesday 13th October 2021</li>
    <li class="">Morning</li>
    <!-- <li class="">{{(ImportSurveyList.survey_type != null) ? ImportSurveyList.survey_type : '--'}}</li> -->
    <li class="">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle p-0" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="../assets/img/dropdown-dots.svg">
        </button>
        <div class="dropdown-menu p-0 border-0 rounded shadow bg-light" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#"><img src="../assets/img/importfile.svg">Import</a>
        </div>
      </div>
    </li>
  </ul>



</div>


<ng-template #mymodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form>
      <label for="exampleInputEmail1">Survey Name</label>
      <input type="text" class="form-control mb-0" formControlName="username" id="username" placeholder="username">
    </form>
  </div>
  <div class="modal-footer border-0 sumit_popup">
    <button type="button" class="btn border-0 text-white pl-4 pr-4" (click)="modal.close('Save click')">Add</button>
  </div>
</ng-template>