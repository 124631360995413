import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs/index";
import { ApiResponse } from "../model/api.response";
import { General } from "../model/general";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private general: General = new General();

  constructor(private http: HttpClient) { }
  baseUrl: string = 'https://benwillson.com/cube/api/';    
  
  login(loginPayload: { email: any; password: any; code: any; '2factortoken': any; }): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'surveyor/login', this.general.getFormData(loginPayload));
  }

  loginStatus(statusPayload: {}): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'surveyor/login/status', statusPayload);
  }

  logout(logoutPayload: {}): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'surveyor/logout', logoutPayload);
  }

  forgotPassword(passwordPayload: { email: any; step:any; }): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'surveyor/login/reset', this.general.getFormData(passwordPayload));
  }

  surveyImportList(surveyListPayload: {date: any; limit:any; after: any; page:any; }): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v2/surveyor/survey/import/list', {params: surveyListPayload});
  }


  getSurveyImportDetails(surveyImportPayload: {survey_id: any; report_id:any; }): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v2/surveyor/survey/import', {params: surveyImportPayload});
  }

  getItemList(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v2/surveyor/item/list');
  }

  getCategoryList(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v2/surveyor/type/list');
  }

  getTemplateList(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v2/surveyor/template/list');
  }

  getTemplateDetails(templatePayload:{template_id: any;}): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v2/surveyor/template/import', {params: templatePayload});
  }
  
  uploadSurveyData(surveyDataPayload:any): Observable<ApiResponse> {    
    return this.http.post<ApiResponse>(this.baseUrl + 'v2/surveyor/survey/upload', surveyDataPayload);
  }
  
  uploadSurveyImages(surveyImagesPayload:{image_number:any; step:any; upload_id: any; image:any; image_time:any; image_note:any}): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'v2/surveyor/survey/upload', this.general.getFormData(surveyImagesPayload));
  }
  
  getSurveyorDetails(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'surveyor/surveyor/view');
  }
  
  
}
