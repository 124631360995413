<nav class="navbar justify-content-between dashboard_main p-3">
  <a class="navbar-brand">
    <h6>{{pageTitle}}</h6>
  </a>
 
  <form class="form-inline" *ngIf="isNewSurveyButton!=true">
    <button class="btn my-2 my-sm-0 text-white" (click)="open(Notesmodal)" *ngIf="isNewSurveyButton==false">Add Notes</button>
    <button class="btn my-2 my-sm-0 text-white" (click)="open(uploadmodal)">Add Image</button>
  </form>
  <!--
  <form class="form-inline" *ngIf="isRealDashboardPage==true">
    <button class="btn my-2 my-sm-0 text-white" (click)="open(mymodal)">Create Survey</button>
  </form>
  -->
</nav>


<ng-template #Notesmodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    
    <form [formGroup]="NotesForm" (ngSubmit)="onSubmit()">
      <label for="exampleInputEmail1">Note Details</label>
      <textarea class="form-control mb-0 border" rows="5" placeholder="Write Note..." formControlName="note"> </textarea>
      <div class="text-danger"
        *ngIf="(NotesFormControl.note.touched || submitted) && NotesFormControl.note.errors?.required">
        Note is required
      </div>
      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
      </div>
    </form>
  </div>
  
</ng-template>

<!--
<ng-template #mymodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form>
      <label for="exampleInputEmail1">Survey Name</label>
      <input type="text" class="form-control mb-0" id="surveyName" placeholder="Survey Name">
    </form>
  </div>
  <div class="modal-footer border-0 sumit_popup">
    <button type="button" class="btn border-0 text-white pl-4 pr-4" (click)="modal.close('Save click')">Add</button>
  </div>
</ng-template>
-->

<!--
<ng-template #mymodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup">
    <form (ngSubmit)="onSubmitNewSurvey(newSurveyForm)" #newSurveyForm="ngForm">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="survey_name">Survey Name</label>
            <input type="text" class="form-control mb-0" placeholder="Survey Name" autocomplete="off" name="survey_name" [ngModel]="" #survey_name="ngModel" required>

            <div class="text-danger" *ngIf="!survey_name.value && submittedNewSurvey">
              Survey Name is Required
            </div>            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="exampleInputSurvey">Survey Date</label>
            <input type="hidden" value="{{todayDateSurvey}}" name="survey_date" [(ngModel)]="todayDateSurvey" #survey_date="ngModel" />
            <div class="made-calender-icon">
              <input class="form-control" placeholder="yyyy-mm-dd" name="survey_datePicker" value="{{todayDateSurvey}}" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateChangeNewSurvey($event)" (click)="d.toggle()" autocomplete="off" >
              <span class="date-span"(click)="d.toggle()" ><img src="../assets/img/calendar.png" alt="calender" style="max-width:23px;"></span>
            </div>           
          </div>
        </div>
      </div>

      <div class="modal-footer border-0 sumit_popup">
        <button type="submit" class="btn border-0 text-white pl-4 pr-4">Add</button>
      </div>

    </form>
  </div>
  
</ng-template>
-->

<ng-template #uploadmodal let-modal>
  <div class="modal-header border-0">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body survey_popup add-picture-model">
    <form class="uploadfile12" [formGroup]="uploadForm" (ngSubmit)="onSubmitUpload()">
      <div class="row p-4 upload-images-notes">  
        <div class="col-md-12">      
          <div class="form-group files text-center">
            <input type="file" accept="image/png, image/gif, image/jpeg" formControlName="image" (change)="onFileChange($event)">
            <div class="upload">
              <button  class="btn btn-primary mt-2 mb-2 border-0 text-white font-weight-bold">Upload File</button>
              <!-- <h6>Drag & drop multiple files to upload</h6> -->
            </div>
            <div class="text-danger text-left"
            *ngIf="(submitted) && UploadFormControl.image.errors?.required">
            Upload Image
          </div>
          </div>      
        </div>
        <div class="col-md-12">
          <div class="form-group mb-0">      
            <textarea class="form-control border mb-0" rows="4" placeholder="Image Notes" formControlName="image_note"></textarea>
            <!-- <div class="text-danger"
            *ngIf="(UploadFormControl.image_note.touched || submitted) && UploadFormControl.image_note.errors?.required">
            Image Note is required
          </div> -->
          </div>
        </div>  
        <div class="col-md-12 mt-3">
          <div class="form-group mb-0">  
            <div class="show-demo"> 
            <img [src]="imageSrc" *ngIf="imageSrc">
          </div>   
          </div>
        </div>     
        <div class="col text-center">
          <button type="submit" class="btn my-2 my-sm-0 text-white theme-bg submit-btn">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>