import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { IndexedDBService } from '../services/indexed-db.service';
import { ApiService } from "../services/api.service";
import { forkJoin, Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  closeResult!: string;
  submittedCategory=false;
  submittedItem=false;
  submittedTemplate=false;
  submittedUnits=false;
  itemUpdatemessage='';
  categoryUpdatemessage='';
  templateUpdatemessage='';
  listUpdatedMessage='';
  unitUpdatedMessage='';
  measurementUnitsForm = new FormGroup({});

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal,private http: HttpClient, private IndexedDBService: IndexedDBService, private apiService: ApiService, public dialogService: DialogService) {
    
    this.IndexedDBService.connectToDB();
   }

  ngOnInit(): void {

    this.measurementUnitsForm = this.formBuilder.group({
      linear_units : new FormControl('cm'),    
      cubic_units : new FormControl('cm'),
    });

    this.IndexedDBService.getDefaultUnits().then((getResponse: any) => {  
      if(getResponse){        
        this.measurementUnitsForm.patchValue({
          linear_units: getResponse.linear_units,       
          cubic_units: getResponse.cubic_units       
        });  
      }else{
        const unitsPayload = {
          linear_units : 'cm',    
          cubic_units : 'cm'             
        }  
        this.IndexedDBService.addMeasurementUnits(unitsPayload).then((response: any) => {                   
          if(response) {
            //this.unitUpdatedMessage = "Measurement units updated";         
          }else{
            //this.unitUpdatedMessage = "Error updating measurement units";
          }
        })
      }
    });

  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }

  latestListDownload(){
    this.submittedItem=true;
    let itemList = this.apiService.getItemList();
    let categoryList = this.apiService.getCategoryList();
    let templateList = this.apiService.getTemplateList();
    return forkJoin([itemList,categoryList, templateList]).subscribe(results => {
      
      this.IndexedDBService.addInventoryItems(results[0].data).then((resItem) => {       
        if(resItem){
          this.itemUpdatemessage="Catalogue items updated";         
        }         
                 
      });
      this.IndexedDBService.addCategories(results[1].data).then((resCategory) => {
        if(resCategory){        
          this.categoryUpdatemessage="Catalogue categories updated"; 
        }
      });
      this.IndexedDBService.addTemplateList(results[2].data).then((resTemplate) => { 
        if(resTemplate){        
          this.templateUpdatemessage="Property templates updated";
        } 
      });      
        this.listUpdatedMessage='Catalogue and templates updated successfully';
        this.submittedItem=false;

      setTimeout(() => {
        this.listUpdatedMessage='';
      }, 4000);

    });  
 
  }

  itemListDownload(){   
    this.submittedItem=true;
    this.apiService.getItemList().subscribe(  
      (res)=>{        
      
      if(res) {
        this.IndexedDBService.addInventoryItems(res.data).then(() => {          
          this.itemUpdatemessage="Catalogue items updated";         
        });
      }else{
        this.itemUpdatemessage="Error updating catalogue items";
      } 
      this.submittedItem=false;
    },
    (err)=>{  
      console.log("fail");
      this.itemUpdatemessage="Error updating catalogue items";
      this.submittedItem=false;     
    });
  }


  CategoryListDownload(){   
    this.submittedCategory=true;
    this.apiService.getCategoryList().subscribe(  
      (res)=>{         
     
      if(res) { 
        this.IndexedDBService.addCategories(res.data).then(() => {          
          this.categoryUpdatemessage="Catalogue categories updated"; 
        });
      }else{
        this.categoryUpdatemessage="Error updating catalogue categories";

      } 
      this.submittedCategory=false; 
    },
    (err)=>{  
      console.log("fail"); 
      this.categoryUpdatemessage="Error updating catalogue categories";
      this.submittedCategory=false;     
    });
  }

  templateListDownload(){   
    this.submittedTemplate=true;
    this.apiService.getTemplateList().subscribe(  
      (res)=>{         
      if(res) {
        this.IndexedDBService.addTemplateList(res.data).then(() => {          
          this.templateUpdatemessage="Property templates updated"; 
        });          
      }else{
        this.templateUpdatemessage="Error updating roperty templates";
      }  
      this.submittedTemplate=false;   
    },
    (err)=>{  
      console.log("fail"); 
      this.templateUpdatemessage="Error updating roperty templates";
      this.submittedTemplate=false;    
    });
  }


  onSubmitUnits() {
    this.submittedUnits = true;
    if (this.measurementUnitsForm.valid) {
      const unitsPayload = {
        linear_units : this.measurementUnitsForm.controls.linear_units.value,    
        cubic_units : this.measurementUnitsForm.controls.cubic_units.value             
      }

      this.IndexedDBService.addMeasurementUnits(unitsPayload).then((response: any) => {                   
        if(response) {
          console.log(response);            
          this.unitUpdatedMessage = "Default measurement units updated";         
        }else{
          this.unitUpdatedMessage = "Error updating measurement units";
        }

        console.log(unitsPayload); 
        this.submittedUnits = false;
        setTimeout(() => {
          this.unitUpdatedMessage='';
        }, 4000);
      });      
    }
  }
  

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {    
      if (this.measurementUnitsForm.dirty && this.submittedUnits) {
          $event.returnValue = true;
      }
  }
  canDeactivate(): Observable<boolean> | boolean {
    if (this.measurementUnitsForm.dirty && this.submittedUnits) {
        return this.dialogService.confirm('You have unsaved changes! If you leave, your changes will be lost.');
    }
    return true;
  }
  

}
